import React from 'react';
import './index.scss'



const Specialist = () => {
    return (

        <div>
            todo...
        </div>

    )
}
export default Specialist;


import { request } from "@/utils"



export function googleOauth2Login(code) {
    return request({
        url: "/user/oauth/googleLogin",
        method: "get",
        params: {
            code: code,
            name: "jack"
        }
    })
}


export function followUser(uid) {
    return request({
        url: "/user/follow",
        method: "get",
        params: {
            uid: uid,
        }
    })
}

export function emailVerify(code){
    return request({
        url: "/user/email/verification",
        method: "get",
        params: {
            code: code,
        }
    })
}

export function register(data){
    return request({
        url: "/user/registerByEmail",
        method: "post",
        data:data
    })
}

export function getCaptchaApi(email) {
    return request({
        url: "/user/getCaptchaCode",
        method: "get",
        params: {
            email: email
        }
    })
}


export function loginAPI (formData) {
    return request({
        url: '/user/login',
        method: 'POST',
        data: formData
    })

}

// 2. 获取用户信息

export function getProfileByEmailAPI (email) {
    return request({
        url: '/user/personal/profile',
        method: 'GET',
        params: {
            email: email,
        },
    })
}


export function getProfileByTokenApi () {
    return request({
        url: '/user/personal/profile',
        method: 'GET',
    })
}


export function getProfileByNameAPI (name) {
    return request({
        url: '/user/getProfileByName',
        method: 'GET',
        params: {
            name: name,
        },
    })
}

export function updateProfile (data){
    return request({
        url: '/user/personal/profile',
        method: 'POST',
        data: data,
    })
}


export function uploadAvatar (file) {
    return request({
        url: '/user/personal/uploadAvatar',
        method: 'POST',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
import {Avatar, Card, List} from "antd";
import {FireFilled} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import "./index.scss"
import {default_avatar_png} from "@/data/data";
import {getRecDataAPI} from "@/apis/question";
import {Link} from "react-router-dom";

const {Meta} = Card;


const TopArticle = () => {

    const [topArticleList, setTopArticleList] = useState([]);

    useEffect(() => {
        getRecDataAPI().then(response => {
            if (response.code === 0) {
                setTopArticleList(response.data);
            }
        }).catch(
            (err) => {
                console.log(err)
            }
        )

    }, [])


    return (
        <div>
            <Card style={{marginBottom: '10px'}}>
                <div style={{marginBottom: '10px', fontSize: '18px'}}>
                    <FireFilled /> <span style={{marginLeft: '10px'}}>Hot Article</span>
                </div>
                <div>
                    <List
                        itemLayout="horizontal"
                        dataSource={topArticleList}
                        renderItem={(item) => (
                            <Meta
                                style={{marginBottom: '10px'}}
                                avatar={<Avatar size={40}
                                                shape={"square"}
                                                src={item.avatar || default_avatar_png}/>}
                                title={
                                    <div className={'ellipsis-text-2'}>
                                        <Link to={'/'}>{item.description}</Link>
                                    </div>}
                            />
                        )}
                    />
                </div>
            </Card>
        </div>
    )
}

export default TopArticle



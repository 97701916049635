
import Header from "@/components/header";
import {Outlet} from "react-router-dom";
import LoginModal from "@/components/login";
const Layout = () => {
    return (

        <div>
            <Header/>
            <Outlet/>
            <LoginModal/>
        </div>

    )
}

export default Layout
import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';



const { Dragger } = Upload;

const UploadFileBox = () => {

    const onChange = (info) => {
        const {status} = info.file;
        if (status !== 'uploading') {
            console.log("uploading---", info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const onDrop = (e) => {
        console.log('Dropped files', e.dataTransfer.files);
    }

    return (
        <Dragger
            name={'file'}
            multiple={false}
            action={'https://greenboat.top/api/upload'}
            onChange={onChange}
            onDrop={onDrop}
            accept=".pdf,.doc,.docx,.jpg,.png" // 限制可上传文件类型
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined/>
            </p>
            <p className="ant-upload-text">Upload your file</p>
            <p className="ant-upload-hint">
                Click to upload or drag and drop
                Supported file: pdf, word, image
            </p>
        </Dragger>
    )
};
export default UploadFileBox;




const About = () => {
    return (
        <div>
            About Page
        </div>
    )
}


export default About
import React from "react";
import {Flex, Timeline} from "antd";
import {ClockCircleOutlined} from "@ant-design/icons";
import "./index.scss"
import moment from "moment";

const PdTimeline = ({post}) => {

    const PriorityDateResp = []

    if (post.post_type !== 3) {
        return null;
    }

    if (post.fileDate) {
        const date = moment.unix(parseInt(post.fileDate)).format("YYYY-MM-DD");
        PriorityDateResp.push({content: `submit case at ${date}`, validate: true})
    }else{
        PriorityDateResp.push({content: `waiting for submitting case`, validate: false})
    }

    if (post.priorityDate) {
        const date =  moment.unix(parseInt(post.priorityDate)).format("YYYY-MM-DD");
        PriorityDateResp.push({content: `priority date is ${date}`, validate: true})
    }else{
        PriorityDateResp.push({content: `waiting for priority date`, validate: false})
    }

    if (post.rfeDate) {
        const date = moment.unix(parseInt(post.rfeDate)).format("YYYY-MM-DD");
        PriorityDateResp.push({content: `receive RFE at ${date}`, validate: true})
    }else{
        PriorityDateResp.push({content: `waiting for result`, validate: false})
    }

    if (post.approveDate) {
        const date = moment.unix(parseInt(post.approveDate)).format("YYYY-MM-DD");
        PriorityDateResp.push({content: `case approved at ${date}`, validate: true})
    }else{
        PriorityDateResp.push({content: `waiting for the result after RFE`, validate: false})
    }


    const item = PriorityDateResp.map(eachDate => {
        if (eachDate.validate) {
            return {
                children: eachDate.content,  // 当 validate 为 true
            };
        } else {
            return {
                dot: <ClockCircleOutlined />,
                color: 'red',
                children: eachDate.content,  // 当 validate 为 false
            };
        }
    });


    return (
        <Flex justify={'center'} align="center" style={{marginTop: 25, width: '100%'}} >
            <Timeline
                mode="alternate"
                items={item}
            />
        </Flex>
    )


}


export default PdTimeline;

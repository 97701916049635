import React from 'react'
import Heading from "@/components/common/heading";
import {Button, Card, Carousel, Col, Flex, Image, Row, Space} from "antd";
import {Features, proceedFlow, LatestPosts, QuickFilingItems, professionalService} from "@/data/nav";
import Footer from "@/components/footer";
import {
    ArrowRightOutlined,
} from "@ant-design/icons";
import './index.scss'


const HomePage = () => {
    return (
        <div>
            <section className='hero'>
                <div className='container8'>
                    <Heading title='Your Immigration Journey Starts Here '
                             subtitle='Track, analyze, and get consultation on your immigration/visa cases.'/>
                    <div className={'container8 flexCenter'}>
                        <Button className='chat-button' href={'/chat'} iconPosition={"end"}
                                icon={<ArrowRightOutlined/>}>
                            Evaluate your case
                        </Button>
                    </div>
                </div>
            </section>


            <section className='backgroundWithColor'>
                <div className='container8'>
                    <Heading title='Typical Petition Flow' subtitle='Helping you every step of the way.'/>
                    <div className='grid4 mtop'>
                        {proceedFlow.map((items, index) => (
                            <Space direction={'vertical'} key={index} align={'center'}>

                                <Image src={items.cover} alt='' preview={false} height={120}/>
                                <p style={{marginTop: '1rem', textAlign: "center", height: '5rem'}}>{items.total}</p>
                                <div className="circle">
                                    <span>{items.step}</span>
                                </div>
                            </Space>
                        ))}
                    </div>

                </div>
            </section>

            {/*网站功能介绍*/}
            <section className={'container6 background'}>
                <Heading title='ALL In One Immigration Platform'></Heading>
                <br/>
                <Row gutter={[32, 32]} justify="center">
                    {Features.map((feature, index) => (
                        <Col xs={24} sm={24} md={12} key={index}>
                            <Space align={"start"}>
                                <div style={{marginRight: '20px'}}>
                                    {feature.icon}
                                </div>
                                <div>
                                    <h3 style={{
                                        fontSize: '20px',
                                        marginBottom: '10px'
                                    }}>{feature.title}</h3>
                                    <p>{feature.content}</p>
                                </div>
                            </Space>
                        </Col>
                    ))}
                </Row>
            </section>

            {/*Quick filing*/}
            <section style={{background: 'linear-gradient(135deg, #3a7bd5, #3a6073)', padding: '50px 0', color: '#fff'}}>
                <div className={'container6'}>
                    <Heading title="Quick Filing"></Heading>
                    <br/>
                    <Row gutter={[32, 32]} justify="center">
                        {QuickFilingItems.map((feature, index) => (
                            <Col xs={24} sm={12} md={12} key={index}>
                                <Space align={"start"}>
                                    <div style={{marginRight: '20px'}}>
                                        {feature.icon}
                                    </div>
                                    <div>
                                        <h3 style={{
                                            color: '#fff',
                                            fontSize: '20px',
                                            marginBottom: '10px'
                                        }}>{feature.title}</h3>
                                        <p style={{color: '#d1d1d1'}}>{feature.content}</p>
                                    </div>
                                </Space>
                            </Col>
                        ))}
                    </Row>
                </div>
            </section>

            {/*Professional Services*/}
            <section className={'backgroundWithColor'}>
                <div className={'container6'}>
                    <Heading title={'Specialist Service'}></Heading>
                    <Row justify={'center'}>
                        {professionalService.map((item, index) => (
                            <Col xs={18} sm={18} md={18} key={index}>
                                <Space size={'large'} >
                                    <Image src={item.icon} height={120} preview={false}></Image>
                                    <p>{item.description}</p>
                                </Space>
                            </Col>
                        ))}
                    </Row>
                </div>
            </section>

            {/* post走马灯  */}
            <section className={'container8 background'}>
                <div className="carousel-container">
                    <h2 className="carousel-title">- Latest Posts -</h2>
                    <Carousel
                        dots={true}
                        infinite={true}
                        autoplay={true}
                        slidesToShow={4}
                        draggable
                        className="custom-carousel"
                    >
                        {LatestPosts.map((item, index) => (
                            <div key={index} className="carousel-card">
                                <Card
                                    hoverable
                                    cover={<img alt={item.title} src={item.image}/>}
                                    className="custom-card"

                                >
                                    <h3>{item.title}</h3>
                                    <p>{item.date}</p>
                                    <p>{item.description}</p>
                                    <Button type="link" icon={<ArrowRightOutlined/>}>Read More</Button>
                                </Card>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section>

            {/*服务使用量统计*/}
            <div className={'rate mtop'}>

            </div>

            <Footer/>
        </div>
    )
}

export default HomePage




import Title from "antd/es/typography/Title";
import {Divider, Form, Input, Select} from "antd";
import {Field} from "@/data/data";
import React from "react";


const ResearchAwards = () => {


    return (
        <div>
            <div>
                <Title level={3}>Research & Awards</Title>
            </div>
            <Divider/>
            <Form.Item name="research" label='Research Field'>
                <Select>
                    {Field.map((item, index) => (
                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item name='interest' label='Research Interest'>
                <Input.TextArea rows={5}></Input.TextArea>
            </Form.Item>

            <Form.Item name='scholarURL' label='Google Scholar Url'>
                <Input />
            </Form.Item>
            <Title level={4}>Unlisted Articles</Title>
            <p>Articles Not Indexed on Google Scholar</p>
            <Divider/>
        </div>
    )
}

export default ResearchAwards;
import React, {useEffect} from "react"
import {Link} from "react-router-dom"
import {Avatar} from "antd"
import {useDispatch, useSelector} from "react-redux"
import { setUserInfo} from "@/store/modules/user";
import {getProfileByTokenApi} from "@/apis/user";
import {isEmptyObj} from "@/utils";
import {default_avatar_png} from "@/data/data";


const HeaderAvatar = () => {

    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.user.userInfo)

    useEffect(() => {
        if (isEmptyObj(userInfo)) {
            getProfileByTokenApi().then((res) => {
                    dispatch(setUserInfo(res.data))
                }
            ).catch(error => {
                console.log(error)
            })
        }
    }, [])

    return (
            <Link to={'/user'}>
                <Avatar shape="square"
                        src={userInfo?.avatar || default_avatar_png}
                        alt='failed'/>
            </Link>
    );
}

export default HeaderAvatar;

import React, {useState} from 'react';
import {DesktopOutlined, HomeOutlined, UserOutlined} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import {Outlet} from "react-router-dom";
const {Content, Sider } = Layout;



const items = [
    {
        key: 1,
        label: 'Dashboard',
        icon: <HomeOutlined />,
    },
    {
        key: 2,
        label: 'Account',
        icon: <UserOutlined />,
    },
    {
        key: 3,
        label: 'Help',
        icon: <DesktopOutlined />,
    },
];
const QuickFiling = () => {

    const [current, setCurrent] = useState(1);
    const onClick = (e) => {
        setCurrent(e.key);
    };

    return (
        <Layout
            style={{
                minHeight: '93vh',
            }}
        >
            <Sider theme="light" >
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="inline"
                    items={items}
                />
            </Sider>
            <Layout>
                <Content style={{margin: '10px 16px'}}>
                    <Outlet/>
                </Content>
            </Layout>
        </Layout>
    );
};
export default QuickFiling;


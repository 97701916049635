import React, {useEffect} from "react"
import LOGO from "@/assets/logo/greenboat.png"
import HeaderAvatar from "@/components/avatar"
import {Link,  useNavigate} from "react-router-dom"
import {Button, Image, Space} from "antd";
import {nav} from "@/data/nav";
import {useSelector, useDispatch} from "react-redux";
import "./index.css"
import {googleOauth2Login} from "@/apis/user";
import {setShowLoginModal, setToken, setUserInfo} from "@/store/modules/user";
import Logout from "@/components/logout";

const Header = () => {

    const isLogin = useSelector(state => state.user.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            // 请求后端服务获取 access_token 和用户信息
            googleOauth2Login(code)
                .then(response => {
                    if (response.code === 0) {
                        dispatch(setToken(response.data.token));
                    }
                    dispatch(setUserInfo(response.data));
                    // 刷新页面以显示更新后的内容
                    navigate("/")
                })
                .catch(error => {
                    console.error('Login failed:', error);
                });
        }
    }, [dispatch]);


    return (
        <div className='header'>
            <div className={'flexCenter'}>
                <div style={{marginRight: '4rem'}}>
                    <Link to={"/"}><Image src={LOGO} alt='' style={{width: '160px', height: 'auto'}}
                                          preview={false}/></Link>
                </div>
                <Space size={"large"}>
                    {nav.map((item, index) => (
                        <Link key={index} to={item.path}>{item.text}</Link>))}
                </Space>

                <div style={{marginLeft: '9rem'}}>
                    {isLogin ? <span>
                                    <HeaderAvatar/> <Logout/>
                                </span> : <Button shape={'round'}
                                                  onClick={() => {dispatch(setShowLoginModal(true))}}>
                                                Login
                                            </Button>}
                </div>
            </div>
        </div>)
}
export default Header
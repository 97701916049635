import React, {useState, useEffect} from 'react';
import {Button, Flex, Form, Input, message} from 'antd';
import {getCaptchaApi, register} from '@/apis/user'
import {useDispatch} from "react-redux";
import {setShowLoginModal, setToken, setUserInfo} from "@/store/modules/user";
import {CodepenOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";





const RegisterForm = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(false);
    const [timer, setTimer] = useState(0);

    const getCaptcha = () => {
        const email = form.getFieldValue('email');
        if (!email) {
            message.error("please enter a valid email");
            return;
        }
        getCaptchaApi(email).then(
            response => {
                console.log(response)
                if (response.code === 0) {
                    setDisabled(true);
                    setTimer(60);  // 禁用按钮60秒
                }else{
                    throw new Error("getCaptcha error");
                }
            }
        ).catch(
            error => {
                message.error("getCaptcha error");
                console.log(error);
            }
        )
    }

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(timer => timer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setDisabled(false);
        }
    }, [timer]);

    const onFinish = (value) => {
        register(value)
            .then((response) => {
                if (response.code === 0) {
                    dispatch(setToken(response.data.token))
                    dispatch(setUserInfo(response.data))
                    dispatch(setShowLoginModal(false))
                    navigate(location.pathname)
                } else {
                    throw new Error(response.msg);
                }
            })
            .catch((error) => {
                console.log(error)
                message.error("Register Failed, please try again later!")
            });
    };


    return (
        <Flex align={'center'} justify={'center'} vertical={true}>
            <Form
                style={{width:'60%',}}
                form={form}
                onFinish={onFinish}
                name="register-form"
            >

                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size="large" prefix={<UserOutlined/>} placeholder="Email"/>
                </Form.Item>


                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input.Password size="large" prefix={<LockOutlined/>} placeholder="Password"/>
                </Form.Item>

                <Form.Item
                    name="captcha_code"
                >
                    <Input size="large" placeholder="Captcha" prefix={<CodepenOutlined />}
                           suffix={
                                <Button disabled={disabled} type={'text'} size={'small'} onClick={getCaptcha} >
                                    {disabled ? timer : 'Get'}
                                </Button>}
                    />
                </Form.Item>
                <Button block={true} style={{marginBottom: '5px'}} type="primary" htmlType="submit">
                    Sign up
                </Button>
            </Form>
        </Flex>
    )
}


export default RegisterForm
import React, {useState, useEffect} from 'react';
import { Button, List} from 'antd';
import {getCommentPageApi} from "@/apis/question";
import WriteComment from "@/components/communityComp/comment/writeComment";
import EachComment from "@/components/communityComp/comment/commentItem";


const Comments = ({qid}) => {


    const [page, setPage] = useState(1);
    const [comments, setComments] = useState([]);
    const [cond, setCond] = useState('recent');
    const [total, setTotal] = useState(0);


    const onLoadMore = () => {
        setPage((prev) => prev + 1)
    }

    useEffect(() => {
        getCommentPageApi(qid, page, 5,'', cond).then(response => {
            if (response.code === 0) {
                setTotal(response.data.total);
                setComments(prev => [...prev, ...response.data.comments]);
            } else {
                throw new Error('get Comment failed')
            }
        }).catch(
            (err) => {
                console.log(err);
            }
        )
    }, [qid, page, cond]);

    const loadMore = (
          <div style={{textAlign: 'center', marginTop: 12, lineHeight: '32px'}}>
            <Button type={'text'} onClick={onLoadMore}>Loading more</Button>
        </div>
    )

    const updateCommentItem =(newCommentItem) => {
        setComments(prev => [...prev, newCommentItem])
    }

    return (
        <div style={{marginTop: '25px'}}>
            <WriteComment qid={qid} updateCommentItem={updateCommentItem}/>
            <List
                split={false}
                className="comment-list"
                itemLayout="horizontal"
                loadMore={total > comments.length && loadMore}
                dataSource={comments}
                renderItem={item => (<EachComment qid={qid} key={item.id} item={item}/>)}
            />
        </div>
    );
}
export default Comments;

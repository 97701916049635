import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css"
import "normalize.css"
import {RouterProvider} from "react-router-dom";
import {Provider} from "react-redux";
import store from './store'
import AppRoutes from "@/router";
import {App, ConfigProvider} from "antd";
import {green} from '@ant-design/colors';


ConfigProvider.config({
    holderRender: (children) => (

        <ConfigProvider >
            <App
                message={{
                    maxCount: 1,
                    top: 160,
                }}
            >
                {children}
            </App>
        </ConfigProvider>

    ),
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: green[6], // 全局主色
                },
            }}
        >
            <RouterProvider router={AppRoutes}/>
        </ConfigProvider>
    </Provider>
);



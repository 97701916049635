import React from 'react';
import {Typography, Tabs, Modal} from 'antd';
import LoginForm from "src/components/login/loginForm";
import RegisterForm from "src/components/login/registerForm";
import {useDispatch, useSelector} from "react-redux";
import {setShowLoginModal} from "@/store/modules/user";


const {Title} = Typography


const LoginModal = () => {

    const dispatch = useDispatch()
    const showLoginModal = useSelector(state => state.user.showLoginModal);

    const items = [
        {
            label: (<h3>Login</h3>),
            key: '1',
            children: <LoginForm />
        },
        {
            label: (<h3>Register</h3>),
            key: '2',
            children: <RegisterForm />
        },
    ]
    return (

        <Modal
            footer={null}
            open={showLoginModal}
            onCancel={() => {dispatch(setShowLoginModal(false))}}
        >
            <div style={{height: '65vh'}}>
                <Title style={{color: 'green', textAlign: 'center'}}>GreenBoat</Title>
                <Tabs defaultActiveKey="1" items={items}/>
            </div>
        </Modal>

    );
};
export default LoginModal;




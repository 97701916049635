import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Steps } from 'antd';
import './index.scss';
import ProfileInfoStepForm from "@/components/quickfilingComp/personalInfo";
import EduEmployStepForm from "@/components/quickfilingComp/eduEmployForm";
import ResearchAwards from "@/components/quickfilingComp/researchAwards";
import ExternalRecAdoption from "@/components/quickfilingComp/external";

const {Step} = Steps;



const getSteps = (form) => {

    return  [
        {
            title: 'Personal Information',
            key: 'personalInformation',
            content: (
                <ProfileInfoStepForm />
            ),
        },
        {
            title: 'Education and Employment History',
            key: 'eduEmployHistory',
            content:
                <EduEmployStepForm form={form}/>
        },
        {
            title: 'Research & Awards',
            key: 'researchAndAwards',
            content: <ResearchAwards />
        },
        {
            title: 'External Recognition & Adoption',
            key: 'externalRecognition',
            content: <ExternalRecAdoption />
        },
    ];

}


const ProfileStepForm = () => {

    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();

    const steps = getSteps(form);
    // 当页面加载时，从 localStorage 中加载表单数据
    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('quickfiling-formData')) || {};
        const stepKey = steps[current].key;
        if (savedData[stepKey]) {
            form.setFieldsValue(savedData[stepKey]);
        }
    }, [current, form]);

    // 保存当前步骤的数据到 localStorage
    const saveCurrentStepData =  () => {
        try {
            // const values = await form.validateFields();
            // await form.validateFields();
            const values = form.getFieldsValue();
            const savedData = JSON.parse(localStorage.getItem('quickfiling-formData')) || {};
            const stepKey = steps[current].key;
            localStorage.setItem('quickfiling-formData', JSON.stringify({ ...savedData, [stepKey]: values }));
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };

    const next = async () => {
        try {
            await form.validateFields();
            // 保存当前步骤的数据
            saveCurrentStepData();
            setCurrent(current + 1);
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };


    const handleSubmit = async () => {
        const isValid =  saveCurrentStepData();
        if (!isValid) return;
        const savedData = JSON.parse(localStorage.getItem('quickfiling-formData')) || {};
        console.log('Final data:', savedData); // 可以在此查看所有步骤的数据
    };

    return (
        <div className="step-form-container">
            <div className="progress-bar">
                <Steps direction="vertical" current={current} >
                    {steps.map((item, index) => (
                        <Step
                            key={index}
                            title={item.title}
                            className={current >= index ? 'step-completed' : 'step-pending'}
                        />
                    ))}
                </Steps>
            </div>
            <div className="form-content">
                <Form form={form} layout="vertical">
                    <div>{steps[current].content}</div>
                    <div className="form-navigation">
                        {current > 0 && (
                            <Button style={{margin: '0 8px'}} onClick={prev}>
                                上一步
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={next}>
                                下一步
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={handleSubmit}>
                                提交
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ProfileStepForm;

import {request} from "@/utils";




export async function checkCaseApi(caseNum) {
    return request({
        url: "/checkCaseStatus",
        method: "GET",
        params: {
            caseNum: caseNum,
        }
    })
}



export async function addCommentApi(data) {
    return request({
        url: `/comments/add`,
        method: "POST",
        data: data
    });
}



export async function getCommentPageApi(qid, page, pageSize, comment_id= '', cond = 'recent') {
    return request({
        url: `/comments/getCommentsPage`,
        method: "GET",
        params: {
            object_id: qid,
            page: page,
            cond: cond,
            comment_id: comment_id,
            page_size: pageSize
        }
    });
}


export async function checkLikedApi(qid) {
    return request({
        url: `/post/checkLiked`,
        method: "GET",
        params: {
            question_id: qid
        }
    });
}

export async function checkSavedApi(id) {
    return request({
        url: `/collection/checkSaved`,
        method: "GET",
        params: {
            object_id: id
        }
    })
}



export async function getPersonalCollection(page, pageSize) {
    return request({
        // url: '/post/saved',
        url: '/post/getPersonalCollectionPost',
        method: 'GET',
        params : {
            page: page,
            pageSize: pageSize,
        }
    })
}

export async function savePostItem(data) {
    return request({
        url: '/collection/save',
        method: 'POST',
        data: data,
    })
}



export async function getQuestionLikesCount(data) {
    return request({
        url: `/post/getLikesCount`,
        method: 'GET',
        params: {
            question_id: data.question_id,
            author_id : data.author_id,
        }
    })
}

export async function addQuestionLikes(data) {
    return request({
        url: `/post/likes`,
        method: 'POST',
        data: data
    })
}



export function addQuestion(data) {
    return request({
        url: '/post/addPost',
        method: 'POST',
        data: data
    })
}

export function getQuestionAPI(page, pageSize, cond) {
    return request({
        url: '/post/getPostByPage',
        method: 'GET',
        params : {
            page: page,
            pageSize: pageSize,
            cond: cond,
        }
    })
}

export  function getPersonalQuestionsAPI(page, pageSize) {
    return request({
        url: '/post/getPersonalPost',
        method: 'GET',
        params : {
            page: page,
            pageSize: pageSize,
            cond: "newest",
        }
    })

}


export function getRecDataAPI() {
    return request({
        url: '/user/getHotUsers',
        method: 'GET'
    })
}



import React, {useState} from "react";
import {addCommentApi} from "@/apis/question";
import {Avatar, Button, Flex, Input, Popover} from "antd";
import {default_avatar_png} from "@/data/data";
import Picker from "@emoji-mart/react";
import {SmileOutlined} from "@ant-design/icons";
import {emojiCountStrategy, customExceedFormatter} from '@/utils'

const WriteComment = ({qid , item = null, updateCommentItem}) => {

    const [inputValue, setInputValue] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const createComment = () => {

        if (!inputValue.trim()) {
            return
        }

        let newComment = {
            reply_comment_id: '',
            original_text: inputValue.trim(),
            object_id: qid,
            reply_user_id: item?.user_id || '',
        };
        if (item != null ) {
            newComment.reply_comment_id = item.reply_comment_id === ''?item.comment_id:item.reply_comment_id;
        }
        addCommentApi(newComment).then(response => {
            if (response.code === 0) {
                setInputValue('');
                if (updateCommentItem) {
                    updateCommentItem(response.data)
                }
            }
        }).catch(
            (err) => {
                console.log(err);
            }
        )
    };

    const handleEmojiSelect = (emoji) => {
        setInputValue(inputValue + emoji.native);
        setShowEmojiPicker(false);
    };



    return (

        <Flex style={{marginBottom: 10, marginTop: 5}}>
            <Avatar src={default_avatar_png} alt="User"/>
            <div style={{marginLeft: 10, flex: 1}}>
                <Input.TextArea style={{borderRadius: 20}}
                                autoSize={{ minRows: 1, maxRows: 4 }}
                                maxLength={150}
                                count={{
                                    strategy: emojiCountStrategy,
                                    show: ({value, count, maxLength}) => (
                                        <span>{count}/{maxLength} </span>
                                    ),
                                    exceedFormatter: customExceedFormatter,
                                }}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder="add comment..."
                />
                <Flex justify={'start'} align={'center'}>
                    <Button type="primary" size={'small'} shape={'round'}
                            onClick={() => createComment()}>
                        Reply
                    </Button>
                    <Popover
                        content={<Picker set="apple" onEmojiSelect={handleEmojiSelect} />}
                        placement="bottom"
                        open={showEmojiPicker}
                    >
                        <Button type={"text"} onClick={()=>setShowEmojiPicker(!showEmojiPicker)} icon={<SmileOutlined />} />
                    </Popover>
                </Flex>
            </div>
        </Flex>

    )
}

export default WriteComment;
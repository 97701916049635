import React from 'react';
import {useQuill} from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import './index.scss';
import {Button, Flex, Input,  notification} from "antd";
import {addQuestion} from "@/apis/question";
import {useNavigate} from "react-router-dom";

const EditorWithQuill = () => {


        const [api, contextHolder] = notification.useNotification();
        const [title, setTitle] = React.useState('');
        const {quill, quillRef, Quill} = useQuill({
            modules: {
                toolbar: {
                    container: '#toolbar', // 使用工具栏的容器 ID
                }, blotFormatter: {},
            },
        });
        const navigate = useNavigate()
        const openNotification = (message) => {
            api.error({
                message: message,
                description:"",
                placement:'bottomRight'
            });
        };

        if (Quill && !quill) {
            Quill.register('modules/blotFormatter', BlotFormatter);
        }
        const handleGetContent = () => {
            if (quill) {
                // const content = quill.getContents();  // 获取json格式的内容
                // const text = quill.getText().trim();  // 获取纯文本内容并去除前后空格
                const htmlContent = quill.root.innerHTML;    // 获取HTML格式内容
                if (!htmlContent || !title) {
                    openNotification("A title and body text are required to publish your article.")
                    return;
                }
                const data = {
                    title: title,
                    content: htmlContent,
                    post_type: 1
                }
                addQuestion(data).then( (response) => {
                    if ( response.code === 0) {
                        setTitle('')
                        navigate('/community')
                    }else{
                        openNotification("A title and body text are required to publish your article.")
                    }
                }).catch(
                    err => {
                        openNotification("An error occurred while publishing your article.")
                        console.log(err)
                    }
                )
            }
        }


        return (

            <div className={'editor-container'}>
                {contextHolder}
                <Flex justify={'space-between'} align={'center'}>
                    <div id="toolbar">
                        <select className="ql-header" defaultValue="">
                            <option value="1"/>
                            <option value="2"/>
                            <option value=""/>
                        </select>
                        <button className="ql-bold"/>
                        <button className="ql-italic"/>
                        <button className="ql-underline"/>
                        <button className="ql-strike"/>
                        <select className="ql-color"/>
                        <select className="ql-background"/>
                        <button className="ql-link"/>
                        <button className="ql-image"/>
                        <button className="ql-clean"/>
                    </div>
                    <Button type={'primary'} onClick={handleGetContent}> submit</Button>
                </Flex>
                <div style={{marginTop: '15px'}}>
                    <Input placeholder='Article Title'
                           value={title}
                           onChange={(e) => setTitle(e.target.value)}></Input>
                </div>
                <div ref={quillRef}/>
            </div>);
    }
;


export default EditorWithQuill;


import {Avatar, Button, Card, Flex} from "antd";
import "./index.scss"
import React, {useEffect} from "react";
import SideBar from "src/components/communityComp/siderbar";
import {useDispatch, useSelector} from "react-redux";
import {getProfileByTokenApi} from "@/apis/user";
import {setUserInfo} from "@/store/modules/user";
import {useNavigate} from "react-router-dom";
import {isEmptyObj} from "@/utils";


const Profile = () => {

    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.user.userInfo)
    const navigate = useNavigate()
    useEffect(() => {
        if (isEmptyObj(userInfo)) {
            getProfileByTokenApi().then(
                res => {
                    dispatch(setUserInfo(res.data))
                }
            ).catch(error => {
                console.log(error)
            })
        }
    }, [])

    const clickProfile = () => {
        navigate('/user', {state: userInfo})
    }

    return (
        <div>
            <Card >
                <Flex style={{marginBottom: "10px"}} vertical={true} align={'center'}>
                    <Flex vertical={true} align={'center'}>
                        <div>
                            <Avatar
                                src={userInfo?.avatar || ""}
                                size={80}
                                shape="square"
                                alt={"please login"}
                            />
                        </div>
                        <h2>{userInfo?.username || ""}</h2>
                        <div className={'ellipsis-text-2'} style={{maxWidth: '10rem'}}>{userInfo?.description}</div>
                    </Flex>
                    <div style={{marginTop: 16}}>
                        <Button type="primary" block onClick={clickProfile}>My Profile</Button>
                    </div>
                </Flex>
            </Card>
            <Card hoverable={true} style={{marginTop: 16}}>
                <SideBar/>
            </Card>
        </div>
    )
}


export default Profile
import React from 'react';



const QuickFilingAccount = () => {

    return (
        <div>
            account----
        </div>
    );
};
export default QuickFilingAccount;
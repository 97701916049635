import React, {useState} from 'react';
import {Button, Divider, Flex, Input, notification, Popover} from 'antd';
import Picker from "@emoji-mart/react";
import {SmileOutlined} from "@ant-design/icons";
import {emojiCountStrategy, customExceedFormatter} from '@/utils'
import {addQuestion} from "@/apis/question";
import {useNavigate} from "react-router-dom";


const {TextArea } = Input



const StartPost = ({setOpen}) => {
    const [inputValue, setInputValue] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()


    const handleEmojiSelect = (emoji) => {
        setInputValue(inputValue + emoji.native);
        setShowEmojiPicker(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const openNotification = (message) => {
        api.error({
            message: message,
            description:"",
            placement:'bottomRight'
        });
    };

    const submitPost =() => {

        const data = {
            content: inputValue,
            post_type: 2
        }
        addQuestion(data).then( (response) => {
            if ( response.code === 0) {
                setInputValue('')
                setOpen(false)
                navigate('/community')
            }else{
                throw new Error(response.msg)
            }
        }).catch(
            err => {
                openNotification("An error occurred while publishing your post.")
                console.log(err)
            }
        )

    }

    return (
        <>
            <div>
                {contextHolder}
                <TextArea
                    style={{fontSize: 18}}
                    maxLength={150}
                    autoSize={{ minRows: 12, maxRows: 12 }}
                    placeholder="What do you want to talk about?"
                    variant={'borderless'}
                    value={inputValue}
                    onChange={handleInputChange}
                    count={{
                        strategy: emojiCountStrategy,
                        show: ({value, count, maxLength}) => (
                            <span>{count}/{maxLength} </span>
                        ),
                        exceedFormatter: customExceedFormatter,
                    }}
                />
                <Divider />
                <Flex justify={'space-between'}>
                    <div>
                        <Popover
                            content={<Picker set="apple" onEmojiSelect={handleEmojiSelect} style={{width: '300vw',height: '300vh'}} />}
                            placement="top"
                            open={showEmojiPicker}
                        >
                            <Button type={"text"} onClick={()=>setShowEmojiPicker(!showEmojiPicker)} icon={<SmileOutlined />} />
                        </Popover>
                        {/*<Button type={"text"} size={"middle"} icon={<PictureOutlined />}> </Button>*/}
                    </div>
                    <Button type={"primary"} shape={"round"} disabled={inputValue.trim()===''} onClick={submitPost}> Post </Button>
                </Flex>
            </div>


        </>
    )
};
export default StartPost;
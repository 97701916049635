import footlogo from "@/assets/logo/greenboat.png";
import {footer} from "@/data/nav";
import React from "react";
import "./index.css"
import {Button, Image, Input} from "antd";


function Footer() {
    return (
        <div>
            <div className={'footer'}>
                <div className='footer-container'>
                    <div>
                        <div className='logo'>
                            <Image src={footlogo} alt='' width={250} preview={false}/>
                            <h2>Do You Need Help With Anything?</h2>
                            <p>Receive updates, hot deals, tutorials, discounts sent in your inbox every month</p>
                            <div className='flexBetween'>
                                <Input size={"small"} type='text' placeholder='Email Address'/>
                                <Button type={"primary"} size={"large"}>Subscribe</Button>
                            </div>
                        </div>
                    </div>

                    {footer.map((val) => (
                        <div key={val.index}>
                            <h3>{val.title}</h3>
                            <ul>
                                {val.text.map((items) => (
                                    <li key={ items.id}> {items.list} </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className='legal'>
                <span>© 2021 RentUP. Designd By GorkCoder.</span>
            </div>
        </div>
    )
}

export default Footer
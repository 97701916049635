import React from 'react';
import {Button, DatePicker, Flex, Form, Select} from 'antd';
import moment from "moment";
import {addQuestion} from "@/apis/question";


const options = [
    {value: 'niw', label: 'NIW'},
    {value: 'eb1a', label: 'EB1A'},
];


const PriorityDate = ({setOpen}) => {

    const [pdForm] = Form.useForm();

    const onFinish = (values) => {

        const data = {
            fileType: values.fileType[0],
            fileDate: values.fileDate ? String(moment(values.fileDate).unix()) : null,
            priority: values.priorityDate ? String(moment(values.priorityDate).unix()) : null,
            rfe: values.rfeDate ? String(moment(values.rfeDate).unix()) : null,
            approved: values.approveDate ? String(moment(values.approveDate).unix()) : null,
            post_type: 3,
        }
        addQuestion(data).then((response) => {
            if (response.code === 0) {
                pdForm.resetFields();  // 清空表单
                setOpen(false)
            } else {
                throw new Error("post failed");
            }
        }).catch(
            err => {
                console.log(err);
            }
        )
    };


    return (
        <>
            <div>
                <div className={'flexCenter'} style={{width: '100%'}}>
                    <Form
                        form={pdForm}
                        onFinish={onFinish}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 15,
                        }}
                        labelAlign={'left'}
                        layout="horizontal"
                        size={'large'}
                        style={{width: '70%'}}
                    >
                        <Form.Item label="FileType" name='fileType' rules={[
                            {
                                required: true,
                                message: 'Please input your FileType!',
                            },
                        ]}>
                            <Select
                                mode="tags"
                                maxCount={1}
                                style={{
                                    width: '63%',
                                }}
                                maxTagCount={1}
                                placeholder="select or type"
                                // onChange={handleChange}
                                options={options}
                            />
                        </Form.Item>
                        <Form.Item label="FileDate" name="fileDate" rules={[
                            {
                                required: true,
                                message: 'Please input your FileDate!',
                            },
                        ]}>
                            <DatePicker/>
                        </Form.Item>
                        <Form.Item label="PriorityDate" name="priorityDate">
                            <DatePicker/>
                        </Form.Item>
                        <Form.Item label="RFE" name="rfeDate">
                            <DatePicker/>
                        </Form.Item>
                        <Form.Item label="Approved" name="approveDate">
                            <DatePicker/>
                        </Form.Item>
                        <Flex justify={'end'}>
                            <Button type={"primary"} htmlType="submit"> Post </Button>
                        </Flex>
                    </Form>

                </div>

            </div>


        </>
    )
};
export default PriorityDate;
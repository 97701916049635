// export const default_avatar_png = "https://lawyer-niweb1a.oss-us-west-1.aliyuncs.com/2.png"
export const default_avatar_png = "https://lawyer-niweb1a.oss-us-west-1.aliyuncs.com/WX20240812-155920.png"


export const Field = [
    {
        name: 'Agricultural Sciences',
        value: 0
    },
    {
        name: 'Biology & Biochemistry',
        value: 1
    },
    {
        name: 'Chemistry',
        value: 2
    },
    {
        name: 'Clinical Medicine',
        value: 4
    },
    {
        name: 'Computer Science',
        value: 5
    },
    {
        name: 'Economics & Business',
        value: 6
    },
    {
        name: 'Engineering',
        value: 7
    },
    {
        name: 'Environment/Ecology',
        value: 8
    },
    {
        name: 'Geosciences',
        value: 9
    },
    {
        name: 'Immunology',
        value: 10
    }, {
        name: 'Materials Science',
        value: 11
    }, {
        name: 'Mathematics',
        value: 12
    }, {
        name: 'Microbiology',
        value: 13
    }, {
        name: 'Molecular Biology & Genetics',
        value: 14
    },
    {
        name: 'Psychiatry/Psychology',
        value: 15
    },
    {
        name: 'Social Sciences & General',
        value: 16
    },
    {
        name: 'Space Science',
        value: 17
    },
    {
        name: 'Plant & Animal Science',
        value: 18
    },
    {
        name: 'Multidisciplinary',
        value: 19
    },
    {
        name: 'Neuroscience & Behavior',
        value: 20
    },
    {
        name: 'Pharmacology & Toxicology',
        value: 21
    },
    {
        name: 'Physics',
        value: 22
    },
    {
        name: 'Others',
        value: 23
    },

]


export const CITYLIST = [
    {
        id: "35",
        label: "北京市",
        value: "110000",
        children: [
            {
                id: "1022",
                label: "市辖区",
                value: "110100",
                children: [
                    {
                        id: "44",
                        label: "东城区",
                        value: "110101",
                        children: []
                    },
                    {
                        id: "45",
                        label: "西城区",
                        value: "110102",
                        children: []
                    },
                    {
                        id: "46",
                        label: "朝阳区",
                        value: "110105",
                        children: []
                    },
                    {
                        id: "47",
                        label: "丰台区",
                        value: "110106",
                        children: []
                    },
                    {
                        id: "48",
                        label: "石景山区",
                        value: "110107",
                        children: []
                    },
                    {
                        id: "49",
                        label: "海淀区",
                        value: "110108",
                        children: []
                    },
                    {
                        id: "50",
                        label: "门头沟区",
                        value: "110109",
                        children: []
                    },
                    {
                        id: "51",
                        label: "房山区",
                        value: "110111",
                        children: []
                    },
                    {
                        id: "52",
                        label: "通州区",
                        value: "110112",
                        children: []
                    },
                    {
                        id: "53",
                        label: "顺义区",
                        value: "110113",
                        children: []
                    },
                    {
                        id: "54",
                        label: "昌平区",
                        value: "110114",
                        children: []
                    },
                    {
                        id: "55",
                        label: "大兴区",
                        value: "110115",
                        children: []
                    },
                    {
                        id: "56",
                        label: "怀柔区",
                        value: "110116",
                        children: []
                    },
                    {
                        id: "57",
                        label: "平谷区",
                        value: "110117",
                        children: []
                    }
                ]
            },
            {
                id: "1023",
                label: "县",
                value: "110200",
                children: [
                    {
                        id: "58",
                        label: "密云县",
                        value: "110228",
                        children: []
                    },
                    {
                        id: "59",
                        label: "延庆县",
                        value: "110229",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "1",
        label: "上海市",
        value: "310000",
        children: [
            {
                id: "1096",
                label: "市辖区",
                value: "310100",
                children: [
                    {
                        id: "825",
                        label: "黄浦区",
                        value: "310101",
                        children: []
                    },
                    {
                        id: "826",
                        label: "卢湾区",
                        value: "310103",
                        children: []
                    },
                    {
                        id: "827",
                        label: "徐汇区",
                        value: "310104",
                        children: []
                    },
                    {
                        id: "828",
                        label: "长宁区",
                        value: "310105",
                        children: []
                    },
                    {
                        id: "829",
                        label: "静安区",
                        value: "310106",
                        children: []
                    },
                    {
                        id: "830",
                        label: "普陀区",
                        value: "310107",
                        children: []
                    },
                    {
                        id: "831",
                        label: "闸北区",
                        value: "310108",
                        children: []
                    },
                    {
                        id: "832",
                        label: "虹口区",
                        value: "310109",
                        children: []
                    },
                    {
                        id: "833",
                        label: "杨浦区",
                        value: "310110",
                        children: []
                    },
                    {
                        id: "834",
                        label: "闵行区",
                        value: "310112",
                        children: []
                    },
                    {
                        id: "835",
                        label: "宝山区",
                        value: "310113",
                        children: []
                    },
                    {
                        id: "836",
                        label: "嘉定区",
                        value: "310114",
                        children: []
                    },
                    {
                        id: "837",
                        label: "浦东新区",
                        value: "310115",
                        children: []
                    },
                    {
                        id: "838",
                        label: "金山区",
                        value: "310116",
                        children: []
                    },
                    {
                        id: "839",
                        label: "松江区",
                        value: "310117",
                        children: []
                    },
                    {
                        id: "840",
                        label: "青浦区",
                        value: "310118",
                        children: []
                    },
                    {
                        id: "841",
                        label: "奉贤区",
                        value: "310120",
                        children: []
                    }
                ]
            },
            {
                id: "1097",
                label: "县",
                value: "310200",
                children: [
                    {
                        id: "842",
                        label: "崇明县",
                        value: "310230",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "2",
        label: "天津市",
        value: "120000",
        children: [
            {
                id: "1024",
                label: "市辖区",
                value: "120100",
                children: [
                    {
                        id: "60",
                        label: "和平区",
                        value: "120101",
                        children: []
                    },
                    {
                        id: "61",
                        label: "河东区",
                        value: "120102",
                        children: []
                    },
                    {
                        id: "62",
                        label: "河西区",
                        value: "120103",
                        children: []
                    },
                    {
                        id: "63",
                        label: "南开区",
                        value: "120104",
                        children: []
                    },
                    {
                        id: "64",
                        label: "河北区",
                        value: "120105",
                        children: []
                    },
                    {
                        id: "65",
                        label: "红桥区",
                        value: "120106",
                        children: []
                    },
                    {
                        id: "66",
                        label: "东丽区",
                        value: "120110",
                        children: []
                    },
                    {
                        id: "67",
                        label: "西青区",
                        value: "120111",
                        children: []
                    },
                    {
                        id: "68",
                        label: "津南区",
                        value: "120112",
                        children: []
                    },
                    {
                        id: "69",
                        label: "北辰区",
                        value: "120113",
                        children: []
                    },
                    {
                        id: "70",
                        label: "武清区",
                        value: "120114",
                        children: []
                    },
                    {
                        id: "71",
                        label: "宝坻区",
                        value: "120115",
                        children: []
                    },
                    {
                        id: "72",
                        label: "滨海新区",
                        value: "120116",
                        children: []
                    }
                ]
            },
            {
                id: "1025",
                label: "县",
                value: "120200",
                children: [
                    {
                        id: "73",
                        label: "宁河县",
                        value: "120221",
                        children: []
                    },
                    {
                        id: "74",
                        label: "静海县",
                        value: "120223",
                        children: []
                    },
                    {
                        id: "75",
                        label: "蓟县",
                        value: "120225",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "3",
        label: "重庆市",
        value: "500000",
        children: [
            {
                id: "1260",
                label: "市辖区",
                value: "500100",
                children: [
                    {
                        id: "2261",
                        label: "万州区",
                        value: "500101",
                        children: []
                    },
                    {
                        id: "2262",
                        label: "涪陵区",
                        value: "500102",
                        children: []
                    },
                    {
                        id: "2263",
                        label: "渝中区",
                        value: "500103",
                        children: []
                    },
                    {
                        id: "2264",
                        label: "大渡口区",
                        value: "500104",
                        children: []
                    },
                    {
                        id: "2265",
                        label: "江北区",
                        value: "500105",
                        children: []
                    },
                    {
                        id: "2266",
                        label: "沙坪坝区",
                        value: "500106",
                        children: []
                    },
                    {
                        id: "2267",
                        label: "九龙坡区",
                        value: "500107",
                        children: []
                    },
                    {
                        id: "2268",
                        label: "南岸区",
                        value: "500108",
                        children: []
                    },
                    {
                        id: "2269",
                        label: "北碚区",
                        value: "500109",
                        children: []
                    },
                    {
                        id: "2270",
                        label: "万盛区",
                        value: "500110",
                        children: []
                    },
                    {
                        id: "2271",
                        label: "双桥区",
                        value: "500111",
                        children: []
                    },
                    {
                        id: "2272",
                        label: "渝北区",
                        value: "500112",
                        children: []
                    },
                    {
                        id: "2273",
                        label: "巴南区",
                        value: "500113",
                        children: []
                    },
                    {
                        id: "2274",
                        label: "黔江区",
                        value: "500114",
                        children: []
                    },
                    {
                        id: "2275",
                        label: "长寿区",
                        value: "500115",
                        children: []
                    },
                    {
                        id: "2276",
                        label: "江津区",
                        value: "500116",
                        children: []
                    },
                    {
                        id: "2277",
                        label: "合川区",
                        value: "500117",
                        children: []
                    },
                    {
                        id: "2278",
                        label: "永川区",
                        value: "500118",
                        children: []
                    },
                    {
                        id: "2279",
                        label: "南川区",
                        value: "500119",
                        children: []
                    }
                ]
            },
            {
                id: "1261",
                label: "县",
                value: "500200",
                children: [
                    {
                        id: "2280",
                        label: "綦江县",
                        value: "500222",
                        children: []
                    },
                    {
                        id: "2281",
                        label: "潼南县",
                        value: "500223",
                        children: []
                    },
                    {
                        id: "2282",
                        label: "铜梁县",
                        value: "500224",
                        children: []
                    },
                    {
                        id: "2283",
                        label: "大足县",
                        value: "500225",
                        children: []
                    },
                    {
                        id: "2284",
                        label: "荣昌县",
                        value: "500226",
                        children: []
                    },
                    {
                        id: "2285",
                        label: "璧山县",
                        value: "500227",
                        children: []
                    },
                    {
                        id: "2286",
                        label: "梁平县",
                        value: "500228",
                        children: []
                    },
                    {
                        id: "2287",
                        label: "城口县",
                        value: "500229",
                        children: []
                    },
                    {
                        id: "2288",
                        label: "丰都县",
                        value: "500230",
                        children: []
                    },
                    {
                        id: "2289",
                        label: "垫江县",
                        value: "500231",
                        children: []
                    },
                    {
                        id: "2290",
                        label: "武隆县",
                        value: "500232",
                        children: []
                    },
                    {
                        id: "2291",
                        label: "忠县",
                        value: "500233",
                        children: []
                    },
                    {
                        id: "2292",
                        label: "开县",
                        value: "500234",
                        children: []
                    },
                    {
                        id: "2293",
                        label: "云阳县",
                        value: "500235",
                        children: []
                    },
                    {
                        id: "2294",
                        label: "奉节县",
                        value: "500236",
                        children: []
                    },
                    {
                        id: "2295",
                        label: "巫山县",
                        value: "500237",
                        children: []
                    },
                    {
                        id: "2296",
                        label: "巫溪县",
                        value: "500238",
                        children: []
                    },
                    {
                        id: "2297",
                        label: "石柱土家族自治县",
                        value: "500240",
                        children: []
                    },
                    {
                        id: "2298",
                        label: "秀山土家族苗族自治县",
                        value: "500241",
                        children: []
                    },
                    {
                        id: "2299",
                        label: "酉阳土家族苗族自治县",
                        value: "500242",
                        children: []
                    },
                    {
                        id: "2300",
                        label: "彭水苗族土家族自治县",
                        value: "500243",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "4",
        label: "河北省",
        value: "130000",
        children: [
            {
                id: "1026",
                label: "石家庄市",
                value: "130100",
                children: [
                    {
                        id: "76",
                        label: "市辖区",
                        value: "130101",
                        children: []
                    },
                    {
                        id: "77",
                        label: "长安区",
                        value: "130102",
                        children: []
                    },
                    {
                        id: "78",
                        label: "桥东区",
                        value: "130103",
                        children: []
                    },
                    {
                        id: "79",
                        label: "桥西区",
                        value: "130104",
                        children: []
                    },
                    {
                        id: "80",
                        label: "新华区",
                        value: "130105",
                        children: []
                    },
                    {
                        id: "81",
                        label: "井陉矿区",
                        value: "130107",
                        children: []
                    },
                    {
                        id: "82",
                        label: "裕华区",
                        value: "130108",
                        children: []
                    },
                    {
                        id: "83",
                        label: "井陉县",
                        value: "130121",
                        children: []
                    },
                    {
                        id: "84",
                        label: "正定县",
                        value: "130123",
                        children: []
                    },
                    {
                        id: "85",
                        label: "栾城县",
                        value: "130124",
                        children: []
                    },
                    {
                        id: "86",
                        label: "行唐县",
                        value: "130125",
                        children: []
                    },
                    {
                        id: "87",
                        label: "灵寿县",
                        value: "130126",
                        children: []
                    },
                    {
                        id: "88",
                        label: "高邑县",
                        value: "130127",
                        children: []
                    },
                    {
                        id: "89",
                        label: "深泽县",
                        value: "130128",
                        children: []
                    },
                    {
                        id: "90",
                        label: "赞皇县",
                        value: "130129",
                        children: []
                    },
                    {
                        id: "91",
                        label: "无极县",
                        value: "130130",
                        children: []
                    },
                    {
                        id: "92",
                        label: "平山县",
                        value: "130131",
                        children: []
                    },
                    {
                        id: "93",
                        label: "元氏县",
                        value: "130132",
                        children: []
                    },
                    {
                        id: "94",
                        label: "赵县",
                        value: "130133",
                        children: []
                    },
                    {
                        id: "95",
                        label: "辛集市",
                        value: "130181",
                        children: []
                    },
                    {
                        id: "96",
                        label: "藁城市",
                        value: "130182",
                        children: []
                    },
                    {
                        id: "97",
                        label: "晋州市",
                        value: "130183",
                        children: []
                    },
                    {
                        id: "98",
                        label: "新乐市",
                        value: "130184",
                        children: []
                    },
                    {
                        id: "99",
                        label: "鹿泉市",
                        value: "130185",
                        children: []
                    }
                ]
            },
            {
                id: "1027",
                label: "唐山市",
                value: "130200",
                children: [
                    {
                        id: "100",
                        label: "市辖区",
                        value: "130201",
                        children: []
                    },
                    {
                        id: "101",
                        label: "路南区",
                        value: "130202",
                        children: []
                    },
                    {
                        id: "102",
                        label: "路北区",
                        value: "130203",
                        children: []
                    },
                    {
                        id: "103",
                        label: "古冶区",
                        value: "130204",
                        children: []
                    },
                    {
                        id: "104",
                        label: "开平区",
                        value: "130205",
                        children: []
                    },
                    {
                        id: "105",
                        label: "丰南区",
                        value: "130207",
                        children: []
                    },
                    {
                        id: "106",
                        label: "丰润区",
                        value: "130208",
                        children: []
                    },
                    {
                        id: "107",
                        label: "滦县",
                        value: "130223",
                        children: []
                    },
                    {
                        id: "108",
                        label: "滦南县",
                        value: "130224",
                        children: []
                    },
                    {
                        id: "109",
                        label: "乐亭县",
                        value: "130225",
                        children: []
                    },
                    {
                        id: "110",
                        label: "迁西县",
                        value: "130227",
                        children: []
                    },
                    {
                        id: "111",
                        label: "玉田县",
                        value: "130229",
                        children: []
                    },
                    {
                        id: "112",
                        label: "唐海县",
                        value: "130230",
                        children: []
                    },
                    {
                        id: "113",
                        label: "遵化市",
                        value: "130281",
                        children: []
                    },
                    {
                        id: "114",
                        label: "迁安市",
                        value: "130283",
                        children: []
                    }
                ]
            },
            {
                id: "1028",
                label: "秦皇岛市",
                value: "130300",
                children: [
                    {
                        id: "115",
                        label: "市辖区",
                        value: "130301",
                        children: []
                    },
                    {
                        id: "116",
                        label: "海港区",
                        value: "130302",
                        children: []
                    },
                    {
                        id: "117",
                        label: "山海关区",
                        value: "130303",
                        children: []
                    },
                    {
                        id: "118",
                        label: "北戴河区",
                        value: "130304",
                        children: []
                    },
                    {
                        id: "119",
                        label: "青龙满族自治县",
                        value: "130321",
                        children: []
                    },
                    {
                        id: "120",
                        label: "昌黎县",
                        value: "130322",
                        children: []
                    },
                    {
                        id: "121",
                        label: "抚宁县",
                        value: "130323",
                        children: []
                    },
                    {
                        id: "122",
                        label: "卢龙县",
                        value: "130324",
                        children: []
                    }
                ]
            },
            {
                id: "1029",
                label: "邯郸市",
                value: "130400",
                children: [
                    {
                        id: "123",
                        label: "市辖区",
                        value: "130401",
                        children: []
                    },
                    {
                        id: "124",
                        label: "邯山区",
                        value: "130402",
                        children: []
                    },
                    {
                        id: "125",
                        label: "丛台区",
                        value: "130403",
                        children: []
                    },
                    {
                        id: "126",
                        label: "复兴区",
                        value: "130404",
                        children: []
                    },
                    {
                        id: "127",
                        label: "峰峰矿区",
                        value: "130406",
                        children: []
                    },
                    {
                        id: "128",
                        label: "邯郸县",
                        value: "130421",
                        children: []
                    },
                    {
                        id: "129",
                        label: "临漳县",
                        value: "130423",
                        children: []
                    },
                    {
                        id: "130",
                        label: "成安县",
                        value: "130424",
                        children: []
                    },
                    {
                        id: "131",
                        label: "大名县",
                        value: "130425",
                        children: []
                    },
                    {
                        id: "132",
                        label: "涉县",
                        value: "130426",
                        children: []
                    },
                    {
                        id: "133",
                        label: "磁县",
                        value: "130427",
                        children: []
                    },
                    {
                        id: "134",
                        label: "肥乡县",
                        value: "130428",
                        children: []
                    },
                    {
                        id: "135",
                        label: "永年县",
                        value: "130429",
                        children: []
                    },
                    {
                        id: "136",
                        label: "邱县",
                        value: "130430",
                        children: []
                    },
                    {
                        id: "137",
                        label: "鸡泽县",
                        value: "130431",
                        children: []
                    },
                    {
                        id: "138",
                        label: "广平县",
                        value: "130432",
                        children: []
                    },
                    {
                        id: "139",
                        label: "馆陶县",
                        value: "130433",
                        children: []
                    },
                    {
                        id: "140",
                        label: "魏县",
                        value: "130434",
                        children: []
                    },
                    {
                        id: "141",
                        label: "曲周县",
                        value: "130435",
                        children: []
                    },
                    {
                        id: "142",
                        label: "武安市",
                        value: "130481",
                        children: []
                    }
                ]
            },
            {
                id: "1030",
                label: "邢台市",
                value: "130500",
                children: [
                    {
                        id: "143",
                        label: "市辖区",
                        value: "130501",
                        children: []
                    },
                    {
                        id: "144",
                        label: "桥东区",
                        value: "130502",
                        children: []
                    },
                    {
                        id: "145",
                        label: "桥西区",
                        value: "130503",
                        children: []
                    },
                    {
                        id: "146",
                        label: "邢台县",
                        value: "130521",
                        children: []
                    },
                    {
                        id: "147",
                        label: "临城县",
                        value: "130522",
                        children: []
                    },
                    {
                        id: "148",
                        label: "内丘县",
                        value: "130523",
                        children: []
                    },
                    {
                        id: "149",
                        label: "柏乡县",
                        value: "130524",
                        children: []
                    },
                    {
                        id: "150",
                        label: "隆尧县",
                        value: "130525",
                        children: []
                    },
                    {
                        id: "151",
                        label: "任县",
                        value: "130526",
                        children: []
                    },
                    {
                        id: "152",
                        label: "南和县",
                        value: "130527",
                        children: []
                    },
                    {
                        id: "153",
                        label: "宁晋县",
                        value: "130528",
                        children: []
                    },
                    {
                        id: "154",
                        label: "巨鹿县",
                        value: "130529",
                        children: []
                    },
                    {
                        id: "155",
                        label: "新河县",
                        value: "130530",
                        children: []
                    },
                    {
                        id: "156",
                        label: "广宗县",
                        value: "130531",
                        children: []
                    },
                    {
                        id: "157",
                        label: "平乡县",
                        value: "130532",
                        children: []
                    },
                    {
                        id: "158",
                        label: "威县",
                        value: "130533",
                        children: []
                    },
                    {
                        id: "159",
                        label: "清河县",
                        value: "130534",
                        children: []
                    },
                    {
                        id: "160",
                        label: "临西县",
                        value: "130535",
                        children: []
                    },
                    {
                        id: "161",
                        label: "南宫市",
                        value: "130581",
                        children: []
                    },
                    {
                        id: "162",
                        label: "沙河市",
                        value: "130582",
                        children: []
                    }
                ]
            },
            {
                id: "1031",
                label: "保定市",
                value: "130600",
                children: [
                    {
                        id: "163",
                        label: "市辖区",
                        value: "130601",
                        children: []
                    },
                    {
                        id: "164",
                        label: "新市区",
                        value: "130602",
                        children: []
                    },
                    {
                        id: "165",
                        label: "北市区",
                        value: "130603",
                        children: []
                    },
                    {
                        id: "166",
                        label: "南市区",
                        value: "130604",
                        children: []
                    },
                    {
                        id: "167",
                        label: "满城县",
                        value: "130621",
                        children: []
                    },
                    {
                        id: "168",
                        label: "清苑县",
                        value: "130622",
                        children: []
                    },
                    {
                        id: "169",
                        label: "涞水县",
                        value: "130623",
                        children: []
                    },
                    {
                        id: "170",
                        label: "阜平县",
                        value: "130624",
                        children: []
                    },
                    {
                        id: "171",
                        label: "徐水县",
                        value: "130625",
                        children: []
                    },
                    {
                        id: "172",
                        label: "定兴县",
                        value: "130626",
                        children: []
                    },
                    {
                        id: "173",
                        label: "唐县",
                        value: "130627",
                        children: []
                    },
                    {
                        id: "174",
                        label: "高阳县",
                        value: "130628",
                        children: []
                    },
                    {
                        id: "175",
                        label: "容城县",
                        value: "130629",
                        children: []
                    },
                    {
                        id: "176",
                        label: "涞源县",
                        value: "130630",
                        children: []
                    },
                    {
                        id: "177",
                        label: "望都县",
                        value: "130631",
                        children: []
                    },
                    {
                        id: "178",
                        label: "安新县",
                        value: "130632",
                        children: []
                    },
                    {
                        id: "179",
                        label: "易县",
                        value: "130633",
                        children: []
                    },
                    {
                        id: "180",
                        label: "曲阳县",
                        value: "130634",
                        children: []
                    },
                    {
                        id: "181",
                        label: "蠡县",
                        value: "130635",
                        children: []
                    },
                    {
                        id: "182",
                        label: "顺平县",
                        value: "130636",
                        children: []
                    },
                    {
                        id: "183",
                        label: "博野县",
                        value: "130637",
                        children: []
                    },
                    {
                        id: "184",
                        label: "雄县",
                        value: "130638",
                        children: []
                    },
                    {
                        id: "185",
                        label: "涿州市",
                        value: "130681",
                        children: []
                    },
                    {
                        id: "186",
                        label: "定州市",
                        value: "130682",
                        children: []
                    },
                    {
                        id: "187",
                        label: "安国市",
                        value: "130683",
                        children: []
                    },
                    {
                        id: "188",
                        label: "高碑店市",
                        value: "130684",
                        children: []
                    }
                ]
            },
            {
                id: "1032",
                label: "张家口市",
                value: "130700",
                children: [
                    {
                        id: "189",
                        label: "市辖区",
                        value: "130701",
                        children: []
                    },
                    {
                        id: "190",
                        label: "桥东区",
                        value: "130702",
                        children: []
                    },
                    {
                        id: "191",
                        label: "桥西区",
                        value: "130703",
                        children: []
                    },
                    {
                        id: "192",
                        label: "宣化区",
                        value: "130705",
                        children: []
                    },
                    {
                        id: "193",
                        label: "下花园区",
                        value: "130706",
                        children: []
                    },
                    {
                        id: "194",
                        label: "宣化县",
                        value: "130721",
                        children: []
                    },
                    {
                        id: "195",
                        label: "张北县",
                        value: "130722",
                        children: []
                    },
                    {
                        id: "196",
                        label: "康保县",
                        value: "130723",
                        children: []
                    },
                    {
                        id: "197",
                        label: "沽源县",
                        value: "130724",
                        children: []
                    },
                    {
                        id: "198",
                        label: "尚义县",
                        value: "130725",
                        children: []
                    },
                    {
                        id: "199",
                        label: "蔚县",
                        value: "130726",
                        children: []
                    },
                    {
                        id: "200",
                        label: "阳原县",
                        value: "130727",
                        children: []
                    },
                    {
                        id: "201",
                        label: "怀安县",
                        value: "130728",
                        children: []
                    },
                    {
                        id: "202",
                        label: "万全县",
                        value: "130729",
                        children: []
                    },
                    {
                        id: "203",
                        label: "怀来县",
                        value: "130730",
                        children: []
                    },
                    {
                        id: "204",
                        label: "涿鹿县",
                        value: "130731",
                        children: []
                    },
                    {
                        id: "205",
                        label: "赤城县",
                        value: "130732",
                        children: []
                    },
                    {
                        id: "206",
                        label: "崇礼县",
                        value: "130733",
                        children: []
                    }
                ]
            },
            {
                id: "1033",
                label: "承德市",
                value: "130800",
                children: [
                    {
                        id: "207",
                        label: "市辖区",
                        value: "130801",
                        children: []
                    },
                    {
                        id: "208",
                        label: "双桥区",
                        value: "130802",
                        children: []
                    },
                    {
                        id: "209",
                        label: "双滦区",
                        value: "130803",
                        children: []
                    },
                    {
                        id: "210",
                        label: "鹰手营子矿区",
                        value: "130804",
                        children: []
                    },
                    {
                        id: "211",
                        label: "承德县",
                        value: "130821",
                        children: []
                    },
                    {
                        id: "212",
                        label: "兴隆县",
                        value: "130822",
                        children: []
                    },
                    {
                        id: "213",
                        label: "平泉县",
                        value: "130823",
                        children: []
                    },
                    {
                        id: "214",
                        label: "滦平县",
                        value: "130824",
                        children: []
                    },
                    {
                        id: "215",
                        label: "隆化县",
                        value: "130825",
                        children: []
                    },
                    {
                        id: "216",
                        label: "丰宁满族自治县",
                        value: "130826",
                        children: []
                    },
                    {
                        id: "217",
                        label: "宽城满族自治县",
                        value: "130827",
                        children: []
                    },
                    {
                        id: "218",
                        label: "围场满族蒙古族自治县",
                        value: "130828",
                        children: []
                    }
                ]
            },
            {
                id: "1034",
                label: "沧州市",
                value: "130900",
                children: [
                    {
                        id: "219",
                        label: "市辖区",
                        value: "130901",
                        children: []
                    },
                    {
                        id: "220",
                        label: "新华区",
                        value: "130902",
                        children: []
                    },
                    {
                        id: "221",
                        label: "运河区",
                        value: "130903",
                        children: []
                    },
                    {
                        id: "222",
                        label: "沧县",
                        value: "130921",
                        children: []
                    },
                    {
                        id: "223",
                        label: "青县",
                        value: "130922",
                        children: []
                    },
                    {
                        id: "224",
                        label: "东光县",
                        value: "130923",
                        children: []
                    },
                    {
                        id: "225",
                        label: "海兴县",
                        value: "130924",
                        children: []
                    },
                    {
                        id: "226",
                        label: "盐山县",
                        value: "130925",
                        children: []
                    },
                    {
                        id: "227",
                        label: "肃宁县",
                        value: "130926",
                        children: []
                    },
                    {
                        id: "228",
                        label: "南皮县",
                        value: "130927",
                        children: []
                    },
                    {
                        id: "229",
                        label: "吴桥县",
                        value: "130928",
                        children: []
                    },
                    {
                        id: "230",
                        label: "献县",
                        value: "130929",
                        children: []
                    },
                    {
                        id: "231",
                        label: "孟村回族自治县",
                        value: "130930",
                        children: []
                    },
                    {
                        id: "232",
                        label: "泊头市",
                        value: "130981",
                        children: []
                    },
                    {
                        id: "233",
                        label: "任丘市",
                        value: "130982",
                        children: []
                    },
                    {
                        id: "234",
                        label: "黄骅市",
                        value: "130983",
                        children: []
                    },
                    {
                        id: "235",
                        label: "河间市",
                        value: "130984",
                        children: []
                    }
                ]
            },
            {
                id: "1035",
                label: "廊坊市",
                value: "131000",
                children: [
                    {
                        id: "236",
                        label: "市辖区",
                        value: "131001",
                        children: []
                    },
                    {
                        id: "237",
                        label: "安次区",
                        value: "131002",
                        children: []
                    },
                    {
                        id: "238",
                        label: "广阳区",
                        value: "131003",
                        children: []
                    },
                    {
                        id: "239",
                        label: "固安县",
                        value: "131022",
                        children: []
                    },
                    {
                        id: "240",
                        label: "永清县",
                        value: "131023",
                        children: []
                    },
                    {
                        id: "241",
                        label: "香河县",
                        value: "131024",
                        children: []
                    },
                    {
                        id: "242",
                        label: "大城县",
                        value: "131025",
                        children: []
                    },
                    {
                        id: "243",
                        label: "文安县",
                        value: "131026",
                        children: []
                    },
                    {
                        id: "244",
                        label: "大厂回族自治县",
                        value: "131028",
                        children: []
                    },
                    {
                        id: "245",
                        label: "霸州市",
                        value: "131081",
                        children: []
                    },
                    {
                        id: "246",
                        label: "三河市",
                        value: "131082",
                        children: []
                    }
                ]
            },
            {
                id: "1036",
                label: "衡水市",
                value: "131100",
                children: [
                    {
                        id: "247",
                        label: "市辖区",
                        value: "131101",
                        children: []
                    },
                    {
                        id: "248",
                        label: "桃城区",
                        value: "131102",
                        children: []
                    },
                    {
                        id: "249",
                        label: "枣强县",
                        value: "131121",
                        children: []
                    },
                    {
                        id: "250",
                        label: "武邑县",
                        value: "131122",
                        children: []
                    },
                    {
                        id: "251",
                        label: "武强县",
                        value: "131123",
                        children: []
                    },
                    {
                        id: "252",
                        label: "饶阳县",
                        value: "131124",
                        children: []
                    },
                    {
                        id: "253",
                        label: "安平县",
                        value: "131125",
                        children: []
                    },
                    {
                        id: "254",
                        label: "故城县",
                        value: "131126",
                        children: []
                    },
                    {
                        id: "255",
                        label: "景县",
                        value: "131127",
                        children: []
                    },
                    {
                        id: "256",
                        label: "阜城县",
                        value: "131128",
                        children: []
                    },
                    {
                        id: "257",
                        label: "冀州市",
                        value: "131181",
                        children: []
                    },
                    {
                        id: "258",
                        label: "深州市",
                        value: "131182",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "5",
        label: "山西省",
        value: "140000",
        children: [
            {
                id: "1037",
                label: "太原市",
                value: "140100",
                children: [
                    {
                        id: "259",
                        label: "市辖区",
                        value: "140101",
                        children: []
                    },
                    {
                        id: "260",
                        label: "小店区",
                        value: "140105",
                        children: []
                    },
                    {
                        id: "261",
                        label: "迎泽区",
                        value: "140106",
                        children: []
                    },
                    {
                        id: "262",
                        label: "杏花岭区",
                        value: "140107",
                        children: []
                    },
                    {
                        id: "263",
                        label: "尖草坪区",
                        value: "140108",
                        children: []
                    },
                    {
                        id: "264",
                        label: "万柏林区",
                        value: "140109",
                        children: []
                    },
                    {
                        id: "265",
                        label: "晋源区",
                        value: "140110",
                        children: []
                    },
                    {
                        id: "266",
                        label: "清徐县",
                        value: "140121",
                        children: []
                    },
                    {
                        id: "267",
                        label: "阳曲县",
                        value: "140122",
                        children: []
                    },
                    {
                        id: "268",
                        label: "娄烦县",
                        value: "140123",
                        children: []
                    },
                    {
                        id: "269",
                        label: "古交市",
                        value: "140181",
                        children: []
                    }
                ]
            },
            {
                id: "1038",
                label: "大同市",
                value: "140200",
                children: [
                    {
                        id: "270",
                        label: "市辖区",
                        value: "140201",
                        children: []
                    },
                    {
                        id: "271",
                        label: "城区",
                        value: "140202",
                        children: []
                    },
                    {
                        id: "272",
                        label: "矿区",
                        value: "140203",
                        children: []
                    },
                    {
                        id: "273",
                        label: "南郊区",
                        value: "140211",
                        children: []
                    },
                    {
                        id: "274",
                        label: "新荣区",
                        value: "140212",
                        children: []
                    },
                    {
                        id: "275",
                        label: "阳高县",
                        value: "140221",
                        children: []
                    },
                    {
                        id: "276",
                        label: "天镇县",
                        value: "140222",
                        children: []
                    },
                    {
                        id: "277",
                        label: "广灵县",
                        value: "140223",
                        children: []
                    },
                    {
                        id: "278",
                        label: "灵丘县",
                        value: "140224",
                        children: []
                    },
                    {
                        id: "279",
                        label: "浑源县",
                        value: "140225",
                        children: []
                    },
                    {
                        id: "280",
                        label: "左云县",
                        value: "140226",
                        children: []
                    },
                    {
                        id: "281",
                        label: "大同县",
                        value: "140227",
                        children: []
                    }
                ]
            },
            {
                id: "1039",
                label: "阳泉市",
                value: "140300",
                children: [
                    {
                        id: "282",
                        label: "市辖区",
                        value: "140301",
                        children: []
                    },
                    {
                        id: "283",
                        label: "城区",
                        value: "140302",
                        children: []
                    },
                    {
                        id: "284",
                        label: "矿区",
                        value: "140303",
                        children: []
                    },
                    {
                        id: "285",
                        label: "郊区",
                        value: "140311",
                        children: []
                    },
                    {
                        id: "286",
                        label: "平定县",
                        value: "140321",
                        children: []
                    },
                    {
                        id: "287",
                        label: "盂县",
                        value: "140322",
                        children: []
                    }
                ]
            },
            {
                id: "1040",
                label: "长治市",
                value: "140400",
                children: [
                    {
                        id: "288",
                        label: "市辖区",
                        value: "140401",
                        children: []
                    },
                    {
                        id: "289",
                        label: "城区",
                        value: "140402",
                        children: []
                    },
                    {
                        id: "290",
                        label: "郊区",
                        value: "140411",
                        children: []
                    },
                    {
                        id: "291",
                        label: "长治县",
                        value: "140421",
                        children: []
                    },
                    {
                        id: "292",
                        label: "襄垣县",
                        value: "140423",
                        children: []
                    },
                    {
                        id: "293",
                        label: "屯留县",
                        value: "140424",
                        children: []
                    },
                    {
                        id: "294",
                        label: "平顺县",
                        value: "140425",
                        children: []
                    },
                    {
                        id: "295",
                        label: "黎城县",
                        value: "140426",
                        children: []
                    },
                    {
                        id: "296",
                        label: "壶关县",
                        value: "140427",
                        children: []
                    },
                    {
                        id: "297",
                        label: "长子县",
                        value: "140428",
                        children: []
                    },
                    {
                        id: "298",
                        label: "武乡县",
                        value: "140429",
                        children: []
                    },
                    {
                        id: "299",
                        label: "沁县",
                        value: "140430",
                        children: []
                    },
                    {
                        id: "300",
                        label: "沁源县",
                        value: "140431",
                        children: []
                    },
                    {
                        id: "301",
                        label: "潞城市",
                        value: "140481",
                        children: []
                    }
                ]
            },
            {
                id: "1041",
                label: "晋城市",
                value: "140500",
                children: [
                    {
                        id: "302",
                        label: "市辖区",
                        value: "140501",
                        children: []
                    },
                    {
                        id: "303",
                        label: "城区",
                        value: "140502",
                        children: []
                    },
                    {
                        id: "304",
                        label: "沁水县",
                        value: "140521",
                        children: []
                    },
                    {
                        id: "305",
                        label: "阳城县",
                        value: "140522",
                        children: []
                    },
                    {
                        id: "306",
                        label: "陵川县",
                        value: "140524",
                        children: []
                    },
                    {
                        id: "307",
                        label: "泽州县",
                        value: "140525",
                        children: []
                    },
                    {
                        id: "308",
                        label: "高平市",
                        value: "140581",
                        children: []
                    }
                ]
            },
            {
                id: "1042",
                label: "朔州市",
                value: "140600",
                children: [
                    {
                        id: "309",
                        label: "市辖区",
                        value: "140601",
                        children: []
                    },
                    {
                        id: "310",
                        label: "朔城区",
                        value: "140602",
                        children: []
                    },
                    {
                        id: "311",
                        label: "平鲁区",
                        value: "140603",
                        children: []
                    },
                    {
                        id: "312",
                        label: "山阴县",
                        value: "140621",
                        children: []
                    },
                    {
                        id: "313",
                        label: "应县",
                        value: "140622",
                        children: []
                    },
                    {
                        id: "314",
                        label: "右玉县",
                        value: "140623",
                        children: []
                    },
                    {
                        id: "315",
                        label: "怀仁县",
                        value: "140624",
                        children: []
                    }
                ]
            },
            {
                id: "1043",
                label: "晋中市",
                value: "140700",
                children: [
                    {
                        id: "316",
                        label: "市辖区",
                        value: "140701",
                        children: []
                    },
                    {
                        id: "317",
                        label: "榆次区",
                        value: "140702",
                        children: []
                    },
                    {
                        id: "318",
                        label: "榆社县",
                        value: "140721",
                        children: []
                    },
                    {
                        id: "319",
                        label: "左权县",
                        value: "140722",
                        children: []
                    },
                    {
                        id: "320",
                        label: "和顺县",
                        value: "140723",
                        children: []
                    },
                    {
                        id: "321",
                        label: "昔阳县",
                        value: "140724",
                        children: []
                    },
                    {
                        id: "322",
                        label: "寿阳县",
                        value: "140725",
                        children: []
                    },
                    {
                        id: "323",
                        label: "太谷县",
                        value: "140726",
                        children: []
                    },
                    {
                        id: "324",
                        label: "祁县",
                        value: "140727",
                        children: []
                    },
                    {
                        id: "325",
                        label: "平遥县",
                        value: "140728",
                        children: []
                    },
                    {
                        id: "326",
                        label: "灵石县",
                        value: "140729",
                        children: []
                    },
                    {
                        id: "327",
                        label: "介休市",
                        value: "140781",
                        children: []
                    }
                ]
            },
            {
                id: "1044",
                label: "运城市",
                value: "140800",
                children: [
                    {
                        id: "328",
                        label: "市辖区",
                        value: "140801",
                        children: []
                    },
                    {
                        id: "329",
                        label: "盐湖区",
                        value: "140802",
                        children: []
                    },
                    {
                        id: "330",
                        label: "临猗县",
                        value: "140821",
                        children: []
                    },
                    {
                        id: "331",
                        label: "万荣县",
                        value: "140822",
                        children: []
                    },
                    {
                        id: "332",
                        label: "闻喜县",
                        value: "140823",
                        children: []
                    },
                    {
                        id: "333",
                        label: "稷山县",
                        value: "140824",
                        children: []
                    },
                    {
                        id: "334",
                        label: "新绛县",
                        value: "140825",
                        children: []
                    },
                    {
                        id: "335",
                        label: "绛县",
                        value: "140826",
                        children: []
                    },
                    {
                        id: "336",
                        label: "垣曲县",
                        value: "140827",
                        children: []
                    },
                    {
                        id: "337",
                        label: "夏县",
                        value: "140828",
                        children: []
                    },
                    {
                        id: "338",
                        label: "平陆县",
                        value: "140829",
                        children: []
                    },
                    {
                        id: "339",
                        label: "芮城县",
                        value: "140830",
                        children: []
                    },
                    {
                        id: "340",
                        label: "永济市",
                        value: "140881",
                        children: []
                    },
                    {
                        id: "341",
                        label: "河津市",
                        value: "140882",
                        children: []
                    }
                ]
            },
            {
                id: "1045",
                label: "忻州市",
                value: "140900",
                children: [
                    {
                        id: "342",
                        label: "市辖区",
                        value: "140901",
                        children: []
                    },
                    {
                        id: "343",
                        label: "忻府区",
                        value: "140902",
                        children: []
                    },
                    {
                        id: "344",
                        label: "定襄县",
                        value: "140921",
                        children: []
                    },
                    {
                        id: "345",
                        label: "五台县",
                        value: "140922",
                        children: []
                    },
                    {
                        id: "346",
                        label: "代县",
                        value: "140923",
                        children: []
                    },
                    {
                        id: "347",
                        label: "繁峙县",
                        value: "140924",
                        children: []
                    },
                    {
                        id: "348",
                        label: "宁武县",
                        value: "140925",
                        children: []
                    },
                    {
                        id: "349",
                        label: "静乐县",
                        value: "140926",
                        children: []
                    },
                    {
                        id: "350",
                        label: "神池县",
                        value: "140927",
                        children: []
                    },
                    {
                        id: "351",
                        label: "五寨县",
                        value: "140928",
                        children: []
                    },
                    {
                        id: "352",
                        label: "岢岚县",
                        value: "140929",
                        children: []
                    },
                    {
                        id: "353",
                        label: "河曲县",
                        value: "140930",
                        children: []
                    },
                    {
                        id: "354",
                        label: "保德县",
                        value: "140931",
                        children: []
                    },
                    {
                        id: "355",
                        label: "偏关县",
                        value: "140932",
                        children: []
                    },
                    {
                        id: "356",
                        label: "原平市",
                        value: "140981",
                        children: []
                    }
                ]
            },
            {
                id: "1046",
                label: "临汾市",
                value: "141000",
                children: [
                    {
                        id: "357",
                        label: "市辖区",
                        value: "141001",
                        children: []
                    },
                    {
                        id: "358",
                        label: "尧都区",
                        value: "141002",
                        children: []
                    },
                    {
                        id: "359",
                        label: "曲沃县",
                        value: "141021",
                        children: []
                    },
                    {
                        id: "360",
                        label: "翼城县",
                        value: "141022",
                        children: []
                    },
                    {
                        id: "361",
                        label: "襄汾县",
                        value: "141023",
                        children: []
                    },
                    {
                        id: "362",
                        label: "洪洞县",
                        value: "141024",
                        children: []
                    },
                    {
                        id: "363",
                        label: "古县",
                        value: "141025",
                        children: []
                    },
                    {
                        id: "364",
                        label: "安泽县",
                        value: "141026",
                        children: []
                    },
                    {
                        id: "365",
                        label: "浮山县",
                        value: "141027",
                        children: []
                    },
                    {
                        id: "366",
                        label: "吉县",
                        value: "141028",
                        children: []
                    },
                    {
                        id: "367",
                        label: "乡宁县",
                        value: "141029",
                        children: []
                    },
                    {
                        id: "368",
                        label: "大宁县",
                        value: "141030",
                        children: []
                    },
                    {
                        id: "369",
                        label: "隰县",
                        value: "141031",
                        children: []
                    },
                    {
                        id: "370",
                        label: "永和县",
                        value: "141032",
                        children: []
                    },
                    {
                        id: "371",
                        label: "蒲县",
                        value: "141033",
                        children: []
                    },
                    {
                        id: "372",
                        label: "汾西县",
                        value: "141034",
                        children: []
                    },
                    {
                        id: "373",
                        label: "侯马市",
                        value: "141081",
                        children: []
                    },
                    {
                        id: "374",
                        label: "霍州市",
                        value: "141082",
                        children: []
                    }
                ]
            },
            {
                id: "1047",
                label: "吕梁市",
                value: "141100",
                children: [
                    {
                        id: "375",
                        label: "市辖区",
                        value: "141101",
                        children: []
                    },
                    {
                        id: "376",
                        label: "离石区",
                        value: "141102",
                        children: []
                    },
                    {
                        id: "377",
                        label: "文水县",
                        value: "141121",
                        children: []
                    },
                    {
                        id: "378",
                        label: "交城县",
                        value: "141122",
                        children: []
                    },
                    {
                        id: "379",
                        label: "兴县",
                        value: "141123",
                        children: []
                    },
                    {
                        id: "380",
                        label: "临县",
                        value: "141124",
                        children: []
                    },
                    {
                        id: "381",
                        label: "柳林县",
                        value: "141125",
                        children: []
                    },
                    {
                        id: "382",
                        label: "石楼县",
                        value: "141126",
                        children: []
                    },
                    {
                        id: "383",
                        label: "岚县",
                        value: "141127",
                        children: []
                    },
                    {
                        id: "384",
                        label: "方山县",
                        value: "141128",
                        children: []
                    },
                    {
                        id: "385",
                        label: "中阳县",
                        value: "141129",
                        children: []
                    },
                    {
                        id: "386",
                        label: "交口县",
                        value: "141130",
                        children: []
                    },
                    {
                        id: "387",
                        label: "孝义市",
                        value: "141181",
                        children: []
                    },
                    {
                        id: "388",
                        label: "汾阳市",
                        value: "141182",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "6",
        label: "内蒙古自治区",
        value: "150000",
        children: [
            {
                id: "1048",
                label: "呼和浩特市",
                value: "150100",
                children: [
                    {
                        id: "389",
                        label: "市辖区",
                        value: "150101",
                        children: []
                    },
                    {
                        id: "390",
                        label: "新城区",
                        value: "150102",
                        children: []
                    },
                    {
                        id: "391",
                        label: "回民区",
                        value: "150103",
                        children: []
                    },
                    {
                        id: "392",
                        label: "玉泉区",
                        value: "150104",
                        children: []
                    },
                    {
                        id: "393",
                        label: "赛罕区",
                        value: "150105",
                        children: []
                    },
                    {
                        id: "394",
                        label: "土默特左旗",
                        value: "150121",
                        children: []
                    },
                    {
                        id: "395",
                        label: "托克托县",
                        value: "150122",
                        children: []
                    },
                    {
                        id: "396",
                        label: "和林格尔县",
                        value: "150123",
                        children: []
                    },
                    {
                        id: "397",
                        label: "清水河县",
                        value: "150124",
                        children: []
                    },
                    {
                        id: "398",
                        label: "武川县",
                        value: "150125",
                        children: []
                    }
                ]
            },
            {
                id: "1049",
                label: "包头市",
                value: "150200",
                children: [
                    {
                        id: "399",
                        label: "市辖区",
                        value: "150201",
                        children: []
                    },
                    {
                        id: "400",
                        label: "东河区",
                        value: "150202",
                        children: []
                    },
                    {
                        id: "401",
                        label: "昆都仑区",
                        value: "150203",
                        children: []
                    },
                    {
                        id: "402",
                        label: "青山区",
                        value: "150204",
                        children: []
                    },
                    {
                        id: "403",
                        label: "石拐区",
                        value: "150205",
                        children: []
                    },
                    {
                        id: "404",
                        label: "白云鄂博矿区",
                        value: "150206",
                        children: []
                    },
                    {
                        id: "405",
                        label: "九原区",
                        value: "150207",
                        children: []
                    },
                    {
                        id: "406",
                        label: "土默特右旗",
                        value: "150221",
                        children: []
                    },
                    {
                        id: "407",
                        label: "固阳县",
                        value: "150222",
                        children: []
                    },
                    {
                        id: "408",
                        label: "达尔罕茂明安联合旗",
                        value: "150223",
                        children: []
                    }
                ]
            },
            {
                id: "1050",
                label: "乌海市",
                value: "150300",
                children: [
                    {
                        id: "409",
                        label: "市辖区",
                        value: "150301",
                        children: []
                    },
                    {
                        id: "410",
                        label: "海勃湾区",
                        value: "150302",
                        children: []
                    },
                    {
                        id: "411",
                        label: "海南区",
                        value: "150303",
                        children: []
                    },
                    {
                        id: "412",
                        label: "乌达区",
                        value: "150304",
                        children: []
                    }
                ]
            },
            {
                id: "1051",
                label: "赤峰市",
                value: "150400",
                children: [
                    {
                        id: "413",
                        label: "市辖区",
                        value: "150401",
                        children: []
                    },
                    {
                        id: "414",
                        label: "红山区",
                        value: "150402",
                        children: []
                    },
                    {
                        id: "415",
                        label: "元宝山区",
                        value: "150403",
                        children: []
                    },
                    {
                        id: "416",
                        label: "松山区",
                        value: "150404",
                        children: []
                    },
                    {
                        id: "417",
                        label: "阿鲁科尔沁旗",
                        value: "150421",
                        children: []
                    },
                    {
                        id: "418",
                        label: "巴林左旗",
                        value: "150422",
                        children: []
                    },
                    {
                        id: "419",
                        label: "巴林右旗",
                        value: "150423",
                        children: []
                    },
                    {
                        id: "420",
                        label: "林西县",
                        value: "150424",
                        children: []
                    },
                    {
                        id: "421",
                        label: "克什克腾旗",
                        value: "150425",
                        children: []
                    },
                    {
                        id: "422",
                        label: "翁牛特旗",
                        value: "150426",
                        children: []
                    },
                    {
                        id: "423",
                        label: "喀喇沁旗",
                        value: "150428",
                        children: []
                    },
                    {
                        id: "424",
                        label: "宁城县",
                        value: "150429",
                        children: []
                    },
                    {
                        id: "425",
                        label: "敖汉旗",
                        value: "150430",
                        children: []
                    }
                ]
            },
            {
                id: "1052",
                label: "通辽市",
                value: "150500",
                children: [
                    {
                        id: "426",
                        label: "市辖区",
                        value: "150501",
                        children: []
                    },
                    {
                        id: "427",
                        label: "科尔沁区",
                        value: "150502",
                        children: []
                    },
                    {
                        id: "428",
                        label: "科尔沁左翼中旗",
                        value: "150521",
                        children: []
                    },
                    {
                        id: "429",
                        label: "科尔沁左翼后旗",
                        value: "150522",
                        children: []
                    },
                    {
                        id: "430",
                        label: "开鲁县",
                        value: "150523",
                        children: []
                    },
                    {
                        id: "431",
                        label: "库伦旗",
                        value: "150524",
                        children: []
                    },
                    {
                        id: "432",
                        label: "奈曼旗",
                        value: "150525",
                        children: []
                    },
                    {
                        id: "433",
                        label: "扎鲁特旗",
                        value: "150526",
                        children: []
                    },
                    {
                        id: "434",
                        label: "霍林郭勒市",
                        value: "150581",
                        children: []
                    }
                ]
            },
            {
                id: "1053",
                label: "鄂尔多斯市",
                value: "150600",
                children: [
                    {
                        id: "435",
                        label: "市辖区",
                        value: "150601",
                        children: []
                    },
                    {
                        id: "436",
                        label: "东胜区",
                        value: "150602",
                        children: []
                    },
                    {
                        id: "437",
                        label: "达拉特旗",
                        value: "150621",
                        children: []
                    },
                    {
                        id: "438",
                        label: "准格尔旗",
                        value: "150622",
                        children: []
                    },
                    {
                        id: "439",
                        label: "鄂托克前旗",
                        value: "150623",
                        children: []
                    },
                    {
                        id: "440",
                        label: "鄂托克旗",
                        value: "150624",
                        children: []
                    },
                    {
                        id: "441",
                        label: "杭锦旗",
                        value: "150625",
                        children: []
                    },
                    {
                        id: "442",
                        label: "乌审旗",
                        value: "150626",
                        children: []
                    },
                    {
                        id: "443",
                        label: "伊金霍洛旗",
                        value: "150627",
                        children: []
                    }
                ]
            },
            {
                id: "1054",
                label: "呼伦贝尔市",
                value: "150700",
                children: [
                    {
                        id: "444",
                        label: "市辖区",
                        value: "150701",
                        children: []
                    },
                    {
                        id: "445",
                        label: "海拉尔区",
                        value: "150702",
                        children: []
                    },
                    {
                        id: "446",
                        label: "阿荣旗",
                        value: "150721",
                        children: []
                    },
                    {
                        id: "447",
                        label: "莫力达瓦达斡尔族自治旗",
                        value: "150722",
                        children: []
                    },
                    {
                        id: "448",
                        label: "鄂伦春自治旗",
                        value: "150723",
                        children: []
                    },
                    {
                        id: "449",
                        label: "鄂温克族自治旗",
                        value: "150724",
                        children: []
                    },
                    {
                        id: "450",
                        label: "陈巴尔虎旗",
                        value: "150725",
                        children: []
                    },
                    {
                        id: "451",
                        label: "新巴尔虎左旗",
                        value: "150726",
                        children: []
                    },
                    {
                        id: "452",
                        label: "新巴尔虎右旗",
                        value: "150727",
                        children: []
                    },
                    {
                        id: "453",
                        label: "满洲里市",
                        value: "150781",
                        children: []
                    },
                    {
                        id: "454",
                        label: "牙克石市",
                        value: "150782",
                        children: []
                    },
                    {
                        id: "455",
                        label: "扎兰屯市",
                        value: "150783",
                        children: []
                    },
                    {
                        id: "456",
                        label: "额尔古纳市",
                        value: "150784",
                        children: []
                    },
                    {
                        id: "457",
                        label: "根河市",
                        value: "150785",
                        children: []
                    }
                ]
            },
            {
                id: "1055",
                label: "巴彦淖尔市",
                value: "150800",
                children: [
                    {
                        id: "458",
                        label: "市辖区",
                        value: "150801",
                        children: []
                    },
                    {
                        id: "459",
                        label: "临河区",
                        value: "150802",
                        children: []
                    },
                    {
                        id: "460",
                        label: "五原县",
                        value: "150821",
                        children: []
                    },
                    {
                        id: "461",
                        label: "磴口县",
                        value: "150822",
                        children: []
                    },
                    {
                        id: "462",
                        label: "乌拉特前旗",
                        value: "150823",
                        children: []
                    },
                    {
                        id: "463",
                        label: "乌拉特中旗",
                        value: "150824",
                        children: []
                    },
                    {
                        id: "464",
                        label: "乌拉特后旗",
                        value: "150825",
                        children: []
                    },
                    {
                        id: "465",
                        label: "杭锦后旗",
                        value: "150826",
                        children: []
                    }
                ]
            },
            {
                id: "1056",
                label: "乌兰察布市",
                value: "150900",
                children: [
                    {
                        id: "466",
                        label: "市辖区",
                        value: "150901",
                        children: []
                    },
                    {
                        id: "467",
                        label: "集宁区",
                        value: "150902",
                        children: []
                    },
                    {
                        id: "468",
                        label: "卓资县",
                        value: "150921",
                        children: []
                    },
                    {
                        id: "469",
                        label: "化德县",
                        value: "150922",
                        children: []
                    },
                    {
                        id: "470",
                        label: "商都县",
                        value: "150923",
                        children: []
                    },
                    {
                        id: "471",
                        label: "兴和县",
                        value: "150924",
                        children: []
                    },
                    {
                        id: "472",
                        label: "凉城县",
                        value: "150925",
                        children: []
                    },
                    {
                        id: "473",
                        label: "察哈尔右翼前旗",
                        value: "150926",
                        children: []
                    },
                    {
                        id: "474",
                        label: "察哈尔右翼中旗",
                        value: "150927",
                        children: []
                    },
                    {
                        id: "475",
                        label: "察哈尔右翼后旗",
                        value: "150928",
                        children: []
                    },
                    {
                        id: "476",
                        label: "四子王旗",
                        value: "150929",
                        children: []
                    },
                    {
                        id: "477",
                        label: "丰镇市",
                        value: "150981",
                        children: []
                    }
                ]
            },
            {
                id: "1057",
                label: "兴安盟",
                value: "152200",
                children: [
                    {
                        id: "478",
                        label: "乌兰浩特市",
                        value: "152201",
                        children: []
                    },
                    {
                        id: "479",
                        label: "阿尔山市",
                        value: "152202",
                        children: []
                    },
                    {
                        id: "480",
                        label: "科尔沁右翼前旗",
                        value: "152221",
                        children: []
                    },
                    {
                        id: "481",
                        label: "科尔沁右翼中旗",
                        value: "152222",
                        children: []
                    },
                    {
                        id: "482",
                        label: "扎赉特旗",
                        value: "152223",
                        children: []
                    },
                    {
                        id: "483",
                        label: "突泉县",
                        value: "152224",
                        children: []
                    }
                ]
            },
            {
                id: "1058",
                label: "锡林郭勒盟",
                value: "152500",
                children: [
                    {
                        id: "484",
                        label: "二连浩特市",
                        value: "152501",
                        children: []
                    },
                    {
                        id: "485",
                        label: "锡林浩特市",
                        value: "152502",
                        children: []
                    },
                    {
                        id: "486",
                        label: "阿巴嘎旗",
                        value: "152522",
                        children: []
                    },
                    {
                        id: "487",
                        label: "苏尼特左旗",
                        value: "152523",
                        children: []
                    },
                    {
                        id: "488",
                        label: "苏尼特右旗",
                        value: "152524",
                        children: []
                    },
                    {
                        id: "489",
                        label: "东乌珠穆沁旗",
                        value: "152525",
                        children: []
                    },
                    {
                        id: "490",
                        label: "西乌珠穆沁旗",
                        value: "152526",
                        children: []
                    },
                    {
                        id: "491",
                        label: "太仆寺旗",
                        value: "152527",
                        children: []
                    },
                    {
                        id: "492",
                        label: "镶黄旗",
                        value: "152528",
                        children: []
                    },
                    {
                        id: "493",
                        label: "正镶白旗",
                        value: "152529",
                        children: []
                    },
                    {
                        id: "494",
                        label: "正蓝旗",
                        value: "152530",
                        children: []
                    },
                    {
                        id: "495",
                        label: "多伦县",
                        value: "152531",
                        children: []
                    }
                ]
            },
            {
                id: "1059",
                label: "阿拉善盟",
                value: "152900",
                children: [
                    {
                        id: "496",
                        label: "阿拉善左旗",
                        value: "152921",
                        children: []
                    },
                    {
                        id: "497",
                        label: "阿拉善右旗",
                        value: "152922",
                        children: []
                    },
                    {
                        id: "498",
                        label: "额济纳旗",
                        value: "152923",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "7",
        label: "辽宁省",
        value: "210000",
        children: [
            {
                id: "1060",
                label: "沈阳市",
                value: "210100",
                children: [
                    {
                        id: "499",
                        label: "市辖区",
                        value: "210101",
                        children: []
                    },
                    {
                        id: "500",
                        label: "和平区",
                        value: "210102",
                        children: []
                    },
                    {
                        id: "501",
                        label: "沈河区",
                        value: "210103",
                        children: []
                    },
                    {
                        id: "502",
                        label: "大东区",
                        value: "210104",
                        children: []
                    },
                    {
                        id: "503",
                        label: "皇姑区",
                        value: "210105",
                        children: []
                    },
                    {
                        id: "504",
                        label: "铁西区",
                        value: "210106",
                        children: []
                    },
                    {
                        id: "505",
                        label: "苏家屯区",
                        value: "210111",
                        children: []
                    },
                    {
                        id: "506",
                        label: "东陵区",
                        value: "210112",
                        children: []
                    },
                    {
                        id: "507",
                        label: "沈北新区",
                        value: "210113",
                        children: []
                    },
                    {
                        id: "508",
                        label: "于洪区",
                        value: "210114",
                        children: []
                    },
                    {
                        id: "509",
                        label: "辽中县",
                        value: "210122",
                        children: []
                    },
                    {
                        id: "510",
                        label: "康平县",
                        value: "210123",
                        children: []
                    },
                    {
                        id: "511",
                        label: "法库县",
                        value: "210124",
                        children: []
                    },
                    {
                        id: "512",
                        label: "新民市",
                        value: "210181",
                        children: []
                    }
                ]
            },
            {
                id: "1061",
                label: "大连市",
                value: "210200",
                children: [
                    {
                        id: "513",
                        label: "市辖区",
                        value: "210201",
                        children: []
                    },
                    {
                        id: "514",
                        label: "中山区",
                        value: "210202",
                        children: []
                    },
                    {
                        id: "515",
                        label: "西岗区",
                        value: "210203",
                        children: []
                    },
                    {
                        id: "516",
                        label: "沙河口区",
                        value: "210204",
                        children: []
                    },
                    {
                        id: "517",
                        label: "甘井子区",
                        value: "210211",
                        children: []
                    },
                    {
                        id: "518",
                        label: "旅顺口区",
                        value: "210212",
                        children: []
                    },
                    {
                        id: "519",
                        label: "金州区",
                        value: "210213",
                        children: []
                    },
                    {
                        id: "520",
                        label: "长海县",
                        value: "210224",
                        children: []
                    },
                    {
                        id: "521",
                        label: "瓦房店市",
                        value: "210281",
                        children: []
                    },
                    {
                        id: "522",
                        label: "普兰店市",
                        value: "210282",
                        children: []
                    },
                    {
                        id: "523",
                        label: "庄河市",
                        value: "210283",
                        children: []
                    }
                ]
            },
            {
                id: "1062",
                label: "鞍山市",
                value: "210300",
                children: [
                    {
                        id: "524",
                        label: "市辖区",
                        value: "210301",
                        children: []
                    },
                    {
                        id: "525",
                        label: "铁东区",
                        value: "210302",
                        children: []
                    },
                    {
                        id: "526",
                        label: "铁西区",
                        value: "210303",
                        children: []
                    },
                    {
                        id: "527",
                        label: "立山区",
                        value: "210304",
                        children: []
                    },
                    {
                        id: "528",
                        label: "千山区",
                        value: "210311",
                        children: []
                    },
                    {
                        id: "529",
                        label: "台安县",
                        value: "210321",
                        children: []
                    },
                    {
                        id: "530",
                        label: "岫岩满族自治县",
                        value: "210323",
                        children: []
                    },
                    {
                        id: "531",
                        label: "海城市",
                        value: "210381",
                        children: []
                    }
                ]
            },
            {
                id: "1063",
                label: "抚顺市",
                value: "210400",
                children: [
                    {
                        id: "532",
                        label: "市辖区",
                        value: "210401",
                        children: []
                    },
                    {
                        id: "533",
                        label: "新抚区",
                        value: "210402",
                        children: []
                    },
                    {
                        id: "534",
                        label: "东洲区",
                        value: "210403",
                        children: []
                    },
                    {
                        id: "535",
                        label: "望花区",
                        value: "210404",
                        children: []
                    },
                    {
                        id: "536",
                        label: "顺城区",
                        value: "210411",
                        children: []
                    },
                    {
                        id: "537",
                        label: "抚顺县",
                        value: "210421",
                        children: []
                    },
                    {
                        id: "538",
                        label: "新宾满族自治县",
                        value: "210422",
                        children: []
                    },
                    {
                        id: "539",
                        label: "清原满族自治县",
                        value: "210423",
                        children: []
                    }
                ]
            },
            {
                id: "1064",
                label: "本溪市",
                value: "210500",
                children: [
                    {
                        id: "540",
                        label: "市辖区",
                        value: "210501",
                        children: []
                    },
                    {
                        id: "541",
                        label: "平山区",
                        value: "210502",
                        children: []
                    },
                    {
                        id: "542",
                        label: "溪湖区",
                        value: "210503",
                        children: []
                    },
                    {
                        id: "543",
                        label: "明山区",
                        value: "210504",
                        children: []
                    },
                    {
                        id: "544",
                        label: "南芬区",
                        value: "210505",
                        children: []
                    },
                    {
                        id: "545",
                        label: "本溪满族自治县",
                        value: "210521",
                        children: []
                    },
                    {
                        id: "546",
                        label: "桓仁满族自治县",
                        value: "210522",
                        children: []
                    }
                ]
            },
            {
                id: "1065",
                label: "丹东市",
                value: "210600",
                children: [
                    {
                        id: "547",
                        label: "市辖区",
                        value: "210601",
                        children: []
                    },
                    {
                        id: "548",
                        label: "元宝区",
                        value: "210602",
                        children: []
                    },
                    {
                        id: "549",
                        label: "振兴区",
                        value: "210603",
                        children: []
                    },
                    {
                        id: "550",
                        label: "振安区",
                        value: "210604",
                        children: []
                    },
                    {
                        id: "551",
                        label: "宽甸满族自治县",
                        value: "210624",
                        children: []
                    },
                    {
                        id: "552",
                        label: "东港市",
                        value: "210681",
                        children: []
                    },
                    {
                        id: "553",
                        label: "凤城市",
                        value: "210682",
                        children: []
                    }
                ]
            },
            {
                id: "1066",
                label: "锦州市",
                value: "210700",
                children: [
                    {
                        id: "554",
                        label: "市辖区",
                        value: "210701",
                        children: []
                    },
                    {
                        id: "555",
                        label: "古塔区",
                        value: "210702",
                        children: []
                    },
                    {
                        id: "556",
                        label: "凌河区",
                        value: "210703",
                        children: []
                    },
                    {
                        id: "557",
                        label: "太和区",
                        value: "210711",
                        children: []
                    },
                    {
                        id: "558",
                        label: "黑山县",
                        value: "210726",
                        children: []
                    },
                    {
                        id: "559",
                        label: "义县",
                        value: "210727",
                        children: []
                    },
                    {
                        id: "560",
                        label: "凌海市",
                        value: "210781",
                        children: []
                    },
                    {
                        id: "561",
                        label: "北镇市",
                        value: "210782",
                        children: []
                    }
                ]
            },
            {
                id: "1067",
                label: "营口市",
                value: "210800",
                children: [
                    {
                        id: "562",
                        label: "市辖区",
                        value: "210801",
                        children: []
                    },
                    {
                        id: "563",
                        label: "站前区",
                        value: "210802",
                        children: []
                    },
                    {
                        id: "564",
                        label: "西市区",
                        value: "210803",
                        children: []
                    },
                    {
                        id: "565",
                        label: "鲅鱼圈区",
                        value: "210804",
                        children: []
                    },
                    {
                        id: "566",
                        label: "老边区",
                        value: "210811",
                        children: []
                    },
                    {
                        id: "567",
                        label: "盖州市",
                        value: "210881",
                        children: []
                    },
                    {
                        id: "568",
                        label: "大石桥市",
                        value: "210882",
                        children: []
                    }
                ]
            },
            {
                id: "1068",
                label: "阜新市",
                value: "210900",
                children: [
                    {
                        id: "569",
                        label: "市辖区",
                        value: "210901",
                        children: []
                    },
                    {
                        id: "570",
                        label: "海州区",
                        value: "210902",
                        children: []
                    },
                    {
                        id: "571",
                        label: "新邱区",
                        value: "210903",
                        children: []
                    },
                    {
                        id: "572",
                        label: "太平区",
                        value: "210904",
                        children: []
                    },
                    {
                        id: "573",
                        label: "清河门区",
                        value: "210905",
                        children: []
                    },
                    {
                        id: "574",
                        label: "细河区",
                        value: "210911",
                        children: []
                    },
                    {
                        id: "575",
                        label: "阜新蒙古族自治县",
                        value: "210921",
                        children: []
                    },
                    {
                        id: "576",
                        label: "彰武县",
                        value: "210922",
                        children: []
                    }
                ]
            },
            {
                id: "1069",
                label: "辽阳市",
                value: "211000",
                children: [
                    {
                        id: "577",
                        label: "市辖区",
                        value: "211001",
                        children: []
                    },
                    {
                        id: "578",
                        label: "白塔区",
                        value: "211002",
                        children: []
                    },
                    {
                        id: "579",
                        label: "文圣区",
                        value: "211003",
                        children: []
                    },
                    {
                        id: "580",
                        label: "宏伟区",
                        value: "211004",
                        children: []
                    },
                    {
                        id: "581",
                        label: "弓长岭区",
                        value: "211005",
                        children: []
                    },
                    {
                        id: "582",
                        label: "太子河区",
                        value: "211011",
                        children: []
                    },
                    {
                        id: "583",
                        label: "辽阳县",
                        value: "211021",
                        children: []
                    },
                    {
                        id: "584",
                        label: "灯塔市",
                        value: "211081",
                        children: []
                    }
                ]
            },
            {
                id: "1070",
                label: "盘锦市",
                value: "211100",
                children: [
                    {
                        id: "585",
                        label: "市辖区",
                        value: "211101",
                        children: []
                    },
                    {
                        id: "586",
                        label: "双台子区",
                        value: "211102",
                        children: []
                    },
                    {
                        id: "587",
                        label: "兴隆台区",
                        value: "211103",
                        children: []
                    },
                    {
                        id: "588",
                        label: "大洼县",
                        value: "211121",
                        children: []
                    },
                    {
                        id: "589",
                        label: "盘山县",
                        value: "211122",
                        children: []
                    }
                ]
            },
            {
                id: "1071",
                label: "铁岭市",
                value: "211200",
                children: [
                    {
                        id: "590",
                        label: "市辖区",
                        value: "211201",
                        children: []
                    },
                    {
                        id: "591",
                        label: "银州区",
                        value: "211202",
                        children: []
                    },
                    {
                        id: "592",
                        label: "清河区",
                        value: "211204",
                        children: []
                    },
                    {
                        id: "593",
                        label: "铁岭县",
                        value: "211221",
                        children: []
                    },
                    {
                        id: "594",
                        label: "西丰县",
                        value: "211223",
                        children: []
                    },
                    {
                        id: "595",
                        label: "昌图县",
                        value: "211224",
                        children: []
                    },
                    {
                        id: "596",
                        label: "调兵山市",
                        value: "211281",
                        children: []
                    },
                    {
                        id: "597",
                        label: "开原市",
                        value: "211282",
                        children: []
                    }
                ]
            },
            {
                id: "1072",
                label: "朝阳市",
                value: "211300",
                children: [
                    {
                        id: "598",
                        label: "市辖区",
                        value: "211301",
                        children: []
                    },
                    {
                        id: "599",
                        label: "双塔区",
                        value: "211302",
                        children: []
                    },
                    {
                        id: "600",
                        label: "龙城区",
                        value: "211303",
                        children: []
                    },
                    {
                        id: "601",
                        label: "朝阳县",
                        value: "211321",
                        children: []
                    },
                    {
                        id: "602",
                        label: "建平县",
                        value: "211322",
                        children: []
                    },
                    {
                        id: "603",
                        label: "喀喇沁左翼蒙古族自治县",
                        value: "211324",
                        children: []
                    },
                    {
                        id: "604",
                        label: "北票市",
                        value: "211381",
                        children: []
                    },
                    {
                        id: "605",
                        label: "凌源市",
                        value: "211382",
                        children: []
                    }
                ]
            },
            {
                id: "1073",
                label: "葫芦岛市",
                value: "211400",
                children: [
                    {
                        id: "606",
                        label: "市辖区",
                        value: "211401",
                        children: []
                    },
                    {
                        id: "607",
                        label: "连山区",
                        value: "211402",
                        children: []
                    },
                    {
                        id: "608",
                        label: "龙港区",
                        value: "211403",
                        children: []
                    },
                    {
                        id: "609",
                        label: "南票区",
                        value: "211404",
                        children: []
                    },
                    {
                        id: "610",
                        label: "绥中县",
                        value: "211421",
                        children: []
                    },
                    {
                        id: "611",
                        label: "建昌县",
                        value: "211422",
                        children: []
                    },
                    {
                        id: "612",
                        label: "兴城市",
                        value: "211481",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "8",
        label: "吉林省",
        value: "220000",
        children: [
            {
                id: "1074",
                label: "长春市",
                value: "220100",
                children: [
                    {
                        id: "613",
                        label: "市辖区",
                        value: "220101",
                        children: []
                    },
                    {
                        id: "614",
                        label: "南关区",
                        value: "220102",
                        children: []
                    },
                    {
                        id: "615",
                        label: "宽城区",
                        value: "220103",
                        children: []
                    },
                    {
                        id: "616",
                        label: "朝阳区",
                        value: "220104",
                        children: []
                    },
                    {
                        id: "617",
                        label: "二道区",
                        value: "220105",
                        children: []
                    },
                    {
                        id: "618",
                        label: "绿园区",
                        value: "220106",
                        children: []
                    },
                    {
                        id: "619",
                        label: "双阳区",
                        value: "220112",
                        children: []
                    },
                    {
                        id: "620",
                        label: "农安县",
                        value: "220122",
                        children: []
                    },
                    {
                        id: "621",
                        label: "九台市",
                        value: "220181",
                        children: []
                    },
                    {
                        id: "622",
                        label: "榆树市",
                        value: "220182",
                        children: []
                    },
                    {
                        id: "623",
                        label: "德惠市",
                        value: "220183",
                        children: []
                    }
                ]
            },
            {
                id: "1075",
                label: "吉林市",
                value: "220200",
                children: [
                    {
                        id: "624",
                        label: "市辖区",
                        value: "220201",
                        children: []
                    },
                    {
                        id: "625",
                        label: "昌邑区",
                        value: "220202",
                        children: []
                    },
                    {
                        id: "626",
                        label: "龙潭区",
                        value: "220203",
                        children: []
                    },
                    {
                        id: "627",
                        label: "船营区",
                        value: "220204",
                        children: []
                    },
                    {
                        id: "628",
                        label: "丰满区",
                        value: "220211",
                        children: []
                    },
                    {
                        id: "629",
                        label: "永吉县",
                        value: "220221",
                        children: []
                    },
                    {
                        id: "630",
                        label: "蛟河市",
                        value: "220281",
                        children: []
                    },
                    {
                        id: "631",
                        label: "桦甸市",
                        value: "220282",
                        children: []
                    },
                    {
                        id: "632",
                        label: "舒兰市",
                        value: "220283",
                        children: []
                    },
                    {
                        id: "633",
                        label: "磐石市",
                        value: "220284",
                        children: []
                    }
                ]
            },
            {
                id: "1076",
                label: "四平市",
                value: "220300",
                children: [
                    {
                        id: "634",
                        label: "市辖区",
                        value: "220301",
                        children: []
                    },
                    {
                        id: "635",
                        label: "铁西区",
                        value: "220302",
                        children: []
                    },
                    {
                        id: "636",
                        label: "铁东区",
                        value: "220303",
                        children: []
                    },
                    {
                        id: "637",
                        label: "梨树县",
                        value: "220322",
                        children: []
                    },
                    {
                        id: "638",
                        label: "伊通满族自治县",
                        value: "220323",
                        children: []
                    },
                    {
                        id: "639",
                        label: "公主岭市",
                        value: "220381",
                        children: []
                    },
                    {
                        id: "640",
                        label: "双辽市",
                        value: "220382",
                        children: []
                    }
                ]
            },
            {
                id: "1077",
                label: "辽源市",
                value: "220400",
                children: [
                    {
                        id: "641",
                        label: "市辖区",
                        value: "220401",
                        children: []
                    },
                    {
                        id: "642",
                        label: "龙山区",
                        value: "220402",
                        children: []
                    },
                    {
                        id: "643",
                        label: "西安区",
                        value: "220403",
                        children: []
                    },
                    {
                        id: "644",
                        label: "东丰县",
                        value: "220421",
                        children: []
                    },
                    {
                        id: "645",
                        label: "东辽县",
                        value: "220422",
                        children: []
                    }
                ]
            },
            {
                id: "1078",
                label: "通化市",
                value: "220500",
                children: [
                    {
                        id: "646",
                        label: "市辖区",
                        value: "220501",
                        children: []
                    },
                    {
                        id: "647",
                        label: "东昌区",
                        value: "220502",
                        children: []
                    },
                    {
                        id: "648",
                        label: "二道江区",
                        value: "220503",
                        children: []
                    },
                    {
                        id: "649",
                        label: "通化县",
                        value: "220521",
                        children: []
                    },
                    {
                        id: "650",
                        label: "辉南县",
                        value: "220523",
                        children: []
                    },
                    {
                        id: "651",
                        label: "柳河县",
                        value: "220524",
                        children: []
                    },
                    {
                        id: "652",
                        label: "梅河口市",
                        value: "220581",
                        children: []
                    },
                    {
                        id: "653",
                        label: "集安市",
                        value: "220582",
                        children: []
                    }
                ]
            },
            {
                id: "1079",
                label: "白山市",
                value: "220600",
                children: [
                    {
                        id: "654",
                        label: "市辖区",
                        value: "220601",
                        children: []
                    },
                    {
                        id: "655",
                        label: "八道江区",
                        value: "220602",
                        children: []
                    },
                    {
                        id: "656",
                        label: "江源区",
                        value: "220605",
                        children: []
                    },
                    {
                        id: "657",
                        label: "抚松县",
                        value: "220621",
                        children: []
                    },
                    {
                        id: "658",
                        label: "靖宇县",
                        value: "220622",
                        children: []
                    },
                    {
                        id: "659",
                        label: "长白朝鲜族自治县",
                        value: "220623",
                        children: []
                    },
                    {
                        id: "660",
                        label: "临江市",
                        value: "220681",
                        children: []
                    }
                ]
            },
            {
                id: "1080",
                label: "松原市",
                value: "220700",
                children: [
                    {
                        id: "661",
                        label: "市辖区",
                        value: "220701",
                        children: []
                    },
                    {
                        id: "662",
                        label: "宁江区",
                        value: "220702",
                        children: []
                    },
                    {
                        id: "663",
                        label: "前郭尔罗斯蒙古族自治县",
                        value: "220721",
                        children: []
                    },
                    {
                        id: "664",
                        label: "长岭县",
                        value: "220722",
                        children: []
                    },
                    {
                        id: "665",
                        label: "乾安县",
                        value: "220723",
                        children: []
                    },
                    {
                        id: "666",
                        label: "扶余县",
                        value: "220724",
                        children: []
                    }
                ]
            },
            {
                id: "1081",
                label: "白城市",
                value: "220800",
                children: [
                    {
                        id: "667",
                        label: "市辖区",
                        value: "220801",
                        children: []
                    },
                    {
                        id: "668",
                        label: "洮北区",
                        value: "220802",
                        children: []
                    },
                    {
                        id: "669",
                        label: "镇赉县",
                        value: "220821",
                        children: []
                    },
                    {
                        id: "670",
                        label: "通榆县",
                        value: "220822",
                        children: []
                    },
                    {
                        id: "671",
                        label: "洮南市",
                        value: "220881",
                        children: []
                    },
                    {
                        id: "672",
                        label: "大安市",
                        value: "220882",
                        children: []
                    }
                ]
            },
            {
                id: "1082",
                label: "延边朝鲜族自治州",
                value: "222400",
                children: [
                    {
                        id: "673",
                        label: "延吉市",
                        value: "222401",
                        children: []
                    },
                    {
                        id: "674",
                        label: "图们市",
                        value: "222402",
                        children: []
                    },
                    {
                        id: "675",
                        label: "敦化市",
                        value: "222403",
                        children: []
                    },
                    {
                        id: "676",
                        label: "珲春市",
                        value: "222404",
                        children: []
                    },
                    {
                        id: "677",
                        label: "龙井市",
                        value: "222405",
                        children: []
                    },
                    {
                        id: "678",
                        label: "和龙市",
                        value: "222406",
                        children: []
                    },
                    {
                        id: "679",
                        label: "汪清县",
                        value: "222424",
                        children: []
                    },
                    {
                        id: "680",
                        label: "安图县",
                        value: "222426",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "9",
        label: "黑龙江省",
        value: "230000",
        children: [
            {
                id: "1083",
                label: "哈尔滨市",
                value: "230100",
                children: [
                    {
                        id: "681",
                        label: "市辖区",
                        value: "230101",
                        children: []
                    },
                    {
                        id: "682",
                        label: "道里区",
                        value: "230102",
                        children: []
                    },
                    {
                        id: "683",
                        label: "南岗区",
                        value: "230103",
                        children: []
                    },
                    {
                        id: "684",
                        label: "道外区",
                        value: "230104",
                        children: []
                    },
                    {
                        id: "685",
                        label: "平房区",
                        value: "230108",
                        children: []
                    },
                    {
                        id: "686",
                        label: "松北区",
                        value: "230109",
                        children: []
                    },
                    {
                        id: "687",
                        label: "香坊区",
                        value: "230110",
                        children: []
                    },
                    {
                        id: "688",
                        label: "呼兰区",
                        value: "230111",
                        children: []
                    },
                    {
                        id: "689",
                        label: "阿城区",
                        value: "230112",
                        children: []
                    },
                    {
                        id: "690",
                        label: "依兰县",
                        value: "230123",
                        children: []
                    },
                    {
                        id: "691",
                        label: "方正县",
                        value: "230124",
                        children: []
                    },
                    {
                        id: "692",
                        label: "宾县",
                        value: "230125",
                        children: []
                    },
                    {
                        id: "693",
                        label: "巴彦县",
                        value: "230126",
                        children: []
                    },
                    {
                        id: "694",
                        label: "木兰县",
                        value: "230127",
                        children: []
                    },
                    {
                        id: "695",
                        label: "通河县",
                        value: "230128",
                        children: []
                    },
                    {
                        id: "696",
                        label: "延寿县",
                        value: "230129",
                        children: []
                    },
                    {
                        id: "697",
                        label: "双城市",
                        value: "230182",
                        children: []
                    },
                    {
                        id: "698",
                        label: "尚志市",
                        value: "230183",
                        children: []
                    },
                    {
                        id: "699",
                        label: "五常市",
                        value: "230184",
                        children: []
                    }
                ]
            },
            {
                id: "1084",
                label: "齐齐哈尔市",
                value: "230200",
                children: [
                    {
                        id: "700",
                        label: "市辖区",
                        value: "230201",
                        children: []
                    },
                    {
                        id: "701",
                        label: "龙沙区",
                        value: "230202",
                        children: []
                    },
                    {
                        id: "702",
                        label: "建华区",
                        value: "230203",
                        children: []
                    },
                    {
                        id: "703",
                        label: "铁锋区",
                        value: "230204",
                        children: []
                    },
                    {
                        id: "704",
                        label: "昂昂溪区",
                        value: "230205",
                        children: []
                    },
                    {
                        id: "705",
                        label: "富拉尔基区",
                        value: "230206",
                        children: []
                    },
                    {
                        id: "706",
                        label: "碾子山区",
                        value: "230207",
                        children: []
                    },
                    {
                        id: "707",
                        label: "梅里斯达斡尔族区",
                        value: "230208",
                        children: []
                    },
                    {
                        id: "708",
                        label: "龙江县",
                        value: "230221",
                        children: []
                    },
                    {
                        id: "709",
                        label: "依安县",
                        value: "230223",
                        children: []
                    },
                    {
                        id: "710",
                        label: "泰来县",
                        value: "230224",
                        children: []
                    },
                    {
                        id: "711",
                        label: "甘南县",
                        value: "230225",
                        children: []
                    },
                    {
                        id: "712",
                        label: "富裕县",
                        value: "230227",
                        children: []
                    },
                    {
                        id: "713",
                        label: "克山县",
                        value: "230229",
                        children: []
                    },
                    {
                        id: "714",
                        label: "克东县",
                        value: "230230",
                        children: []
                    },
                    {
                        id: "715",
                        label: "拜泉县",
                        value: "230231",
                        children: []
                    },
                    {
                        id: "716",
                        label: "讷河市",
                        value: "230281",
                        children: []
                    }
                ]
            },
            {
                id: "1085",
                label: "鸡西市",
                value: "230300",
                children: [
                    {
                        id: "717",
                        label: "市辖区",
                        value: "230301",
                        children: []
                    },
                    {
                        id: "718",
                        label: "鸡冠区",
                        value: "230302",
                        children: []
                    },
                    {
                        id: "719",
                        label: "恒山区",
                        value: "230303",
                        children: []
                    },
                    {
                        id: "720",
                        label: "滴道区",
                        value: "230304",
                        children: []
                    },
                    {
                        id: "721",
                        label: "梨树区",
                        value: "230305",
                        children: []
                    },
                    {
                        id: "722",
                        label: "城子河区",
                        value: "230306",
                        children: []
                    },
                    {
                        id: "723",
                        label: "麻山区",
                        value: "230307",
                        children: []
                    },
                    {
                        id: "724",
                        label: "鸡东县",
                        value: "230321",
                        children: []
                    },
                    {
                        id: "725",
                        label: "虎林市",
                        value: "230381",
                        children: []
                    },
                    {
                        id: "726",
                        label: "密山市",
                        value: "230382",
                        children: []
                    }
                ]
            },
            {
                id: "1086",
                label: "鹤岗市",
                value: "230400",
                children: [
                    {
                        id: "727",
                        label: "市辖区",
                        value: "230401",
                        children: []
                    },
                    {
                        id: "728",
                        label: "向阳区",
                        value: "230402",
                        children: []
                    },
                    {
                        id: "729",
                        label: "工农区",
                        value: "230403",
                        children: []
                    },
                    {
                        id: "730",
                        label: "南山区",
                        value: "230404",
                        children: []
                    },
                    {
                        id: "731",
                        label: "兴安区",
                        value: "230405",
                        children: []
                    },
                    {
                        id: "732",
                        label: "东山区",
                        value: "230406",
                        children: []
                    },
                    {
                        id: "733",
                        label: "兴山区",
                        value: "230407",
                        children: []
                    },
                    {
                        id: "734",
                        label: "萝北县",
                        value: "230421",
                        children: []
                    },
                    {
                        id: "735",
                        label: "绥滨县",
                        value: "230422",
                        children: []
                    }
                ]
            },
            {
                id: "1087",
                label: "双鸭山市",
                value: "230500",
                children: [
                    {
                        id: "736",
                        label: "市辖区",
                        value: "230501",
                        children: []
                    },
                    {
                        id: "737",
                        label: "尖山区",
                        value: "230502",
                        children: []
                    },
                    {
                        id: "738",
                        label: "岭东区",
                        value: "230503",
                        children: []
                    },
                    {
                        id: "739",
                        label: "四方台区",
                        value: "230505",
                        children: []
                    },
                    {
                        id: "740",
                        label: "宝山区",
                        value: "230506",
                        children: []
                    },
                    {
                        id: "741",
                        label: "集贤县",
                        value: "230521",
                        children: []
                    },
                    {
                        id: "742",
                        label: "友谊县",
                        value: "230522",
                        children: []
                    },
                    {
                        id: "743",
                        label: "宝清县",
                        value: "230523",
                        children: []
                    },
                    {
                        id: "744",
                        label: "饶河县",
                        value: "230524",
                        children: []
                    }
                ]
            },
            {
                id: "1088",
                label: "大庆市",
                value: "230600",
                children: [
                    {
                        id: "745",
                        label: "市辖区",
                        value: "230601",
                        children: []
                    },
                    {
                        id: "746",
                        label: "萨尔图区",
                        value: "230602",
                        children: []
                    },
                    {
                        id: "747",
                        label: "龙凤区",
                        value: "230603",
                        children: []
                    },
                    {
                        id: "748",
                        label: "让胡路区",
                        value: "230604",
                        children: []
                    },
                    {
                        id: "749",
                        label: "红岗区",
                        value: "230605",
                        children: []
                    },
                    {
                        id: "750",
                        label: "大同区",
                        value: "230606",
                        children: []
                    },
                    {
                        id: "751",
                        label: "肇州县",
                        value: "230621",
                        children: []
                    },
                    {
                        id: "752",
                        label: "肇源县",
                        value: "230622",
                        children: []
                    },
                    {
                        id: "753",
                        label: "林甸县",
                        value: "230623",
                        children: []
                    },
                    {
                        id: "754",
                        label: "杜尔伯特蒙古族自治县",
                        value: "230624",
                        children: []
                    }
                ]
            },
            {
                id: "1089",
                label: "伊春市",
                value: "230700",
                children: [
                    {
                        id: "755",
                        label: "市辖区",
                        value: "230701",
                        children: []
                    },
                    {
                        id: "756",
                        label: "伊春区",
                        value: "230702",
                        children: []
                    },
                    {
                        id: "757",
                        label: "南岔区",
                        value: "230703",
                        children: []
                    },
                    {
                        id: "758",
                        label: "友好区",
                        value: "230704",
                        children: []
                    },
                    {
                        id: "759",
                        label: "西林区",
                        value: "230705",
                        children: []
                    },
                    {
                        id: "760",
                        label: "翠峦区",
                        value: "230706",
                        children: []
                    },
                    {
                        id: "761",
                        label: "新青区",
                        value: "230707",
                        children: []
                    },
                    {
                        id: "762",
                        label: "美溪区",
                        value: "230708",
                        children: []
                    },
                    {
                        id: "763",
                        label: "金山屯区",
                        value: "230709",
                        children: []
                    },
                    {
                        id: "764",
                        label: "五营区",
                        value: "230710",
                        children: []
                    },
                    {
                        id: "765",
                        label: "乌马河区",
                        value: "230711",
                        children: []
                    },
                    {
                        id: "766",
                        label: "汤旺河区",
                        value: "230712",
                        children: []
                    },
                    {
                        id: "767",
                        label: "带岭区",
                        value: "230713",
                        children: []
                    },
                    {
                        id: "768",
                        label: "乌伊岭区",
                        value: "230714",
                        children: []
                    },
                    {
                        id: "769",
                        label: "红星区",
                        value: "230715",
                        children: []
                    },
                    {
                        id: "770",
                        label: "上甘岭区",
                        value: "230716",
                        children: []
                    },
                    {
                        id: "771",
                        label: "嘉荫县",
                        value: "230722",
                        children: []
                    },
                    {
                        id: "772",
                        label: "铁力市",
                        value: "230781",
                        children: []
                    }
                ]
            },
            {
                id: "1090",
                label: "佳木斯市",
                value: "230800",
                children: [
                    {
                        id: "773",
                        label: "市辖区",
                        value: "230801",
                        children: []
                    },
                    {
                        id: "774",
                        label: "向阳区",
                        value: "230803",
                        children: []
                    },
                    {
                        id: "775",
                        label: "前进区",
                        value: "230804",
                        children: []
                    },
                    {
                        id: "776",
                        label: "东风区",
                        value: "230805",
                        children: []
                    },
                    {
                        id: "777",
                        label: "郊区",
                        value: "230811",
                        children: []
                    },
                    {
                        id: "778",
                        label: "桦南县",
                        value: "230822",
                        children: []
                    },
                    {
                        id: "779",
                        label: "桦川县",
                        value: "230826",
                        children: []
                    },
                    {
                        id: "780",
                        label: "汤原县",
                        value: "230828",
                        children: []
                    },
                    {
                        id: "781",
                        label: "抚远县",
                        value: "230833",
                        children: []
                    },
                    {
                        id: "782",
                        label: "同江市",
                        value: "230881",
                        children: []
                    },
                    {
                        id: "783",
                        label: "富锦市",
                        value: "230882",
                        children: []
                    }
                ]
            },
            {
                id: "1091",
                label: "七台河市",
                value: "230900",
                children: [
                    {
                        id: "784",
                        label: "市辖区",
                        value: "230901",
                        children: []
                    },
                    {
                        id: "785",
                        label: "新兴区",
                        value: "230902",
                        children: []
                    },
                    {
                        id: "786",
                        label: "桃山区",
                        value: "230903",
                        children: []
                    },
                    {
                        id: "787",
                        label: "茄子河区",
                        value: "230904",
                        children: []
                    },
                    {
                        id: "788",
                        label: "勃利县",
                        value: "230921",
                        children: []
                    }
                ]
            },
            {
                id: "1092",
                label: "牡丹江市",
                value: "231000",
                children: [
                    {
                        id: "789",
                        label: "市辖区",
                        value: "231001",
                        children: []
                    },
                    {
                        id: "790",
                        label: "东安区",
                        value: "231002",
                        children: []
                    },
                    {
                        id: "791",
                        label: "阳明区",
                        value: "231003",
                        children: []
                    },
                    {
                        id: "792",
                        label: "爱民区",
                        value: "231004",
                        children: []
                    },
                    {
                        id: "793",
                        label: "西安区",
                        value: "231005",
                        children: []
                    },
                    {
                        id: "794",
                        label: "东宁县",
                        value: "231024",
                        children: []
                    },
                    {
                        id: "795",
                        label: "林口县",
                        value: "231025",
                        children: []
                    },
                    {
                        id: "796",
                        label: "绥芬河市",
                        value: "231081",
                        children: []
                    },
                    {
                        id: "797",
                        label: "海林市",
                        value: "231083",
                        children: []
                    },
                    {
                        id: "798",
                        label: "宁安市",
                        value: "231084",
                        children: []
                    },
                    {
                        id: "799",
                        label: "穆棱市",
                        value: "231085",
                        children: []
                    }
                ]
            },
            {
                id: "1093",
                label: "黑河市",
                value: "231100",
                children: [
                    {
                        id: "800",
                        label: "市辖区",
                        value: "231101",
                        children: []
                    },
                    {
                        id: "801",
                        label: "爱辉区",
                        value: "231102",
                        children: []
                    },
                    {
                        id: "802",
                        label: "嫩江县",
                        value: "231121",
                        children: []
                    },
                    {
                        id: "803",
                        label: "逊克县",
                        value: "231123",
                        children: []
                    },
                    {
                        id: "804",
                        label: "孙吴县",
                        value: "231124",
                        children: []
                    },
                    {
                        id: "805",
                        label: "北安市",
                        value: "231181",
                        children: []
                    },
                    {
                        id: "806",
                        label: "五大连池市",
                        value: "231182",
                        children: []
                    }
                ]
            },
            {
                id: "1094",
                label: "绥化市",
                value: "231200",
                children: [
                    {
                        id: "807",
                        label: "市辖区",
                        value: "231201",
                        children: []
                    },
                    {
                        id: "808",
                        label: "北林区",
                        value: "231202",
                        children: []
                    },
                    {
                        id: "809",
                        label: "望奎县",
                        value: "231221",
                        children: []
                    },
                    {
                        id: "810",
                        label: "兰西县",
                        value: "231222",
                        children: []
                    },
                    {
                        id: "811",
                        label: "青冈县",
                        value: "231223",
                        children: []
                    },
                    {
                        id: "812",
                        label: "庆安县",
                        value: "231224",
                        children: []
                    },
                    {
                        id: "813",
                        label: "明水县",
                        value: "231225",
                        children: []
                    },
                    {
                        id: "814",
                        label: "绥棱县",
                        value: "231226",
                        children: []
                    },
                    {
                        id: "815",
                        label: "安达市",
                        value: "231281",
                        children: []
                    },
                    {
                        id: "816",
                        label: "肇东市",
                        value: "231282",
                        children: []
                    },
                    {
                        id: "817",
                        label: "海伦市",
                        value: "231283",
                        children: []
                    }
                ]
            },
            {
                id: "1095",
                label: "大兴安岭地区",
                value: "232700",
                children: [
                    {
                        id: "818",
                        label: "加格达奇区",
                        value: "232701",
                        children: []
                    },
                    {
                        id: "819",
                        label: "松岭区",
                        value: "232702",
                        children: []
                    },
                    {
                        id: "820",
                        label: "新林区",
                        value: "232703",
                        children: []
                    },
                    {
                        id: "821",
                        label: "呼中区",
                        value: "232704",
                        children: []
                    },
                    {
                        id: "822",
                        label: "呼玛县",
                        value: "232721",
                        children: []
                    },
                    {
                        id: "823",
                        label: "塔河县",
                        value: "232722",
                        children: []
                    },
                    {
                        id: "824",
                        label: "漠河县",
                        value: "232723",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "10",
        label: "江苏省",
        value: "320000",
        children: [
            {
                id: "1098",
                label: "南京市",
                value: "320100",
                children: [
                    {
                        id: "843",
                        label: "市辖区",
                        value: "320101",
                        children: []
                    },
                    {
                        id: "844",
                        label: "玄武区",
                        value: "320102",
                        children: []
                    },
                    {
                        id: "845",
                        label: "白下区",
                        value: "320103",
                        children: []
                    },
                    {
                        id: "846",
                        label: "秦淮区",
                        value: "320104",
                        children: []
                    },
                    {
                        id: "847",
                        label: "建邺区",
                        value: "320105",
                        children: []
                    },
                    {
                        id: "848",
                        label: "鼓楼区",
                        value: "320106",
                        children: []
                    },
                    {
                        id: "849",
                        label: "下关区",
                        value: "320107",
                        children: []
                    },
                    {
                        id: "850",
                        label: "浦口区",
                        value: "320111",
                        children: []
                    },
                    {
                        id: "851",
                        label: "栖霞区",
                        value: "320113",
                        children: []
                    },
                    {
                        id: "852",
                        label: "雨花台区",
                        value: "320114",
                        children: []
                    },
                    {
                        id: "853",
                        label: "江宁区",
                        value: "320115",
                        children: []
                    },
                    {
                        id: "854",
                        label: "六合区",
                        value: "320116",
                        children: []
                    },
                    {
                        id: "855",
                        label: "溧水县",
                        value: "320124",
                        children: []
                    },
                    {
                        id: "856",
                        label: "高淳县",
                        value: "320125",
                        children: []
                    }
                ]
            },
            {
                id: "1099",
                label: "无锡市",
                value: "320200",
                children: [
                    {
                        id: "857",
                        label: "市辖区",
                        value: "320201",
                        children: []
                    },
                    {
                        id: "858",
                        label: "崇安区",
                        value: "320202",
                        children: []
                    },
                    {
                        id: "859",
                        label: "南长区",
                        value: "320203",
                        children: []
                    },
                    {
                        id: "860",
                        label: "北塘区",
                        value: "320204",
                        children: []
                    },
                    {
                        id: "861",
                        label: "锡山区",
                        value: "320205",
                        children: []
                    },
                    {
                        id: "862",
                        label: "惠山区",
                        value: "320206",
                        children: []
                    },
                    {
                        id: "863",
                        label: "滨湖区",
                        value: "320211",
                        children: []
                    },
                    {
                        id: "864",
                        label: "江阴市",
                        value: "320281",
                        children: []
                    },
                    {
                        id: "865",
                        label: "宜兴市",
                        value: "320282",
                        children: []
                    }
                ]
            },
            {
                id: "1100",
                label: "徐州市",
                value: "320300",
                children: [
                    {
                        id: "866",
                        label: "市辖区",
                        value: "320301",
                        children: []
                    },
                    {
                        id: "867",
                        label: "鼓楼区",
                        value: "320302",
                        children: []
                    },
                    {
                        id: "868",
                        label: "云龙区",
                        value: "320303",
                        children: []
                    },
                    {
                        id: "869",
                        label: "贾汪区",
                        value: "320305",
                        children: []
                    },
                    {
                        id: "870",
                        label: "泉山区",
                        value: "320311",
                        children: []
                    },
                    {
                        id: "871",
                        label: "铜山区",
                        value: "320312",
                        children: []
                    },
                    {
                        id: "872",
                        label: "丰县",
                        value: "320321",
                        children: []
                    },
                    {
                        id: "873",
                        label: "沛县",
                        value: "320322",
                        children: []
                    },
                    {
                        id: "874",
                        label: "睢宁县",
                        value: "320324",
                        children: []
                    },
                    {
                        id: "875",
                        label: "新沂市",
                        value: "320381",
                        children: []
                    },
                    {
                        id: "876",
                        label: "邳州市",
                        value: "320382",
                        children: []
                    }
                ]
            },
            {
                id: "1101",
                label: "常州市",
                value: "320400",
                children: [
                    {
                        id: "877",
                        label: "市辖区",
                        value: "320401",
                        children: []
                    },
                    {
                        id: "878",
                        label: "天宁区",
                        value: "320402",
                        children: []
                    },
                    {
                        id: "879",
                        label: "钟楼区",
                        value: "320404",
                        children: []
                    },
                    {
                        id: "880",
                        label: "戚墅堰区",
                        value: "320405",
                        children: []
                    },
                    {
                        id: "881",
                        label: "新北区",
                        value: "320411",
                        children: []
                    },
                    {
                        id: "882",
                        label: "武进区",
                        value: "320412",
                        children: []
                    },
                    {
                        id: "883",
                        label: "溧阳市",
                        value: "320481",
                        children: []
                    },
                    {
                        id: "884",
                        label: "金坛市",
                        value: "320482",
                        children: []
                    }
                ]
            },
            {
                id: "1102",
                label: "苏州市",
                value: "320500",
                children: [
                    {
                        id: "885",
                        label: "市辖区",
                        value: "320501",
                        children: []
                    },
                    {
                        id: "886",
                        label: "沧浪区",
                        value: "320502",
                        children: []
                    },
                    {
                        id: "887",
                        label: "平江区",
                        value: "320503",
                        children: []
                    },
                    {
                        id: "888",
                        label: "金阊区",
                        value: "320504",
                        children: []
                    },
                    {
                        id: "889",
                        label: "虎丘区",
                        value: "320505",
                        children: []
                    },
                    {
                        id: "890",
                        label: "吴中区",
                        value: "320506",
                        children: []
                    },
                    {
                        id: "891",
                        label: "相城区",
                        value: "320507",
                        children: []
                    },
                    {
                        id: "892",
                        label: "常熟市",
                        value: "320581",
                        children: []
                    },
                    {
                        id: "893",
                        label: "张家港市",
                        value: "320582",
                        children: []
                    },
                    {
                        id: "894",
                        label: "昆山市",
                        value: "320583",
                        children: []
                    },
                    {
                        id: "895",
                        label: "吴江市",
                        value: "320584",
                        children: []
                    },
                    {
                        id: "896",
                        label: "太仓市",
                        value: "320585",
                        children: []
                    }
                ]
            },
            {
                id: "1103",
                label: "南通市",
                value: "320600",
                children: [
                    {
                        id: "897",
                        label: "市辖区",
                        value: "320601",
                        children: []
                    },
                    {
                        id: "898",
                        label: "崇川区",
                        value: "320602",
                        children: []
                    },
                    {
                        id: "899",
                        label: "港闸区",
                        value: "320611",
                        children: []
                    },
                    {
                        id: "900",
                        label: "通州区",
                        value: "320612",
                        children: []
                    },
                    {
                        id: "901",
                        label: "海安县",
                        value: "320621",
                        children: []
                    },
                    {
                        id: "902",
                        label: "如东县",
                        value: "320623",
                        children: []
                    },
                    {
                        id: "903",
                        label: "启东市",
                        value: "320681",
                        children: []
                    },
                    {
                        id: "904",
                        label: "如皋市",
                        value: "320682",
                        children: []
                    },
                    {
                        id: "905",
                        label: "海门市",
                        value: "320684",
                        children: []
                    }
                ]
            },
            {
                id: "1104",
                label: "连云港市",
                value: "320700",
                children: [
                    {
                        id: "906",
                        label: "市辖区",
                        value: "320701",
                        children: []
                    },
                    {
                        id: "907",
                        label: "连云区",
                        value: "320703",
                        children: []
                    },
                    {
                        id: "908",
                        label: "新浦区",
                        value: "320705",
                        children: []
                    },
                    {
                        id: "909",
                        label: "海州区",
                        value: "320706",
                        children: []
                    },
                    {
                        id: "910",
                        label: "赣榆县",
                        value: "320721",
                        children: []
                    },
                    {
                        id: "911",
                        label: "东海县",
                        value: "320722",
                        children: []
                    },
                    {
                        id: "912",
                        label: "灌云县",
                        value: "320723",
                        children: []
                    },
                    {
                        id: "913",
                        label: "灌南县",
                        value: "320724",
                        children: []
                    }
                ]
            },
            {
                id: "1105",
                label: "淮安市",
                value: "320800",
                children: [
                    {
                        id: "914",
                        label: "市辖区",
                        value: "320801",
                        children: []
                    },
                    {
                        id: "915",
                        label: "清河区",
                        value: "320802",
                        children: []
                    },
                    {
                        id: "916",
                        label: "楚州区",
                        value: "320803",
                        children: []
                    },
                    {
                        id: "917",
                        label: "淮阴区",
                        value: "320804",
                        children: []
                    },
                    {
                        id: "918",
                        label: "清浦区",
                        value: "320811",
                        children: []
                    },
                    {
                        id: "919",
                        label: "涟水县",
                        value: "320826",
                        children: []
                    },
                    {
                        id: "920",
                        label: "洪泽县",
                        value: "320829",
                        children: []
                    },
                    {
                        id: "921",
                        label: "盱眙县",
                        value: "320830",
                        children: []
                    },
                    {
                        id: "922",
                        label: "金湖县",
                        value: "320831",
                        children: []
                    }
                ]
            },
            {
                id: "1106",
                label: "盐城市",
                value: "320900",
                children: [
                    {
                        id: "923",
                        label: "市辖区",
                        value: "320901",
                        children: []
                    },
                    {
                        id: "924",
                        label: "亭湖区",
                        value: "320902",
                        children: []
                    },
                    {
                        id: "925",
                        label: "盐都区",
                        value: "320903",
                        children: []
                    },
                    {
                        id: "926",
                        label: "响水县",
                        value: "320921",
                        children: []
                    },
                    {
                        id: "927",
                        label: "滨海县",
                        value: "320922",
                        children: []
                    },
                    {
                        id: "928",
                        label: "阜宁县",
                        value: "320923",
                        children: []
                    },
                    {
                        id: "929",
                        label: "射阳县",
                        value: "320924",
                        children: []
                    },
                    {
                        id: "930",
                        label: "建湖县",
                        value: "320925",
                        children: []
                    },
                    {
                        id: "931",
                        label: "东台市",
                        value: "320981",
                        children: []
                    },
                    {
                        id: "932",
                        label: "大丰市",
                        value: "320982",
                        children: []
                    }
                ]
            },
            {
                id: "1107",
                label: "扬州市",
                value: "321000",
                children: [
                    {
                        id: "933",
                        label: "市辖区",
                        value: "321001",
                        children: []
                    },
                    {
                        id: "934",
                        label: "广陵区",
                        value: "321002",
                        children: []
                    },
                    {
                        id: "935",
                        label: "邗江区",
                        value: "321003",
                        children: []
                    },
                    {
                        id: "936",
                        label: "维扬区",
                        value: "321011",
                        children: []
                    },
                    {
                        id: "937",
                        label: "宝应县",
                        value: "321023",
                        children: []
                    },
                    {
                        id: "938",
                        label: "仪征市",
                        value: "321081",
                        children: []
                    },
                    {
                        id: "939",
                        label: "高邮市",
                        value: "321084",
                        children: []
                    },
                    {
                        id: "940",
                        label: "江都市",
                        value: "321088",
                        children: []
                    }
                ]
            },
            {
                id: "1108",
                label: "镇江市",
                value: "321100",
                children: [
                    {
                        id: "941",
                        label: "市辖区",
                        value: "321101",
                        children: []
                    },
                    {
                        id: "942",
                        label: "京口区",
                        value: "321102",
                        children: []
                    },
                    {
                        id: "943",
                        label: "润州区",
                        value: "321111",
                        children: []
                    },
                    {
                        id: "944",
                        label: "丹徒区",
                        value: "321112",
                        children: []
                    },
                    {
                        id: "945",
                        label: "丹阳市",
                        value: "321181",
                        children: []
                    },
                    {
                        id: "946",
                        label: "扬中市",
                        value: "321182",
                        children: []
                    },
                    {
                        id: "947",
                        label: "句容市",
                        value: "321183",
                        children: []
                    }
                ]
            },
            {
                id: "1109",
                label: "泰州市",
                value: "321200",
                children: [
                    {
                        id: "948",
                        label: "市辖区",
                        value: "321201",
                        children: []
                    },
                    {
                        id: "949",
                        label: "海陵区",
                        value: "321202",
                        children: []
                    },
                    {
                        id: "950",
                        label: "高港区",
                        value: "321203",
                        children: []
                    },
                    {
                        id: "951",
                        label: "兴化市",
                        value: "321281",
                        children: []
                    },
                    {
                        id: "952",
                        label: "靖江市",
                        value: "321282",
                        children: []
                    },
                    {
                        id: "953",
                        label: "泰兴市",
                        value: "321283",
                        children: []
                    },
                    {
                        id: "954",
                        label: "姜堰市",
                        value: "321284",
                        children: []
                    }
                ]
            },
            {
                id: "1110",
                label: "宿迁市",
                value: "321300",
                children: [
                    {
                        id: "955",
                        label: "市辖区",
                        value: "321301",
                        children: []
                    },
                    {
                        id: "956",
                        label: "宿城区",
                        value: "321302",
                        children: []
                    },
                    {
                        id: "957",
                        label: "宿豫区",
                        value: "321311",
                        children: []
                    },
                    {
                        id: "958",
                        label: "沭阳县",
                        value: "321322",
                        children: []
                    },
                    {
                        id: "959",
                        label: "泗阳县",
                        value: "321323",
                        children: []
                    },
                    {
                        id: "960",
                        label: "泗洪县",
                        value: "321324",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "11",
        label: "浙江省",
        value: "330000",
        children: [
            {
                id: "1111",
                label: "杭州市",
                value: "330100",
                children: [
                    {
                        id: "961",
                        label: "市辖区",
                        value: "330101",
                        children: []
                    },
                    {
                        id: "962",
                        label: "上城区",
                        value: "330102",
                        children: []
                    },
                    {
                        id: "963",
                        label: "下城区",
                        value: "330103",
                        children: []
                    },
                    {
                        id: "964",
                        label: "江干区",
                        value: "330104",
                        children: []
                    },
                    {
                        id: "965",
                        label: "拱墅区",
                        value: "330105",
                        children: []
                    },
                    {
                        id: "966",
                        label: "西湖区",
                        value: "330106",
                        children: []
                    },
                    {
                        id: "967",
                        label: "滨江区",
                        value: "330108",
                        children: []
                    },
                    {
                        id: "968",
                        label: "萧山区",
                        value: "330109",
                        children: []
                    },
                    {
                        id: "969",
                        label: "余杭区",
                        value: "330110",
                        children: []
                    },
                    {
                        id: "970",
                        label: "桐庐县",
                        value: "330122",
                        children: []
                    },
                    {
                        id: "971",
                        label: "淳安县",
                        value: "330127",
                        children: []
                    },
                    {
                        id: "972",
                        label: "建德市",
                        value: "330182",
                        children: []
                    },
                    {
                        id: "973",
                        label: "富阳市",
                        value: "330183",
                        children: []
                    },
                    {
                        id: "974",
                        label: "临安市",
                        value: "330185",
                        children: []
                    }
                ]
            },
            {
                id: "1112",
                label: "宁波市",
                value: "330200",
                children: [
                    {
                        id: "975",
                        label: "市辖区",
                        value: "330201",
                        children: []
                    },
                    {
                        id: "976",
                        label: "海曙区",
                        value: "330203",
                        children: []
                    },
                    {
                        id: "977",
                        label: "江东区",
                        value: "330204",
                        children: []
                    },
                    {
                        id: "978",
                        label: "江北区",
                        value: "330205",
                        children: []
                    },
                    {
                        id: "979",
                        label: "北仑区",
                        value: "330206",
                        children: []
                    },
                    {
                        id: "980",
                        label: "镇海区",
                        value: "330211",
                        children: []
                    },
                    {
                        id: "981",
                        label: "鄞州区",
                        value: "330212",
                        children: []
                    },
                    {
                        id: "982",
                        label: "象山县",
                        value: "330225",
                        children: []
                    },
                    {
                        id: "983",
                        label: "宁海县",
                        value: "330226",
                        children: []
                    },
                    {
                        id: "984",
                        label: "余姚市",
                        value: "330281",
                        children: []
                    },
                    {
                        id: "985",
                        label: "慈溪市",
                        value: "330282",
                        children: []
                    },
                    {
                        id: "986",
                        label: "奉化市",
                        value: "330283",
                        children: []
                    }
                ]
            },
            {
                id: "1113",
                label: "温州市",
                value: "330300",
                children: [
                    {
                        id: "987",
                        label: "市辖区",
                        value: "330301",
                        children: []
                    },
                    {
                        id: "988",
                        label: "鹿城区",
                        value: "330302",
                        children: []
                    },
                    {
                        id: "989",
                        label: "龙湾区",
                        value: "330303",
                        children: []
                    },
                    {
                        id: "990",
                        label: "瓯海区",
                        value: "330304",
                        children: []
                    },
                    {
                        id: "991",
                        label: "洞头县",
                        value: "330322",
                        children: []
                    },
                    {
                        id: "992",
                        label: "永嘉县",
                        value: "330324",
                        children: []
                    },
                    {
                        id: "993",
                        label: "平阳县",
                        value: "330326",
                        children: []
                    },
                    {
                        id: "994",
                        label: "苍南县",
                        value: "330327",
                        children: []
                    },
                    {
                        id: "995",
                        label: "文成县",
                        value: "330328",
                        children: []
                    },
                    {
                        id: "996",
                        label: "泰顺县",
                        value: "330329",
                        children: []
                    },
                    {
                        id: "997",
                        label: "瑞安市",
                        value: "330381",
                        children: []
                    },
                    {
                        id: "998",
                        label: "乐清市",
                        value: "330382",
                        children: []
                    }
                ]
            },
            {
                id: "1114",
                label: "嘉兴市",
                value: "330400",
                children: [
                    {
                        id: "999",
                        label: "市辖区",
                        value: "330401",
                        children: []
                    },
                    {
                        id: "1000",
                        label: "南湖区",
                        value: "330402",
                        children: []
                    },
                    {
                        id: "1001",
                        label: "秀洲区",
                        value: "330411",
                        children: []
                    },
                    {
                        id: "1002",
                        label: "嘉善县",
                        value: "330421",
                        children: []
                    },
                    {
                        id: "1003",
                        label: "海盐县",
                        value: "330424",
                        children: []
                    },
                    {
                        id: "1004",
                        label: "海宁市",
                        value: "330481",
                        children: []
                    },
                    {
                        id: "1005",
                        label: "平湖市",
                        value: "330482",
                        children: []
                    },
                    {
                        id: "1006",
                        label: "桐乡市",
                        value: "330483",
                        children: []
                    }
                ]
            },
            {
                id: "1115",
                label: "湖州市",
                value: "330500",
                children: [
                    {
                        id: "1007",
                        label: "市辖区",
                        value: "330501",
                        children: []
                    },
                    {
                        id: "1008",
                        label: "吴兴区",
                        value: "330502",
                        children: []
                    },
                    {
                        id: "1009",
                        label: "南浔区",
                        value: "330503",
                        children: []
                    },
                    {
                        id: "1010",
                        label: "德清县",
                        value: "330521",
                        children: []
                    },
                    {
                        id: "1011",
                        label: "长兴县",
                        value: "330522",
                        children: []
                    },
                    {
                        id: "1012",
                        label: "安吉县",
                        value: "330523",
                        children: []
                    }
                ]
            },
            {
                id: "1116",
                label: "绍兴市",
                value: "330600",
                children: [
                    {
                        id: "1013",
                        label: "市辖区",
                        value: "330601",
                        children: []
                    },
                    {
                        id: "1014",
                        label: "越城区",
                        value: "330602",
                        children: []
                    },
                    {
                        id: "1015",
                        label: "绍兴县",
                        value: "330621",
                        children: []
                    },
                    {
                        id: "1016",
                        label: "新昌县",
                        value: "330624",
                        children: []
                    },
                    {
                        id: "1017",
                        label: "诸暨市",
                        value: "330681",
                        children: []
                    },
                    {
                        id: "1018",
                        label: "上虞市",
                        value: "330682",
                        children: []
                    },
                    {
                        id: "1019",
                        label: "嵊州市",
                        value: "330683",
                        children: []
                    }
                ]
            },
            {
                id: "1117",
                label: "金华市",
                value: "330700",
                children: [
                    {
                        id: "1020",
                        label: "市辖区",
                        value: "330701",
                        children: []
                    },
                    {
                        id: "1021",
                        label: "婺城区",
                        value: "330702",
                        children: []
                    },
                    {
                        id: "1022",
                        label: "金东区",
                        value: "330703",
                        children: []
                    },
                    {
                        id: "1023",
                        label: "武义县",
                        value: "330723",
                        children: []
                    },
                    {
                        id: "1024",
                        label: "浦江县",
                        value: "330726",
                        children: []
                    },
                    {
                        id: "1025",
                        label: "磐安县",
                        value: "330727",
                        children: []
                    },
                    {
                        id: "1026",
                        label: "兰溪市",
                        value: "330781",
                        children: []
                    },
                    {
                        id: "1027",
                        label: "义乌市",
                        value: "330782",
                        children: []
                    },
                    {
                        id: "1028",
                        label: "东阳市",
                        value: "330783",
                        children: []
                    },
                    {
                        id: "1029",
                        label: "永康市",
                        value: "330784",
                        children: []
                    }
                ]
            },
            {
                id: "1118",
                label: "衢州市",
                value: "330800",
                children: [
                    {
                        id: "1030",
                        label: "市辖区",
                        value: "330801",
                        children: []
                    },
                    {
                        id: "1031",
                        label: "柯城区",
                        value: "330802",
                        children: []
                    },
                    {
                        id: "1032",
                        label: "衢江区",
                        value: "330803",
                        children: []
                    },
                    {
                        id: "1033",
                        label: "常山县",
                        value: "330822",
                        children: []
                    },
                    {
                        id: "1034",
                        label: "开化县",
                        value: "330824",
                        children: []
                    },
                    {
                        id: "1035",
                        label: "龙游县",
                        value: "330825",
                        children: []
                    },
                    {
                        id: "1036",
                        label: "江山市",
                        value: "330881",
                        children: []
                    }
                ]
            },
            {
                id: "1119",
                label: "舟山市",
                value: "330900",
                children: [
                    {
                        id: "1037",
                        label: "市辖区",
                        value: "330901",
                        children: []
                    },
                    {
                        id: "1038",
                        label: "定海区",
                        value: "330902",
                        children: []
                    },
                    {
                        id: "1039",
                        label: "普陀区",
                        value: "330903",
                        children: []
                    },
                    {
                        id: "1040",
                        label: "岱山县",
                        value: "330921",
                        children: []
                    },
                    {
                        id: "1041",
                        label: "嵊泗县",
                        value: "330922",
                        children: []
                    }
                ]
            },
            {
                id: "1120",
                label: "台州市",
                value: "331000",
                children: [
                    {
                        id: "1042",
                        label: "市辖区",
                        value: "331001",
                        children: []
                    },
                    {
                        id: "1043",
                        label: "椒江区",
                        value: "331002",
                        children: []
                    },
                    {
                        id: "1044",
                        label: "黄岩区",
                        value: "331003",
                        children: []
                    },
                    {
                        id: "1045",
                        label: "路桥区",
                        value: "331004",
                        children: []
                    },
                    {
                        id: "1046",
                        label: "玉环县",
                        value: "331021",
                        children: []
                    },
                    {
                        id: "1047",
                        label: "三门县",
                        value: "331022",
                        children: []
                    },
                    {
                        id: "1048",
                        label: "天台县",
                        value: "331023",
                        children: []
                    },
                    {
                        id: "1049",
                        label: "仙居县",
                        value: "331024",
                        children: []
                    },
                    {
                        id: "1050",
                        label: "温岭市",
                        value: "331081",
                        children: []
                    },
                    {
                        id: "1051",
                        label: "临海市",
                        value: "331082",
                        children: []
                    }
                ]
            },
            {
                id: "1121",
                label: "丽水市",
                value: "331100",
                children: [
                    {
                        id: "1052",
                        label: "市辖区",
                        value: "331101",
                        children: []
                    },
                    {
                        id: "1053",
                        label: "莲都区",
                        value: "331102",
                        children: []
                    },
                    {
                        id: "1054",
                        label: "青田县",
                        value: "331121",
                        children: []
                    },
                    {
                        id: "1055",
                        label: "缙云县",
                        value: "331122",
                        children: []
                    },
                    {
                        id: "1056",
                        label: "遂昌县",
                        value: "331123",
                        children: []
                    },
                    {
                        id: "1057",
                        label: "松阳县",
                        value: "331124",
                        children: []
                    },
                    {
                        id: "1058",
                        label: "云和县",
                        value: "331125",
                        children: []
                    },
                    {
                        id: "1059",
                        label: "庆元县",
                        value: "331126",
                        children: []
                    },
                    {
                        id: "1060",
                        label: "景宁畲族自治县",
                        value: "331127",
                        children: []
                    },
                    {
                        id: "1061",
                        label: "龙泉市",
                        value: "331181",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "12",
        label: "安徽省",
        value: "340000",
        children: [
            {
                id: "1122",
                label: "合肥市",
                value: "340100",
                children: [
                    {
                        id: "1062",
                        label: "市辖区",
                        value: "340101",
                        children: []
                    },
                    {
                        id: "1063",
                        label: "瑶海区",
                        value: "340102",
                        children: []
                    },
                    {
                        id: "1064",
                        label: "庐阳区",
                        value: "340103",
                        children: []
                    },
                    {
                        id: "1065",
                        label: "蜀山区",
                        value: "340104",
                        children: []
                    },
                    {
                        id: "1066",
                        label: "包河区",
                        value: "340111",
                        children: []
                    },
                    {
                        id: "1067",
                        label: "长丰县",
                        value: "340121",
                        children: []
                    },
                    {
                        id: "1068",
                        label: "肥东县",
                        value: "340122",
                        children: []
                    },
                    {
                        id: "1069",
                        label: "肥西县",
                        value: "340123",
                        children: []
                    }
                ]
            },
            {
                id: "1123",
                label: "芜湖市",
                value: "340200",
                children: [
                    {
                        id: "1070",
                        label: "市辖区",
                        value: "340201",
                        children: []
                    },
                    {
                        id: "1071",
                        label: "镜湖区",
                        value: "340202",
                        children: []
                    },
                    {
                        id: "1072",
                        label: "弋江区",
                        value: "340203",
                        children: []
                    },
                    {
                        id: "1073",
                        label: "鸠江区",
                        value: "340207",
                        children: []
                    },
                    {
                        id: "1074",
                        label: "三山区",
                        value: "340208",
                        children: []
                    },
                    {
                        id: "1075",
                        label: "芜湖县",
                        value: "340221",
                        children: []
                    },
                    {
                        id: "1076",
                        label: "繁昌县",
                        value: "340222",
                        children: []
                    },
                    {
                        id: "1077",
                        label: "南陵县",
                        value: "340223",
                        children: []
                    }
                ]
            },
            {
                id: "1124",
                label: "蚌埠市",
                value: "340300",
                children: [
                    {
                        id: "1078",
                        label: "市辖区",
                        value: "340301",
                        children: []
                    },
                    {
                        id: "1079",
                        label: "龙子湖区",
                        value: "340302",
                        children: []
                    },
                    {
                        id: "1080",
                        label: "蚌山区",
                        value: "340303",
                        children: []
                    },
                    {
                        id: "1081",
                        label: "禹会区",
                        value: "340304",
                        children: []
                    },
                    {
                        id: "1082",
                        label: "淮上区",
                        value: "340311",
                        children: []
                    },
                    {
                        id: "1083",
                        label: "怀远县",
                        value: "340321",
                        children: []
                    },
                    {
                        id: "1084",
                        label: "五河县",
                        value: "340322",
                        children: []
                    },
                    {
                        id: "1085",
                        label: "固镇县",
                        value: "340323",
                        children: []
                    }
                ]
            },
            {
                id: "1125",
                label: "淮南市",
                value: "340400",
                children: [
                    {
                        id: "1086",
                        label: "市辖区",
                        value: "340401",
                        children: []
                    },
                    {
                        id: "1087",
                        label: "大通区",
                        value: "340402",
                        children: []
                    },
                    {
                        id: "1088",
                        label: "田家庵区",
                        value: "340403",
                        children: []
                    },
                    {
                        id: "1089",
                        label: "谢家集区",
                        value: "340404",
                        children: []
                    },
                    {
                        id: "1090",
                        label: "八公山区",
                        value: "340405",
                        children: []
                    },
                    {
                        id: "1091",
                        label: "潘集区",
                        value: "340406",
                        children: []
                    },
                    {
                        id: "1092",
                        label: "凤台县",
                        value: "340421",
                        children: []
                    }
                ]
            },
            {
                id: "1126",
                label: "马鞍山市",
                value: "340500",
                children: [
                    {
                        id: "1093",
                        label: "市辖区",
                        value: "340501",
                        children: []
                    },
                    {
                        id: "1094",
                        label: "金家庄区",
                        value: "340502",
                        children: []
                    },
                    {
                        id: "1095",
                        label: "花山区",
                        value: "340503",
                        children: []
                    },
                    {
                        id: "1096",
                        label: "雨山区",
                        value: "340504",
                        children: []
                    },
                    {
                        id: "1097",
                        label: "当涂县",
                        value: "340521",
                        children: []
                    }
                ]
            },
            {
                id: "1127",
                label: "淮北市",
                value: "340600",
                children: [
                    {
                        id: "1098",
                        label: "市辖区",
                        value: "340601",
                        children: []
                    },
                    {
                        id: "1099",
                        label: "杜集区",
                        value: "340602",
                        children: []
                    },
                    {
                        id: "1100",
                        label: "相山区",
                        value: "340603",
                        children: []
                    },
                    {
                        id: "1101",
                        label: "烈山区",
                        value: "340604",
                        children: []
                    },
                    {
                        id: "1102",
                        label: "濉溪县",
                        value: "340621",
                        children: []
                    }
                ]
            },
            {
                id: "1128",
                label: "铜陵市",
                value: "340700",
                children: [
                    {
                        id: "1103",
                        label: "市辖区",
                        value: "340701",
                        children: []
                    },
                    {
                        id: "1104",
                        label: "铜官山区",
                        value: "340702",
                        children: []
                    },
                    {
                        id: "1105",
                        label: "狮子山区",
                        value: "340703",
                        children: []
                    },
                    {
                        id: "1106",
                        label: "郊区",
                        value: "340711",
                        children: []
                    },
                    {
                        id: "1107",
                        label: "铜陵县",
                        value: "340721",
                        children: []
                    }
                ]
            },
            {
                id: "1129",
                label: "安庆市",
                value: "340800",
                children: [
                    {
                        id: "1108",
                        label: "市辖区",
                        value: "340801",
                        children: []
                    },
                    {
                        id: "1109",
                        label: "迎江区",
                        value: "340802",
                        children: []
                    },
                    {
                        id: "1110",
                        label: "大观区",
                        value: "340803",
                        children: []
                    },
                    {
                        id: "1111",
                        label: "宜秀区",
                        value: "340811",
                        children: []
                    },
                    {
                        id: "1112",
                        label: "怀宁县",
                        value: "340822",
                        children: []
                    },
                    {
                        id: "1113",
                        label: "枞阳县",
                        value: "340823",
                        children: []
                    },
                    {
                        id: "1114",
                        label: "潜山县",
                        value: "340824",
                        children: []
                    },
                    {
                        id: "1115",
                        label: "太湖县",
                        value: "340825",
                        children: []
                    },
                    {
                        id: "1116",
                        label: "宿松县",
                        value: "340826",
                        children: []
                    },
                    {
                        id: "1117",
                        label: "望江县",
                        value: "340827",
                        children: []
                    },
                    {
                        id: "1118",
                        label: "岳西县",
                        value: "340828",
                        children: []
                    },
                    {
                        id: "1119",
                        label: "桐城市",
                        value: "340881",
                        children: []
                    }
                ]
            },
            {
                id: "1130",
                label: "黄山市",
                value: "341000",
                children: [
                    {
                        id: "1120",
                        label: "市辖区",
                        value: "341001",
                        children: []
                    },
                    {
                        id: "1121",
                        label: "屯溪区",
                        value: "341002",
                        children: []
                    },
                    {
                        id: "1122",
                        label: "黄山区",
                        value: "341003",
                        children: []
                    },
                    {
                        id: "1123",
                        label: "徽州区",
                        value: "341004",
                        children: []
                    },
                    {
                        id: "1124",
                        label: "歙县",
                        value: "341021",
                        children: []
                    },
                    {
                        id: "1125",
                        label: "休宁县",
                        value: "341022",
                        children: []
                    },
                    {
                        id: "1126",
                        label: "黟县",
                        value: "341023",
                        children: []
                    },
                    {
                        id: "1127",
                        label: "祁门县",
                        value: "341024",
                        children: []
                    }
                ]
            },
            {
                id: "1131",
                label: "滁州市",
                value: "341100",
                children: [
                    {
                        id: "1128",
                        label: "市辖区",
                        value: "341101",
                        children: []
                    },
                    {
                        id: "1129",
                        label: "琅琊区",
                        value: "341102",
                        children: []
                    },
                    {
                        id: "1130",
                        label: "南谯区",
                        value: "341103",
                        children: []
                    },
                    {
                        id: "1131",
                        label: "来安县",
                        value: "341122",
                        children: []
                    },
                    {
                        id: "1132",
                        label: "全椒县",
                        value: "341124",
                        children: []
                    },
                    {
                        id: "1133",
                        label: "定远县",
                        value: "341125",
                        children: []
                    },
                    {
                        id: "1134",
                        label: "凤阳县",
                        value: "341126",
                        children: []
                    },
                    {
                        id: "1135",
                        label: "天长市",
                        value: "341181",
                        children: []
                    },
                    {
                        id: "1136",
                        label: "明光市",
                        value: "341182",
                        children: []
                    }
                ]
            },
            {
                id: "1132",
                label: "阜阳市",
                value: "341200",
                children: [
                    {
                        id: "1137",
                        label: "市辖区",
                        value: "341201",
                        children: []
                    },
                    {
                        id: "1138",
                        label: "颍州区",
                        value: "341202",
                        children: []
                    },
                    {
                        id: "1139",
                        label: "颍东区",
                        value: "341203",
                        children: []
                    },
                    {
                        id: "1140",
                        label: "颍泉区",
                        value: "341204",
                        children: []
                    },
                    {
                        id: "1141",
                        label: "临泉县",
                        value: "341221",
                        children: []
                    },
                    {
                        id: "1142",
                        label: "太和县",
                        value: "341222",
                        children: []
                    },
                    {
                        id: "1143",
                        label: "阜南县",
                        value: "341225",
                        children: []
                    },
                    {
                        id: "1144",
                        label: "颍上县",
                        value: "341226",
                        children: []
                    },
                    {
                        id: "1145",
                        label: "界首市",
                        value: "341282",
                        children: []
                    }
                ]
            },
            {
                id: "1133",
                label: "宿州市",
                value: "341300",
                children: [
                    {
                        id: "1146",
                        label: "市辖区",
                        value: "341301",
                        children: []
                    },
                    {
                        id: "1147",
                        label: "??桥区",
                        value: "341302",
                        children: []
                    },
                    {
                        id: "1148",
                        label: "砀山县",
                        value: "341321",
                        children: []
                    },
                    {
                        id: "1149",
                        label: "萧县",
                        value: "341322",
                        children: []
                    },
                    {
                        id: "1150",
                        label: "灵璧县",
                        value: "341323",
                        children: []
                    },
                    {
                        id: "1151",
                        label: "泗县",
                        value: "341324",
                        children: []
                    }
                ]
            },
            {
                id: "1134",
                label: "巢湖市",
                value: "341400",
                children: [
                    {
                        id: "1152",
                        label: "市辖区",
                        value: "341401",
                        children: []
                    },
                    {
                        id: "1153",
                        label: "居巢区",
                        value: "341402",
                        children: []
                    },
                    {
                        id: "1154",
                        label: "庐江县",
                        value: "341421",
                        children: []
                    },
                    {
                        id: "1155",
                        label: "无为县",
                        value: "341422",
                        children: []
                    },
                    {
                        id: "1156",
                        label: "含山县",
                        value: "341423",
                        children: []
                    },
                    {
                        id: "1157",
                        label: "和县",
                        value: "341424",
                        children: []
                    }
                ]
            },
            {
                id: "1135",
                label: "六安市",
                value: "341500",
                children: [
                    {
                        id: "1158",
                        label: "市辖区",
                        value: "341501",
                        children: []
                    },
                    {
                        id: "1159",
                        label: "金安区",
                        value: "341502",
                        children: []
                    },
                    {
                        id: "1160",
                        label: "裕安区",
                        value: "341503",
                        children: []
                    },
                    {
                        id: "1161",
                        label: "寿县",
                        value: "341521",
                        children: []
                    },
                    {
                        id: "1162",
                        label: "霍邱县",
                        value: "341522",
                        children: []
                    },
                    {
                        id: "1163",
                        label: "舒城县",
                        value: "341523",
                        children: []
                    },
                    {
                        id: "1164",
                        label: "金寨县",
                        value: "341524",
                        children: []
                    },
                    {
                        id: "1165",
                        label: "霍山县",
                        value: "341525",
                        children: []
                    }
                ]
            },
            {
                id: "1136",
                label: "亳州市",
                value: "341600",
                children: [
                    {
                        id: "1166",
                        label: "市辖区",
                        value: "341601",
                        children: []
                    },
                    {
                        id: "1167",
                        label: "谯城区",
                        value: "341602",
                        children: []
                    },
                    {
                        id: "1168",
                        label: "涡阳县",
                        value: "341621",
                        children: []
                    },
                    {
                        id: "1169",
                        label: "蒙城县",
                        value: "341622",
                        children: []
                    },
                    {
                        id: "1170",
                        label: "利辛县",
                        value: "341623",
                        children: []
                    }
                ]
            },
            {
                id: "1137",
                label: "池州市",
                value: "341700",
                children: [
                    {
                        id: "1171",
                        label: "市辖区",
                        value: "341701",
                        children: []
                    },
                    {
                        id: "1172",
                        label: "贵池区",
                        value: "341702",
                        children: []
                    },
                    {
                        id: "1173",
                        label: "东至县",
                        value: "341721",
                        children: []
                    },
                    {
                        id: "1174",
                        label: "石台县",
                        value: "341722",
                        children: []
                    },
                    {
                        id: "1175",
                        label: "青阳县",
                        value: "341723",
                        children: []
                    }
                ]
            },
            {
                id: "1138",
                label: "宣城市",
                value: "341800",
                children: [
                    {
                        id: "1176",
                        label: "市辖区",
                        value: "341801",
                        children: []
                    },
                    {
                        id: "1177",
                        label: "宣州区",
                        value: "341802",
                        children: []
                    },
                    {
                        id: "1178",
                        label: "郎溪县",
                        value: "341821",
                        children: []
                    },
                    {
                        id: "1179",
                        label: "广德县",
                        value: "341822",
                        children: []
                    },
                    {
                        id: "1180",
                        label: "泾县",
                        value: "341823",
                        children: []
                    },
                    {
                        id: "1181",
                        label: "绩溪县",
                        value: "341824",
                        children: []
                    },
                    {
                        id: "1182",
                        label: "旌德县",
                        value: "341825",
                        children: []
                    },
                    {
                        id: "1183",
                        label: "宁国市",
                        value: "341881",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "13",
        label: "福建省",
        value: "350000",
        children: [
            {
                id: "1139",
                label: "福州市",
                value: "350100",
                children: [
                    {
                        id: "1184",
                        label: "市辖区",
                        value: "350101",
                        children: []
                    },
                    {
                        id: "1185",
                        label: "鼓楼区",
                        value: "350102",
                        children: []
                    },
                    {
                        id: "1186",
                        label: "台江区",
                        value: "350103",
                        children: []
                    },
                    {
                        id: "1187",
                        label: "仓山区",
                        value: "350104",
                        children: []
                    },
                    {
                        id: "1188",
                        label: "马尾区",
                        value: "350105",
                        children: []
                    },
                    {
                        id: "1189",
                        label: "晋安区",
                        value: "350111",
                        children: []
                    },
                    {
                        id: "1190",
                        label: "闽侯县",
                        value: "350121",
                        children: []
                    },
                    {
                        id: "1191",
                        label: "连江县",
                        value: "350122",
                        children: []
                    },
                    {
                        id: "1192",
                        label: "罗源县",
                        value: "350123",
                        children: []
                    },
                    {
                        id: "1193",
                        label: "闽清县",
                        value: "350124",
                        children: []
                    },
                    {
                        id: "1194",
                        label: "永泰县",
                        value: "350125",
                        children: []
                    },
                    {
                        id: "1195",
                        label: "平潭县",
                        value: "350128",
                        children: []
                    },
                    {
                        id: "1196",
                        label: "福清市",
                        value: "350181",
                        children: []
                    },
                    {
                        id: "1197",
                        label: "长乐市",
                        value: "350182",
                        children: []
                    }
                ]
            },
            {
                id: "1140",
                label: "厦门市",
                value: "350200",
                children: [
                    {
                        id: "1198",
                        label: "市辖区",
                        value: "350201",
                        children: []
                    },
                    {
                        id: "1199",
                        label: "思明区",
                        value: "350203",
                        children: []
                    },
                    {
                        id: "1200",
                        label: "海沧区",
                        value: "350205",
                        children: []
                    },
                    {
                        id: "1201",
                        label: "湖里区",
                        value: "350206",
                        children: []
                    },
                    {
                        id: "1202",
                        label: "集美区",
                        value: "350211",
                        children: []
                    },
                    {
                        id: "1203",
                        label: "同安区",
                        value: "350212",
                        children: []
                    },
                    {
                        id: "1204",
                        label: "翔安区",
                        value: "350213",
                        children: []
                    }
                ]
            },
            {
                id: "1141",
                label: "莆田市",
                value: "350300",
                children: [
                    {
                        id: "1205",
                        label: "市辖区",
                        value: "350301",
                        children: []
                    },
                    {
                        id: "1206",
                        label: "城厢区",
                        value: "350302",
                        children: []
                    },
                    {
                        id: "1207",
                        label: "涵江区",
                        value: "350303",
                        children: []
                    },
                    {
                        id: "1208",
                        label: "荔城区",
                        value: "350304",
                        children: []
                    },
                    {
                        id: "1209",
                        label: "秀屿区",
                        value: "350305",
                        children: []
                    },
                    {
                        id: "1210",
                        label: "仙游县",
                        value: "350322",
                        children: []
                    }
                ]
            },
            {
                id: "1142",
                label: "三明市",
                value: "350400",
                children: [
                    {
                        id: "1211",
                        label: "市辖区",
                        value: "350401",
                        children: []
                    },
                    {
                        id: "1212",
                        label: "梅列区",
                        value: "350402",
                        children: []
                    },
                    {
                        id: "1213",
                        label: "三元区",
                        value: "350403",
                        children: []
                    },
                    {
                        id: "1214",
                        label: "明溪县",
                        value: "350421",
                        children: []
                    },
                    {
                        id: "1215",
                        label: "清流县",
                        value: "350423",
                        children: []
                    },
                    {
                        id: "1216",
                        label: "宁化县",
                        value: "350424",
                        children: []
                    },
                    {
                        id: "1217",
                        label: "大田县",
                        value: "350425",
                        children: []
                    },
                    {
                        id: "1218",
                        label: "尤溪县",
                        value: "350426",
                        children: []
                    },
                    {
                        id: "1219",
                        label: "沙县",
                        value: "350427",
                        children: []
                    },
                    {
                        id: "1220",
                        label: "将乐县",
                        value: "350428",
                        children: []
                    },
                    {
                        id: "1221",
                        label: "泰宁县",
                        value: "350429",
                        children: []
                    },
                    {
                        id: "1222",
                        label: "建宁县",
                        value: "350430",
                        children: []
                    },
                    {
                        id: "1223",
                        label: "永安市",
                        value: "350481",
                        children: []
                    }
                ]
            },
            {
                id: "1143",
                label: "泉州市",
                value: "350500",
                children: [
                    {
                        id: "1224",
                        label: "市辖区",
                        value: "350501",
                        children: []
                    },
                    {
                        id: "1225",
                        label: "鲤城区",
                        value: "350502",
                        children: []
                    },
                    {
                        id: "1226",
                        label: "丰泽区",
                        value: "350503",
                        children: []
                    },
                    {
                        id: "1227",
                        label: "洛江区",
                        value: "350504",
                        children: []
                    },
                    {
                        id: "1228",
                        label: "泉港区",
                        value: "350505",
                        children: []
                    },
                    {
                        id: "1229",
                        label: "惠安县",
                        value: "350521",
                        children: []
                    },
                    {
                        id: "1230",
                        label: "安溪县",
                        value: "350524",
                        children: []
                    },
                    {
                        id: "1231",
                        label: "永春县",
                        value: "350525",
                        children: []
                    },
                    {
                        id: "1232",
                        label: "德化县",
                        value: "350526",
                        children: []
                    },
                    {
                        id: "1233",
                        label: "金门县",
                        value: "350527",
                        children: []
                    },
                    {
                        id: "1234",
                        label: "石狮市",
                        value: "350581",
                        children: []
                    },
                    {
                        id: "1235",
                        label: "晋江市",
                        value: "350582",
                        children: []
                    },
                    {
                        id: "1236",
                        label: "南安市",
                        value: "350583",
                        children: []
                    }
                ]
            },
            {
                id: "1144",
                label: "漳州市",
                value: "350600",
                children: [
                    {
                        id: "1237",
                        label: "市辖区",
                        value: "350601",
                        children: []
                    },
                    {
                        id: "1238",
                        label: "芗城区",
                        value: "350602",
                        children: []
                    },
                    {
                        id: "1239",
                        label: "龙文区",
                        value: "350603",
                        children: []
                    },
                    {
                        id: "1240",
                        label: "云霄县",
                        value: "350622",
                        children: []
                    },
                    {
                        id: "1241",
                        label: "漳浦县",
                        value: "350623",
                        children: []
                    },
                    {
                        id: "1242",
                        label: "诏安县",
                        value: "350624",
                        children: []
                    },
                    {
                        id: "1243",
                        label: "长泰县",
                        value: "350625",
                        children: []
                    },
                    {
                        id: "1244",
                        label: "东山县",
                        value: "350626",
                        children: []
                    },
                    {
                        id: "1245",
                        label: "南靖县",
                        value: "350627",
                        children: []
                    },
                    {
                        id: "1246",
                        label: "平和县",
                        value: "350628",
                        children: []
                    },
                    {
                        id: "1247",
                        label: "华安县",
                        value: "350629",
                        children: []
                    },
                    {
                        id: "1248",
                        label: "龙海市",
                        value: "350681",
                        children: []
                    }
                ]
            },
            {
                id: "1145",
                label: "南平市",
                value: "350700",
                children: [
                    {
                        id: "1249",
                        label: "市辖区",
                        value: "350701",
                        children: []
                    },
                    {
                        id: "1250",
                        label: "延平区",
                        value: "350702",
                        children: []
                    },
                    {
                        id: "1251",
                        label: "顺昌县",
                        value: "350721",
                        children: []
                    },
                    {
                        id: "1252",
                        label: "浦城县",
                        value: "350722",
                        children: []
                    },
                    {
                        id: "1253",
                        label: "光泽县",
                        value: "350723",
                        children: []
                    },
                    {
                        id: "1254",
                        label: "松溪县",
                        value: "350724",
                        children: []
                    },
                    {
                        id: "1255",
                        label: "政和县",
                        value: "350725",
                        children: []
                    },
                    {
                        id: "1256",
                        label: "邵武市",
                        value: "350781",
                        children: []
                    },
                    {
                        id: "1257",
                        label: "武夷山市",
                        value: "350782",
                        children: []
                    },
                    {
                        id: "1258",
                        label: "建瓯市",
                        value: "350783",
                        children: []
                    },
                    {
                        id: "1259",
                        label: "建阳市",
                        value: "350784",
                        children: []
                    }
                ]
            },
            {
                id: "1146",
                label: "龙岩市",
                value: "350800",
                children: [
                    {
                        id: "1260",
                        label: "市辖区",
                        value: "350801",
                        children: []
                    },
                    {
                        id: "1261",
                        label: "新罗区",
                        value: "350802",
                        children: []
                    },
                    {
                        id: "1262",
                        label: "长汀县",
                        value: "350821",
                        children: []
                    },
                    {
                        id: "1263",
                        label: "永定县",
                        value: "350822",
                        children: []
                    },
                    {
                        id: "1264",
                        label: "上杭县",
                        value: "350823",
                        children: []
                    },
                    {
                        id: "1265",
                        label: "武平县",
                        value: "350824",
                        children: []
                    },
                    {
                        id: "1266",
                        label: "连城县",
                        value: "350825",
                        children: []
                    },
                    {
                        id: "1267",
                        label: "漳平市",
                        value: "350881",
                        children: []
                    }
                ]
            },
            {
                id: "1147",
                label: "宁德市",
                value: "350900",
                children: [
                    {
                        id: "1268",
                        label: "市辖区",
                        value: "350901",
                        children: []
                    },
                    {
                        id: "1269",
                        label: "蕉城区",
                        value: "350902",
                        children: []
                    },
                    {
                        id: "1270",
                        label: "霞浦县",
                        value: "350921",
                        children: []
                    },
                    {
                        id: "1271",
                        label: "古田县",
                        value: "350922",
                        children: []
                    },
                    {
                        id: "1272",
                        label: "屏南县",
                        value: "350923",
                        children: []
                    },
                    {
                        id: "1273",
                        label: "寿宁县",
                        value: "350924",
                        children: []
                    },
                    {
                        id: "1274",
                        label: "周宁县",
                        value: "350925",
                        children: []
                    },
                    {
                        id: "1275",
                        label: "柘荣县",
                        value: "350926",
                        children: []
                    },
                    {
                        id: "1276",
                        label: "福安市",
                        value: "350981",
                        children: []
                    },
                    {
                        id: "1277",
                        label: "福鼎市",
                        value: "350982",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "14",
        label: "江西省",
        value: "360000",
        children: [
            {
                id: "1148",
                label: "南昌市",
                value: "360100",
                children: [
                    {
                        id: "1278",
                        label: "市辖区",
                        value: "360101",
                        children: []
                    },
                    {
                        id: "1279",
                        label: "东湖区",
                        value: "360102",
                        children: []
                    },
                    {
                        id: "1280",
                        label: "西湖区",
                        value: "360103",
                        children: []
                    },
                    {
                        id: "1281",
                        label: "青云谱区",
                        value: "360104",
                        children: []
                    },
                    {
                        id: "1282",
                        label: "湾里区",
                        value: "360105",
                        children: []
                    },
                    {
                        id: "1283",
                        label: "青山湖区",
                        value: "360111",
                        children: []
                    },
                    {
                        id: "1284",
                        label: "南昌县",
                        value: "360121",
                        children: []
                    },
                    {
                        id: "1285",
                        label: "新建县",
                        value: "360122",
                        children: []
                    },
                    {
                        id: "1286",
                        label: "安义县",
                        value: "360123",
                        children: []
                    },
                    {
                        id: "1287",
                        label: "进贤县",
                        value: "360124",
                        children: []
                    }
                ]
            },
            {
                id: "1149",
                label: "景德镇市",
                value: "360200",
                children: [
                    {
                        id: "1288",
                        label: "市辖区",
                        value: "360201",
                        children: []
                    },
                    {
                        id: "1289",
                        label: "昌江区",
                        value: "360202",
                        children: []
                    },
                    {
                        id: "1290",
                        label: "珠山区",
                        value: "360203",
                        children: []
                    },
                    {
                        id: "1291",
                        label: "浮梁县",
                        value: "360222",
                        children: []
                    },
                    {
                        id: "1292",
                        label: "乐平市",
                        value: "360281",
                        children: []
                    }
                ]
            },
            {
                id: "1150",
                label: "萍乡市",
                value: "360300",
                children: [
                    {
                        id: "1293",
                        label: "市辖区",
                        value: "360301",
                        children: []
                    },
                    {
                        id: "1294",
                        label: "安源区",
                        value: "360302",
                        children: []
                    },
                    {
                        id: "1295",
                        label: "湘东区",
                        value: "360313",
                        children: []
                    },
                    {
                        id: "1296",
                        label: "莲花县",
                        value: "360321",
                        children: []
                    },
                    {
                        id: "1297",
                        label: "上栗县",
                        value: "360322",
                        children: []
                    },
                    {
                        id: "1298",
                        label: "芦溪县",
                        value: "360323",
                        children: []
                    }
                ]
            },
            {
                id: "1151",
                label: "九江市",
                value: "360400",
                children: [
                    {
                        id: "1299",
                        label: "市辖区",
                        value: "360401",
                        children: []
                    },
                    {
                        id: "1300",
                        label: "庐山区",
                        value: "360402",
                        children: []
                    },
                    {
                        id: "1301",
                        label: "浔阳区",
                        value: "360403",
                        children: []
                    },
                    {
                        id: "1302",
                        label: "九江县",
                        value: "360421",
                        children: []
                    },
                    {
                        id: "1303",
                        label: "武宁县",
                        value: "360423",
                        children: []
                    },
                    {
                        id: "1304",
                        label: "修水县",
                        value: "360424",
                        children: []
                    },
                    {
                        id: "1305",
                        label: "永修县",
                        value: "360425",
                        children: []
                    },
                    {
                        id: "1306",
                        label: "德安县",
                        value: "360426",
                        children: []
                    },
                    {
                        id: "1307",
                        label: "星子县",
                        value: "360427",
                        children: []
                    },
                    {
                        id: "1308",
                        label: "都昌县",
                        value: "360428",
                        children: []
                    },
                    {
                        id: "1309",
                        label: "湖口县",
                        value: "360429",
                        children: []
                    },
                    {
                        id: "1310",
                        label: "彭泽县",
                        value: "360430",
                        children: []
                    },
                    {
                        id: "1311",
                        label: "瑞昌市",
                        value: "360481",
                        children: []
                    },
                    {
                        id: "1312",
                        label: "共青城市",
                        value: "360482",
                        children: []
                    }
                ]
            },
            {
                id: "1152",
                label: "新余市",
                value: "360500",
                children: [
                    {
                        id: "1313",
                        label: "市辖区",
                        value: "360501",
                        children: []
                    },
                    {
                        id: "1314",
                        label: "渝水区",
                        value: "360502",
                        children: []
                    },
                    {
                        id: "1315",
                        label: "分宜县",
                        value: "360521",
                        children: []
                    }
                ]
            },
            {
                id: "1153",
                label: "鹰潭市",
                value: "360600",
                children: [
                    {
                        id: "1316",
                        label: "市辖区",
                        value: "360601",
                        children: []
                    },
                    {
                        id: "1317",
                        label: "月湖区",
                        value: "360602",
                        children: []
                    },
                    {
                        id: "1318",
                        label: "余江县",
                        value: "360622",
                        children: []
                    },
                    {
                        id: "1319",
                        label: "贵溪市",
                        value: "360681",
                        children: []
                    }
                ]
            },
            {
                id: "1154",
                label: "赣州市",
                value: "360700",
                children: [
                    {
                        id: "1320",
                        label: "市辖区",
                        value: "360701",
                        children: []
                    },
                    {
                        id: "1321",
                        label: "章贡区",
                        value: "360702",
                        children: []
                    },
                    {
                        id: "1322",
                        label: "赣县",
                        value: "360721",
                        children: []
                    },
                    {
                        id: "1323",
                        label: "信丰县",
                        value: "360722",
                        children: []
                    },
                    {
                        id: "1324",
                        label: "大余县",
                        value: "360723",
                        children: []
                    },
                    {
                        id: "1325",
                        label: "上犹县",
                        value: "360724",
                        children: []
                    },
                    {
                        id: "1326",
                        label: "崇义县",
                        value: "360725",
                        children: []
                    },
                    {
                        id: "1327",
                        label: "安远县",
                        value: "360726",
                        children: []
                    },
                    {
                        id: "1328",
                        label: "龙南县",
                        value: "360727",
                        children: []
                    },
                    {
                        id: "1329",
                        label: "定南县",
                        value: "360728",
                        children: []
                    },
                    {
                        id: "1330",
                        label: "全南县",
                        value: "360729",
                        children: []
                    },
                    {
                        id: "1331",
                        label: "宁都县",
                        value: "360730",
                        children: []
                    },
                    {
                        id: "1332",
                        label: "于都县",
                        value: "360731",
                        children: []
                    },
                    {
                        id: "1333",
                        label: "兴国县",
                        value: "360732",
                        children: []
                    },
                    {
                        id: "1334",
                        label: "会昌县",
                        value: "360733",
                        children: []
                    },
                    {
                        id: "1335",
                        label: "寻乌县",
                        value: "360734",
                        children: []
                    },
                    {
                        id: "1336",
                        label: "石城县",
                        value: "360735",
                        children: []
                    },
                    {
                        id: "1337",
                        label: "瑞金市",
                        value: "360781",
                        children: []
                    },
                    {
                        id: "1338",
                        label: "南康市",
                        value: "360782",
                        children: []
                    }
                ]
            },
            {
                id: "1155",
                label: "吉安市",
                value: "360800",
                children: [
                    {
                        id: "1339",
                        label: "市辖区",
                        value: "360801",
                        children: []
                    },
                    {
                        id: "1340",
                        label: "吉州区",
                        value: "360802",
                        children: []
                    },
                    {
                        id: "1341",
                        label: "青原区",
                        value: "360803",
                        children: []
                    },
                    {
                        id: "1342",
                        label: "吉安县",
                        value: "360821",
                        children: []
                    },
                    {
                        id: "1343",
                        label: "吉水县",
                        value: "360822",
                        children: []
                    },
                    {
                        id: "1344",
                        label: "峡江县",
                        value: "360823",
                        children: []
                    },
                    {
                        id: "1345",
                        label: "新干县",
                        value: "360824",
                        children: []
                    },
                    {
                        id: "1346",
                        label: "永丰县",
                        value: "360825",
                        children: []
                    },
                    {
                        id: "1347",
                        label: "泰和县",
                        value: "360826",
                        children: []
                    },
                    {
                        id: "1348",
                        label: "遂川县",
                        value: "360827",
                        children: []
                    },
                    {
                        id: "1349",
                        label: "万安县",
                        value: "360828",
                        children: []
                    },
                    {
                        id: "1350",
                        label: "安福县",
                        value: "360829",
                        children: []
                    },
                    {
                        id: "1351",
                        label: "永新县",
                        value: "360830",
                        children: []
                    },
                    {
                        id: "1352",
                        label: "井冈山市",
                        value: "360881",
                        children: []
                    }
                ]
            },
            {
                id: "1156",
                label: "宜春市",
                value: "360900",
                children: [
                    {
                        id: "1353",
                        label: "市辖区",
                        value: "360901",
                        children: []
                    },
                    {
                        id: "1354",
                        label: "袁州区",
                        value: "360902",
                        children: []
                    },
                    {
                        id: "1355",
                        label: "奉新县",
                        value: "360921",
                        children: []
                    },
                    {
                        id: "1356",
                        label: "万载县",
                        value: "360922",
                        children: []
                    },
                    {
                        id: "1357",
                        label: "上高县",
                        value: "360923",
                        children: []
                    },
                    {
                        id: "1358",
                        label: "宜丰县",
                        value: "360924",
                        children: []
                    },
                    {
                        id: "1359",
                        label: "靖安县",
                        value: "360925",
                        children: []
                    },
                    {
                        id: "1360",
                        label: "铜鼓县",
                        value: "360926",
                        children: []
                    },
                    {
                        id: "1361",
                        label: "丰城市",
                        value: "360981",
                        children: []
                    },
                    {
                        id: "1362",
                        label: "樟树市",
                        value: "360982",
                        children: []
                    },
                    {
                        id: "1363",
                        label: "高安市",
                        value: "360983",
                        children: []
                    }
                ]
            },
            {
                id: "1157",
                label: "抚州市",
                value: "361000",
                children: [
                    {
                        id: "1364",
                        label: "市辖区",
                        value: "361001",
                        children: []
                    },
                    {
                        id: "1365",
                        label: "临川区",
                        value: "361002",
                        children: []
                    },
                    {
                        id: "1366",
                        label: "南城县",
                        value: "361021",
                        children: []
                    },
                    {
                        id: "1367",
                        label: "黎川县",
                        value: "361022",
                        children: []
                    },
                    {
                        id: "1368",
                        label: "南丰县",
                        value: "361023",
                        children: []
                    },
                    {
                        id: "1369",
                        label: "崇仁县",
                        value: "361024",
                        children: []
                    },
                    {
                        id: "1370",
                        label: "乐安县",
                        value: "361025",
                        children: []
                    },
                    {
                        id: "1371",
                        label: "宜黄县",
                        value: "361026",
                        children: []
                    },
                    {
                        id: "1372",
                        label: "金溪县",
                        value: "361027",
                        children: []
                    },
                    {
                        id: "1373",
                        label: "资溪县",
                        value: "361028",
                        children: []
                    },
                    {
                        id: "1374",
                        label: "东乡县",
                        value: "361029",
                        children: []
                    },
                    {
                        id: "1375",
                        label: "广昌县",
                        value: "361030",
                        children: []
                    }
                ]
            },
            {
                id: "1158",
                label: "上饶市",
                value: "361100",
                children: [
                    {
                        id: "1376",
                        label: "市辖区",
                        value: "361101",
                        children: []
                    },
                    {
                        id: "1377",
                        label: "信州区",
                        value: "361102",
                        children: []
                    },
                    {
                        id: "1378",
                        label: "上饶县",
                        value: "361121",
                        children: []
                    },
                    {
                        id: "1379",
                        label: "广丰县",
                        value: "361122",
                        children: []
                    },
                    {
                        id: "1380",
                        label: "玉山县",
                        value: "361123",
                        children: []
                    },
                    {
                        id: "1381",
                        label: "铅山县",
                        value: "361124",
                        children: []
                    },
                    {
                        id: "1382",
                        label: "横峰县",
                        value: "361125",
                        children: []
                    },
                    {
                        id: "1383",
                        label: "弋阳县",
                        value: "361126",
                        children: []
                    },
                    {
                        id: "1384",
                        label: "余干县",
                        value: "361127",
                        children: []
                    },
                    {
                        id: "1385",
                        label: "鄱阳县",
                        value: "361128",
                        children: []
                    },
                    {
                        id: "1386",
                        label: "万年县",
                        value: "361129",
                        children: []
                    },
                    {
                        id: "1387",
                        label: "婺源县",
                        value: "361130",
                        children: []
                    },
                    {
                        id: "1388",
                        label: "德兴市",
                        value: "361181",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "15",
        label: "山东省",
        value: "370000",
        children: [
            {
                id: "1159",
                label: "济南市",
                value: "370100",
                children: [
                    {
                        id: "1389",
                        label: "市辖区",
                        value: "370101",
                        children: []
                    },
                    {
                        id: "1390",
                        label: "历下区",
                        value: "370102",
                        children: []
                    },
                    {
                        id: "1391",
                        label: "市中区",
                        value: "370103",
                        children: []
                    },
                    {
                        id: "1392",
                        label: "槐荫区",
                        value: "370104",
                        children: []
                    },
                    {
                        id: "1393",
                        label: "天桥区",
                        value: "370105",
                        children: []
                    },
                    {
                        id: "1394",
                        label: "历城区",
                        value: "370112",
                        children: []
                    },
                    {
                        id: "1395",
                        label: "长清区",
                        value: "370113",
                        children: []
                    },
                    {
                        id: "1396",
                        label: "平阴县",
                        value: "370124",
                        children: []
                    },
                    {
                        id: "1397",
                        label: "济阳县",
                        value: "370125",
                        children: []
                    },
                    {
                        id: "1398",
                        label: "商河县",
                        value: "370126",
                        children: []
                    },
                    {
                        id: "1399",
                        label: "章丘市",
                        value: "370181",
                        children: []
                    }
                ]
            },
            {
                id: "1160",
                label: "青岛市",
                value: "370200",
                children: [
                    {
                        id: "1400",
                        label: "市辖区",
                        value: "370201",
                        children: []
                    },
                    {
                        id: "1401",
                        label: "市南区",
                        value: "370202",
                        children: []
                    },
                    {
                        id: "1402",
                        label: "市北区",
                        value: "370203",
                        children: []
                    },
                    {
                        id: "1403",
                        label: "四方区",
                        value: "370205",
                        children: []
                    },
                    {
                        id: "1404",
                        label: "黄岛区",
                        value: "370211",
                        children: []
                    },
                    {
                        id: "1405",
                        label: "崂山区",
                        value: "370212",
                        children: []
                    },
                    {
                        id: "1406",
                        label: "李沧区",
                        value: "370213",
                        children: []
                    },
                    {
                        id: "1407",
                        label: "城阳区",
                        value: "370214",
                        children: []
                    },
                    {
                        id: "1408",
                        label: "胶州市",
                        value: "370281",
                        children: []
                    },
                    {
                        id: "1409",
                        label: "即墨市",
                        value: "370282",
                        children: []
                    },
                    {
                        id: "1410",
                        label: "平度市",
                        value: "370283",
                        children: []
                    },
                    {
                        id: "1411",
                        label: "胶南市",
                        value: "370284",
                        children: []
                    },
                    {
                        id: "1412",
                        label: "莱西市",
                        value: "370285",
                        children: []
                    }
                ]
            },
            {
                id: "1161",
                label: "淄博市",
                value: "370300",
                children: [
                    {
                        id: "1413",
                        label: "市辖区",
                        value: "370301",
                        children: []
                    },
                    {
                        id: "1414",
                        label: "淄川区",
                        value: "370302",
                        children: []
                    },
                    {
                        id: "1415",
                        label: "张店区",
                        value: "370303",
                        children: []
                    },
                    {
                        id: "1416",
                        label: "博山区",
                        value: "370304",
                        children: []
                    },
                    {
                        id: "1417",
                        label: "临淄区",
                        value: "370305",
                        children: []
                    },
                    {
                        id: "1418",
                        label: "周村区",
                        value: "370306",
                        children: []
                    },
                    {
                        id: "1419",
                        label: "桓台县",
                        value: "370321",
                        children: []
                    },
                    {
                        id: "1420",
                        label: "高青县",
                        value: "370322",
                        children: []
                    },
                    {
                        id: "1421",
                        label: "沂源县",
                        value: "370323",
                        children: []
                    }
                ]
            },
            {
                id: "1162",
                label: "枣庄市",
                value: "370400",
                children: [
                    {
                        id: "1422",
                        label: "市辖区",
                        value: "370401",
                        children: []
                    },
                    {
                        id: "1423",
                        label: "市中区",
                        value: "370402",
                        children: []
                    },
                    {
                        id: "1424",
                        label: "薛城区",
                        value: "370403",
                        children: []
                    },
                    {
                        id: "1425",
                        label: "峄城区",
                        value: "370404",
                        children: []
                    },
                    {
                        id: "1426",
                        label: "台儿庄区",
                        value: "370405",
                        children: []
                    },
                    {
                        id: "1427",
                        label: "山亭区",
                        value: "370406",
                        children: []
                    },
                    {
                        id: "1428",
                        label: "滕州市",
                        value: "370481",
                        children: []
                    }
                ]
            },
            {
                id: "1163",
                label: "东营市",
                value: "370500",
                children: [
                    {
                        id: "1429",
                        label: "市辖区",
                        value: "370501",
                        children: []
                    },
                    {
                        id: "1430",
                        label: "东营区",
                        value: "370502",
                        children: []
                    },
                    {
                        id: "1431",
                        label: "河口区",
                        value: "370503",
                        children: []
                    },
                    {
                        id: "1432",
                        label: "垦利县",
                        value: "370521",
                        children: []
                    },
                    {
                        id: "1433",
                        label: "利津县",
                        value: "370522",
                        children: []
                    },
                    {
                        id: "1434",
                        label: "广饶县",
                        value: "370523",
                        children: []
                    }
                ]
            },
            {
                id: "1164",
                label: "烟台市",
                value: "370600",
                children: [
                    {
                        id: "1435",
                        label: "市辖区",
                        value: "370601",
                        children: []
                    },
                    {
                        id: "1436",
                        label: "芝罘区",
                        value: "370602",
                        children: []
                    },
                    {
                        id: "1437",
                        label: "福山区",
                        value: "370611",
                        children: []
                    },
                    {
                        id: "1438",
                        label: "牟平区",
                        value: "370612",
                        children: []
                    },
                    {
                        id: "1439",
                        label: "莱山区",
                        value: "370613",
                        children: []
                    },
                    {
                        id: "1440",
                        label: "长岛县",
                        value: "370634",
                        children: []
                    },
                    {
                        id: "1441",
                        label: "龙口市",
                        value: "370681",
                        children: []
                    },
                    {
                        id: "1442",
                        label: "莱阳市",
                        value: "370682",
                        children: []
                    },
                    {
                        id: "1443",
                        label: "莱州市",
                        value: "370683",
                        children: []
                    },
                    {
                        id: "1444",
                        label: "蓬莱市",
                        value: "370684",
                        children: []
                    },
                    {
                        id: "1445",
                        label: "招远市",
                        value: "370685",
                        children: []
                    },
                    {
                        id: "1446",
                        label: "栖霞市",
                        value: "370686",
                        children: []
                    },
                    {
                        id: "1447",
                        label: "海阳市",
                        value: "370687",
                        children: []
                    }
                ]
            },
            {
                id: "1165",
                label: "潍坊市",
                value: "370700",
                children: [
                    {
                        id: "1448",
                        label: "市辖区",
                        value: "370701",
                        children: []
                    },
                    {
                        id: "1449",
                        label: "潍城区",
                        value: "370702",
                        children: []
                    },
                    {
                        id: "1450",
                        label: "寒亭区",
                        value: "370703",
                        children: []
                    },
                    {
                        id: "1451",
                        label: "坊子区",
                        value: "370704",
                        children: []
                    },
                    {
                        id: "1452",
                        label: "奎文区",
                        value: "370705",
                        children: []
                    },
                    {
                        id: "1453",
                        label: "临朐县",
                        value: "370724",
                        children: []
                    },
                    {
                        id: "1454",
                        label: "昌乐县",
                        value: "370725",
                        children: []
                    },
                    {
                        id: "1455",
                        label: "青州市",
                        value: "370781",
                        children: []
                    },
                    {
                        id: "1456",
                        label: "诸城市",
                        value: "370782",
                        children: []
                    },
                    {
                        id: "1457",
                        label: "寿光市",
                        value: "370783",
                        children: []
                    },
                    {
                        id: "1458",
                        label: "安丘市",
                        value: "370784",
                        children: []
                    },
                    {
                        id: "1459",
                        label: "高密市",
                        value: "370785",
                        children: []
                    },
                    {
                        id: "1460",
                        label: "昌邑市",
                        value: "370786",
                        children: []
                    }
                ]
            },
            {
                id: "1166",
                label: "济宁市",
                value: "370800",
                children: [
                    {
                        id: "1461",
                        label: "市辖区",
                        value: "370801",
                        children: []
                    },
                    {
                        id: "1462",
                        label: "市中区",
                        value: "370802",
                        children: []
                    },
                    {
                        id: "1463",
                        label: "任城区",
                        value: "370811",
                        children: []
                    },
                    {
                        id: "1464",
                        label: "微山县",
                        value: "370826",
                        children: []
                    },
                    {
                        id: "1465",
                        label: "鱼台县",
                        value: "370827",
                        children: []
                    },
                    {
                        id: "1466",
                        label: "金乡县",
                        value: "370828",
                        children: []
                    },
                    {
                        id: "1467",
                        label: "嘉祥县",
                        value: "370829",
                        children: []
                    },
                    {
                        id: "1468",
                        label: "汶上县",
                        value: "370830",
                        children: []
                    },
                    {
                        id: "1469",
                        label: "泗水县",
                        value: "370831",
                        children: []
                    },
                    {
                        id: "1470",
                        label: "梁山县",
                        value: "370832",
                        children: []
                    },
                    {
                        id: "1471",
                        label: "曲阜市",
                        value: "370881",
                        children: []
                    },
                    {
                        id: "1472",
                        label: "兖州市",
                        value: "370882",
                        children: []
                    },
                    {
                        id: "1473",
                        label: "邹城市",
                        value: "370883",
                        children: []
                    }
                ]
            },
            {
                id: "1167",
                label: "泰安市",
                value: "370900",
                children: [
                    {
                        id: "1474",
                        label: "市辖区",
                        value: "370901",
                        children: []
                    },
                    {
                        id: "1475",
                        label: "泰山区",
                        value: "370902",
                        children: []
                    },
                    {
                        id: "1476",
                        label: "岱岳区",
                        value: "370911",
                        children: []
                    },
                    {
                        id: "1477",
                        label: "宁阳县",
                        value: "370921",
                        children: []
                    },
                    {
                        id: "1478",
                        label: "东平县",
                        value: "370923",
                        children: []
                    },
                    {
                        id: "1479",
                        label: "新泰市",
                        value: "370982",
                        children: []
                    },
                    {
                        id: "1480",
                        label: "肥城市",
                        value: "370983",
                        children: []
                    }
                ]
            },
            {
                id: "1168",
                label: "威海市",
                value: "371000",
                children: [
                    {
                        id: "1481",
                        label: "市辖区",
                        value: "371001",
                        children: []
                    },
                    {
                        id: "1482",
                        label: "环翠区",
                        value: "371002",
                        children: []
                    },
                    {
                        id: "1483",
                        label: "文登市",
                        value: "371081",
                        children: []
                    },
                    {
                        id: "1484",
                        label: "荣成市",
                        value: "371082",
                        children: []
                    },
                    {
                        id: "1485",
                        label: "乳山市",
                        value: "371083",
                        children: []
                    }
                ]
            },
            {
                id: "1169",
                label: "日照市",
                value: "371100",
                children: [
                    {
                        id: "1486",
                        label: "市辖区",
                        value: "371101",
                        children: []
                    },
                    {
                        id: "1487",
                        label: "东港区",
                        value: "371102",
                        children: []
                    },
                    {
                        id: "1488",
                        label: "岚山区",
                        value: "371103",
                        children: []
                    },
                    {
                        id: "1489",
                        label: "五莲县",
                        value: "371121",
                        children: []
                    },
                    {
                        id: "1490",
                        label: "莒县",
                        value: "371122",
                        children: []
                    }
                ]
            },
            {
                id: "1170",
                label: "莱芜市",
                value: "371200",
                children: [
                    {
                        id: "1491",
                        label: "市辖区",
                        value: "371201",
                        children: []
                    },
                    {
                        id: "1492",
                        label: "莱城区",
                        value: "371202",
                        children: []
                    },
                    {
                        id: "1493",
                        label: "钢城区",
                        value: "371203",
                        children: []
                    }
                ]
            },
            {
                id: "1171",
                label: "临沂市",
                value: "371300",
                children: [
                    {
                        id: "1494",
                        label: "市辖区",
                        value: "371301",
                        children: []
                    },
                    {
                        id: "1495",
                        label: "兰山区",
                        value: "371302",
                        children: []
                    },
                    {
                        id: "1496",
                        label: "罗庄区",
                        value: "371311",
                        children: []
                    },
                    {
                        id: "1497",
                        label: "河东区",
                        value: "371312",
                        children: []
                    },
                    {
                        id: "1498",
                        label: "沂南县",
                        value: "371321",
                        children: []
                    },
                    {
                        id: "1499",
                        label: "郯城县",
                        value: "371322",
                        children: []
                    },
                    {
                        id: "1500",
                        label: "沂水县",
                        value: "371323",
                        children: []
                    },
                    {
                        id: "1501",
                        label: "苍山县",
                        value: "371324",
                        children: []
                    },
                    {
                        id: "1502",
                        label: "费县",
                        value: "371325",
                        children: []
                    },
                    {
                        id: "1503",
                        label: "平邑县",
                        value: "371326",
                        children: []
                    },
                    {
                        id: "1504",
                        label: "莒南县",
                        value: "371327",
                        children: []
                    },
                    {
                        id: "1505",
                        label: "蒙阴县",
                        value: "371328",
                        children: []
                    },
                    {
                        id: "1506",
                        label: "临沭县",
                        value: "371329",
                        children: []
                    }
                ]
            },
            {
                id: "1172",
                label: "德州市",
                value: "371400",
                children: [
                    {
                        id: "1507",
                        label: "市辖区",
                        value: "371401",
                        children: []
                    },
                    {
                        id: "1508",
                        label: "德城区",
                        value: "371402",
                        children: []
                    },
                    {
                        id: "1509",
                        label: "陵县",
                        value: "371421",
                        children: []
                    },
                    {
                        id: "1510",
                        label: "宁津县",
                        value: "371422",
                        children: []
                    },
                    {
                        id: "1511",
                        label: "庆云县",
                        value: "371423",
                        children: []
                    },
                    {
                        id: "1512",
                        label: "临邑县",
                        value: "371424",
                        children: []
                    },
                    {
                        id: "1513",
                        label: "齐河县",
                        value: "371425",
                        children: []
                    },
                    {
                        id: "1514",
                        label: "平原县",
                        value: "371426",
                        children: []
                    },
                    {
                        id: "1515",
                        label: "夏津县",
                        value: "371427",
                        children: []
                    },
                    {
                        id: "1516",
                        label: "武城县",
                        value: "371428",
                        children: []
                    },
                    {
                        id: "1517",
                        label: "乐陵市",
                        value: "371481",
                        children: []
                    },
                    {
                        id: "1518",
                        label: "禹城市",
                        value: "371482",
                        children: []
                    }
                ]
            },
            {
                id: "1173",
                label: "聊城市",
                value: "371500",
                children: [
                    {
                        id: "1519",
                        label: "市辖区",
                        value: "371501",
                        children: []
                    },
                    {
                        id: "1520",
                        label: "东昌府区",
                        value: "371502",
                        children: []
                    },
                    {
                        id: "1521",
                        label: "阳谷县",
                        value: "371521",
                        children: []
                    },
                    {
                        id: "1522",
                        label: "莘县",
                        value: "371522",
                        children: []
                    },
                    {
                        id: "1523",
                        label: "茌平县",
                        value: "371523",
                        children: []
                    },
                    {
                        id: "1524",
                        label: "东阿县",
                        value: "371524",
                        children: []
                    },
                    {
                        id: "1525",
                        label: "冠县",
                        value: "371525",
                        children: []
                    },
                    {
                        id: "1526",
                        label: "高唐县",
                        value: "371526",
                        children: []
                    },
                    {
                        id: "1527",
                        label: "临清市",
                        value: "371581",
                        children: []
                    }
                ]
            },
            {
                id: "1174",
                label: "滨州市",
                value: "371600",
                children: [
                    {
                        id: "1528",
                        label: "市辖区",
                        value: "371601",
                        children: []
                    },
                    {
                        id: "1529",
                        label: "滨城区",
                        value: "371602",
                        children: []
                    },
                    {
                        id: "1530",
                        label: "惠民县",
                        value: "371621",
                        children: []
                    },
                    {
                        id: "1531",
                        label: "阳信县",
                        value: "371622",
                        children: []
                    },
                    {
                        id: "1532",
                        label: "无棣县",
                        value: "371623",
                        children: []
                    },
                    {
                        id: "1533",
                        label: "沾化县",
                        value: "371624",
                        children: []
                    },
                    {
                        id: "1534",
                        label: "博兴县",
                        value: "371625",
                        children: []
                    },
                    {
                        id: "1535",
                        label: "邹平县",
                        value: "371626",
                        children: []
                    }
                ]
            },
            {
                id: "1175",
                label: "菏泽市",
                value: "371700",
                children: [
                    {
                        id: "1536",
                        label: "市辖区",
                        value: "371701",
                        children: []
                    },
                    {
                        id: "1537",
                        label: "牡丹区",
                        value: "371702",
                        children: []
                    },
                    {
                        id: "1538",
                        label: "曹县",
                        value: "371721",
                        children: []
                    },
                    {
                        id: "1539",
                        label: "单县",
                        value: "371722",
                        children: []
                    },
                    {
                        id: "1540",
                        label: "成武县",
                        value: "371723",
                        children: []
                    },
                    {
                        id: "1541",
                        label: "巨野县",
                        value: "371724",
                        children: []
                    },
                    {
                        id: "1542",
                        label: "郓城县",
                        value: "371725",
                        children: []
                    },
                    {
                        id: "1543",
                        label: "鄄城县",
                        value: "371726",
                        children: []
                    },
                    {
                        id: "1544",
                        label: "定陶县",
                        value: "371727",
                        children: []
                    },
                    {
                        id: "1545",
                        label: "东明县",
                        value: "371728",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "16",
        label: "河南省",
        value: "410000",
        children: [
            {
                id: "1176",
                label: "郑州市",
                value: "410100",
                children: [
                    {
                        id: "1546",
                        label: "市辖区",
                        value: "410101",
                        children: []
                    },
                    {
                        id: "1547",
                        label: "中原区",
                        value: "410102",
                        children: []
                    },
                    {
                        id: "1548",
                        label: "二七区",
                        value: "410103",
                        children: []
                    },
                    {
                        id: "1549",
                        label: "管城回族区",
                        value: "410104",
                        children: []
                    },
                    {
                        id: "1550",
                        label: "金水区",
                        value: "410105",
                        children: []
                    },
                    {
                        id: "1551",
                        label: "上街区",
                        value: "410106",
                        children: []
                    },
                    {
                        id: "1552",
                        label: "惠济区",
                        value: "410108",
                        children: []
                    },
                    {
                        id: "1553",
                        label: "中牟县",
                        value: "410122",
                        children: []
                    },
                    {
                        id: "1554",
                        label: "巩义市",
                        value: "410181",
                        children: []
                    },
                    {
                        id: "1555",
                        label: "荥阳市",
                        value: "410182",
                        children: []
                    },
                    {
                        id: "1556",
                        label: "新密市",
                        value: "410183",
                        children: []
                    },
                    {
                        id: "1557",
                        label: "新郑市",
                        value: "410184",
                        children: []
                    },
                    {
                        id: "1558",
                        label: "登封市",
                        value: "410185",
                        children: []
                    }
                ]
            },
            {
                id: "1177",
                label: "开封市",
                value: "410200",
                children: [
                    {
                        id: "1559",
                        label: "市辖区",
                        value: "410201",
                        children: []
                    },
                    {
                        id: "1560",
                        label: "龙亭区",
                        value: "410202",
                        children: []
                    },
                    {
                        id: "1561",
                        label: "顺河回族区",
                        value: "410203",
                        children: []
                    },
                    {
                        id: "1562",
                        label: "鼓楼区",
                        value: "410204",
                        children: []
                    },
                    {
                        id: "1563",
                        label: "禹王台区",
                        value: "410205",
                        children: []
                    },
                    {
                        id: "1564",
                        label: "金明区",
                        value: "410211",
                        children: []
                    },
                    {
                        id: "1565",
                        label: "杞县",
                        value: "410221",
                        children: []
                    },
                    {
                        id: "1566",
                        label: "通许县",
                        value: "410222",
                        children: []
                    },
                    {
                        id: "1567",
                        label: "尉氏县",
                        value: "410223",
                        children: []
                    },
                    {
                        id: "1568",
                        label: "开封县",
                        value: "410224",
                        children: []
                    },
                    {
                        id: "1569",
                        label: "兰考县",
                        value: "410225",
                        children: []
                    }
                ]
            },
            {
                id: "1178",
                label: "洛阳市",
                value: "410300",
                children: [
                    {
                        id: "1570",
                        label: "市辖区",
                        value: "410301",
                        children: []
                    },
                    {
                        id: "1571",
                        label: "老城区",
                        value: "410302",
                        children: []
                    },
                    {
                        id: "1572",
                        label: "西工区",
                        value: "410303",
                        children: []
                    },
                    {
                        id: "1573",
                        label: "?e河回族区",
                        value: "410304",
                        children: []
                    },
                    {
                        id: "1574",
                        label: "涧西区",
                        value: "410305",
                        children: []
                    },
                    {
                        id: "1575",
                        label: "吉利区",
                        value: "410306",
                        children: []
                    },
                    {
                        id: "1576",
                        label: "洛龙区",
                        value: "410311",
                        children: []
                    },
                    {
                        id: "1577",
                        label: "孟津县",
                        value: "410322",
                        children: []
                    },
                    {
                        id: "1578",
                        label: "新安县",
                        value: "410323",
                        children: []
                    },
                    {
                        id: "1579",
                        label: "栾川县",
                        value: "410324",
                        children: []
                    },
                    {
                        id: "1580",
                        label: "嵩县",
                        value: "410325",
                        children: []
                    },
                    {
                        id: "1581",
                        label: "汝阳县",
                        value: "410326",
                        children: []
                    },
                    {
                        id: "1582",
                        label: "宜阳县",
                        value: "410327",
                        children: []
                    },
                    {
                        id: "1583",
                        label: "洛宁县",
                        value: "410328",
                        children: []
                    },
                    {
                        id: "1584",
                        label: "伊川县",
                        value: "410329",
                        children: []
                    },
                    {
                        id: "1585",
                        label: "偃师市",
                        value: "410381",
                        children: []
                    }
                ]
            },
            {
                id: "1179",
                label: "平顶山市",
                value: "410400",
                children: [
                    {
                        id: "1586",
                        label: "市辖区",
                        value: "410401",
                        children: []
                    },
                    {
                        id: "1587",
                        label: "新华区",
                        value: "410402",
                        children: []
                    },
                    {
                        id: "1588",
                        label: "卫东区",
                        value: "410403",
                        children: []
                    },
                    {
                        id: "1589",
                        label: "石龙区",
                        value: "410404",
                        children: []
                    },
                    {
                        id: "1590",
                        label: "湛河区",
                        value: "410411",
                        children: []
                    },
                    {
                        id: "1591",
                        label: "宝丰县",
                        value: "410421",
                        children: []
                    },
                    {
                        id: "1592",
                        label: "叶县",
                        value: "410422",
                        children: []
                    },
                    {
                        id: "1593",
                        label: "鲁山县",
                        value: "410423",
                        children: []
                    },
                    {
                        id: "1594",
                        label: "郏县",
                        value: "410425",
                        children: []
                    },
                    {
                        id: "1595",
                        label: "舞钢市",
                        value: "410481",
                        children: []
                    },
                    {
                        id: "1596",
                        label: "汝州市",
                        value: "410482",
                        children: []
                    }
                ]
            },
            {
                id: "1180",
                label: "安阳市",
                value: "410500",
                children: [
                    {
                        id: "1597",
                        label: "市辖区",
                        value: "410501",
                        children: []
                    },
                    {
                        id: "1598",
                        label: "文峰区",
                        value: "410502",
                        children: []
                    },
                    {
                        id: "1599",
                        label: "北关区",
                        value: "410503",
                        children: []
                    },
                    {
                        id: "1600",
                        label: "殷都区",
                        value: "410505",
                        children: []
                    },
                    {
                        id: "1601",
                        label: "龙安区",
                        value: "410506",
                        children: []
                    },
                    {
                        id: "1602",
                        label: "安阳县",
                        value: "410522",
                        children: []
                    },
                    {
                        id: "1603",
                        label: "汤阴县",
                        value: "410523",
                        children: []
                    },
                    {
                        id: "1604",
                        label: "滑县",
                        value: "410526",
                        children: []
                    },
                    {
                        id: "1605",
                        label: "内黄县",
                        value: "410527",
                        children: []
                    },
                    {
                        id: "1606",
                        label: "林州市",
                        value: "410581",
                        children: []
                    }
                ]
            },
            {
                id: "1181",
                label: "鹤壁市",
                value: "410600",
                children: [
                    {
                        id: "1607",
                        label: "市辖区",
                        value: "410601",
                        children: []
                    },
                    {
                        id: "1608",
                        label: "鹤山区",
                        value: "410602",
                        children: []
                    },
                    {
                        id: "1609",
                        label: "山城区",
                        value: "410603",
                        children: []
                    },
                    {
                        id: "1610",
                        label: "淇滨区",
                        value: "410611",
                        children: []
                    },
                    {
                        id: "1611",
                        label: "浚县",
                        value: "410621",
                        children: []
                    },
                    {
                        id: "1612",
                        label: "淇县",
                        value: "410622",
                        children: []
                    }
                ]
            },
            {
                id: "1182",
                label: "新乡市",
                value: "410700",
                children: [
                    {
                        id: "1613",
                        label: "市辖区",
                        value: "410701",
                        children: []
                    },
                    {
                        id: "1614",
                        label: "红旗区",
                        value: "410702",
                        children: []
                    },
                    {
                        id: "1615",
                        label: "卫滨区",
                        value: "410703",
                        children: []
                    },
                    {
                        id: "1616",
                        label: "凤泉区",
                        value: "410704",
                        children: []
                    },
                    {
                        id: "1617",
                        label: "牧野区",
                        value: "410711",
                        children: []
                    },
                    {
                        id: "1618",
                        label: "新乡县",
                        value: "410721",
                        children: []
                    },
                    {
                        id: "1619",
                        label: "获嘉县",
                        value: "410724",
                        children: []
                    },
                    {
                        id: "1620",
                        label: "原阳县",
                        value: "410725",
                        children: []
                    },
                    {
                        id: "1621",
                        label: "延津县",
                        value: "410726",
                        children: []
                    },
                    {
                        id: "1622",
                        label: "封丘县",
                        value: "410727",
                        children: []
                    },
                    {
                        id: "1623",
                        label: "长垣县",
                        value: "410728",
                        children: []
                    },
                    {
                        id: "1624",
                        label: "卫辉市",
                        value: "410781",
                        children: []
                    },
                    {
                        id: "1625",
                        label: "辉县市",
                        value: "410782",
                        children: []
                    }
                ]
            },
            {
                id: "1183",
                label: "焦作市",
                value: "410800",
                children: [
                    {
                        id: "1626",
                        label: "市辖区",
                        value: "410801",
                        children: []
                    },
                    {
                        id: "1627",
                        label: "解放区",
                        value: "410802",
                        children: []
                    },
                    {
                        id: "1628",
                        label: "中站区",
                        value: "410803",
                        children: []
                    },
                    {
                        id: "1629",
                        label: "马村区",
                        value: "410804",
                        children: []
                    },
                    {
                        id: "1630",
                        label: "山阳区",
                        value: "410811",
                        children: []
                    },
                    {
                        id: "1631",
                        label: "修武县",
                        value: "410821",
                        children: []
                    },
                    {
                        id: "1632",
                        label: "博爱县",
                        value: "410822",
                        children: []
                    },
                    {
                        id: "1633",
                        label: "武陟县",
                        value: "410823",
                        children: []
                    },
                    {
                        id: "1634",
                        label: "温县",
                        value: "410825",
                        children: []
                    },
                    {
                        id: "1635",
                        label: "沁阳市",
                        value: "410882",
                        children: []
                    },
                    {
                        id: "1636",
                        label: "孟州市",
                        value: "410883",
                        children: []
                    }
                ]
            },
            {
                id: "1184",
                label: "濮阳市",
                value: "410900",
                children: [
                    {
                        id: "1637",
                        label: "市辖区",
                        value: "410901",
                        children: []
                    },
                    {
                        id: "1638",
                        label: "华龙区",
                        value: "410902",
                        children: []
                    },
                    {
                        id: "1639",
                        label: "清丰县",
                        value: "410922",
                        children: []
                    },
                    {
                        id: "1640",
                        label: "南乐县",
                        value: "410923",
                        children: []
                    },
                    {
                        id: "1641",
                        label: "范县",
                        value: "410926",
                        children: []
                    },
                    {
                        id: "1642",
                        label: "台前县",
                        value: "410927",
                        children: []
                    },
                    {
                        id: "1643",
                        label: "濮阳县",
                        value: "410928",
                        children: []
                    }
                ]
            },
            {
                id: "1185",
                label: "许昌市",
                value: "411000",
                children: [
                    {
                        id: "1644",
                        label: "市辖区",
                        value: "411001",
                        children: []
                    },
                    {
                        id: "1645",
                        label: "魏都区",
                        value: "411002",
                        children: []
                    },
                    {
                        id: "1646",
                        label: "许昌县",
                        value: "411023",
                        children: []
                    },
                    {
                        id: "1647",
                        label: "鄢陵县",
                        value: "411024",
                        children: []
                    },
                    {
                        id: "1648",
                        label: "襄城县",
                        value: "411025",
                        children: []
                    },
                    {
                        id: "1649",
                        label: "禹州市",
                        value: "411081",
                        children: []
                    },
                    {
                        id: "1650",
                        label: "长葛市",
                        value: "411082",
                        children: []
                    }
                ]
            },
            {
                id: "1186",
                label: "漯河市",
                value: "411100",
                children: [
                    {
                        id: "1651",
                        label: "市辖区",
                        value: "411101",
                        children: []
                    },
                    {
                        id: "1652",
                        label: "源汇区",
                        value: "411102",
                        children: []
                    },
                    {
                        id: "1653",
                        label: "郾城区",
                        value: "411103",
                        children: []
                    },
                    {
                        id: "1654",
                        label: "召陵区",
                        value: "411104",
                        children: []
                    },
                    {
                        id: "1655",
                        label: "舞阳县",
                        value: "411121",
                        children: []
                    },
                    {
                        id: "1656",
                        label: "临颍县",
                        value: "411122",
                        children: []
                    }
                ]
            },
            {
                id: "1187",
                label: "三门峡市",
                value: "411200",
                children: [
                    {
                        id: "1657",
                        label: "市辖区",
                        value: "411201",
                        children: []
                    },
                    {
                        id: "1658",
                        label: "湖滨区",
                        value: "411202",
                        children: []
                    },
                    {
                        id: "1659",
                        label: "渑池县",
                        value: "411221",
                        children: []
                    },
                    {
                        id: "1660",
                        label: "陕县",
                        value: "411222",
                        children: []
                    },
                    {
                        id: "1661",
                        label: "卢氏县",
                        value: "411224",
                        children: []
                    },
                    {
                        id: "1662",
                        label: "义马市",
                        value: "411281",
                        children: []
                    },
                    {
                        id: "1663",
                        label: "灵宝市",
                        value: "411282",
                        children: []
                    }
                ]
            },
            {
                id: "1188",
                label: "南阳市",
                value: "411300",
                children: [
                    {
                        id: "1664",
                        label: "市辖区",
                        value: "411301",
                        children: []
                    },
                    {
                        id: "1665",
                        label: "宛城区",
                        value: "411302",
                        children: []
                    },
                    {
                        id: "1666",
                        label: "卧龙区",
                        value: "411303",
                        children: []
                    },
                    {
                        id: "1667",
                        label: "南召县",
                        value: "411321",
                        children: []
                    },
                    {
                        id: "1668",
                        label: "方城县",
                        value: "411322",
                        children: []
                    },
                    {
                        id: "1669",
                        label: "西峡县",
                        value: "411323",
                        children: []
                    },
                    {
                        id: "1670",
                        label: "镇平县",
                        value: "411324",
                        children: []
                    },
                    {
                        id: "1671",
                        label: "内乡县",
                        value: "411325",
                        children: []
                    },
                    {
                        id: "1672",
                        label: "淅川县",
                        value: "411326",
                        children: []
                    },
                    {
                        id: "1673",
                        label: "社旗县",
                        value: "411327",
                        children: []
                    },
                    {
                        id: "1674",
                        label: "唐河县",
                        value: "411328",
                        children: []
                    },
                    {
                        id: "1675",
                        label: "新野县",
                        value: "411329",
                        children: []
                    },
                    {
                        id: "1676",
                        label: "桐柏县",
                        value: "411330",
                        children: []
                    },
                    {
                        id: "1677",
                        label: "邓州市",
                        value: "411381",
                        children: []
                    }
                ]
            },
            {
                id: "1189",
                label: "商丘市",
                value: "411400",
                children: [
                    {
                        id: "1678",
                        label: "市辖区",
                        value: "411401",
                        children: []
                    },
                    {
                        id: "1679",
                        label: "梁园区",
                        value: "411402",
                        children: []
                    },
                    {
                        id: "1680",
                        label: "睢阳区",
                        value: "411403",
                        children: []
                    },
                    {
                        id: "1681",
                        label: "民权县",
                        value: "411421",
                        children: []
                    },
                    {
                        id: "1682",
                        label: "睢县",
                        value: "411422",
                        children: []
                    },
                    {
                        id: "1683",
                        label: "宁陵县",
                        value: "411423",
                        children: []
                    },
                    {
                        id: "1684",
                        label: "柘城县",
                        value: "411424",
                        children: []
                    },
                    {
                        id: "1685",
                        label: "虞城县",
                        value: "411425",
                        children: []
                    },
                    {
                        id: "1686",
                        label: "夏邑县",
                        value: "411426",
                        children: []
                    },
                    {
                        id: "1687",
                        label: "永城市",
                        value: "411481",
                        children: []
                    }
                ]
            },
            {
                id: "1190",
                label: "信阳市",
                value: "411500",
                children: [
                    {
                        id: "1688",
                        label: "市辖区",
                        value: "411501",
                        children: []
                    },
                    {
                        id: "1689",
                        label: "?负忧?",
                        value: "411502",
                        children: []
                    },
                    {
                        id: "1690",
                        label: "平桥区",
                        value: "411503",
                        children: []
                    },
                    {
                        id: "1691",
                        label: "罗山县",
                        value: "411521",
                        children: []
                    },
                    {
                        id: "1692",
                        label: "光山县",
                        value: "411522",
                        children: []
                    },
                    {
                        id: "1693",
                        label: "新县",
                        value: "411523",
                        children: []
                    },
                    {
                        id: "1694",
                        label: "商城县",
                        value: "411524",
                        children: []
                    },
                    {
                        id: "1695",
                        label: "固始县",
                        value: "411525",
                        children: []
                    },
                    {
                        id: "1696",
                        label: "潢川县",
                        value: "411526",
                        children: []
                    },
                    {
                        id: "1697",
                        label: "淮滨县",
                        value: "411527",
                        children: []
                    },
                    {
                        id: "1698",
                        label: "息县",
                        value: "411528",
                        children: []
                    }
                ]
            },
            {
                id: "1191",
                label: "周口市",
                value: "411600",
                children: [
                    {
                        id: "1699",
                        label: "市辖区",
                        value: "411601",
                        children: []
                    },
                    {
                        id: "1700",
                        label: "川汇区",
                        value: "411602",
                        children: []
                    },
                    {
                        id: "1701",
                        label: "扶沟县",
                        value: "411621",
                        children: []
                    },
                    {
                        id: "1702",
                        label: "西华县",
                        value: "411622",
                        children: []
                    },
                    {
                        id: "1703",
                        label: "商水县",
                        value: "411623",
                        children: []
                    },
                    {
                        id: "1704",
                        label: "沈丘县",
                        value: "411624",
                        children: []
                    },
                    {
                        id: "1705",
                        label: "郸城县",
                        value: "411625",
                        children: []
                    },
                    {
                        id: "1706",
                        label: "淮阳县",
                        value: "411626",
                        children: []
                    },
                    {
                        id: "1707",
                        label: "太康县",
                        value: "411627",
                        children: []
                    },
                    {
                        id: "1708",
                        label: "鹿邑县",
                        value: "411628",
                        children: []
                    },
                    {
                        id: "1709",
                        label: "项城市",
                        value: "411681",
                        children: []
                    }
                ]
            },
            {
                id: "1192",
                label: "驻马店市",
                value: "411700",
                children: [
                    {
                        id: "1710",
                        label: "市辖区",
                        value: "411701",
                        children: []
                    },
                    {
                        id: "1711",
                        label: "驿城区",
                        value: "411702",
                        children: []
                    },
                    {
                        id: "1712",
                        label: "西平县",
                        value: "411721",
                        children: []
                    },
                    {
                        id: "1713",
                        label: "上蔡县",
                        value: "411722",
                        children: []
                    },
                    {
                        id: "1714",
                        label: "平舆县",
                        value: "411723",
                        children: []
                    },
                    {
                        id: "1715",
                        label: "正阳县",
                        value: "411724",
                        children: []
                    },
                    {
                        id: "1716",
                        label: "确山县",
                        value: "411725",
                        children: []
                    },
                    {
                        id: "1717",
                        label: "泌阳县",
                        value: "411726",
                        children: []
                    },
                    {
                        id: "1718",
                        label: "汝南县",
                        value: "411727",
                        children: []
                    },
                    {
                        id: "1719",
                        label: "遂平县",
                        value: "411728",
                        children: []
                    },
                    {
                        id: "1720",
                        label: "新蔡县",
                        value: "411729",
                        children: []
                    }
                ]
            },
            {
                id: "1193",
                label: "省直辖县级行政区划",
                value: "419000",
                children: [
                    {
                        id: "1721",
                        label: "济源市",
                        value: "419001",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "17",
        label: "湖北省",
        value: "420000",
        children: [
            {
                id: "1194",
                label: "武汉市",
                value: "420100",
                children: [
                    {
                        id: "1722",
                        label: "市辖区",
                        value: "420101",
                        children: []
                    },
                    {
                        id: "1723",
                        label: "江岸区",
                        value: "420102",
                        children: []
                    },
                    {
                        id: "1724",
                        label: "江汉区",
                        value: "420103",
                        children: []
                    },
                    {
                        id: "1725",
                        label: "?口区",
                        value: "420104",
                        children: []
                    },
                    {
                        id: "1726",
                        label: "汉阳区",
                        value: "420105",
                        children: []
                    },
                    {
                        id: "1727",
                        label: "武昌区",
                        value: "420106",
                        children: []
                    },
                    {
                        id: "1728",
                        label: "青山区",
                        value: "420107",
                        children: []
                    },
                    {
                        id: "1729",
                        label: "洪山区",
                        value: "420111",
                        children: []
                    },
                    {
                        id: "1730",
                        label: "东西湖区",
                        value: "420112",
                        children: []
                    },
                    {
                        id: "1731",
                        label: "汉南区",
                        value: "420113",
                        children: []
                    },
                    {
                        id: "1732",
                        label: "蔡甸区",
                        value: "420114",
                        children: []
                    },
                    {
                        id: "1733",
                        label: "江夏区",
                        value: "420115",
                        children: []
                    },
                    {
                        id: "1734",
                        label: "黄陂区",
                        value: "420116",
                        children: []
                    },
                    {
                        id: "1735",
                        label: "新洲区",
                        value: "420117",
                        children: []
                    }
                ]
            },
            {
                id: "1195",
                label: "黄石市",
                value: "420200",
                children: [
                    {
                        id: "1736",
                        label: "市辖区",
                        value: "420201",
                        children: []
                    },
                    {
                        id: "1737",
                        label: "黄石港区",
                        value: "420202",
                        children: []
                    },
                    {
                        id: "1738",
                        label: "西塞山区",
                        value: "420203",
                        children: []
                    },
                    {
                        id: "1739",
                        label: "下陆区",
                        value: "420204",
                        children: []
                    },
                    {
                        id: "1740",
                        label: "铁山区",
                        value: "420205",
                        children: []
                    },
                    {
                        id: "1741",
                        label: "阳新县",
                        value: "420222",
                        children: []
                    },
                    {
                        id: "1742",
                        label: "大冶市",
                        value: "420281",
                        children: []
                    }
                ]
            },
            {
                id: "1196",
                label: "十堰市",
                value: "420300",
                children: [
                    {
                        id: "1743",
                        label: "市辖区",
                        value: "420301",
                        children: []
                    },
                    {
                        id: "1744",
                        label: "茅箭区",
                        value: "420302",
                        children: []
                    },
                    {
                        id: "1745",
                        label: "张湾区",
                        value: "420303",
                        children: []
                    },
                    {
                        id: "1746",
                        label: "郧县",
                        value: "420321",
                        children: []
                    },
                    {
                        id: "1747",
                        label: "郧西县",
                        value: "420322",
                        children: []
                    },
                    {
                        id: "1748",
                        label: "竹山县",
                        value: "420323",
                        children: []
                    },
                    {
                        id: "1749",
                        label: "竹溪县",
                        value: "420324",
                        children: []
                    },
                    {
                        id: "1750",
                        label: "房县",
                        value: "420325",
                        children: []
                    },
                    {
                        id: "1751",
                        label: "丹江口市",
                        value: "420381",
                        children: []
                    }
                ]
            },
            {
                id: "1197",
                label: "宜昌市",
                value: "420500",
                children: [
                    {
                        id: "1752",
                        label: "市辖区",
                        value: "420501",
                        children: []
                    },
                    {
                        id: "1753",
                        label: "西陵区",
                        value: "420502",
                        children: []
                    },
                    {
                        id: "1754",
                        label: "伍家岗区",
                        value: "420503",
                        children: []
                    },
                    {
                        id: "1755",
                        label: "点军区",
                        value: "420504",
                        children: []
                    },
                    {
                        id: "1756",
                        label: "?V亭区",
                        value: "420505",
                        children: []
                    },
                    {
                        id: "1757",
                        label: "夷陵区",
                        value: "420506",
                        children: []
                    },
                    {
                        id: "1758",
                        label: "远安县",
                        value: "420525",
                        children: []
                    },
                    {
                        id: "1759",
                        label: "兴山县",
                        value: "420526",
                        children: []
                    },
                    {
                        id: "1760",
                        label: "秭归县",
                        value: "420527",
                        children: []
                    },
                    {
                        id: "1761",
                        label: "长阳土家族自治县",
                        value: "420528",
                        children: []
                    },
                    {
                        id: "1762",
                        label: "五峰土家族自治县",
                        value: "420529",
                        children: []
                    },
                    {
                        id: "1763",
                        label: "宜都市",
                        value: "420581",
                        children: []
                    },
                    {
                        id: "1764",
                        label: "当阳市",
                        value: "420582",
                        children: []
                    },
                    {
                        id: "1765",
                        label: "枝江市",
                        value: "420583",
                        children: []
                    }
                ]
            },
            {
                id: "1198",
                label: "襄樊市",
                value: "420600",
                children: [
                    {
                        id: "1766",
                        label: "市辖区",
                        value: "420601",
                        children: []
                    },
                    {
                        id: "1767",
                        label: "襄城区",
                        value: "420602",
                        children: []
                    },
                    {
                        id: "1768",
                        label: "樊城区",
                        value: "420606",
                        children: []
                    },
                    {
                        id: "1769",
                        label: "襄阳区",
                        value: "420607",
                        children: []
                    },
                    {
                        id: "1770",
                        label: "南漳县",
                        value: "420624",
                        children: []
                    },
                    {
                        id: "1771",
                        label: "谷城县",
                        value: "420625",
                        children: []
                    },
                    {
                        id: "1772",
                        label: "保康县",
                        value: "420626",
                        children: []
                    },
                    {
                        id: "1773",
                        label: "老河口市",
                        value: "420682",
                        children: []
                    },
                    {
                        id: "1774",
                        label: "枣阳市",
                        value: "420683",
                        children: []
                    },
                    {
                        id: "1775",
                        label: "宜城市",
                        value: "420684",
                        children: []
                    }
                ]
            },
            {
                id: "1199",
                label: "鄂州市",
                value: "420700",
                children: [
                    {
                        id: "1776",
                        label: "市辖区",
                        value: "420701",
                        children: []
                    },
                    {
                        id: "1777",
                        label: "梁子湖区",
                        value: "420702",
                        children: []
                    },
                    {
                        id: "1778",
                        label: "华容区",
                        value: "420703",
                        children: []
                    },
                    {
                        id: "1779",
                        label: "鄂城区",
                        value: "420704",
                        children: []
                    }
                ]
            },
            {
                id: "1200",
                label: "荆门市",
                value: "420800",
                children: [
                    {
                        id: "1780",
                        label: "市辖区",
                        value: "420801",
                        children: []
                    },
                    {
                        id: "1781",
                        label: "东宝区",
                        value: "420802",
                        children: []
                    },
                    {
                        id: "1782",
                        label: "掇刀区",
                        value: "420804",
                        children: []
                    },
                    {
                        id: "1783",
                        label: "京山县",
                        value: "420821",
                        children: []
                    },
                    {
                        id: "1784",
                        label: "沙洋县",
                        value: "420822",
                        children: []
                    },
                    {
                        id: "1785",
                        label: "钟祥市",
                        value: "420881",
                        children: []
                    }
                ]
            },
            {
                id: "1201",
                label: "孝感市",
                value: "420900",
                children: [
                    {
                        id: "1786",
                        label: "市辖区",
                        value: "420901",
                        children: []
                    },
                    {
                        id: "1787",
                        label: "孝南区",
                        value: "420902",
                        children: []
                    },
                    {
                        id: "1788",
                        label: "孝昌县",
                        value: "420921",
                        children: []
                    },
                    {
                        id: "1789",
                        label: "大悟县",
                        value: "420922",
                        children: []
                    },
                    {
                        id: "1790",
                        label: "云梦县",
                        value: "420923",
                        children: []
                    },
                    {
                        id: "1791",
                        label: "应城市",
                        value: "420981",
                        children: []
                    },
                    {
                        id: "1792",
                        label: "安陆市",
                        value: "420982",
                        children: []
                    },
                    {
                        id: "1793",
                        label: "汉川市",
                        value: "420984",
                        children: []
                    }
                ]
            },
            {
                id: "1202",
                label: "荆州市",
                value: "421000",
                children: [
                    {
                        id: "1794",
                        label: "市辖区",
                        value: "421001",
                        children: []
                    },
                    {
                        id: "1795",
                        label: "沙市区",
                        value: "421002",
                        children: []
                    },
                    {
                        id: "1796",
                        label: "荆州区",
                        value: "421003",
                        children: []
                    },
                    {
                        id: "1797",
                        label: "公安县",
                        value: "421022",
                        children: []
                    },
                    {
                        id: "1798",
                        label: "监利县",
                        value: "421023",
                        children: []
                    },
                    {
                        id: "1799",
                        label: "江陵县",
                        value: "421024",
                        children: []
                    },
                    {
                        id: "1800",
                        label: "石首市",
                        value: "421081",
                        children: []
                    },
                    {
                        id: "1801",
                        label: "洪湖市",
                        value: "421083",
                        children: []
                    },
                    {
                        id: "1802",
                        label: "松滋市",
                        value: "421087",
                        children: []
                    }
                ]
            },
            {
                id: "1203",
                label: "黄冈市",
                value: "421100",
                children: [
                    {
                        id: "1803",
                        label: "市辖区",
                        value: "421101",
                        children: []
                    },
                    {
                        id: "1804",
                        label: "黄州区",
                        value: "421102",
                        children: []
                    },
                    {
                        id: "1805",
                        label: "团风县",
                        value: "421121",
                        children: []
                    },
                    {
                        id: "1806",
                        label: "红安县",
                        value: "421122",
                        children: []
                    },
                    {
                        id: "1807",
                        label: "罗田县",
                        value: "421123",
                        children: []
                    },
                    {
                        id: "1808",
                        label: "英山县",
                        value: "421124",
                        children: []
                    },
                    {
                        id: "1809",
                        label: "浠水县",
                        value: "421125",
                        children: []
                    },
                    {
                        id: "1810",
                        label: "蕲春县",
                        value: "421126",
                        children: []
                    },
                    {
                        id: "1811",
                        label: "黄梅县",
                        value: "421127",
                        children: []
                    },
                    {
                        id: "1812",
                        label: "麻城市",
                        value: "421181",
                        children: []
                    },
                    {
                        id: "1813",
                        label: "武穴市",
                        value: "421182",
                        children: []
                    }
                ]
            },
            {
                id: "1204",
                label: "咸宁市",
                value: "421200",
                children: [
                    {
                        id: "1814",
                        label: "市辖区",
                        value: "421201",
                        children: []
                    },
                    {
                        id: "1815",
                        label: "咸安区",
                        value: "421202",
                        children: []
                    },
                    {
                        id: "1816",
                        label: "嘉鱼县",
                        value: "421221",
                        children: []
                    },
                    {
                        id: "1817",
                        label: "通城县",
                        value: "421222",
                        children: []
                    },
                    {
                        id: "1818",
                        label: "崇阳县",
                        value: "421223",
                        children: []
                    },
                    {
                        id: "1819",
                        label: "通山县",
                        value: "421224",
                        children: []
                    },
                    {
                        id: "1820",
                        label: "赤壁市",
                        value: "421281",
                        children: []
                    }
                ]
            },
            {
                id: "1205",
                label: "随州市",
                value: "421300",
                children: [
                    {
                        id: "1821",
                        label: "市辖区",
                        value: "421301",
                        children: []
                    },
                    {
                        id: "1822",
                        label: "曾都区",
                        value: "421303",
                        children: []
                    },
                    {
                        id: "1823",
                        label: "随县",
                        value: "421321",
                        children: []
                    },
                    {
                        id: "1824",
                        label: "广水市",
                        value: "421381",
                        children: []
                    }
                ]
            },
            {
                id: "1206",
                label: "恩施土家族苗族自治州",
                value: "422800",
                children: [
                    {
                        id: "1825",
                        label: "恩施市",
                        value: "422801",
                        children: []
                    },
                    {
                        id: "1826",
                        label: "利川市",
                        value: "422802",
                        children: []
                    },
                    {
                        id: "1827",
                        label: "建始县",
                        value: "422822",
                        children: []
                    },
                    {
                        id: "1828",
                        label: "巴东县",
                        value: "422823",
                        children: []
                    },
                    {
                        id: "1829",
                        label: "宣恩县",
                        value: "422825",
                        children: []
                    },
                    {
                        id: "1830",
                        label: "咸丰县",
                        value: "422826",
                        children: []
                    },
                    {
                        id: "1831",
                        label: "来凤县",
                        value: "422827",
                        children: []
                    },
                    {
                        id: "1832",
                        label: "鹤峰县",
                        value: "422828",
                        children: []
                    }
                ]
            },
            {
                id: "1207",
                label: "省直辖县级行政区划",
                value: "429000",
                children: [
                    {
                        id: "1833",
                        label: "仙桃市",
                        value: "429004",
                        children: []
                    },
                    {
                        id: "1834",
                        label: "潜江市",
                        value: "429005",
                        children: []
                    },
                    {
                        id: "1835",
                        label: "天门市",
                        value: "429006",
                        children: []
                    },
                    {
                        id: "1836",
                        label: "神农架林区",
                        value: "429021",
                        children: []
                    }
                ]
            },
            {
                id: "1368",
                label: "DYS-当阳市",
                value: "DY",
                children: []
            }
        ]
    },
    {
        id: "18",
        label: "湖南省",
        value: "430000",
        children: [
            {
                id: "1208",
                label: "长沙市",
                value: "430100",
                children: [
                    {
                        id: "1837",
                        label: "市辖区",
                        value: "430101",
                        children: []
                    },
                    {
                        id: "1838",
                        label: "芙蓉区",
                        value: "430102",
                        children: []
                    },
                    {
                        id: "1839",
                        label: "天心区",
                        value: "430103",
                        children: []
                    },
                    {
                        id: "1840",
                        label: "岳麓区",
                        value: "430104",
                        children: []
                    },
                    {
                        id: "1841",
                        label: "开福区",
                        value: "430105",
                        children: []
                    },
                    {
                        id: "1842",
                        label: "雨花区",
                        value: "430111",
                        children: []
                    },
                    {
                        id: "1843",
                        label: "长沙县",
                        value: "430121",
                        children: []
                    },
                    {
                        id: "1844",
                        label: "望城县",
                        value: "430122",
                        children: []
                    },
                    {
                        id: "1845",
                        label: "宁乡县",
                        value: "430124",
                        children: []
                    },
                    {
                        id: "1846",
                        label: "浏阳市",
                        value: "430181",
                        children: []
                    }
                ]
            },
            {
                id: "1209",
                label: "株洲市",
                value: "430200",
                children: [
                    {
                        id: "1847",
                        label: "市辖区",
                        value: "430201",
                        children: []
                    },
                    {
                        id: "1848",
                        label: "荷塘区",
                        value: "430202",
                        children: []
                    },
                    {
                        id: "1849",
                        label: "芦淞区",
                        value: "430203",
                        children: []
                    },
                    {
                        id: "1850",
                        label: "石峰区",
                        value: "430204",
                        children: []
                    },
                    {
                        id: "1851",
                        label: "天元区",
                        value: "430211",
                        children: []
                    },
                    {
                        id: "1852",
                        label: "株洲县",
                        value: "430221",
                        children: []
                    },
                    {
                        id: "1853",
                        label: "攸县",
                        value: "430223",
                        children: []
                    },
                    {
                        id: "1854",
                        label: "茶陵县",
                        value: "430224",
                        children: []
                    },
                    {
                        id: "1855",
                        label: "炎陵县",
                        value: "430225",
                        children: []
                    },
                    {
                        id: "1856",
                        label: "醴陵市",
                        value: "430281",
                        children: []
                    }
                ]
            },
            {
                id: "1210",
                label: "湘潭市",
                value: "430300",
                children: [
                    {
                        id: "1857",
                        label: "市辖区",
                        value: "430301",
                        children: []
                    },
                    {
                        id: "1858",
                        label: "雨湖区",
                        value: "430302",
                        children: []
                    },
                    {
                        id: "1859",
                        label: "岳塘区",
                        value: "430304",
                        children: []
                    },
                    {
                        id: "1860",
                        label: "湘潭县",
                        value: "430321",
                        children: []
                    },
                    {
                        id: "1861",
                        label: "湘乡市",
                        value: "430381",
                        children: []
                    },
                    {
                        id: "1862",
                        label: "韶山市",
                        value: "430382",
                        children: []
                    }
                ]
            },
            {
                id: "1211",
                label: "衡阳市",
                value: "430400",
                children: [
                    {
                        id: "1863",
                        label: "市辖区",
                        value: "430401",
                        children: []
                    },
                    {
                        id: "1864",
                        label: "珠晖区",
                        value: "430405",
                        children: []
                    },
                    {
                        id: "1865",
                        label: "雁峰区",
                        value: "430406",
                        children: []
                    },
                    {
                        id: "1866",
                        label: "石鼓区",
                        value: "430407",
                        children: []
                    },
                    {
                        id: "1867",
                        label: "蒸湘区",
                        value: "430408",
                        children: []
                    },
                    {
                        id: "1868",
                        label: "南岳区",
                        value: "430412",
                        children: []
                    },
                    {
                        id: "1869",
                        label: "衡阳县",
                        value: "430421",
                        children: []
                    },
                    {
                        id: "1870",
                        label: "衡南县",
                        value: "430422",
                        children: []
                    },
                    {
                        id: "1871",
                        label: "衡山县",
                        value: "430423",
                        children: []
                    },
                    {
                        id: "1872",
                        label: "衡东县",
                        value: "430424",
                        children: []
                    },
                    {
                        id: "1873",
                        label: "祁东县",
                        value: "430426",
                        children: []
                    },
                    {
                        id: "1874",
                        label: "耒阳市",
                        value: "430481",
                        children: []
                    },
                    {
                        id: "1875",
                        label: "常宁市",
                        value: "430482",
                        children: []
                    }
                ]
            },
            {
                id: "1212",
                label: "邵阳市",
                value: "430500",
                children: [
                    {
                        id: "1876",
                        label: "市辖区",
                        value: "430501",
                        children: []
                    },
                    {
                        id: "1877",
                        label: "双清区",
                        value: "430502",
                        children: []
                    },
                    {
                        id: "1878",
                        label: "大祥区",
                        value: "430503",
                        children: []
                    },
                    {
                        id: "1879",
                        label: "北塔区",
                        value: "430511",
                        children: []
                    },
                    {
                        id: "1880",
                        label: "邵东县",
                        value: "430521",
                        children: []
                    },
                    {
                        id: "1881",
                        label: "新邵县",
                        value: "430522",
                        children: []
                    },
                    {
                        id: "1882",
                        label: "邵阳县",
                        value: "430523",
                        children: []
                    },
                    {
                        id: "1883",
                        label: "隆回县",
                        value: "430524",
                        children: []
                    },
                    {
                        id: "1884",
                        label: "洞口县",
                        value: "430525",
                        children: []
                    },
                    {
                        id: "1885",
                        label: "绥宁县",
                        value: "430527",
                        children: []
                    },
                    {
                        id: "1886",
                        label: "新宁县",
                        value: "430528",
                        children: []
                    },
                    {
                        id: "1887",
                        label: "城步苗族自治县",
                        value: "430529",
                        children: []
                    },
                    {
                        id: "1888",
                        label: "武冈市",
                        value: "430581",
                        children: []
                    }
                ]
            },
            {
                id: "1213",
                label: "岳阳市",
                value: "430600",
                children: [
                    {
                        id: "1889",
                        label: "市辖区",
                        value: "430601",
                        children: []
                    },
                    {
                        id: "1890",
                        label: "岳阳楼区",
                        value: "430602",
                        children: []
                    },
                    {
                        id: "1891",
                        label: "云溪区",
                        value: "430603",
                        children: []
                    },
                    {
                        id: "1892",
                        label: "君山区",
                        value: "430611",
                        children: []
                    },
                    {
                        id: "1893",
                        label: "岳阳县",
                        value: "430621",
                        children: []
                    },
                    {
                        id: "1894",
                        label: "华容县",
                        value: "430623",
                        children: []
                    },
                    {
                        id: "1895",
                        label: "湘阴县",
                        value: "430624",
                        children: []
                    },
                    {
                        id: "1896",
                        label: "平江县",
                        value: "430626",
                        children: []
                    },
                    {
                        id: "1897",
                        label: "汨罗市",
                        value: "430681",
                        children: []
                    },
                    {
                        id: "1898",
                        label: "临湘市",
                        value: "430682",
                        children: []
                    }
                ]
            },
            {
                id: "1214",
                label: "常德市",
                value: "430700",
                children: [
                    {
                        id: "1899",
                        label: "市辖区",
                        value: "430701",
                        children: []
                    },
                    {
                        id: "1900",
                        label: "武陵区",
                        value: "430702",
                        children: []
                    },
                    {
                        id: "1901",
                        label: "鼎城区",
                        value: "430703",
                        children: []
                    },
                    {
                        id: "1902",
                        label: "安乡县",
                        value: "430721",
                        children: []
                    },
                    {
                        id: "1903",
                        label: "汉寿县",
                        value: "430722",
                        children: []
                    },
                    {
                        id: "1904",
                        label: "澧县",
                        value: "430723",
                        children: []
                    },
                    {
                        id: "1905",
                        label: "临澧县",
                        value: "430724",
                        children: []
                    },
                    {
                        id: "1906",
                        label: "桃源县",
                        value: "430725",
                        children: []
                    },
                    {
                        id: "1907",
                        label: "石门县",
                        value: "430726",
                        children: []
                    },
                    {
                        id: "1908",
                        label: "津市市",
                        value: "430781",
                        children: []
                    }
                ]
            },
            {
                id: "1215",
                label: "张家界市",
                value: "430800",
                children: [
                    {
                        id: "1909",
                        label: "市辖区",
                        value: "430801",
                        children: []
                    },
                    {
                        id: "1910",
                        label: "永定区",
                        value: "430802",
                        children: []
                    },
                    {
                        id: "1911",
                        label: "武陵源区",
                        value: "430811",
                        children: []
                    },
                    {
                        id: "1912",
                        label: "慈利县",
                        value: "430821",
                        children: []
                    },
                    {
                        id: "1913",
                        label: "桑植县",
                        value: "430822",
                        children: []
                    }
                ]
            },
            {
                id: "1216",
                label: "益阳市",
                value: "430900",
                children: [
                    {
                        id: "1914",
                        label: "市辖区",
                        value: "430901",
                        children: []
                    },
                    {
                        id: "1915",
                        label: "资阳区",
                        value: "430902",
                        children: []
                    },
                    {
                        id: "1916",
                        label: "赫山区",
                        value: "430903",
                        children: []
                    },
                    {
                        id: "1917",
                        label: "南县",
                        value: "430921",
                        children: []
                    },
                    {
                        id: "1918",
                        label: "桃江县",
                        value: "430922",
                        children: []
                    },
                    {
                        id: "1919",
                        label: "安化县",
                        value: "430923",
                        children: []
                    },
                    {
                        id: "1920",
                        label: "沅江市",
                        value: "430981",
                        children: []
                    }
                ]
            },
            {
                id: "1217",
                label: "郴州市",
                value: "431000",
                children: [
                    {
                        id: "1921",
                        label: "市辖区",
                        value: "431001",
                        children: []
                    },
                    {
                        id: "1922",
                        label: "北湖区",
                        value: "431002",
                        children: []
                    },
                    {
                        id: "1923",
                        label: "苏仙区",
                        value: "431003",
                        children: []
                    },
                    {
                        id: "1924",
                        label: "桂阳县",
                        value: "431021",
                        children: []
                    },
                    {
                        id: "1925",
                        label: "宜章县",
                        value: "431022",
                        children: []
                    },
                    {
                        id: "1926",
                        label: "永兴县",
                        value: "431023",
                        children: []
                    },
                    {
                        id: "1927",
                        label: "嘉禾县",
                        value: "431024",
                        children: []
                    },
                    {
                        id: "1928",
                        label: "临武县",
                        value: "431025",
                        children: []
                    },
                    {
                        id: "1929",
                        label: "汝城县",
                        value: "431026",
                        children: []
                    },
                    {
                        id: "1930",
                        label: "桂东县",
                        value: "431027",
                        children: []
                    },
                    {
                        id: "1931",
                        label: "安仁县",
                        value: "431028",
                        children: []
                    },
                    {
                        id: "1932",
                        label: "资兴市",
                        value: "431081",
                        children: []
                    }
                ]
            },
            {
                id: "1218",
                label: "永州市",
                value: "431100",
                children: [
                    {
                        id: "1933",
                        label: "市辖区",
                        value: "431101",
                        children: []
                    },
                    {
                        id: "1934",
                        label: "零陵区",
                        value: "431102",
                        children: []
                    },
                    {
                        id: "1935",
                        label: "冷水滩区",
                        value: "431103",
                        children: []
                    },
                    {
                        id: "1936",
                        label: "祁阳县",
                        value: "431121",
                        children: []
                    },
                    {
                        id: "1937",
                        label: "东安县",
                        value: "431122",
                        children: []
                    },
                    {
                        id: "1938",
                        label: "双牌县",
                        value: "431123",
                        children: []
                    },
                    {
                        id: "1939",
                        label: "道县",
                        value: "431124",
                        children: []
                    },
                    {
                        id: "1940",
                        label: "江永县",
                        value: "431125",
                        children: []
                    },
                    {
                        id: "1941",
                        label: "宁远县",
                        value: "431126",
                        children: []
                    },
                    {
                        id: "1942",
                        label: "蓝山县",
                        value: "431127",
                        children: []
                    },
                    {
                        id: "1943",
                        label: "新田县",
                        value: "431128",
                        children: []
                    },
                    {
                        id: "1944",
                        label: "江华瑶族自治县",
                        value: "431129",
                        children: []
                    }
                ]
            },
            {
                id: "1219",
                label: "怀化市",
                value: "431200",
                children: [
                    {
                        id: "1945",
                        label: "市辖区",
                        value: "431201",
                        children: []
                    },
                    {
                        id: "1946",
                        label: "鹤城区",
                        value: "431202",
                        children: []
                    },
                    {
                        id: "1947",
                        label: "中方县",
                        value: "431221",
                        children: []
                    },
                    {
                        id: "1948",
                        label: "沅陵县",
                        value: "431222",
                        children: []
                    },
                    {
                        id: "1949",
                        label: "辰溪县",
                        value: "431223",
                        children: []
                    },
                    {
                        id: "1950",
                        label: "溆浦县",
                        value: "431224",
                        children: []
                    },
                    {
                        id: "1951",
                        label: "会同县",
                        value: "431225",
                        children: []
                    },
                    {
                        id: "1952",
                        label: "麻阳苗族自治县",
                        value: "431226",
                        children: []
                    },
                    {
                        id: "1953",
                        label: "新晃侗族自治县",
                        value: "431227",
                        children: []
                    },
                    {
                        id: "1954",
                        label: "芷江侗族自治县",
                        value: "431228",
                        children: []
                    },
                    {
                        id: "1955",
                        label: "靖州苗族侗族自治县",
                        value: "431229",
                        children: []
                    },
                    {
                        id: "1956",
                        label: "通道侗族自治县",
                        value: "431230",
                        children: []
                    },
                    {
                        id: "1957",
                        label: "洪江市",
                        value: "431281",
                        children: []
                    }
                ]
            },
            {
                id: "1220",
                label: "娄底市",
                value: "431300",
                children: [
                    {
                        id: "1958",
                        label: "市辖区",
                        value: "431301",
                        children: []
                    },
                    {
                        id: "1959",
                        label: "娄星区",
                        value: "431302",
                        children: []
                    },
                    {
                        id: "1960",
                        label: "双峰县",
                        value: "431321",
                        children: []
                    },
                    {
                        id: "1961",
                        label: "新化县",
                        value: "431322",
                        children: []
                    },
                    {
                        id: "1962",
                        label: "冷水江市",
                        value: "431381",
                        children: []
                    },
                    {
                        id: "1963",
                        label: "涟源市",
                        value: "431382",
                        children: []
                    }
                ]
            },
            {
                id: "1221",
                label: "湘西土家族苗族自治州",
                value: "433100",
                children: [
                    {
                        id: "1964",
                        label: "吉首市",
                        value: "433101",
                        children: []
                    },
                    {
                        id: "1965",
                        label: "泸溪县",
                        value: "433122",
                        children: []
                    },
                    {
                        id: "1966",
                        label: "凤凰县",
                        value: "433123",
                        children: []
                    },
                    {
                        id: "1967",
                        label: "花垣县",
                        value: "433124",
                        children: []
                    },
                    {
                        id: "1968",
                        label: "保靖县",
                        value: "433125",
                        children: []
                    },
                    {
                        id: "1969",
                        label: "古丈县",
                        value: "433126",
                        children: []
                    },
                    {
                        id: "1970",
                        label: "永顺县",
                        value: "433127",
                        children: []
                    },
                    {
                        id: "1971",
                        label: "龙山县",
                        value: "433130",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "19",
        label: "广东省",
        value: "440000",
        children: [
            {
                id: "1222",
                label: "广州市",
                value: "440100",
                children: [
                    {
                        id: "1972",
                        label: "市辖区",
                        value: "440101",
                        children: []
                    },
                    {
                        id: "1973",
                        label: "荔湾区",
                        value: "440103",
                        children: []
                    },
                    {
                        id: "1974",
                        label: "越秀区",
                        value: "440104",
                        children: []
                    },
                    {
                        id: "1975",
                        label: "海珠区",
                        value: "440105",
                        children: []
                    },
                    {
                        id: "1976",
                        label: "天河区",
                        value: "440106",
                        children: []
                    },
                    {
                        id: "1977",
                        label: "白云区",
                        value: "440111",
                        children: []
                    },
                    {
                        id: "1978",
                        label: "黄埔区",
                        value: "440112",
                        children: []
                    },
                    {
                        id: "1979",
                        label: "番禺区",
                        value: "440113",
                        children: []
                    },
                    {
                        id: "1980",
                        label: "花都区",
                        value: "440114",
                        children: []
                    },
                    {
                        id: "1981",
                        label: "南沙区",
                        value: "440115",
                        children: []
                    },
                    {
                        id: "1982",
                        label: "萝岗区",
                        value: "440116",
                        children: []
                    },
                    {
                        id: "1983",
                        label: "增城市",
                        value: "440183",
                        children: []
                    },
                    {
                        id: "1984",
                        label: "从化市",
                        value: "440184",
                        children: []
                    }
                ]
            },
            {
                id: "1223",
                label: "韶关市",
                value: "440200",
                children: [
                    {
                        id: "1985",
                        label: "市辖区",
                        value: "440201",
                        children: []
                    },
                    {
                        id: "1986",
                        label: "武江区",
                        value: "440203",
                        children: []
                    },
                    {
                        id: "1987",
                        label: "浈江区",
                        value: "440204",
                        children: []
                    },
                    {
                        id: "1988",
                        label: "曲江区",
                        value: "440205",
                        children: []
                    },
                    {
                        id: "1989",
                        label: "始兴县",
                        value: "440222",
                        children: []
                    },
                    {
                        id: "1990",
                        label: "仁化县",
                        value: "440224",
                        children: []
                    },
                    {
                        id: "1991",
                        label: "翁源县",
                        value: "440229",
                        children: []
                    },
                    {
                        id: "1992",
                        label: "乳源瑶族自治县",
                        value: "440232",
                        children: []
                    },
                    {
                        id: "1993",
                        label: "新丰县",
                        value: "440233",
                        children: []
                    },
                    {
                        id: "1994",
                        label: "乐昌市",
                        value: "440281",
                        children: []
                    },
                    {
                        id: "1995",
                        label: "南雄市",
                        value: "440282",
                        children: []
                    }
                ]
            },
            {
                id: "1224",
                label: "深圳市",
                value: "440300",
                children: [
                    {
                        id: "1996",
                        label: "市辖区",
                        value: "440301",
                        children: []
                    },
                    {
                        id: "1997",
                        label: "罗湖区",
                        value: "440303",
                        children: []
                    },
                    {
                        id: "1998",
                        label: "福田区",
                        value: "440304",
                        children: []
                    },
                    {
                        id: "1999",
                        label: "南山区",
                        value: "440305",
                        children: []
                    },
                    {
                        id: "2000",
                        label: "宝安区",
                        value: "440306",
                        children: []
                    },
                    {
                        id: "2001",
                        label: "龙岗区",
                        value: "440307",
                        children: []
                    },
                    {
                        id: "2002",
                        label: "盐田区",
                        value: "440308",
                        children: []
                    }
                ]
            },
            {
                id: "1225",
                label: "珠海市",
                value: "440400",
                children: [
                    {
                        id: "2003",
                        label: "市辖区",
                        value: "440401",
                        children: []
                    },
                    {
                        id: "2004",
                        label: "香洲区",
                        value: "440402",
                        children: []
                    },
                    {
                        id: "2005",
                        label: "斗门区",
                        value: "440403",
                        children: []
                    },
                    {
                        id: "2006",
                        label: "金湾区",
                        value: "440404",
                        children: []
                    }
                ]
            },
            {
                id: "1226",
                label: "汕头市",
                value: "440500",
                children: [
                    {
                        id: "2007",
                        label: "市辖区",
                        value: "440501",
                        children: []
                    },
                    {
                        id: "2008",
                        label: "龙湖区",
                        value: "440507",
                        children: []
                    },
                    {
                        id: "2009",
                        label: "金平区",
                        value: "440511",
                        children: []
                    },
                    {
                        id: "2010",
                        label: "濠江区",
                        value: "440512",
                        children: []
                    },
                    {
                        id: "2011",
                        label: "潮阳区",
                        value: "440513",
                        children: []
                    },
                    {
                        id: "2012",
                        label: "潮南区",
                        value: "440514",
                        children: []
                    },
                    {
                        id: "2013",
                        label: "澄海区",
                        value: "440515",
                        children: []
                    },
                    {
                        id: "2014",
                        label: "南澳县",
                        value: "440523",
                        children: []
                    }
                ]
            },
            {
                id: "1227",
                label: "佛山市",
                value: "440600",
                children: [
                    {
                        id: "2015",
                        label: "市辖区",
                        value: "440601",
                        children: []
                    },
                    {
                        id: "2016",
                        label: "禅城区",
                        value: "440604",
                        children: []
                    },
                    {
                        id: "2017",
                        label: "南海区",
                        value: "440605",
                        children: []
                    },
                    {
                        id: "2018",
                        label: "顺德区",
                        value: "440606",
                        children: []
                    },
                    {
                        id: "2019",
                        label: "三水区",
                        value: "440607",
                        children: []
                    },
                    {
                        id: "2020",
                        label: "高明区",
                        value: "440608",
                        children: []
                    }
                ]
            },
            {
                id: "1228",
                label: "江门市",
                value: "440700",
                children: [
                    {
                        id: "2021",
                        label: "市辖区",
                        value: "440701",
                        children: []
                    },
                    {
                        id: "2022",
                        label: "蓬江区",
                        value: "440703",
                        children: []
                    },
                    {
                        id: "2023",
                        label: "江海区",
                        value: "440704",
                        children: []
                    },
                    {
                        id: "2024",
                        label: "新会区",
                        value: "440705",
                        children: []
                    },
                    {
                        id: "2025",
                        label: "台山市",
                        value: "440781",
                        children: []
                    },
                    {
                        id: "2026",
                        label: "开平市",
                        value: "440783",
                        children: []
                    },
                    {
                        id: "2027",
                        label: "鹤山市",
                        value: "440784",
                        children: []
                    },
                    {
                        id: "2028",
                        label: "恩平市",
                        value: "440785",
                        children: []
                    }
                ]
            },
            {
                id: "1229",
                label: "湛江市",
                value: "440800",
                children: [
                    {
                        id: "2029",
                        label: "市辖区",
                        value: "440801",
                        children: []
                    },
                    {
                        id: "2030",
                        label: "赤坎区",
                        value: "440802",
                        children: []
                    },
                    {
                        id: "2031",
                        label: "霞山区",
                        value: "440803",
                        children: []
                    },
                    {
                        id: "2032",
                        label: "坡头区",
                        value: "440804",
                        children: []
                    },
                    {
                        id: "2033",
                        label: "麻章区",
                        value: "440811",
                        children: []
                    },
                    {
                        id: "2034",
                        label: "遂溪县",
                        value: "440823",
                        children: []
                    },
                    {
                        id: "2035",
                        label: "徐闻县",
                        value: "440825",
                        children: []
                    },
                    {
                        id: "2036",
                        label: "廉江市",
                        value: "440881",
                        children: []
                    },
                    {
                        id: "2037",
                        label: "雷州市",
                        value: "440882",
                        children: []
                    },
                    {
                        id: "2038",
                        label: "吴川市",
                        value: "440883",
                        children: []
                    }
                ]
            },
            {
                id: "1230",
                label: "茂名市",
                value: "440900",
                children: [
                    {
                        id: "2039",
                        label: "市辖区",
                        value: "440901",
                        children: []
                    },
                    {
                        id: "2040",
                        label: "茂南区",
                        value: "440902",
                        children: []
                    },
                    {
                        id: "2041",
                        label: "茂港区",
                        value: "440903",
                        children: []
                    },
                    {
                        id: "2042",
                        label: "电白县",
                        value: "440923",
                        children: []
                    },
                    {
                        id: "2043",
                        label: "高州市",
                        value: "440981",
                        children: []
                    },
                    {
                        id: "2044",
                        label: "化州市",
                        value: "440982",
                        children: []
                    },
                    {
                        id: "2045",
                        label: "信宜市",
                        value: "440983",
                        children: []
                    }
                ]
            },
            {
                id: "1231",
                label: "肇庆市",
                value: "441200",
                children: [
                    {
                        id: "2046",
                        label: "市辖区",
                        value: "441201",
                        children: []
                    },
                    {
                        id: "2047",
                        label: "端州区",
                        value: "441202",
                        children: []
                    },
                    {
                        id: "2048",
                        label: "鼎湖区",
                        value: "441203",
                        children: []
                    },
                    {
                        id: "2049",
                        label: "广宁县",
                        value: "441223",
                        children: []
                    },
                    {
                        id: "2050",
                        label: "怀集县",
                        value: "441224",
                        children: []
                    },
                    {
                        id: "2051",
                        label: "封开县",
                        value: "441225",
                        children: []
                    },
                    {
                        id: "2052",
                        label: "德庆县",
                        value: "441226",
                        children: []
                    },
                    {
                        id: "2053",
                        label: "高要市",
                        value: "441283",
                        children: []
                    },
                    {
                        id: "2054",
                        label: "四会市",
                        value: "441284",
                        children: []
                    }
                ]
            },
            {
                id: "1232",
                label: "惠州市",
                value: "441300",
                children: [
                    {
                        id: "2055",
                        label: "市辖区",
                        value: "441301",
                        children: []
                    },
                    {
                        id: "2056",
                        label: "惠城区",
                        value: "441302",
                        children: []
                    },
                    {
                        id: "2057",
                        label: "惠阳区",
                        value: "441303",
                        children: []
                    },
                    {
                        id: "2058",
                        label: "博罗县",
                        value: "441322",
                        children: []
                    },
                    {
                        id: "2059",
                        label: "惠东县",
                        value: "441323",
                        children: []
                    },
                    {
                        id: "2060",
                        label: "龙门县",
                        value: "441324",
                        children: []
                    }
                ]
            },
            {
                id: "1233",
                label: "梅州市",
                value: "441400",
                children: [
                    {
                        id: "2061",
                        label: "市辖区",
                        value: "441401",
                        children: []
                    },
                    {
                        id: "2062",
                        label: "梅江区",
                        value: "441402",
                        children: []
                    },
                    {
                        id: "2063",
                        label: "梅县",
                        value: "441421",
                        children: []
                    },
                    {
                        id: "2064",
                        label: "大埔县",
                        value: "441422",
                        children: []
                    },
                    {
                        id: "2065",
                        label: "丰顺县",
                        value: "441423",
                        children: []
                    },
                    {
                        id: "2066",
                        label: "五华县",
                        value: "441424",
                        children: []
                    },
                    {
                        id: "2067",
                        label: "平远县",
                        value: "441426",
                        children: []
                    },
                    {
                        id: "2068",
                        label: "蕉岭县",
                        value: "441427",
                        children: []
                    },
                    {
                        id: "2069",
                        label: "兴宁市",
                        value: "441481",
                        children: []
                    }
                ]
            },
            {
                id: "1234",
                label: "汕尾市",
                value: "441500",
                children: [
                    {
                        id: "2070",
                        label: "市辖区",
                        value: "441501",
                        children: []
                    },
                    {
                        id: "2071",
                        label: "城区",
                        value: "441502",
                        children: []
                    },
                    {
                        id: "2072",
                        label: "海丰县",
                        value: "441521",
                        children: []
                    },
                    {
                        id: "2073",
                        label: "陆河县",
                        value: "441523",
                        children: []
                    },
                    {
                        id: "2074",
                        label: "陆丰市",
                        value: "441581",
                        children: []
                    }
                ]
            },
            {
                id: "1235",
                label: "河源市",
                value: "441600",
                children: [
                    {
                        id: "2075",
                        label: "市辖区",
                        value: "441601",
                        children: []
                    },
                    {
                        id: "2076",
                        label: "源城区",
                        value: "441602",
                        children: []
                    },
                    {
                        id: "2077",
                        label: "紫金县",
                        value: "441621",
                        children: []
                    },
                    {
                        id: "2078",
                        label: "龙川县",
                        value: "441622",
                        children: []
                    },
                    {
                        id: "2079",
                        label: "连平县",
                        value: "441623",
                        children: []
                    },
                    {
                        id: "2080",
                        label: "和平县",
                        value: "441624",
                        children: []
                    },
                    {
                        id: "2081",
                        label: "东源县",
                        value: "441625",
                        children: []
                    }
                ]
            },
            {
                id: "1236",
                label: "阳江市",
                value: "441700",
                children: [
                    {
                        id: "2082",
                        label: "市辖区",
                        value: "441701",
                        children: []
                    },
                    {
                        id: "2083",
                        label: "江城区",
                        value: "441702",
                        children: []
                    },
                    {
                        id: "2084",
                        label: "阳西县",
                        value: "441721",
                        children: []
                    },
                    {
                        id: "2085",
                        label: "阳东县",
                        value: "441723",
                        children: []
                    },
                    {
                        id: "2086",
                        label: "阳春市",
                        value: "441781",
                        children: []
                    }
                ]
            },
            {
                id: "1237",
                label: "清远市",
                value: "441800",
                children: [
                    {
                        id: "2087",
                        label: "市辖区",
                        value: "441801",
                        children: []
                    },
                    {
                        id: "2088",
                        label: "清城区",
                        value: "441802",
                        children: []
                    },
                    {
                        id: "2089",
                        label: "佛冈县",
                        value: "441821",
                        children: []
                    },
                    {
                        id: "2090",
                        label: "阳山县",
                        value: "441823",
                        children: []
                    },
                    {
                        id: "2091",
                        label: "连山壮族瑶族自治县",
                        value: "441825",
                        children: []
                    },
                    {
                        id: "2092",
                        label: "连南瑶族自治县",
                        value: "441826",
                        children: []
                    },
                    {
                        id: "2093",
                        label: "清新县",
                        value: "441827",
                        children: []
                    },
                    {
                        id: "2094",
                        label: "英德市",
                        value: "441881",
                        children: []
                    },
                    {
                        id: "2095",
                        label: "连州市",
                        value: "441882",
                        children: []
                    }
                ]
            },
            {
                id: "1238",
                label: "东莞市",
                value: "441900",
                children: []
            },
            {
                id: "1239",
                label: "中山市",
                value: "442000",
                children: []
            },
            {
                id: "1240",
                label: "潮州市",
                value: "445100",
                children: [
                    {
                        id: "2096",
                        label: "市辖区",
                        value: "445101",
                        children: []
                    },
                    {
                        id: "2097",
                        label: "湘桥区",
                        value: "445102",
                        children: []
                    },
                    {
                        id: "2098",
                        label: "潮安县",
                        value: "445121",
                        children: []
                    },
                    {
                        id: "2099",
                        label: "饶平县",
                        value: "445122",
                        children: []
                    }
                ]
            },
            {
                id: "1241",
                label: "揭阳市",
                value: "445200",
                children: [
                    {
                        id: "2100",
                        label: "市辖区",
                        value: "445201",
                        children: []
                    },
                    {
                        id: "2101",
                        label: "榕城区",
                        value: "445202",
                        children: []
                    },
                    {
                        id: "2102",
                        label: "揭东县",
                        value: "445221",
                        children: []
                    },
                    {
                        id: "2103",
                        label: "揭西县",
                        value: "445222",
                        children: []
                    },
                    {
                        id: "2104",
                        label: "惠来县",
                        value: "445224",
                        children: []
                    },
                    {
                        id: "2105",
                        label: "普宁市",
                        value: "445281",
                        children: []
                    }
                ]
            },
            {
                id: "1242",
                label: "云浮市",
                value: "445300",
                children: [
                    {
                        id: "2106",
                        label: "市辖区",
                        value: "445301",
                        children: []
                    },
                    {
                        id: "2107",
                        label: "云城区",
                        value: "445302",
                        children: []
                    },
                    {
                        id: "2108",
                        label: "新兴县",
                        value: "445321",
                        children: []
                    },
                    {
                        id: "2109",
                        label: "郁南县",
                        value: "445322",
                        children: []
                    },
                    {
                        id: "2110",
                        label: "云安县",
                        value: "445323",
                        children: []
                    },
                    {
                        id: "2111",
                        label: "罗定市",
                        value: "445381",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "20",
        label: "广西壮族自治区",
        value: "450000",
        children: [
            {
                id: "1243",
                label: "南宁市",
                value: "450100",
                children: [
                    {
                        id: "2112",
                        label: "市辖区",
                        value: "450101",
                        children: []
                    },
                    {
                        id: "2113",
                        label: "兴宁区",
                        value: "450102",
                        children: []
                    },
                    {
                        id: "2114",
                        label: "青秀区",
                        value: "450103",
                        children: []
                    },
                    {
                        id: "2115",
                        label: "江南区",
                        value: "450105",
                        children: []
                    },
                    {
                        id: "2116",
                        label: "西乡塘区",
                        value: "450107",
                        children: []
                    },
                    {
                        id: "2117",
                        label: "良庆区",
                        value: "450108",
                        children: []
                    },
                    {
                        id: "2118",
                        label: "邕宁区",
                        value: "450109",
                        children: []
                    },
                    {
                        id: "2119",
                        label: "武鸣县",
                        value: "450122",
                        children: []
                    },
                    {
                        id: "2120",
                        label: "隆安县",
                        value: "450123",
                        children: []
                    },
                    {
                        id: "2121",
                        label: "马山县",
                        value: "450124",
                        children: []
                    },
                    {
                        id: "2122",
                        label: "上林县",
                        value: "450125",
                        children: []
                    },
                    {
                        id: "2123",
                        label: "宾阳县",
                        value: "450126",
                        children: []
                    },
                    {
                        id: "2124",
                        label: "横县",
                        value: "450127",
                        children: []
                    }
                ]
            },
            {
                id: "1244",
                label: "柳州市",
                value: "450200",
                children: [
                    {
                        id: "2125",
                        label: "市辖区",
                        value: "450201",
                        children: []
                    },
                    {
                        id: "2126",
                        label: "城中区",
                        value: "450202",
                        children: []
                    },
                    {
                        id: "2127",
                        label: "鱼峰区",
                        value: "450203",
                        children: []
                    },
                    {
                        id: "2128",
                        label: "柳南区",
                        value: "450204",
                        children: []
                    },
                    {
                        id: "2129",
                        label: "柳北区",
                        value: "450205",
                        children: []
                    },
                    {
                        id: "2130",
                        label: "柳江县",
                        value: "450221",
                        children: []
                    },
                    {
                        id: "2131",
                        label: "柳城县",
                        value: "450222",
                        children: []
                    },
                    {
                        id: "2132",
                        label: "鹿寨县",
                        value: "450223",
                        children: []
                    },
                    {
                        id: "2133",
                        label: "融安县",
                        value: "450224",
                        children: []
                    },
                    {
                        id: "2134",
                        label: "融水苗族自治县",
                        value: "450225",
                        children: []
                    },
                    {
                        id: "2135",
                        label: "三江侗族自治县",
                        value: "450226",
                        children: []
                    }
                ]
            },
            {
                id: "1245",
                label: "桂林市",
                value: "450300",
                children: [
                    {
                        id: "2136",
                        label: "市辖区",
                        value: "450301",
                        children: []
                    },
                    {
                        id: "2137",
                        label: "秀峰区",
                        value: "450302",
                        children: []
                    },
                    {
                        id: "2138",
                        label: "叠彩区",
                        value: "450303",
                        children: []
                    },
                    {
                        id: "2139",
                        label: "象山区",
                        value: "450304",
                        children: []
                    },
                    {
                        id: "2140",
                        label: "七星区",
                        value: "450305",
                        children: []
                    },
                    {
                        id: "2141",
                        label: "雁山区",
                        value: "450311",
                        children: []
                    },
                    {
                        id: "2142",
                        label: "阳朔县",
                        value: "450321",
                        children: []
                    },
                    {
                        id: "2143",
                        label: "临桂县",
                        value: "450322",
                        children: []
                    },
                    {
                        id: "2144",
                        label: "灵川县",
                        value: "450323",
                        children: []
                    },
                    {
                        id: "2145",
                        label: "全州县",
                        value: "450324",
                        children: []
                    },
                    {
                        id: "2146",
                        label: "兴安县",
                        value: "450325",
                        children: []
                    },
                    {
                        id: "2147",
                        label: "永福县",
                        value: "450326",
                        children: []
                    },
                    {
                        id: "2148",
                        label: "灌阳县",
                        value: "450327",
                        children: []
                    },
                    {
                        id: "2149",
                        label: "龙胜各族自治县",
                        value: "450328",
                        children: []
                    },
                    {
                        id: "2150",
                        label: "资源县",
                        value: "450329",
                        children: []
                    },
                    {
                        id: "2151",
                        label: "平乐县",
                        value: "450330",
                        children: []
                    },
                    {
                        id: "2152",
                        label: "荔蒲县",
                        value: "450331",
                        children: []
                    },
                    {
                        id: "2153",
                        label: "恭城瑶族自治县",
                        value: "450332",
                        children: []
                    }
                ]
            },
            {
                id: "1246",
                label: "梧州市",
                value: "450400",
                children: [
                    {
                        id: "2154",
                        label: "市辖区",
                        value: "450401",
                        children: []
                    },
                    {
                        id: "2155",
                        label: "万秀区",
                        value: "450403",
                        children: []
                    },
                    {
                        id: "2156",
                        label: "蝶山区",
                        value: "450404",
                        children: []
                    },
                    {
                        id: "2157",
                        label: "长洲区",
                        value: "450405",
                        children: []
                    },
                    {
                        id: "2158",
                        label: "苍梧县",
                        value: "450421",
                        children: []
                    },
                    {
                        id: "2159",
                        label: "藤县",
                        value: "450422",
                        children: []
                    },
                    {
                        id: "2160",
                        label: "蒙山县",
                        value: "450423",
                        children: []
                    },
                    {
                        id: "2161",
                        label: "岑溪市",
                        value: "450481",
                        children: []
                    }
                ]
            },
            {
                id: "1247",
                label: "北海市",
                value: "450500",
                children: [
                    {
                        id: "2162",
                        label: "市辖区",
                        value: "450501",
                        children: []
                    },
                    {
                        id: "2163",
                        label: "海城区",
                        value: "450502",
                        children: []
                    },
                    {
                        id: "2164",
                        label: "银海区",
                        value: "450503",
                        children: []
                    },
                    {
                        id: "2165",
                        label: "铁山港区",
                        value: "450512",
                        children: []
                    },
                    {
                        id: "2166",
                        label: "合浦县",
                        value: "450521",
                        children: []
                    }
                ]
            },
            {
                id: "1248",
                label: "防城港市",
                value: "450600",
                children: [
                    {
                        id: "2167",
                        label: "市辖区",
                        value: "450601",
                        children: []
                    },
                    {
                        id: "2168",
                        label: "港口区",
                        value: "450602",
                        children: []
                    },
                    {
                        id: "2169",
                        label: "防城区",
                        value: "450603",
                        children: []
                    },
                    {
                        id: "2170",
                        label: "上思县",
                        value: "450621",
                        children: []
                    },
                    {
                        id: "2171",
                        label: "东兴市",
                        value: "450681",
                        children: []
                    }
                ]
            },
            {
                id: "1249",
                label: "钦州市",
                value: "450700",
                children: [
                    {
                        id: "2172",
                        label: "市辖区",
                        value: "450701",
                        children: []
                    },
                    {
                        id: "2173",
                        label: "钦南区",
                        value: "450702",
                        children: []
                    },
                    {
                        id: "2174",
                        label: "钦北区",
                        value: "450703",
                        children: []
                    },
                    {
                        id: "2175",
                        label: "灵山县",
                        value: "450721",
                        children: []
                    },
                    {
                        id: "2176",
                        label: "浦北县",
                        value: "450722",
                        children: []
                    }
                ]
            },
            {
                id: "1250",
                label: "贵港市",
                value: "450800",
                children: [
                    {
                        id: "2177",
                        label: "市辖区",
                        value: "450801",
                        children: []
                    },
                    {
                        id: "2178",
                        label: "港北区",
                        value: "450802",
                        children: []
                    },
                    {
                        id: "2179",
                        label: "港南区",
                        value: "450803",
                        children: []
                    },
                    {
                        id: "2180",
                        label: "覃塘区",
                        value: "450804",
                        children: []
                    },
                    {
                        id: "2181",
                        label: "平南县",
                        value: "450821",
                        children: []
                    },
                    {
                        id: "2182",
                        label: "桂平市",
                        value: "450881",
                        children: []
                    }
                ]
            },
            {
                id: "1251",
                label: "玉林市",
                value: "450900",
                children: [
                    {
                        id: "2183",
                        label: "市辖区",
                        value: "450901",
                        children: []
                    },
                    {
                        id: "2184",
                        label: "玉州区",
                        value: "450902",
                        children: []
                    },
                    {
                        id: "2185",
                        label: "容县",
                        value: "450921",
                        children: []
                    },
                    {
                        id: "2186",
                        label: "陆川县",
                        value: "450922",
                        children: []
                    },
                    {
                        id: "2187",
                        label: "博白县",
                        value: "450923",
                        children: []
                    },
                    {
                        id: "2188",
                        label: "兴业县",
                        value: "450924",
                        children: []
                    },
                    {
                        id: "2189",
                        label: "北流市",
                        value: "450981",
                        children: []
                    }
                ]
            },
            {
                id: "1252",
                label: "百色市",
                value: "451000",
                children: [
                    {
                        id: "2190",
                        label: "市辖区",
                        value: "451001",
                        children: []
                    },
                    {
                        id: "2191",
                        label: "右江区",
                        value: "451002",
                        children: []
                    },
                    {
                        id: "2192",
                        label: "田阳县",
                        value: "451021",
                        children: []
                    },
                    {
                        id: "2193",
                        label: "田东县",
                        value: "451022",
                        children: []
                    },
                    {
                        id: "2194",
                        label: "平果县",
                        value: "451023",
                        children: []
                    },
                    {
                        id: "2195",
                        label: "德保县",
                        value: "451024",
                        children: []
                    },
                    {
                        id: "2196",
                        label: "靖西县",
                        value: "451025",
                        children: []
                    },
                    {
                        id: "2197",
                        label: "那坡县",
                        value: "451026",
                        children: []
                    },
                    {
                        id: "2198",
                        label: "凌云县",
                        value: "451027",
                        children: []
                    },
                    {
                        id: "2199",
                        label: "乐业县",
                        value: "451028",
                        children: []
                    },
                    {
                        id: "2200",
                        label: "田林县",
                        value: "451029",
                        children: []
                    },
                    {
                        id: "2201",
                        label: "西林县",
                        value: "451030",
                        children: []
                    },
                    {
                        id: "2202",
                        label: "隆林各族自治县",
                        value: "451031",
                        children: []
                    }
                ]
            },
            {
                id: "1253",
                label: "贺州市",
                value: "451100",
                children: [
                    {
                        id: "2203",
                        label: "市辖区",
                        value: "451101",
                        children: []
                    },
                    {
                        id: "2204",
                        label: "八步区",
                        value: "451102",
                        children: []
                    },
                    {
                        id: "2205",
                        label: "平桂管理区",
                        value: "451119",
                        children: []
                    },
                    {
                        id: "2206",
                        label: "昭平县",
                        value: "451121",
                        children: []
                    },
                    {
                        id: "2207",
                        label: "钟山县",
                        value: "451122",
                        children: []
                    },
                    {
                        id: "2208",
                        label: "富川瑶族自治县",
                        value: "451123",
                        children: []
                    }
                ]
            },
            {
                id: "1254",
                label: "河池市",
                value: "451200",
                children: [
                    {
                        id: "2209",
                        label: "市辖区",
                        value: "451201",
                        children: []
                    },
                    {
                        id: "2210",
                        label: "金城江区",
                        value: "451202",
                        children: []
                    },
                    {
                        id: "2211",
                        label: "南丹县",
                        value: "451221",
                        children: []
                    },
                    {
                        id: "2212",
                        label: "天峨县",
                        value: "451222",
                        children: []
                    },
                    {
                        id: "2213",
                        label: "凤山县",
                        value: "451223",
                        children: []
                    },
                    {
                        id: "2214",
                        label: "东兰县",
                        value: "451224",
                        children: []
                    },
                    {
                        id: "2215",
                        label: "罗城仫佬族自治县",
                        value: "451225",
                        children: []
                    },
                    {
                        id: "2216",
                        label: "环江毛南族自治县",
                        value: "451226",
                        children: []
                    },
                    {
                        id: "2217",
                        label: "巴马瑶族自治县",
                        value: "451227",
                        children: []
                    },
                    {
                        id: "2218",
                        label: "都安瑶族自治县",
                        value: "451228",
                        children: []
                    },
                    {
                        id: "2219",
                        label: "大化瑶族自治县",
                        value: "451229",
                        children: []
                    },
                    {
                        id: "2220",
                        label: "宜州市",
                        value: "451281",
                        children: []
                    }
                ]
            },
            {
                id: "1255",
                label: "来宾市",
                value: "451300",
                children: [
                    {
                        id: "2221",
                        label: "市辖区",
                        value: "451301",
                        children: []
                    },
                    {
                        id: "2222",
                        label: "兴宾区",
                        value: "451302",
                        children: []
                    },
                    {
                        id: "2223",
                        label: "忻城县",
                        value: "451321",
                        children: []
                    },
                    {
                        id: "2224",
                        label: "象州县",
                        value: "451322",
                        children: []
                    },
                    {
                        id: "2225",
                        label: "武宣县",
                        value: "451323",
                        children: []
                    },
                    {
                        id: "2226",
                        label: "金秀瑶族自治县",
                        value: "451324",
                        children: []
                    },
                    {
                        id: "2227",
                        label: "合山市",
                        value: "451381",
                        children: []
                    }
                ]
            },
            {
                id: "1256",
                label: "崇左市",
                value: "451400",
                children: [
                    {
                        id: "2228",
                        label: "市辖区",
                        value: "451401",
                        children: []
                    },
                    {
                        id: "2229",
                        label: "江洲区",
                        value: "451402",
                        children: []
                    },
                    {
                        id: "2230",
                        label: "扶绥县",
                        value: "451421",
                        children: []
                    },
                    {
                        id: "2231",
                        label: "宁明县",
                        value: "451422",
                        children: []
                    },
                    {
                        id: "2232",
                        label: "龙州县",
                        value: "451423",
                        children: []
                    },
                    {
                        id: "2233",
                        label: "大新县",
                        value: "451424",
                        children: []
                    },
                    {
                        id: "2234",
                        label: "天等县",
                        value: "451425",
                        children: []
                    },
                    {
                        id: "2235",
                        label: "凭祥市",
                        value: "451481",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "21",
        label: "海南省",
        value: "460000",
        children: [
            {
                id: "1257",
                label: "海口市",
                value: "460100",
                children: [
                    {
                        id: "2236",
                        label: "市辖区",
                        value: "460101",
                        children: []
                    },
                    {
                        id: "2237",
                        label: "秀英区",
                        value: "460105",
                        children: []
                    },
                    {
                        id: "2238",
                        label: "龙华区",
                        value: "460106",
                        children: []
                    },
                    {
                        id: "2239",
                        label: "琼山区",
                        value: "460107",
                        children: []
                    },
                    {
                        id: "2240",
                        label: "美兰区",
                        value: "460108",
                        children: []
                    }
                ]
            },
            {
                id: "1258",
                label: "三亚市",
                value: "460200",
                children: [
                    {
                        id: "2241",
                        label: "市辖区",
                        value: "460201",
                        children: []
                    }
                ]
            },
            {
                id: "1259",
                label: "省直辖县级行政区划",
                value: "469000",
                children: [
                    {
                        id: "2242",
                        label: "五指山市",
                        value: "469001",
                        children: []
                    },
                    {
                        id: "2243",
                        label: "琼海市",
                        value: "469002",
                        children: []
                    },
                    {
                        id: "2244",
                        label: "儋州市",
                        value: "469003",
                        children: []
                    },
                    {
                        id: "2245",
                        label: "文昌市",
                        value: "469005",
                        children: []
                    },
                    {
                        id: "2246",
                        label: "万宁市",
                        value: "469006",
                        children: []
                    },
                    {
                        id: "2247",
                        label: "东方市",
                        value: "469007",
                        children: []
                    },
                    {
                        id: "2248",
                        label: "定安县",
                        value: "469021",
                        children: []
                    },
                    {
                        id: "2249",
                        label: "屯昌县",
                        value: "469022",
                        children: []
                    },
                    {
                        id: "2250",
                        label: "澄迈县",
                        value: "469023",
                        children: []
                    },
                    {
                        id: "2251",
                        label: "临高县",
                        value: "469024",
                        children: []
                    },
                    {
                        id: "2252",
                        label: "白沙黎族自治县",
                        value: "469025",
                        children: []
                    },
                    {
                        id: "2253",
                        label: "昌江黎族自治县",
                        value: "469026",
                        children: []
                    },
                    {
                        id: "2254",
                        label: "乐东黎族自治县",
                        value: "469027",
                        children: []
                    },
                    {
                        id: "2255",
                        label: "陵水黎族自治县",
                        value: "469028",
                        children: []
                    },
                    {
                        id: "2256",
                        label: "保亭黎族苗族自治县",
                        value: "469029",
                        children: []
                    },
                    {
                        id: "2257",
                        label: "琼中黎族苗族自治县",
                        value: "469030",
                        children: []
                    },
                    {
                        id: "2258",
                        label: "西沙群岛",
                        value: "469031",
                        children: []
                    },
                    {
                        id: "2259",
                        label: "南沙群岛",
                        value: "469032",
                        children: []
                    },
                    {
                        id: "2260",
                        label: "中沙群岛的岛礁及其海域",
                        value: "469033",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "22",
        label: "四川省",
        value: "510000",
        children: [
            {
                id: "1262",
                label: "成都市",
                value: "510100",
                children: [
                    {
                        id: "2301",
                        label: "市辖区",
                        value: "510101",
                        children: []
                    },
                    {
                        id: "2302",
                        label: "锦江区",
                        value: "510104",
                        children: []
                    },
                    {
                        id: "2303",
                        label: "青羊区",
                        value: "510105",
                        children: []
                    },
                    {
                        id: "2304",
                        label: "金牛区",
                        value: "510106",
                        children: []
                    },
                    {
                        id: "2305",
                        label: "武侯区",
                        value: "510107",
                        children: []
                    },
                    {
                        id: "2306",
                        label: "成华区",
                        value: "510108",
                        children: []
                    },
                    {
                        id: "2307",
                        label: "龙泉驿区",
                        value: "510112",
                        children: []
                    },
                    {
                        id: "2308",
                        label: "青白江区",
                        value: "510113",
                        children: []
                    },
                    {
                        id: "2309",
                        label: "新都区",
                        value: "510114",
                        children: []
                    },
                    {
                        id: "2310",
                        label: "温江区",
                        value: "510115",
                        children: []
                    },
                    {
                        id: "2311",
                        label: "金堂县",
                        value: "510121",
                        children: []
                    },
                    {
                        id: "2312",
                        label: "双流县",
                        value: "510122",
                        children: []
                    },
                    {
                        id: "2313",
                        label: "郫县",
                        value: "510124",
                        children: []
                    },
                    {
                        id: "2314",
                        label: "大邑县",
                        value: "510129",
                        children: []
                    },
                    {
                        id: "2315",
                        label: "蒲江县",
                        value: "510131",
                        children: []
                    },
                    {
                        id: "2316",
                        label: "新津县",
                        value: "510132",
                        children: []
                    },
                    {
                        id: "2317",
                        label: "都江堰市",
                        value: "510181",
                        children: []
                    },
                    {
                        id: "2318",
                        label: "彭州市",
                        value: "510182",
                        children: []
                    },
                    {
                        id: "2319",
                        label: "邛崃市",
                        value: "510183",
                        children: []
                    },
                    {
                        id: "2320",
                        label: "崇州市",
                        value: "510184",
                        children: []
                    }
                ]
            },
            {
                id: "1263",
                label: "自贡市",
                value: "510300",
                children: [
                    {
                        id: "2321",
                        label: "市辖区",
                        value: "510301",
                        children: []
                    },
                    {
                        id: "2322",
                        label: "自流井区",
                        value: "510302",
                        children: []
                    },
                    {
                        id: "2323",
                        label: "贡井区",
                        value: "510303",
                        children: []
                    },
                    {
                        id: "2324",
                        label: "大安区",
                        value: "510304",
                        children: []
                    },
                    {
                        id: "2325",
                        label: "沿滩区",
                        value: "510311",
                        children: []
                    },
                    {
                        id: "2326",
                        label: "荣县",
                        value: "510321",
                        children: []
                    },
                    {
                        id: "2327",
                        label: "富顺县",
                        value: "510322",
                        children: []
                    }
                ]
            },
            {
                id: "1264",
                label: "攀枝花市",
                value: "510400",
                children: [
                    {
                        id: "2328",
                        label: "市辖区",
                        value: "510401",
                        children: []
                    },
                    {
                        id: "2329",
                        label: "东区",
                        value: "510402",
                        children: []
                    },
                    {
                        id: "2330",
                        label: "西区",
                        value: "510403",
                        children: []
                    },
                    {
                        id: "2331",
                        label: "仁和区",
                        value: "510411",
                        children: []
                    },
                    {
                        id: "2332",
                        label: "米易县",
                        value: "510421",
                        children: []
                    },
                    {
                        id: "2333",
                        label: "盐边县",
                        value: "510422",
                        children: []
                    }
                ]
            },
            {
                id: "1265",
                label: "泸州市",
                value: "510500",
                children: [
                    {
                        id: "2334",
                        label: "市辖区",
                        value: "510501",
                        children: []
                    },
                    {
                        id: "2335",
                        label: "江阳区",
                        value: "510502",
                        children: []
                    },
                    {
                        id: "2336",
                        label: "纳溪区",
                        value: "510503",
                        children: []
                    },
                    {
                        id: "2337",
                        label: "龙马潭区",
                        value: "510504",
                        children: []
                    },
                    {
                        id: "2338",
                        label: "泸县",
                        value: "510521",
                        children: []
                    },
                    {
                        id: "2339",
                        label: "合江县",
                        value: "510522",
                        children: []
                    },
                    {
                        id: "2340",
                        label: "叙永县",
                        value: "510524",
                        children: []
                    },
                    {
                        id: "2341",
                        label: "古蔺县",
                        value: "510525",
                        children: []
                    }
                ]
            },
            {
                id: "1266",
                label: "德阳市",
                value: "510600",
                children: [
                    {
                        id: "2342",
                        label: "市辖区",
                        value: "510601",
                        children: []
                    },
                    {
                        id: "2343",
                        label: "旌阳区",
                        value: "510603",
                        children: []
                    },
                    {
                        id: "2344",
                        label: "中江县",
                        value: "510623",
                        children: []
                    },
                    {
                        id: "2345",
                        label: "罗江县",
                        value: "510626",
                        children: []
                    },
                    {
                        id: "2346",
                        label: "广汉市",
                        value: "510681",
                        children: []
                    },
                    {
                        id: "2347",
                        label: "什邡市",
                        value: "510682",
                        children: []
                    },
                    {
                        id: "2348",
                        label: "绵竹市",
                        value: "510683",
                        children: []
                    }
                ]
            },
            {
                id: "1267",
                label: "绵阳市",
                value: "510700",
                children: [
                    {
                        id: "2349",
                        label: "市辖区",
                        value: "510701",
                        children: []
                    },
                    {
                        id: "2350",
                        label: "涪城区",
                        value: "510703",
                        children: []
                    },
                    {
                        id: "2351",
                        label: "游仙区",
                        value: "510704",
                        children: []
                    },
                    {
                        id: "2352",
                        label: "三台县",
                        value: "510722",
                        children: []
                    },
                    {
                        id: "2353",
                        label: "盐亭县",
                        value: "510723",
                        children: []
                    },
                    {
                        id: "2354",
                        label: "安县",
                        value: "510724",
                        children: []
                    },
                    {
                        id: "2355",
                        label: "梓潼县",
                        value: "510725",
                        children: []
                    },
                    {
                        id: "2356",
                        label: "北川羌族自治县",
                        value: "510726",
                        children: []
                    },
                    {
                        id: "2357",
                        label: "平武县",
                        value: "510727",
                        children: []
                    },
                    {
                        id: "2358",
                        label: "江油市",
                        value: "510781",
                        children: []
                    }
                ]
            },
            {
                id: "1268",
                label: "广元市",
                value: "510800",
                children: [
                    {
                        id: "2359",
                        label: "市辖区",
                        value: "510801",
                        children: []
                    },
                    {
                        id: "2360",
                        label: "利州区",
                        value: "510802",
                        children: []
                    },
                    {
                        id: "2361",
                        label: "元坝区",
                        value: "510811",
                        children: []
                    },
                    {
                        id: "2362",
                        label: "朝天区",
                        value: "510812",
                        children: []
                    },
                    {
                        id: "2363",
                        label: "旺苍县",
                        value: "510821",
                        children: []
                    },
                    {
                        id: "2364",
                        label: "青川县",
                        value: "510822",
                        children: []
                    },
                    {
                        id: "2365",
                        label: "剑阁县",
                        value: "510823",
                        children: []
                    },
                    {
                        id: "2366",
                        label: "苍溪县",
                        value: "510824",
                        children: []
                    }
                ]
            },
            {
                id: "1269",
                label: "遂宁市",
                value: "510900",
                children: [
                    {
                        id: "2367",
                        label: "市辖区",
                        value: "510901",
                        children: []
                    },
                    {
                        id: "2368",
                        label: "船山区",
                        value: "510903",
                        children: []
                    },
                    {
                        id: "2369",
                        label: "安居区",
                        value: "510904",
                        children: []
                    },
                    {
                        id: "2370",
                        label: "蓬溪县",
                        value: "510921",
                        children: []
                    },
                    {
                        id: "2371",
                        label: "射洪县",
                        value: "510922",
                        children: []
                    },
                    {
                        id: "2372",
                        label: "大英县",
                        value: "510923",
                        children: []
                    }
                ]
            },
            {
                id: "1270",
                label: "内江市",
                value: "511000",
                children: [
                    {
                        id: "2373",
                        label: "市辖区",
                        value: "511001",
                        children: []
                    },
                    {
                        id: "2374",
                        label: "市中区",
                        value: "511002",
                        children: []
                    },
                    {
                        id: "2375",
                        label: "东兴区",
                        value: "511011",
                        children: []
                    },
                    {
                        id: "2376",
                        label: "威远县",
                        value: "511024",
                        children: []
                    },
                    {
                        id: "2377",
                        label: "资中县",
                        value: "511025",
                        children: []
                    },
                    {
                        id: "2378",
                        label: "隆昌县",
                        value: "511028",
                        children: []
                    }
                ]
            },
            {
                id: "1271",
                label: "乐山市",
                value: "511100",
                children: [
                    {
                        id: "2379",
                        label: "市辖区",
                        value: "511101",
                        children: []
                    },
                    {
                        id: "2380",
                        label: "市中区",
                        value: "511102",
                        children: []
                    },
                    {
                        id: "2381",
                        label: "沙湾区",
                        value: "511111",
                        children: []
                    },
                    {
                        id: "2382",
                        label: "五通桥区",
                        value: "511112",
                        children: []
                    },
                    {
                        id: "2383",
                        label: "金口河区",
                        value: "511113",
                        children: []
                    },
                    {
                        id: "2384",
                        label: "犍为县",
                        value: "511123",
                        children: []
                    },
                    {
                        id: "2385",
                        label: "井研县",
                        value: "511124",
                        children: []
                    },
                    {
                        id: "2386",
                        label: "夹江县",
                        value: "511126",
                        children: []
                    },
                    {
                        id: "2387",
                        label: "沐川县",
                        value: "511129",
                        children: []
                    },
                    {
                        id: "2388",
                        label: "峨边彝族自治县",
                        value: "511132",
                        children: []
                    },
                    {
                        id: "2389",
                        label: "马边彝族自治县",
                        value: "511133",
                        children: []
                    },
                    {
                        id: "2390",
                        label: "峨眉山市",
                        value: "511181",
                        children: []
                    }
                ]
            },
            {
                id: "1272",
                label: "南充市",
                value: "511300",
                children: [
                    {
                        id: "2391",
                        label: "市辖区",
                        value: "511301",
                        children: []
                    },
                    {
                        id: "2392",
                        label: "顺庆区",
                        value: "511302",
                        children: []
                    },
                    {
                        id: "2393",
                        label: "高坪区",
                        value: "511303",
                        children: []
                    },
                    {
                        id: "2394",
                        label: "嘉陵区",
                        value: "511304",
                        children: []
                    },
                    {
                        id: "2395",
                        label: "南部县",
                        value: "511321",
                        children: []
                    },
                    {
                        id: "2396",
                        label: "营山县",
                        value: "511322",
                        children: []
                    },
                    {
                        id: "2397",
                        label: "蓬安县",
                        value: "511323",
                        children: []
                    },
                    {
                        id: "2398",
                        label: "仪陇县",
                        value: "511324",
                        children: []
                    },
                    {
                        id: "2399",
                        label: "西充县",
                        value: "511325",
                        children: []
                    },
                    {
                        id: "2400",
                        label: "阆中市",
                        value: "511381",
                        children: []
                    }
                ]
            },
            {
                id: "1273",
                label: "眉山市",
                value: "511400",
                children: [
                    {
                        id: "2401",
                        label: "市辖区",
                        value: "511401",
                        children: []
                    },
                    {
                        id: "2402",
                        label: "东坡区",
                        value: "511402",
                        children: []
                    },
                    {
                        id: "2403",
                        label: "仁寿县",
                        value: "511421",
                        children: []
                    },
                    {
                        id: "2404",
                        label: "彭山县",
                        value: "511422",
                        children: []
                    },
                    {
                        id: "2405",
                        label: "洪雅县",
                        value: "511423",
                        children: []
                    },
                    {
                        id: "2406",
                        label: "丹棱县",
                        value: "511424",
                        children: []
                    },
                    {
                        id: "2407",
                        label: "青神县",
                        value: "511425",
                        children: []
                    }
                ]
            },
            {
                id: "1274",
                label: "宜宾市",
                value: "511500",
                children: [
                    {
                        id: "2408",
                        label: "市辖区",
                        value: "511501",
                        children: []
                    },
                    {
                        id: "2409",
                        label: "翠屏区",
                        value: "511502",
                        children: []
                    },
                    {
                        id: "2410",
                        label: "宜宾县",
                        value: "511521",
                        children: []
                    },
                    {
                        id: "2411",
                        label: "南溪县",
                        value: "511522",
                        children: []
                    },
                    {
                        id: "2412",
                        label: "江安县",
                        value: "511523",
                        children: []
                    },
                    {
                        id: "2413",
                        label: "长宁县",
                        value: "511524",
                        children: []
                    },
                    {
                        id: "2414",
                        label: "高县",
                        value: "511525",
                        children: []
                    },
                    {
                        id: "2415",
                        label: "珙县",
                        value: "511526",
                        children: []
                    },
                    {
                        id: "2416",
                        label: "筠连县",
                        value: "511527",
                        children: []
                    },
                    {
                        id: "2417",
                        label: "兴文县",
                        value: "511528",
                        children: []
                    },
                    {
                        id: "2418",
                        label: "屏山县",
                        value: "511529",
                        children: []
                    }
                ]
            },
            {
                id: "1275",
                label: "广安市",
                value: "511600",
                children: [
                    {
                        id: "2419",
                        label: "市辖区",
                        value: "511601",
                        children: []
                    },
                    {
                        id: "2420",
                        label: "广安区",
                        value: "511602",
                        children: []
                    },
                    {
                        id: "2421",
                        label: "岳池县",
                        value: "511621",
                        children: []
                    },
                    {
                        id: "2422",
                        label: "武胜县",
                        value: "511622",
                        children: []
                    },
                    {
                        id: "2423",
                        label: "邻水县",
                        value: "511623",
                        children: []
                    },
                    {
                        id: "2424",
                        label: "华蓥市",
                        value: "511681",
                        children: []
                    }
                ]
            },
            {
                id: "1276",
                label: "达州市",
                value: "511700",
                children: [
                    {
                        id: "2425",
                        label: "市辖区",
                        value: "511701",
                        children: []
                    },
                    {
                        id: "2426",
                        label: "通川区",
                        value: "511702",
                        children: []
                    },
                    {
                        id: "2427",
                        label: "达县",
                        value: "511721",
                        children: []
                    },
                    {
                        id: "2428",
                        label: "宣汉县",
                        value: "511722",
                        children: []
                    },
                    {
                        id: "2429",
                        label: "开江县",
                        value: "511723",
                        children: []
                    },
                    {
                        id: "2430",
                        label: "大竹县",
                        value: "511724",
                        children: []
                    },
                    {
                        id: "2431",
                        label: "渠县",
                        value: "511725",
                        children: []
                    },
                    {
                        id: "2432",
                        label: "万源市",
                        value: "511781",
                        children: []
                    }
                ]
            },
            {
                id: "1277",
                label: "雅安市",
                value: "511800",
                children: [
                    {
                        id: "2433",
                        label: "市辖区",
                        value: "511801",
                        children: []
                    },
                    {
                        id: "2434",
                        label: "雨城区",
                        value: "511802",
                        children: []
                    },
                    {
                        id: "2435",
                        label: "名山县",
                        value: "511821",
                        children: []
                    },
                    {
                        id: "2436",
                        label: "荥经县",
                        value: "511822",
                        children: []
                    },
                    {
                        id: "2437",
                        label: "汉源县",
                        value: "511823",
                        children: []
                    },
                    {
                        id: "2438",
                        label: "石棉县",
                        value: "511824",
                        children: []
                    },
                    {
                        id: "2439",
                        label: "天全县",
                        value: "511825",
                        children: []
                    },
                    {
                        id: "2440",
                        label: "芦山县",
                        value: "511826",
                        children: []
                    },
                    {
                        id: "2441",
                        label: "宝兴县",
                        value: "511827",
                        children: []
                    }
                ]
            },
            {
                id: "1278",
                label: "巴中市",
                value: "511900",
                children: [
                    {
                        id: "2442",
                        label: "市辖区",
                        value: "511901",
                        children: []
                    },
                    {
                        id: "2443",
                        label: "巴州区",
                        value: "511902",
                        children: []
                    },
                    {
                        id: "2444",
                        label: "通江县",
                        value: "511921",
                        children: []
                    },
                    {
                        id: "2445",
                        label: "南江县",
                        value: "511922",
                        children: []
                    },
                    {
                        id: "2446",
                        label: "平昌县",
                        value: "511923",
                        children: []
                    }
                ]
            },
            {
                id: "1279",
                label: "资阳市",
                value: "512000",
                children: [
                    {
                        id: "2447",
                        label: "市辖区",
                        value: "512001",
                        children: []
                    },
                    {
                        id: "2448",
                        label: "雁江区",
                        value: "512002",
                        children: []
                    },
                    {
                        id: "2449",
                        label: "安岳县",
                        value: "512021",
                        children: []
                    },
                    {
                        id: "2450",
                        label: "乐至县",
                        value: "512022",
                        children: []
                    },
                    {
                        id: "2451",
                        label: "简阳市",
                        value: "512081",
                        children: []
                    }
                ]
            },
            {
                id: "1280",
                label: "阿坝藏族羌族自治州",
                value: "513200",
                children: [
                    {
                        id: "2452",
                        label: "汶川县",
                        value: "513221",
                        children: []
                    },
                    {
                        id: "2453",
                        label: "理县",
                        value: "513222",
                        children: []
                    },
                    {
                        id: "2454",
                        label: "茂县",
                        value: "513223",
                        children: []
                    },
                    {
                        id: "2455",
                        label: "松潘县",
                        value: "513224",
                        children: []
                    },
                    {
                        id: "2456",
                        label: "九寨沟县",
                        value: "513225",
                        children: []
                    },
                    {
                        id: "2457",
                        label: "金川县",
                        value: "513226",
                        children: []
                    },
                    {
                        id: "2458",
                        label: "小金县",
                        value: "513227",
                        children: []
                    },
                    {
                        id: "2459",
                        label: "黑水县",
                        value: "513228",
                        children: []
                    },
                    {
                        id: "2460",
                        label: "马尔康县",
                        value: "513229",
                        children: []
                    },
                    {
                        id: "2461",
                        label: "壤塘县",
                        value: "513230",
                        children: []
                    },
                    {
                        id: "2462",
                        label: "阿坝县",
                        value: "513231",
                        children: []
                    },
                    {
                        id: "2463",
                        label: "若尔盖县",
                        value: "513232",
                        children: []
                    },
                    {
                        id: "2464",
                        label: "红原县",
                        value: "513233",
                        children: []
                    }
                ]
            },
            {
                id: "1281",
                label: "甘孜藏族自治州",
                value: "513300",
                children: [
                    {
                        id: "2465",
                        label: "康定县",
                        value: "513321",
                        children: []
                    },
                    {
                        id: "2466",
                        label: "泸定县",
                        value: "513322",
                        children: []
                    },
                    {
                        id: "2467",
                        label: "丹巴县",
                        value: "513323",
                        children: []
                    },
                    {
                        id: "2468",
                        label: "九龙县",
                        value: "513324",
                        children: []
                    },
                    {
                        id: "2469",
                        label: "雅江县",
                        value: "513325",
                        children: []
                    },
                    {
                        id: "2470",
                        label: "道孚县",
                        value: "513326",
                        children: []
                    },
                    {
                        id: "2471",
                        label: "炉霍县",
                        value: "513327",
                        children: []
                    },
                    {
                        id: "2472",
                        label: "甘孜县",
                        value: "513328",
                        children: []
                    },
                    {
                        id: "2473",
                        label: "新龙县",
                        value: "513329",
                        children: []
                    },
                    {
                        id: "2474",
                        label: "德格县",
                        value: "513330",
                        children: []
                    },
                    {
                        id: "2475",
                        label: "白玉县",
                        value: "513331",
                        children: []
                    },
                    {
                        id: "2476",
                        label: "石渠县",
                        value: "513332",
                        children: []
                    },
                    {
                        id: "2477",
                        label: "色达县",
                        value: "513333",
                        children: []
                    },
                    {
                        id: "2478",
                        label: "理塘县",
                        value: "513334",
                        children: []
                    },
                    {
                        id: "2479",
                        label: "巴塘县",
                        value: "513335",
                        children: []
                    },
                    {
                        id: "2480",
                        label: "乡城县",
                        value: "513336",
                        children: []
                    },
                    {
                        id: "2481",
                        label: "稻城县",
                        value: "513337",
                        children: []
                    },
                    {
                        id: "2482",
                        label: "得荣县",
                        value: "513338",
                        children: []
                    }
                ]
            },
            {
                id: "1282",
                label: "凉山彝族自治州",
                value: "513400",
                children: [
                    {
                        id: "2483",
                        label: "西昌市",
                        value: "513401",
                        children: []
                    },
                    {
                        id: "2484",
                        label: "木里藏族自治县",
                        value: "513422",
                        children: []
                    },
                    {
                        id: "2485",
                        label: "盐源县",
                        value: "513423",
                        children: []
                    },
                    {
                        id: "2486",
                        label: "德昌县",
                        value: "513424",
                        children: []
                    },
                    {
                        id: "2487",
                        label: "会理县",
                        value: "513425",
                        children: []
                    },
                    {
                        id: "2488",
                        label: "会东县",
                        value: "513426",
                        children: []
                    },
                    {
                        id: "2489",
                        label: "宁南县",
                        value: "513427",
                        children: []
                    },
                    {
                        id: "2490",
                        label: "普格县",
                        value: "513428",
                        children: []
                    },
                    {
                        id: "2491",
                        label: "布拖县",
                        value: "513429",
                        children: []
                    },
                    {
                        id: "2492",
                        label: "金阳县",
                        value: "513430",
                        children: []
                    },
                    {
                        id: "2493",
                        label: "昭觉县",
                        value: "513431",
                        children: []
                    },
                    {
                        id: "2494",
                        label: "喜德县",
                        value: "513432",
                        children: []
                    },
                    {
                        id: "2495",
                        label: "冕宁县",
                        value: "513433",
                        children: []
                    },
                    {
                        id: "2496",
                        label: "越西县",
                        value: "513434",
                        children: []
                    },
                    {
                        id: "2497",
                        label: "甘洛县",
                        value: "513435",
                        children: []
                    },
                    {
                        id: "2498",
                        label: "美姑县",
                        value: "513436",
                        children: []
                    },
                    {
                        id: "2499",
                        label: "雷波县",
                        value: "513437",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "23",
        label: "贵州省",
        value: "520000",
        children: [
            {
                id: "1283",
                label: "贵阳市",
                value: "520100",
                children: [
                    {
                        id: "2500",
                        label: "市辖区",
                        value: "520101",
                        children: []
                    },
                    {
                        id: "2501",
                        label: "南明区",
                        value: "520102",
                        children: []
                    },
                    {
                        id: "2502",
                        label: "云岩区",
                        value: "520103",
                        children: []
                    },
                    {
                        id: "2503",
                        label: "花溪区",
                        value: "520111",
                        children: []
                    },
                    {
                        id: "2504",
                        label: "乌当区",
                        value: "520112",
                        children: []
                    },
                    {
                        id: "2505",
                        label: "白云区",
                        value: "520113",
                        children: []
                    },
                    {
                        id: "2506",
                        label: "小河区",
                        value: "520114",
                        children: []
                    },
                    {
                        id: "2507",
                        label: "开阳县",
                        value: "520121",
                        children: []
                    },
                    {
                        id: "2508",
                        label: "息烽县",
                        value: "520122",
                        children: []
                    },
                    {
                        id: "2509",
                        label: "修文县",
                        value: "520123",
                        children: []
                    },
                    {
                        id: "2510",
                        label: "清镇市",
                        value: "520181",
                        children: []
                    }
                ]
            },
            {
                id: "1284",
                label: "六盘水市",
                value: "520200",
                children: [
                    {
                        id: "2511",
                        label: "钟山区",
                        value: "520201",
                        children: []
                    },
                    {
                        id: "2512",
                        label: "六枝特区",
                        value: "520203",
                        children: []
                    },
                    {
                        id: "2513",
                        label: "水城县",
                        value: "520221",
                        children: []
                    },
                    {
                        id: "2514",
                        label: "盘县",
                        value: "520222",
                        children: []
                    }
                ]
            },
            {
                id: "1285",
                label: "遵义市",
                value: "520300",
                children: [
                    {
                        id: "2515",
                        label: "市辖区",
                        value: "520301",
                        children: []
                    },
                    {
                        id: "2516",
                        label: "红花岗区",
                        value: "520302",
                        children: []
                    },
                    {
                        id: "2517",
                        label: "汇川区",
                        value: "520303",
                        children: []
                    },
                    {
                        id: "2518",
                        label: "遵义县",
                        value: "520321",
                        children: []
                    },
                    {
                        id: "2519",
                        label: "桐梓县",
                        value: "520322",
                        children: []
                    },
                    {
                        id: "2520",
                        label: "绥阳县",
                        value: "520323",
                        children: []
                    },
                    {
                        id: "2521",
                        label: "正安县",
                        value: "520324",
                        children: []
                    },
                    {
                        id: "2522",
                        label: "道真仡佬族苗族自治县",
                        value: "520325",
                        children: []
                    },
                    {
                        id: "2523",
                        label: "务川仡佬族苗族自治县",
                        value: "520326",
                        children: []
                    },
                    {
                        id: "2524",
                        label: "凤冈县",
                        value: "520327",
                        children: []
                    },
                    {
                        id: "2525",
                        label: "湄潭县",
                        value: "520328",
                        children: []
                    },
                    {
                        id: "2526",
                        label: "余庆县",
                        value: "520329",
                        children: []
                    },
                    {
                        id: "2527",
                        label: "习水县",
                        value: "520330",
                        children: []
                    },
                    {
                        id: "2528",
                        label: "赤水市",
                        value: "520381",
                        children: []
                    },
                    {
                        id: "2529",
                        label: "仁怀市",
                        value: "520382",
                        children: []
                    }
                ]
            },
            {
                id: "1286",
                label: "安顺市",
                value: "520400",
                children: [
                    {
                        id: "2530",
                        label: "市辖区",
                        value: "520401",
                        children: []
                    },
                    {
                        id: "2531",
                        label: "西秀区",
                        value: "520402",
                        children: []
                    },
                    {
                        id: "2532",
                        label: "平坝县",
                        value: "520421",
                        children: []
                    },
                    {
                        id: "2533",
                        label: "普定县",
                        value: "520422",
                        children: []
                    },
                    {
                        id: "2534",
                        label: "镇宁布依族苗族自治县",
                        value: "520423",
                        children: []
                    },
                    {
                        id: "2535",
                        label: "关岭布依族苗族自治县",
                        value: "520424",
                        children: []
                    },
                    {
                        id: "2536",
                        label: "紫云苗族布依族自治县",
                        value: "520425",
                        children: []
                    }
                ]
            },
            {
                id: "1287",
                label: "铜仁地区",
                value: "522200",
                children: [
                    {
                        id: "2537",
                        label: "铜仁市",
                        value: "522201",
                        children: []
                    },
                    {
                        id: "2538",
                        label: "江口县",
                        value: "522222",
                        children: []
                    },
                    {
                        id: "2539",
                        label: "玉屏侗族自治县",
                        value: "522223",
                        children: []
                    },
                    {
                        id: "2540",
                        label: "石阡县",
                        value: "522224",
                        children: []
                    },
                    {
                        id: "2541",
                        label: "思南县",
                        value: "522225",
                        children: []
                    },
                    {
                        id: "2542",
                        label: "印江土家族苗族自治县",
                        value: "522226",
                        children: []
                    },
                    {
                        id: "2543",
                        label: "德江县",
                        value: "522227",
                        children: []
                    },
                    {
                        id: "2544",
                        label: "沿河土家族自治县",
                        value: "522228",
                        children: []
                    },
                    {
                        id: "2545",
                        label: "松桃苗族自治县",
                        value: "522229",
                        children: []
                    },
                    {
                        id: "2546",
                        label: "万山特区",
                        value: "522230",
                        children: []
                    }
                ]
            },
            {
                id: "1288",
                label: "黔西南布依族苗族自治州",
                value: "522300",
                children: [
                    {
                        id: "2547",
                        label: "兴义市",
                        value: "522301",
                        children: []
                    },
                    {
                        id: "2548",
                        label: "兴仁县",
                        value: "522322",
                        children: []
                    },
                    {
                        id: "2549",
                        label: "普安县",
                        value: "522323",
                        children: []
                    },
                    {
                        id: "2550",
                        label: "晴隆县",
                        value: "522324",
                        children: []
                    },
                    {
                        id: "2551",
                        label: "贞丰县",
                        value: "522325",
                        children: []
                    },
                    {
                        id: "2552",
                        label: "望谟县",
                        value: "522326",
                        children: []
                    },
                    {
                        id: "2553",
                        label: "册亨县",
                        value: "522327",
                        children: []
                    },
                    {
                        id: "2554",
                        label: "安龙县",
                        value: "522328",
                        children: []
                    }
                ]
            },
            {
                id: "1289",
                label: "毕节地区",
                value: "522400",
                children: [
                    {
                        id: "2555",
                        label: "毕节市",
                        value: "522401",
                        children: []
                    },
                    {
                        id: "2556",
                        label: "大方县",
                        value: "522422",
                        children: []
                    },
                    {
                        id: "2557",
                        label: "黔西县",
                        value: "522423",
                        children: []
                    },
                    {
                        id: "2558",
                        label: "金沙县",
                        value: "522424",
                        children: []
                    },
                    {
                        id: "2559",
                        label: "织金县",
                        value: "522425",
                        children: []
                    },
                    {
                        id: "2560",
                        label: "纳雍县",
                        value: "522426",
                        children: []
                    },
                    {
                        id: "2561",
                        label: "威宁彝族回族苗族自治县",
                        value: "522427",
                        children: []
                    },
                    {
                        id: "2562",
                        label: "赫章县",
                        value: "522428",
                        children: []
                    }
                ]
            },
            {
                id: "1290",
                label: "黔东南苗族侗族自治州",
                value: "522600",
                children: [
                    {
                        id: "2563",
                        label: "凯里市",
                        value: "522601",
                        children: []
                    },
                    {
                        id: "2564",
                        label: "黄平县",
                        value: "522622",
                        children: []
                    },
                    {
                        id: "2565",
                        label: "施秉县",
                        value: "522623",
                        children: []
                    },
                    {
                        id: "2566",
                        label: "三穗县",
                        value: "522624",
                        children: []
                    },
                    {
                        id: "2567",
                        label: "镇远县",
                        value: "522625",
                        children: []
                    },
                    {
                        id: "2568",
                        label: "岑巩县",
                        value: "522626",
                        children: []
                    },
                    {
                        id: "2569",
                        label: "天柱县",
                        value: "522627",
                        children: []
                    },
                    {
                        id: "2570",
                        label: "锦屏县",
                        value: "522628",
                        children: []
                    },
                    {
                        id: "2571",
                        label: "剑河县",
                        value: "522629",
                        children: []
                    },
                    {
                        id: "2572",
                        label: "台江县",
                        value: "522630",
                        children: []
                    },
                    {
                        id: "2573",
                        label: "黎平县",
                        value: "522631",
                        children: []
                    },
                    {
                        id: "2574",
                        label: "榕江县",
                        value: "522632",
                        children: []
                    },
                    {
                        id: "2575",
                        label: "从江县",
                        value: "522633",
                        children: []
                    },
                    {
                        id: "2576",
                        label: "雷山县",
                        value: "522634",
                        children: []
                    },
                    {
                        id: "2577",
                        label: "麻江县",
                        value: "522635",
                        children: []
                    },
                    {
                        id: "2578",
                        label: "丹寨县",
                        value: "522636",
                        children: []
                    }
                ]
            },
            {
                id: "1291",
                label: "黔南布依族苗族自治州",
                value: "522700",
                children: [
                    {
                        id: "2579",
                        label: "都匀市",
                        value: "522701",
                        children: []
                    },
                    {
                        id: "2580",
                        label: "福泉市",
                        value: "522702",
                        children: []
                    },
                    {
                        id: "2581",
                        label: "荔波县",
                        value: "522722",
                        children: []
                    },
                    {
                        id: "2582",
                        label: "贵定县",
                        value: "522723",
                        children: []
                    },
                    {
                        id: "2583",
                        label: "瓮安县",
                        value: "522725",
                        children: []
                    },
                    {
                        id: "2584",
                        label: "独山县",
                        value: "522726",
                        children: []
                    },
                    {
                        id: "2585",
                        label: "平塘县",
                        value: "522727",
                        children: []
                    },
                    {
                        id: "2586",
                        label: "罗甸县",
                        value: "522728",
                        children: []
                    },
                    {
                        id: "2587",
                        label: "长顺县",
                        value: "522729",
                        children: []
                    },
                    {
                        id: "2588",
                        label: "龙里县",
                        value: "522730",
                        children: []
                    },
                    {
                        id: "2589",
                        label: "惠水县",
                        value: "522731",
                        children: []
                    },
                    {
                        id: "2590",
                        label: "三都水族自治县",
                        value: "522732",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "24",
        label: "云南省",
        value: "530000",
        children: [
            {
                id: "1292",
                label: "昆明市",
                value: "530100",
                children: [
                    {
                        id: "2591",
                        label: "市辖区",
                        value: "530101",
                        children: []
                    },
                    {
                        id: "2592",
                        label: "五华区",
                        value: "530102",
                        children: []
                    },
                    {
                        id: "2593",
                        label: "盘龙区",
                        value: "530103",
                        children: []
                    },
                    {
                        id: "2594",
                        label: "官渡区",
                        value: "530111",
                        children: []
                    },
                    {
                        id: "2595",
                        label: "西山区",
                        value: "530112",
                        children: []
                    },
                    {
                        id: "2596",
                        label: "东川区",
                        value: "530113",
                        children: []
                    },
                    {
                        id: "2597",
                        label: "呈贡县",
                        value: "530121",
                        children: []
                    },
                    {
                        id: "2598",
                        label: "晋宁县",
                        value: "530122",
                        children: []
                    },
                    {
                        id: "2599",
                        label: "富民县",
                        value: "530124",
                        children: []
                    },
                    {
                        id: "2600",
                        label: "宜良县",
                        value: "530125",
                        children: []
                    },
                    {
                        id: "2601",
                        label: "石林彝族自治县",
                        value: "530126",
                        children: []
                    },
                    {
                        id: "2602",
                        label: "嵩明县",
                        value: "530127",
                        children: []
                    },
                    {
                        id: "2603",
                        label: "禄劝彝族苗族自治县",
                        value: "530128",
                        children: []
                    },
                    {
                        id: "2604",
                        label: "寻甸回族彝族自治县",
                        value: "530129",
                        children: []
                    },
                    {
                        id: "2605",
                        label: "安宁市",
                        value: "530181",
                        children: []
                    }
                ]
            },
            {
                id: "1293",
                label: "曲靖市",
                value: "530300",
                children: [
                    {
                        id: "2606",
                        label: "市辖区",
                        value: "530301",
                        children: []
                    },
                    {
                        id: "2607",
                        label: "麒麟区",
                        value: "530302",
                        children: []
                    },
                    {
                        id: "2608",
                        label: "马龙县",
                        value: "530321",
                        children: []
                    },
                    {
                        id: "2609",
                        label: "陆良县",
                        value: "530322",
                        children: []
                    },
                    {
                        id: "2610",
                        label: "师宗县",
                        value: "530323",
                        children: []
                    },
                    {
                        id: "2611",
                        label: "罗平县",
                        value: "530324",
                        children: []
                    },
                    {
                        id: "2612",
                        label: "富源县",
                        value: "530325",
                        children: []
                    },
                    {
                        id: "2613",
                        label: "会泽县",
                        value: "530326",
                        children: []
                    },
                    {
                        id: "2614",
                        label: "沾益县",
                        value: "530328",
                        children: []
                    },
                    {
                        id: "2615",
                        label: "宣威市",
                        value: "530381",
                        children: []
                    }
                ]
            },
            {
                id: "1294",
                label: "玉溪市",
                value: "530400",
                children: [
                    {
                        id: "2616",
                        label: "市辖区",
                        value: "530401",
                        children: []
                    },
                    {
                        id: "2617",
                        label: "红塔区",
                        value: "530402",
                        children: []
                    },
                    {
                        id: "2618",
                        label: "江川县",
                        value: "530421",
                        children: []
                    },
                    {
                        id: "2619",
                        label: "澄江县",
                        value: "530422",
                        children: []
                    },
                    {
                        id: "2620",
                        label: "通海县",
                        value: "530423",
                        children: []
                    },
                    {
                        id: "2621",
                        label: "华宁县",
                        value: "530424",
                        children: []
                    },
                    {
                        id: "2622",
                        label: "易门县",
                        value: "530425",
                        children: []
                    },
                    {
                        id: "2623",
                        label: "峨山彝族自治县",
                        value: "530426",
                        children: []
                    },
                    {
                        id: "2624",
                        label: "新平彝族傣族自治县",
                        value: "530427",
                        children: []
                    },
                    {
                        id: "2625",
                        label: "元江哈尼族彝族傣族自治县",
                        value: "530428",
                        children: []
                    }
                ]
            },
            {
                id: "1295",
                label: "保山市",
                value: "530500",
                children: [
                    {
                        id: "2626",
                        label: "市辖区",
                        value: "530501",
                        children: []
                    },
                    {
                        id: "2627",
                        label: "隆阳区",
                        value: "530502",
                        children: []
                    },
                    {
                        id: "2628",
                        label: "施甸县",
                        value: "530521",
                        children: []
                    },
                    {
                        id: "2629",
                        label: "腾冲县",
                        value: "530522",
                        children: []
                    },
                    {
                        id: "2630",
                        label: "龙陵县",
                        value: "530523",
                        children: []
                    },
                    {
                        id: "2631",
                        label: "昌宁县",
                        value: "530524",
                        children: []
                    }
                ]
            },
            {
                id: "1296",
                label: "昭通市",
                value: "530600",
                children: [
                    {
                        id: "2632",
                        label: "市辖区",
                        value: "530601",
                        children: []
                    },
                    {
                        id: "2633",
                        label: "昭阳区",
                        value: "530602",
                        children: []
                    },
                    {
                        id: "2634",
                        label: "鲁甸县",
                        value: "530621",
                        children: []
                    },
                    {
                        id: "2635",
                        label: "巧家县",
                        value: "530622",
                        children: []
                    },
                    {
                        id: "2636",
                        label: "盐津县",
                        value: "530623",
                        children: []
                    },
                    {
                        id: "2637",
                        label: "大关县",
                        value: "530624",
                        children: []
                    },
                    {
                        id: "2638",
                        label: "永善县",
                        value: "530625",
                        children: []
                    },
                    {
                        id: "2639",
                        label: "绥江县",
                        value: "530626",
                        children: []
                    },
                    {
                        id: "2640",
                        label: "镇雄县",
                        value: "530627",
                        children: []
                    },
                    {
                        id: "2641",
                        label: "彝良县",
                        value: "530628",
                        children: []
                    },
                    {
                        id: "2642",
                        label: "威信县",
                        value: "530629",
                        children: []
                    },
                    {
                        id: "2643",
                        label: "水富县",
                        value: "530630",
                        children: []
                    }
                ]
            },
            {
                id: "1297",
                label: "丽江市",
                value: "530700",
                children: [
                    {
                        id: "2644",
                        label: "市辖区",
                        value: "530701",
                        children: []
                    },
                    {
                        id: "2645",
                        label: "古城区",
                        value: "530702",
                        children: []
                    },
                    {
                        id: "2646",
                        label: "玉龙纳西族自治县",
                        value: "530721",
                        children: []
                    },
                    {
                        id: "2647",
                        label: "永胜县",
                        value: "530722",
                        children: []
                    },
                    {
                        id: "2648",
                        label: "华坪县",
                        value: "530723",
                        children: []
                    },
                    {
                        id: "2649",
                        label: "宁蒗彝族自治县",
                        value: "530724",
                        children: []
                    }
                ]
            },
            {
                id: "1298",
                label: "普洱市",
                value: "530800",
                children: [
                    {
                        id: "2650",
                        label: "市辖区",
                        value: "530801",
                        children: []
                    },
                    {
                        id: "2651",
                        label: "思茅区",
                        value: "530802",
                        children: []
                    },
                    {
                        id: "2652",
                        label: "宁洱哈尼族彝族自治县",
                        value: "530821",
                        children: []
                    },
                    {
                        id: "2653",
                        label: "墨江哈尼族自治县",
                        value: "530822",
                        children: []
                    },
                    {
                        id: "2654",
                        label: "景东彝族自治县",
                        value: "530823",
                        children: []
                    },
                    {
                        id: "2655",
                        label: "景谷傣族彝族自治县",
                        value: "530824",
                        children: []
                    },
                    {
                        id: "2656",
                        label: "镇沅彝族哈尼族拉祜族自治县",
                        value: "530825",
                        children: []
                    },
                    {
                        id: "2657",
                        label: "江城哈尼族彝族自治县",
                        value: "530826",
                        children: []
                    },
                    {
                        id: "2658",
                        label: "孟连傣族拉祜族佤族自治县",
                        value: "530827",
                        children: []
                    },
                    {
                        id: "2659",
                        label: "澜沧拉祜族自治县",
                        value: "530828",
                        children: []
                    },
                    {
                        id: "2660",
                        label: "西盟佤族自治县",
                        value: "530829",
                        children: []
                    }
                ]
            },
            {
                id: "1299",
                label: "临沧市",
                value: "530900",
                children: [
                    {
                        id: "2661",
                        label: "市辖区",
                        value: "530901",
                        children: []
                    },
                    {
                        id: "2662",
                        label: "临翔区",
                        value: "530902",
                        children: []
                    },
                    {
                        id: "2663",
                        label: "凤庆县",
                        value: "530921",
                        children: []
                    },
                    {
                        id: "2664",
                        label: "云县",
                        value: "530922",
                        children: []
                    },
                    {
                        id: "2665",
                        label: "永德县",
                        value: "530923",
                        children: []
                    },
                    {
                        id: "2666",
                        label: "镇康县",
                        value: "530924",
                        children: []
                    },
                    {
                        id: "2667",
                        label: "双江拉祜族佤族布朗族傣族自治县",
                        value: "530925",
                        children: []
                    },
                    {
                        id: "2668",
                        label: "耿马傣族佤族自治县",
                        value: "530926",
                        children: []
                    },
                    {
                        id: "2669",
                        label: "沧源佤族自治县",
                        value: "530927",
                        children: []
                    }
                ]
            },
            {
                id: "1300",
                label: "楚雄彝族自治州",
                value: "532300",
                children: [
                    {
                        id: "2670",
                        label: "楚雄市",
                        value: "532301",
                        children: []
                    },
                    {
                        id: "2671",
                        label: "双柏县",
                        value: "532322",
                        children: []
                    },
                    {
                        id: "2672",
                        label: "牟定县",
                        value: "532323",
                        children: []
                    },
                    {
                        id: "2673",
                        label: "南华县",
                        value: "532324",
                        children: []
                    },
                    {
                        id: "2674",
                        label: "姚安县",
                        value: "532325",
                        children: []
                    },
                    {
                        id: "2675",
                        label: "大姚县",
                        value: "532326",
                        children: []
                    },
                    {
                        id: "2676",
                        label: "永仁县",
                        value: "532327",
                        children: []
                    },
                    {
                        id: "2677",
                        label: "元谋县",
                        value: "532328",
                        children: []
                    },
                    {
                        id: "2678",
                        label: "武定县",
                        value: "532329",
                        children: []
                    },
                    {
                        id: "2679",
                        label: "禄丰县",
                        value: "532331",
                        children: []
                    }
                ]
            },
            {
                id: "1301",
                label: "红河哈尼族彝族自治州",
                value: "532500",
                children: [
                    {
                        id: "2680",
                        label: "个旧市",
                        value: "532501",
                        children: []
                    },
                    {
                        id: "2681",
                        label: "开远市",
                        value: "532502",
                        children: []
                    },
                    {
                        id: "2682",
                        label: "蒙自市",
                        value: "532503",
                        children: []
                    },
                    {
                        id: "2683",
                        label: "屏边苗族自治县",
                        value: "532523",
                        children: []
                    },
                    {
                        id: "2684",
                        label: "建水县",
                        value: "532524",
                        children: []
                    },
                    {
                        id: "2685",
                        label: "石屏县",
                        value: "532525",
                        children: []
                    },
                    {
                        id: "2686",
                        label: "弥勒县",
                        value: "532526",
                        children: []
                    },
                    {
                        id: "2687",
                        label: "泸西县",
                        value: "532527",
                        children: []
                    },
                    {
                        id: "2688",
                        label: "元阳县",
                        value: "532528",
                        children: []
                    },
                    {
                        id: "2689",
                        label: "红河县",
                        value: "532529",
                        children: []
                    },
                    {
                        id: "2690",
                        label: "金平苗族瑶族傣族自治县",
                        value: "532530",
                        children: []
                    },
                    {
                        id: "2691",
                        label: "绿春县",
                        value: "532531",
                        children: []
                    },
                    {
                        id: "2692",
                        label: "河口瑶族自治县",
                        value: "532532",
                        children: []
                    }
                ]
            },
            {
                id: "1302",
                label: "文山壮族苗族自治州",
                value: "532600",
                children: [
                    {
                        id: "2693",
                        label: "文山县",
                        value: "532621",
                        children: []
                    },
                    {
                        id: "2694",
                        label: "砚山县",
                        value: "532622",
                        children: []
                    },
                    {
                        id: "2695",
                        label: "西畴县",
                        value: "532623",
                        children: []
                    },
                    {
                        id: "2696",
                        label: "麻栗坡县",
                        value: "532624",
                        children: []
                    },
                    {
                        id: "2697",
                        label: "马关县",
                        value: "532625",
                        children: []
                    },
                    {
                        id: "2698",
                        label: "丘北县",
                        value: "532626",
                        children: []
                    },
                    {
                        id: "2699",
                        label: "广南县",
                        value: "532627",
                        children: []
                    },
                    {
                        id: "2700",
                        label: "富宁县",
                        value: "532628",
                        children: []
                    }
                ]
            },
            {
                id: "1303",
                label: "西双版纳傣族自治州",
                value: "532800",
                children: [
                    {
                        id: "2701",
                        label: "景洪市",
                        value: "532801",
                        children: []
                    },
                    {
                        id: "2702",
                        label: "勐海县",
                        value: "532822",
                        children: []
                    },
                    {
                        id: "2703",
                        label: "勐腊县",
                        value: "532823",
                        children: []
                    }
                ]
            },
            {
                id: "1304",
                label: "大理白族自治州",
                value: "532900",
                children: [
                    {
                        id: "2704",
                        label: "大理市",
                        value: "532901",
                        children: []
                    },
                    {
                        id: "2705",
                        label: "漾濞彝族自治县",
                        value: "532922",
                        children: []
                    },
                    {
                        id: "2706",
                        label: "祥云县",
                        value: "532923",
                        children: []
                    },
                    {
                        id: "2707",
                        label: "宾川县",
                        value: "532924",
                        children: []
                    },
                    {
                        id: "2708",
                        label: "弥渡县",
                        value: "532925",
                        children: []
                    },
                    {
                        id: "2709",
                        label: "南涧彝族自治县",
                        value: "532926",
                        children: []
                    },
                    {
                        id: "2710",
                        label: "巍山彝族回族自治县",
                        value: "532927",
                        children: []
                    },
                    {
                        id: "2711",
                        label: "永平县",
                        value: "532928",
                        children: []
                    },
                    {
                        id: "2712",
                        label: "云龙县",
                        value: "532929",
                        children: []
                    },
                    {
                        id: "2713",
                        label: "洱源县",
                        value: "532930",
                        children: []
                    },
                    {
                        id: "2714",
                        label: "剑川县",
                        value: "532931",
                        children: []
                    },
                    {
                        id: "2715",
                        label: "鹤庆县",
                        value: "532932",
                        children: []
                    }
                ]
            },
            {
                id: "1305",
                label: "德宏傣族景颇族自治州",
                value: "533100",
                children: [
                    {
                        id: "2716",
                        label: "瑞丽市",
                        value: "533102",
                        children: []
                    },
                    {
                        id: "2717",
                        label: "芒市",
                        value: "533103",
                        children: []
                    },
                    {
                        id: "2718",
                        label: "梁河县",
                        value: "533122",
                        children: []
                    },
                    {
                        id: "2719",
                        label: "盈江县",
                        value: "533123",
                        children: []
                    },
                    {
                        id: "2720",
                        label: "陇川县",
                        value: "533124",
                        children: []
                    }
                ]
            },
            {
                id: "1306",
                label: "怒江傈僳族自治州",
                value: "533300",
                children: [
                    {
                        id: "2721",
                        label: "泸水县",
                        value: "533321",
                        children: []
                    },
                    {
                        id: "2722",
                        label: "福贡县",
                        value: "533323",
                        children: []
                    },
                    {
                        id: "2723",
                        label: "贡山独龙族怒族自治县",
                        value: "533324",
                        children: []
                    },
                    {
                        id: "2724",
                        label: "兰坪白族普米族自治县",
                        value: "533325",
                        children: []
                    }
                ]
            },
            {
                id: "1307",
                label: "迪庆藏族自治州",
                value: "533400",
                children: [
                    {
                        id: "2725",
                        label: "香格里拉县",
                        value: "533421",
                        children: []
                    },
                    {
                        id: "2726",
                        label: "德钦县",
                        value: "533422",
                        children: []
                    },
                    {
                        id: "2727",
                        label: "维西傈僳族自治县",
                        value: "533423",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "25",
        label: "陕西省",
        value: "610000",
        children: [
            {
                id: "1315",
                label: "西安市",
                value: "610100",
                children: [
                    {
                        id: "2802",
                        label: "市辖区",
                        value: "610101",
                        children: []
                    },
                    {
                        id: "2803",
                        label: "新城区",
                        value: "610102",
                        children: []
                    },
                    {
                        id: "2804",
                        label: "碑林区",
                        value: "610103",
                        children: []
                    },
                    {
                        id: "2805",
                        label: "莲湖区",
                        value: "610104",
                        children: []
                    },
                    {
                        id: "2806",
                        label: "灞桥区",
                        value: "610111",
                        children: []
                    },
                    {
                        id: "2807",
                        label: "未央区",
                        value: "610112",
                        children: []
                    },
                    {
                        id: "2808",
                        label: "雁塔区",
                        value: "610113",
                        children: []
                    },
                    {
                        id: "2809",
                        label: "阎良区",
                        value: "610114",
                        children: []
                    },
                    {
                        id: "2810",
                        label: "临潼区",
                        value: "610115",
                        children: []
                    },
                    {
                        id: "2811",
                        label: "长安区",
                        value: "610116",
                        children: []
                    },
                    {
                        id: "2812",
                        label: "蓝田县",
                        value: "610122",
                        children: []
                    },
                    {
                        id: "2813",
                        label: "周至县",
                        value: "610124",
                        children: []
                    },
                    {
                        id: "2814",
                        label: "户县",
                        value: "610125",
                        children: []
                    },
                    {
                        id: "2815",
                        label: "高陵县",
                        value: "610126",
                        children: []
                    }
                ]
            },
            {
                id: "1316",
                label: "铜川市",
                value: "610200",
                children: [
                    {
                        id: "2816",
                        label: "市辖区",
                        value: "610201",
                        children: []
                    },
                    {
                        id: "2817",
                        label: "王益区",
                        value: "610202",
                        children: []
                    },
                    {
                        id: "2818",
                        label: "印台区",
                        value: "610203",
                        children: []
                    },
                    {
                        id: "2819",
                        label: "耀州区",
                        value: "610204",
                        children: []
                    },
                    {
                        id: "2820",
                        label: "宜君县",
                        value: "610222",
                        children: []
                    }
                ]
            },
            {
                id: "1317",
                label: "宝鸡市",
                value: "610300",
                children: [
                    {
                        id: "2821",
                        label: "市辖区",
                        value: "610301",
                        children: []
                    },
                    {
                        id: "2822",
                        label: "渭滨区",
                        value: "610302",
                        children: []
                    },
                    {
                        id: "2823",
                        label: "金台区",
                        value: "610303",
                        children: []
                    },
                    {
                        id: "2824",
                        label: "陈仓区",
                        value: "610304",
                        children: []
                    },
                    {
                        id: "2825",
                        label: "凤翔县",
                        value: "610322",
                        children: []
                    },
                    {
                        id: "2826",
                        label: "岐山县",
                        value: "610323",
                        children: []
                    },
                    {
                        id: "2827",
                        label: "扶风县",
                        value: "610324",
                        children: []
                    },
                    {
                        id: "2828",
                        label: "眉县",
                        value: "610326",
                        children: []
                    },
                    {
                        id: "2829",
                        label: "陇县",
                        value: "610327",
                        children: []
                    },
                    {
                        id: "2830",
                        label: "千阳县",
                        value: "610328",
                        children: []
                    },
                    {
                        id: "2831",
                        label: "麟游县",
                        value: "610329",
                        children: []
                    },
                    {
                        id: "2832",
                        label: "凤县",
                        value: "610330",
                        children: []
                    },
                    {
                        id: "2833",
                        label: "太白县",
                        value: "610331",
                        children: []
                    }
                ]
            },
            {
                id: "1318",
                label: "咸阳市",
                value: "610400",
                children: [
                    {
                        id: "2834",
                        label: "市辖区",
                        value: "610401",
                        children: []
                    },
                    {
                        id: "2835",
                        label: "秦都区",
                        value: "610402",
                        children: []
                    },
                    {
                        id: "2836",
                        label: "杨陵区",
                        value: "610403",
                        children: []
                    },
                    {
                        id: "2837",
                        label: "渭城区",
                        value: "610404",
                        children: []
                    },
                    {
                        id: "2838",
                        label: "三原县",
                        value: "610422",
                        children: []
                    },
                    {
                        id: "2839",
                        label: "泾阳县",
                        value: "610423",
                        children: []
                    },
                    {
                        id: "2840",
                        label: "乾县",
                        value: "610424",
                        children: []
                    },
                    {
                        id: "2841",
                        label: "礼泉县",
                        value: "610425",
                        children: []
                    },
                    {
                        id: "2842",
                        label: "永寿县",
                        value: "610426",
                        children: []
                    },
                    {
                        id: "2843",
                        label: "彬县",
                        value: "610427",
                        children: []
                    },
                    {
                        id: "2844",
                        label: "长武县",
                        value: "610428",
                        children: []
                    },
                    {
                        id: "2845",
                        label: "旬邑县",
                        value: "610429",
                        children: []
                    },
                    {
                        id: "2846",
                        label: "淳化县",
                        value: "610430",
                        children: []
                    },
                    {
                        id: "2847",
                        label: "武功县",
                        value: "610431",
                        children: []
                    },
                    {
                        id: "2848",
                        label: "兴平市",
                        value: "610481",
                        children: []
                    }
                ]
            },
            {
                id: "1319",
                label: "渭南市",
                value: "610500",
                children: [
                    {
                        id: "2849",
                        label: "市辖区",
                        value: "610501",
                        children: []
                    },
                    {
                        id: "2850",
                        label: "临渭区",
                        value: "610502",
                        children: []
                    },
                    {
                        id: "2851",
                        label: "华县",
                        value: "610521",
                        children: []
                    },
                    {
                        id: "2852",
                        label: "潼关县",
                        value: "610522",
                        children: []
                    },
                    {
                        id: "2853",
                        label: "大荔县",
                        value: "610523",
                        children: []
                    },
                    {
                        id: "2854",
                        label: "合阳县",
                        value: "610524",
                        children: []
                    },
                    {
                        id: "2855",
                        label: "澄城县",
                        value: "610525",
                        children: []
                    },
                    {
                        id: "2856",
                        label: "蒲城县",
                        value: "610526",
                        children: []
                    },
                    {
                        id: "2857",
                        label: "白水县",
                        value: "610527",
                        children: []
                    },
                    {
                        id: "2858",
                        label: "富平县",
                        value: "610528",
                        children: []
                    },
                    {
                        id: "2859",
                        label: "韩城市",
                        value: "610581",
                        children: []
                    },
                    {
                        id: "2860",
                        label: "华阴市",
                        value: "610582",
                        children: []
                    }
                ]
            },
            {
                id: "1320",
                label: "延安市",
                value: "610600",
                children: [
                    {
                        id: "2861",
                        label: "市辖区",
                        value: "610601",
                        children: []
                    },
                    {
                        id: "2862",
                        label: "宝塔区",
                        value: "610602",
                        children: []
                    },
                    {
                        id: "2863",
                        label: "延长县",
                        value: "610621",
                        children: []
                    },
                    {
                        id: "2864",
                        label: "延川县",
                        value: "610622",
                        children: []
                    },
                    {
                        id: "2865",
                        label: "子长县",
                        value: "610623",
                        children: []
                    },
                    {
                        id: "2866",
                        label: "安塞县",
                        value: "610624",
                        children: []
                    },
                    {
                        id: "2867",
                        label: "志丹县",
                        value: "610625",
                        children: []
                    },
                    {
                        id: "2868",
                        label: "吴起县",
                        value: "610626",
                        children: []
                    },
                    {
                        id: "2869",
                        label: "甘泉县",
                        value: "610627",
                        children: []
                    },
                    {
                        id: "2870",
                        label: "富县",
                        value: "610628",
                        children: []
                    },
                    {
                        id: "2871",
                        label: "洛川县",
                        value: "610629",
                        children: []
                    },
                    {
                        id: "2872",
                        label: "宜川县",
                        value: "610630",
                        children: []
                    },
                    {
                        id: "2873",
                        label: "黄龙县",
                        value: "610631",
                        children: []
                    },
                    {
                        id: "2874",
                        label: "黄陵县",
                        value: "610632",
                        children: []
                    }
                ]
            },
            {
                id: "1321",
                label: "汉中市",
                value: "610700",
                children: [
                    {
                        id: "2875",
                        label: "市辖区",
                        value: "610701",
                        children: []
                    },
                    {
                        id: "2876",
                        label: "汉台区",
                        value: "610702",
                        children: []
                    },
                    {
                        id: "2877",
                        label: "南郑县",
                        value: "610721",
                        children: []
                    },
                    {
                        id: "2878",
                        label: "城固县",
                        value: "610722",
                        children: []
                    },
                    {
                        id: "2879",
                        label: "洋县",
                        value: "610723",
                        children: []
                    },
                    {
                        id: "2880",
                        label: "西乡县",
                        value: "610724",
                        children: []
                    },
                    {
                        id: "2881",
                        label: "勉县",
                        value: "610725",
                        children: []
                    },
                    {
                        id: "2882",
                        label: "宁强县",
                        value: "610726",
                        children: []
                    },
                    {
                        id: "2883",
                        label: "略阳县",
                        value: "610727",
                        children: []
                    },
                    {
                        id: "2884",
                        label: "镇巴县",
                        value: "610728",
                        children: []
                    },
                    {
                        id: "2885",
                        label: "留坝县",
                        value: "610729",
                        children: []
                    },
                    {
                        id: "2886",
                        label: "佛坪县",
                        value: "610730",
                        children: []
                    }
                ]
            },
            {
                id: "1322",
                label: "榆林市",
                value: "610800",
                children: [
                    {
                        id: "2887",
                        label: "市辖区",
                        value: "610801",
                        children: []
                    },
                    {
                        id: "2888",
                        label: "榆阳区",
                        value: "610802",
                        children: []
                    },
                    {
                        id: "2889",
                        label: "神木县",
                        value: "610821",
                        children: []
                    },
                    {
                        id: "2890",
                        label: "府谷县",
                        value: "610822",
                        children: []
                    },
                    {
                        id: "2891",
                        label: "横山县",
                        value: "610823",
                        children: []
                    },
                    {
                        id: "2892",
                        label: "靖边县",
                        value: "610824",
                        children: []
                    },
                    {
                        id: "2893",
                        label: "定边县",
                        value: "610825",
                        children: []
                    },
                    {
                        id: "2894",
                        label: "绥德县",
                        value: "610826",
                        children: []
                    },
                    {
                        id: "2895",
                        label: "米脂县",
                        value: "610827",
                        children: []
                    },
                    {
                        id: "2896",
                        label: "佳县",
                        value: "610828",
                        children: []
                    },
                    {
                        id: "2897",
                        label: "吴堡县",
                        value: "610829",
                        children: []
                    },
                    {
                        id: "2898",
                        label: "清涧县",
                        value: "610830",
                        children: []
                    },
                    {
                        id: "2899",
                        label: "子洲县",
                        value: "610831",
                        children: []
                    }
                ]
            },
            {
                id: "1323",
                label: "安康市",
                value: "610900",
                children: [
                    {
                        id: "2900",
                        label: "市辖区",
                        value: "610901",
                        children: []
                    },
                    {
                        id: "2901",
                        label: "汉滨区",
                        value: "610902",
                        children: []
                    },
                    {
                        id: "2902",
                        label: "汉阴县",
                        value: "610921",
                        children: []
                    },
                    {
                        id: "2903",
                        label: "石泉县",
                        value: "610922",
                        children: []
                    },
                    {
                        id: "2904",
                        label: "宁陕县",
                        value: "610923",
                        children: []
                    },
                    {
                        id: "2905",
                        label: "紫阳县",
                        value: "610924",
                        children: []
                    },
                    {
                        id: "2906",
                        label: "岚皋县",
                        value: "610925",
                        children: []
                    },
                    {
                        id: "2907",
                        label: "平利县",
                        value: "610926",
                        children: []
                    },
                    {
                        id: "2908",
                        label: "镇坪县",
                        value: "610927",
                        children: []
                    },
                    {
                        id: "2909",
                        label: "旬阳县",
                        value: "610928",
                        children: []
                    },
                    {
                        id: "2910",
                        label: "白河县",
                        value: "610929",
                        children: []
                    }
                ]
            },
            {
                id: "1324",
                label: "商洛市",
                value: "611000",
                children: [
                    {
                        id: "2911",
                        label: "市辖区",
                        value: "611001",
                        children: []
                    },
                    {
                        id: "2912",
                        label: "商州区",
                        value: "611002",
                        children: []
                    },
                    {
                        id: "2913",
                        label: "洛南县",
                        value: "611021",
                        children: []
                    },
                    {
                        id: "2914",
                        label: "丹凤县",
                        value: "611022",
                        children: []
                    },
                    {
                        id: "2915",
                        label: "商南县",
                        value: "611023",
                        children: []
                    },
                    {
                        id: "2916",
                        label: "山阳县",
                        value: "611024",
                        children: []
                    },
                    {
                        id: "2917",
                        label: "镇安县",
                        value: "611025",
                        children: []
                    },
                    {
                        id: "2918",
                        label: "柞水县",
                        value: "611026",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "26",
        label: "甘肃省",
        value: "620000",
        children: [
            {
                id: "1325",
                label: "兰州市",
                value: "620100",
                children: [
                    {
                        id: "2919",
                        label: "市辖区",
                        value: "620101",
                        children: []
                    },
                    {
                        id: "2920",
                        label: "城关区",
                        value: "620102",
                        children: []
                    },
                    {
                        id: "2921",
                        label: "七里河区",
                        value: "620103",
                        children: []
                    },
                    {
                        id: "2922",
                        label: "西固区",
                        value: "620104",
                        children: []
                    },
                    {
                        id: "2923",
                        label: "安宁区",
                        value: "620105",
                        children: []
                    },
                    {
                        id: "2924",
                        label: "红古区",
                        value: "620111",
                        children: []
                    },
                    {
                        id: "2925",
                        label: "永登县",
                        value: "620121",
                        children: []
                    },
                    {
                        id: "2926",
                        label: "皋兰县",
                        value: "620122",
                        children: []
                    },
                    {
                        id: "2927",
                        label: "榆中县",
                        value: "620123",
                        children: []
                    }
                ]
            },
            {
                id: "1326",
                label: "嘉峪关市",
                value: "620200",
                children: [
                    {
                        id: "2928",
                        label: "市辖区",
                        value: "620201",
                        children: []
                    }
                ]
            },
            {
                id: "1327",
                label: "金昌市",
                value: "620300",
                children: [
                    {
                        id: "2929",
                        label: "市辖区",
                        value: "620301",
                        children: []
                    },
                    {
                        id: "2930",
                        label: "金川区",
                        value: "620302",
                        children: []
                    },
                    {
                        id: "2931",
                        label: "永昌县",
                        value: "620321",
                        children: []
                    }
                ]
            },
            {
                id: "1328",
                label: "白银市",
                value: "620400",
                children: [
                    {
                        id: "2932",
                        label: "市辖区",
                        value: "620401",
                        children: []
                    },
                    {
                        id: "2933",
                        label: "白银区",
                        value: "620402",
                        children: []
                    },
                    {
                        id: "2934",
                        label: "平川区",
                        value: "620403",
                        children: []
                    },
                    {
                        id: "2935",
                        label: "靖远县",
                        value: "620421",
                        children: []
                    },
                    {
                        id: "2936",
                        label: "会宁县",
                        value: "620422",
                        children: []
                    },
                    {
                        id: "2937",
                        label: "景泰县",
                        value: "620423",
                        children: []
                    }
                ]
            },
            {
                id: "1329",
                label: "天水市",
                value: "620500",
                children: [
                    {
                        id: "2938",
                        label: "市辖区",
                        value: "620501",
                        children: []
                    },
                    {
                        id: "2939",
                        label: "秦州区",
                        value: "620502",
                        children: []
                    },
                    {
                        id: "2940",
                        label: "麦积区",
                        value: "620503",
                        children: []
                    },
                    {
                        id: "2941",
                        label: "清水县",
                        value: "620521",
                        children: []
                    },
                    {
                        id: "2942",
                        label: "秦安县",
                        value: "620522",
                        children: []
                    },
                    {
                        id: "2943",
                        label: "甘谷县",
                        value: "620523",
                        children: []
                    },
                    {
                        id: "2944",
                        label: "武山县",
                        value: "620524",
                        children: []
                    },
                    {
                        id: "2945",
                        label: "张家川回族自治县",
                        value: "620525",
                        children: []
                    }
                ]
            },
            {
                id: "1330",
                label: "武威市",
                value: "620600",
                children: [
                    {
                        id: "2946",
                        label: "市辖区",
                        value: "620601",
                        children: []
                    },
                    {
                        id: "2947",
                        label: "凉州区",
                        value: "620602",
                        children: []
                    },
                    {
                        id: "2948",
                        label: "民勤县",
                        value: "620621",
                        children: []
                    },
                    {
                        id: "2949",
                        label: "古浪县",
                        value: "620622",
                        children: []
                    },
                    {
                        id: "2950",
                        label: "天祝藏族自治县",
                        value: "620623",
                        children: []
                    }
                ]
            },
            {
                id: "1331",
                label: "张掖市",
                value: "620700",
                children: [
                    {
                        id: "2951",
                        label: "市辖区",
                        value: "620701",
                        children: []
                    },
                    {
                        id: "2952",
                        label: "甘州区",
                        value: "620702",
                        children: []
                    },
                    {
                        id: "2953",
                        label: "肃南裕固族自治县",
                        value: "620721",
                        children: []
                    },
                    {
                        id: "2954",
                        label: "民乐县",
                        value: "620722",
                        children: []
                    },
                    {
                        id: "2955",
                        label: "临泽县",
                        value: "620723",
                        children: []
                    },
                    {
                        id: "2956",
                        label: "高台县",
                        value: "620724",
                        children: []
                    },
                    {
                        id: "2957",
                        label: "山丹县",
                        value: "620725",
                        children: []
                    }
                ]
            },
            {
                id: "1332",
                label: "平凉市",
                value: "620800",
                children: [
                    {
                        id: "2958",
                        label: "市辖区",
                        value: "620801",
                        children: []
                    },
                    {
                        id: "2959",
                        label: "崆峒区",
                        value: "620802",
                        children: []
                    },
                    {
                        id: "2960",
                        label: "泾川县",
                        value: "620821",
                        children: []
                    },
                    {
                        id: "2961",
                        label: "灵台县",
                        value: "620822",
                        children: []
                    },
                    {
                        id: "2962",
                        label: "崇信县",
                        value: "620823",
                        children: []
                    },
                    {
                        id: "2963",
                        label: "华亭县",
                        value: "620824",
                        children: []
                    },
                    {
                        id: "2964",
                        label: "庄浪县",
                        value: "620825",
                        children: []
                    },
                    {
                        id: "2965",
                        label: "静宁县",
                        value: "620826",
                        children: []
                    }
                ]
            },
            {
                id: "1333",
                label: "酒泉市",
                value: "620900",
                children: [
                    {
                        id: "2966",
                        label: "市辖区",
                        value: "620901",
                        children: []
                    },
                    {
                        id: "2967",
                        label: "肃州区",
                        value: "620902",
                        children: []
                    },
                    {
                        id: "2968",
                        label: "金塔县",
                        value: "620921",
                        children: []
                    },
                    {
                        id: "2969",
                        label: "瓜州县",
                        value: "620922",
                        children: []
                    },
                    {
                        id: "2970",
                        label: "肃北蒙古族自治县",
                        value: "620923",
                        children: []
                    },
                    {
                        id: "2971",
                        label: "阿克塞哈萨克族自治县",
                        value: "620924",
                        children: []
                    },
                    {
                        id: "2972",
                        label: "玉门市",
                        value: "620981",
                        children: []
                    },
                    {
                        id: "2973",
                        label: "敦煌市",
                        value: "620982",
                        children: []
                    }
                ]
            },
            {
                id: "1334",
                label: "庆阳市",
                value: "621000",
                children: [
                    {
                        id: "2974",
                        label: "市辖区",
                        value: "621001",
                        children: []
                    },
                    {
                        id: "2975",
                        label: "西峰区",
                        value: "621002",
                        children: []
                    },
                    {
                        id: "2976",
                        label: "庆城县",
                        value: "621021",
                        children: []
                    },
                    {
                        id: "2977",
                        label: "环县",
                        value: "621022",
                        children: []
                    },
                    {
                        id: "2978",
                        label: "华池县",
                        value: "621023",
                        children: []
                    },
                    {
                        id: "2979",
                        label: "合水县",
                        value: "621024",
                        children: []
                    },
                    {
                        id: "2980",
                        label: "正宁县",
                        value: "621025",
                        children: []
                    },
                    {
                        id: "2981",
                        label: "宁县",
                        value: "621026",
                        children: []
                    },
                    {
                        id: "2982",
                        label: "镇原县",
                        value: "621027",
                        children: []
                    }
                ]
            },
            {
                id: "1335",
                label: "定西市",
                value: "621100",
                children: [
                    {
                        id: "2983",
                        label: "市辖区",
                        value: "621101",
                        children: []
                    },
                    {
                        id: "2984",
                        label: "安定区",
                        value: "621102",
                        children: []
                    },
                    {
                        id: "2985",
                        label: "通渭县",
                        value: "621121",
                        children: []
                    },
                    {
                        id: "2986",
                        label: "陇西县",
                        value: "621122",
                        children: []
                    },
                    {
                        id: "2987",
                        label: "渭源县",
                        value: "621123",
                        children: []
                    },
                    {
                        id: "2988",
                        label: "临洮县",
                        value: "621124",
                        children: []
                    },
                    {
                        id: "2989",
                        label: "漳县",
                        value: "621125",
                        children: []
                    },
                    {
                        id: "2990",
                        label: "岷县",
                        value: "621126",
                        children: []
                    }
                ]
            },
            {
                id: "1336",
                label: "陇南市",
                value: "621200",
                children: [
                    {
                        id: "2991",
                        label: "市辖区",
                        value: "621201",
                        children: []
                    },
                    {
                        id: "2992",
                        label: "武都区",
                        value: "621202",
                        children: []
                    },
                    {
                        id: "2993",
                        label: "成县",
                        value: "621221",
                        children: []
                    },
                    {
                        id: "2994",
                        label: "文县",
                        value: "621222",
                        children: []
                    },
                    {
                        id: "2995",
                        label: "宕昌县",
                        value: "621223",
                        children: []
                    },
                    {
                        id: "2996",
                        label: "康县",
                        value: "621224",
                        children: []
                    },
                    {
                        id: "2997",
                        label: "西和县",
                        value: "621225",
                        children: []
                    },
                    {
                        id: "2998",
                        label: "礼县",
                        value: "621226",
                        children: []
                    },
                    {
                        id: "2999",
                        label: "徽县",
                        value: "621227",
                        children: []
                    },
                    {
                        id: "3000",
                        label: "两当县",
                        value: "621228",
                        children: []
                    }
                ]
            },
            {
                id: "1337",
                label: "临夏回族自治州",
                value: "622900",
                children: [
                    {
                        id: "3001",
                        label: "临夏市",
                        value: "622901",
                        children: []
                    },
                    {
                        id: "3002",
                        label: "临夏县",
                        value: "622921",
                        children: []
                    },
                    {
                        id: "3003",
                        label: "康乐县",
                        value: "622922",
                        children: []
                    },
                    {
                        id: "3004",
                        label: "永靖县",
                        value: "622923",
                        children: []
                    },
                    {
                        id: "3005",
                        label: "广河县",
                        value: "622924",
                        children: []
                    },
                    {
                        id: "3006",
                        label: "和政县",
                        value: "622925",
                        children: []
                    },
                    {
                        id: "3007",
                        label: "东乡族自治县",
                        value: "622926",
                        children: []
                    },
                    {
                        id: "3008",
                        label: "积石山保安族东乡族撒拉族自治县",
                        value: "622927",
                        children: []
                    }
                ]
            },
            {
                id: "1338",
                label: "甘南藏族自治州",
                value: "623000",
                children: [
                    {
                        id: "3009",
                        label: "合作市",
                        value: "623001",
                        children: []
                    },
                    {
                        id: "3010",
                        label: "临潭县",
                        value: "623021",
                        children: []
                    },
                    {
                        id: "3011",
                        label: "卓尼县",
                        value: "623022",
                        children: []
                    },
                    {
                        id: "3012",
                        label: "舟曲县",
                        value: "623023",
                        children: []
                    },
                    {
                        id: "3013",
                        label: "迭部县",
                        value: "623024",
                        children: []
                    },
                    {
                        id: "3014",
                        label: "玛曲县",
                        value: "623025",
                        children: []
                    },
                    {
                        id: "3015",
                        label: "碌曲县",
                        value: "623026",
                        children: []
                    },
                    {
                        id: "3016",
                        label: "夏河县",
                        value: "623027",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "27",
        label: "宁夏回族自治区",
        value: "640000",
        children: [
            {
                id: "1347",
                label: "银川市",
                value: "640100",
                children: [
                    {
                        id: "3061",
                        label: "市辖区",
                        value: "640101",
                        children: []
                    },
                    {
                        id: "3062",
                        label: "兴庆区",
                        value: "640104",
                        children: []
                    },
                    {
                        id: "3063",
                        label: "西夏区",
                        value: "640105",
                        children: []
                    },
                    {
                        id: "3064",
                        label: "金凤区",
                        value: "640106",
                        children: []
                    },
                    {
                        id: "3065",
                        label: "永宁县",
                        value: "640121",
                        children: []
                    },
                    {
                        id: "3066",
                        label: "贺兰县",
                        value: "640122",
                        children: []
                    },
                    {
                        id: "3067",
                        label: "灵武市",
                        value: "640181",
                        children: []
                    }
                ]
            },
            {
                id: "1348",
                label: "石嘴山市",
                value: "640200",
                children: [
                    {
                        id: "3068",
                        label: "市辖区",
                        value: "640201",
                        children: []
                    },
                    {
                        id: "3069",
                        label: "大武口区",
                        value: "640202",
                        children: []
                    },
                    {
                        id: "3070",
                        label: "惠农区",
                        value: "640205",
                        children: []
                    },
                    {
                        id: "3071",
                        label: "平罗县",
                        value: "640221",
                        children: []
                    }
                ]
            },
            {
                id: "1349",
                label: "吴忠市",
                value: "640300",
                children: [
                    {
                        id: "3072",
                        label: "市辖区",
                        value: "640301",
                        children: []
                    },
                    {
                        id: "3073",
                        label: "利通区",
                        value: "640302",
                        children: []
                    },
                    {
                        id: "3074",
                        label: "红寺堡区",
                        value: "640303",
                        children: []
                    },
                    {
                        id: "3075",
                        label: "盐池县",
                        value: "640323",
                        children: []
                    },
                    {
                        id: "3076",
                        label: "同心县",
                        value: "640324",
                        children: []
                    },
                    {
                        id: "3077",
                        label: "青铜峡市",
                        value: "640381",
                        children: []
                    }
                ]
            },
            {
                id: "1350",
                label: "固原市",
                value: "640400",
                children: [
                    {
                        id: "3078",
                        label: "市辖区",
                        value: "640401",
                        children: []
                    },
                    {
                        id: "3079",
                        label: "原州区",
                        value: "640402",
                        children: []
                    },
                    {
                        id: "3080",
                        label: "西吉县",
                        value: "640422",
                        children: []
                    },
                    {
                        id: "3081",
                        label: "隆德县",
                        value: "640423",
                        children: []
                    },
                    {
                        id: "3082",
                        label: "泾源县",
                        value: "640424",
                        children: []
                    },
                    {
                        id: "3083",
                        label: "彭阳县",
                        value: "640425",
                        children: []
                    }
                ]
            },
            {
                id: "1351",
                label: "中卫市",
                value: "640500",
                children: [
                    {
                        id: "3084",
                        label: "市辖区",
                        value: "640501",
                        children: []
                    },
                    {
                        id: "3085",
                        label: "沙坡头区",
                        value: "640502",
                        children: []
                    },
                    {
                        id: "3086",
                        label: "中宁县",
                        value: "640521",
                        children: []
                    },
                    {
                        id: "3087",
                        label: "海原县",
                        value: "640522",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "28",
        label: "青海省",
        value: "630000",
        children: [
            {
                id: "1339",
                label: "西宁市",
                value: "630100",
                children: [
                    {
                        id: "3017",
                        label: "市辖区",
                        value: "630101",
                        children: []
                    },
                    {
                        id: "3018",
                        label: "城东区",
                        value: "630102",
                        children: []
                    },
                    {
                        id: "3019",
                        label: "城中区",
                        value: "630103",
                        children: []
                    },
                    {
                        id: "3020",
                        label: "城西区",
                        value: "630104",
                        children: []
                    },
                    {
                        id: "3021",
                        label: "城北区",
                        value: "630105",
                        children: []
                    },
                    {
                        id: "3022",
                        label: "大通回族土族自治县",
                        value: "630121",
                        children: []
                    },
                    {
                        id: "3023",
                        label: "湟中县",
                        value: "630122",
                        children: []
                    },
                    {
                        id: "3024",
                        label: "湟源县",
                        value: "630123",
                        children: []
                    }
                ]
            },
            {
                id: "1340",
                label: "海东地区",
                value: "632100",
                children: [
                    {
                        id: "3025",
                        label: "平安县",
                        value: "632121",
                        children: []
                    },
                    {
                        id: "3026",
                        label: "民和回族土族自治县",
                        value: "632122",
                        children: []
                    },
                    {
                        id: "3027",
                        label: "乐都县",
                        value: "632123",
                        children: []
                    },
                    {
                        id: "3028",
                        label: "互助土族自治县",
                        value: "632126",
                        children: []
                    },
                    {
                        id: "3029",
                        label: "化隆回族自治县",
                        value: "632127",
                        children: []
                    },
                    {
                        id: "3030",
                        label: "循化撒拉族自治县",
                        value: "632128",
                        children: []
                    }
                ]
            },
            {
                id: "1341",
                label: "海北藏族自治州",
                value: "632200",
                children: [
                    {
                        id: "3031",
                        label: "门源回族自治县",
                        value: "632221",
                        children: []
                    },
                    {
                        id: "3032",
                        label: "祁连县",
                        value: "632222",
                        children: []
                    },
                    {
                        id: "3033",
                        label: "海晏县",
                        value: "632223",
                        children: []
                    },
                    {
                        id: "3034",
                        label: "刚察县",
                        value: "632224",
                        children: []
                    }
                ]
            },
            {
                id: "1342",
                label: "黄南藏族自治州",
                value: "632300",
                children: [
                    {
                        id: "3035",
                        label: "同仁县",
                        value: "632321",
                        children: []
                    },
                    {
                        id: "3036",
                        label: "尖扎县",
                        value: "632322",
                        children: []
                    },
                    {
                        id: "3037",
                        label: "泽库县",
                        value: "632323",
                        children: []
                    },
                    {
                        id: "3038",
                        label: "河南蒙古族自治县",
                        value: "632324",
                        children: []
                    }
                ]
            },
            {
                id: "1343",
                label: "海南藏族自治州",
                value: "632500",
                children: [
                    {
                        id: "3039",
                        label: "共和县",
                        value: "632521",
                        children: []
                    },
                    {
                        id: "3040",
                        label: "同德县",
                        value: "632522",
                        children: []
                    },
                    {
                        id: "3041",
                        label: "贵德县",
                        value: "632523",
                        children: []
                    },
                    {
                        id: "3042",
                        label: "兴海县",
                        value: "632524",
                        children: []
                    },
                    {
                        id: "3043",
                        label: "贵南县",
                        value: "632525",
                        children: []
                    }
                ]
            },
            {
                id: "1344",
                label: "果洛藏族自治州",
                value: "632600",
                children: [
                    {
                        id: "3044",
                        label: "玛沁县",
                        value: "632621",
                        children: []
                    },
                    {
                        id: "3045",
                        label: "班玛县",
                        value: "632622",
                        children: []
                    },
                    {
                        id: "3046",
                        label: "甘德县",
                        value: "632623",
                        children: []
                    },
                    {
                        id: "3047",
                        label: "达日县",
                        value: "632624",
                        children: []
                    },
                    {
                        id: "3048",
                        label: "久治县",
                        value: "632625",
                        children: []
                    },
                    {
                        id: "3049",
                        label: "玛多县",
                        value: "632626",
                        children: []
                    }
                ]
            },
            {
                id: "1345",
                label: "玉树藏族自治州",
                value: "632700",
                children: [
                    {
                        id: "3050",
                        label: "玉树县",
                        value: "632721",
                        children: []
                    },
                    {
                        id: "3051",
                        label: "杂多县",
                        value: "632722",
                        children: []
                    },
                    {
                        id: "3052",
                        label: "称多县",
                        value: "632723",
                        children: []
                    },
                    {
                        id: "3053",
                        label: "治多县",
                        value: "632724",
                        children: []
                    },
                    {
                        id: "3054",
                        label: "囊谦县",
                        value: "632725",
                        children: []
                    },
                    {
                        id: "3055",
                        label: "曲麻莱县",
                        value: "632726",
                        children: []
                    }
                ]
            },
            {
                id: "1346",
                label: "海西蒙古族藏族自治州",
                value: "632800",
                children: [
                    {
                        id: "3056",
                        label: "格尔木市",
                        value: "632801",
                        children: []
                    },
                    {
                        id: "3057",
                        label: "德令哈市",
                        value: "632802",
                        children: []
                    },
                    {
                        id: "3058",
                        label: "乌兰县",
                        value: "632821",
                        children: []
                    },
                    {
                        id: "3059",
                        label: "都兰县",
                        value: "632822",
                        children: []
                    },
                    {
                        id: "3060",
                        label: "天峻县",
                        value: "632823",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "29",
        label: "新疆维吾尔自治区",
        value: "650000",
        children: [
            {
                id: "1352",
                label: "乌鲁木齐市",
                value: "650100",
                children: [
                    {
                        id: "3088",
                        label: "市辖区",
                        value: "650101",
                        children: []
                    },
                    {
                        id: "3089",
                        label: "天山区",
                        value: "650102",
                        children: []
                    },
                    {
                        id: "3090",
                        label: "沙依巴克区",
                        value: "650103",
                        children: []
                    },
                    {
                        id: "3091",
                        label: "新市区",
                        value: "650104",
                        children: []
                    },
                    {
                        id: "3092",
                        label: "水磨沟区",
                        value: "650105",
                        children: []
                    },
                    {
                        id: "3093",
                        label: "头屯河区",
                        value: "650106",
                        children: []
                    },
                    {
                        id: "3094",
                        label: "达坂城区",
                        value: "650107",
                        children: []
                    },
                    {
                        id: "3095",
                        label: "米东区",
                        value: "650109",
                        children: []
                    },
                    {
                        id: "3096",
                        label: "乌鲁木齐县",
                        value: "650121",
                        children: []
                    }
                ]
            },
            {
                id: "1353",
                label: "克拉玛依市",
                value: "650200",
                children: [
                    {
                        id: "3097",
                        label: "市辖区",
                        value: "650201",
                        children: []
                    },
                    {
                        id: "3098",
                        label: "独山子区",
                        value: "650202",
                        children: []
                    },
                    {
                        id: "3099",
                        label: "克拉玛依区",
                        value: "650203",
                        children: []
                    },
                    {
                        id: "3100",
                        label: "白碱滩区",
                        value: "650204",
                        children: []
                    },
                    {
                        id: "3101",
                        label: "乌尔禾区",
                        value: "650205",
                        children: []
                    }
                ]
            },
            {
                id: "1354",
                label: "吐鲁番地区",
                value: "652100",
                children: [
                    {
                        id: "3102",
                        label: "吐鲁番市",
                        value: "652101",
                        children: []
                    },
                    {
                        id: "3103",
                        label: "鄯善县",
                        value: "652122",
                        children: []
                    },
                    {
                        id: "3104",
                        label: "托克逊县",
                        value: "652123",
                        children: []
                    }
                ]
            },
            {
                id: "1355",
                label: "哈密地区",
                value: "652200",
                children: [
                    {
                        id: "3105",
                        label: "哈密市",
                        value: "652201",
                        children: []
                    },
                    {
                        id: "3106",
                        label: "巴里坤哈萨克自治县",
                        value: "652222",
                        children: []
                    },
                    {
                        id: "3107",
                        label: "伊吾县",
                        value: "652223",
                        children: []
                    }
                ]
            },
            {
                id: "1356",
                label: "昌吉回族自治州",
                value: "652300",
                children: [
                    {
                        id: "3108",
                        label: "昌吉市",
                        value: "652301",
                        children: []
                    },
                    {
                        id: "3109",
                        label: "阜康市",
                        value: "652302",
                        children: []
                    },
                    {
                        id: "3110",
                        label: "呼图壁县",
                        value: "652323",
                        children: []
                    },
                    {
                        id: "3111",
                        label: "玛纳斯县",
                        value: "652324",
                        children: []
                    },
                    {
                        id: "3112",
                        label: "奇台县",
                        value: "652325",
                        children: []
                    },
                    {
                        id: "3113",
                        label: "吉木萨尔县",
                        value: "652327",
                        children: []
                    },
                    {
                        id: "3114",
                        label: "木垒哈萨克自治县",
                        value: "652328",
                        children: []
                    }
                ]
            },
            {
                id: "1357",
                label: "博尔塔拉蒙古自治州",
                value: "652700",
                children: [
                    {
                        id: "3115",
                        label: "博乐市",
                        value: "652701",
                        children: []
                    },
                    {
                        id: "3116",
                        label: "精河县",
                        value: "652722",
                        children: []
                    },
                    {
                        id: "3117",
                        label: "温泉县",
                        value: "652723",
                        children: []
                    }
                ]
            },
            {
                id: "1358",
                label: "巴音郭楞蒙古自治州",
                value: "652800",
                children: [
                    {
                        id: "3118",
                        label: "库尔勒市",
                        value: "652801",
                        children: []
                    },
                    {
                        id: "3119",
                        label: "轮台县",
                        value: "652822",
                        children: []
                    },
                    {
                        id: "3120",
                        label: "尉犁县",
                        value: "652823",
                        children: []
                    },
                    {
                        id: "3121",
                        label: "若羌县",
                        value: "652824",
                        children: []
                    },
                    {
                        id: "3122",
                        label: "且末县",
                        value: "652825",
                        children: []
                    },
                    {
                        id: "3123",
                        label: "焉耆回族自治县",
                        value: "652826",
                        children: []
                    },
                    {
                        id: "3124",
                        label: "和静县",
                        value: "652827",
                        children: []
                    },
                    {
                        id: "3125",
                        label: "和硕县",
                        value: "652828",
                        children: []
                    },
                    {
                        id: "3126",
                        label: "博湖县",
                        value: "652829",
                        children: []
                    }
                ]
            },
            {
                id: "1359",
                label: "阿克苏地区",
                value: "652900",
                children: [
                    {
                        id: "3127",
                        label: "阿克苏市",
                        value: "652901",
                        children: []
                    },
                    {
                        id: "3128",
                        label: "温宿县",
                        value: "652922",
                        children: []
                    },
                    {
                        id: "3129",
                        label: "库车县",
                        value: "652923",
                        children: []
                    },
                    {
                        id: "3130",
                        label: "沙雅县",
                        value: "652924",
                        children: []
                    },
                    {
                        id: "3131",
                        label: "新和县",
                        value: "652925",
                        children: []
                    },
                    {
                        id: "3132",
                        label: "拜城县",
                        value: "652926",
                        children: []
                    },
                    {
                        id: "3133",
                        label: "乌什县",
                        value: "652927",
                        children: []
                    },
                    {
                        id: "3134",
                        label: "阿瓦提县",
                        value: "652928",
                        children: []
                    },
                    {
                        id: "3135",
                        label: "柯坪县",
                        value: "652929",
                        children: []
                    }
                ]
            },
            {
                id: "1360",
                label: "克孜勒苏柯尔克孜自治州",
                value: "653000",
                children: [
                    {
                        id: "3136",
                        label: "阿图什市",
                        value: "653001",
                        children: []
                    },
                    {
                        id: "3137",
                        label: "阿克陶县",
                        value: "653022",
                        children: []
                    },
                    {
                        id: "3138",
                        label: "阿合奇县",
                        value: "653023",
                        children: []
                    },
                    {
                        id: "3139",
                        label: "乌恰县",
                        value: "653024",
                        children: []
                    }
                ]
            },
            {
                id: "1361",
                label: "喀什地区",
                value: "653100",
                children: [
                    {
                        id: "3140",
                        label: "喀什市",
                        value: "653101",
                        children: []
                    },
                    {
                        id: "3141",
                        label: "疏附县",
                        value: "653121",
                        children: []
                    },
                    {
                        id: "3142",
                        label: "疏勒县",
                        value: "653122",
                        children: []
                    },
                    {
                        id: "3143",
                        label: "英吉沙县",
                        value: "653123",
                        children: []
                    },
                    {
                        id: "3144",
                        label: "泽普县",
                        value: "653124",
                        children: []
                    },
                    {
                        id: "3145",
                        label: "莎车县",
                        value: "653125",
                        children: []
                    },
                    {
                        id: "3146",
                        label: "叶城县",
                        value: "653126",
                        children: []
                    },
                    {
                        id: "3147",
                        label: "麦盖提县",
                        value: "653127",
                        children: []
                    },
                    {
                        id: "3148",
                        label: "岳普湖县",
                        value: "653128",
                        children: []
                    },
                    {
                        id: "3149",
                        label: "伽师县",
                        value: "653129",
                        children: []
                    },
                    {
                        id: "3150",
                        label: "巴楚县",
                        value: "653130",
                        children: []
                    },
                    {
                        id: "3151",
                        label: "塔什库尔干塔吉克自治县",
                        value: "653131",
                        children: []
                    }
                ]
            },
            {
                id: "1362",
                label: "和田地区",
                value: "653200",
                children: [
                    {
                        id: "3152",
                        label: "和田市",
                        value: "653201",
                        children: []
                    },
                    {
                        id: "3153",
                        label: "和田县",
                        value: "653221",
                        children: []
                    },
                    {
                        id: "3154",
                        label: "墨玉县",
                        value: "653222",
                        children: []
                    },
                    {
                        id: "3155",
                        label: "皮山县",
                        value: "653223",
                        children: []
                    },
                    {
                        id: "3156",
                        label: "洛浦县",
                        value: "653224",
                        children: []
                    },
                    {
                        id: "3157",
                        label: "策勒县",
                        value: "653225",
                        children: []
                    },
                    {
                        id: "3158",
                        label: "于田县",
                        value: "653226",
                        children: []
                    },
                    {
                        id: "3159",
                        label: "民丰县",
                        value: "653227",
                        children: []
                    }
                ]
            },
            {
                id: "1363",
                label: "伊犁哈萨克自治州",
                value: "654000",
                children: [
                    {
                        id: "3160",
                        label: "伊宁市",
                        value: "654002",
                        children: []
                    },
                    {
                        id: "3161",
                        label: "奎屯市",
                        value: "654003",
                        children: []
                    },
                    {
                        id: "3162",
                        label: "伊宁县",
                        value: "654021",
                        children: []
                    },
                    {
                        id: "3163",
                        label: "察布查尔锡伯自治县",
                        value: "654022",
                        children: []
                    },
                    {
                        id: "3164",
                        label: "霍城县",
                        value: "654023",
                        children: []
                    },
                    {
                        id: "3165",
                        label: "巩留县",
                        value: "654024",
                        children: []
                    },
                    {
                        id: "3166",
                        label: "新源县",
                        value: "654025",
                        children: []
                    },
                    {
                        id: "3167",
                        label: "昭苏县",
                        value: "654026",
                        children: []
                    },
                    {
                        id: "3168",
                        label: "特克斯县",
                        value: "654027",
                        children: []
                    },
                    {
                        id: "3169",
                        label: "尼勒克县",
                        value: "654028",
                        children: []
                    }
                ]
            },
            {
                id: "1364",
                label: "塔城地区",
                value: "654200",
                children: [
                    {
                        id: "3170",
                        label: "塔城市",
                        value: "654201",
                        children: []
                    },
                    {
                        id: "3171",
                        label: "乌苏市",
                        value: "654202",
                        children: []
                    },
                    {
                        id: "3172",
                        label: "额敏县",
                        value: "654221",
                        children: []
                    },
                    {
                        id: "3173",
                        label: "沙湾县",
                        value: "654223",
                        children: []
                    },
                    {
                        id: "3174",
                        label: "托里县",
                        value: "654224",
                        children: []
                    },
                    {
                        id: "3175",
                        label: "裕民县",
                        value: "654225",
                        children: []
                    },
                    {
                        id: "3176",
                        label: "和布克赛尔蒙古自治县",
                        value: "654226",
                        children: []
                    }
                ]
            },
            {
                id: "1365",
                label: "阿勒泰地区",
                value: "654300",
                children: [
                    {
                        id: "3177",
                        label: "阿勒泰市",
                        value: "654301",
                        children: []
                    },
                    {
                        id: "3178",
                        label: "布尔津县",
                        value: "654321",
                        children: []
                    },
                    {
                        id: "3179",
                        label: "富蕴县",
                        value: "654322",
                        children: []
                    },
                    {
                        id: "3180",
                        label: "福海县",
                        value: "654323",
                        children: []
                    },
                    {
                        id: "3181",
                        label: "哈巴河县",
                        value: "654324",
                        children: []
                    },
                    {
                        id: "3182",
                        label: "青河县",
                        value: "654325",
                        children: []
                    },
                    {
                        id: "3183",
                        label: "吉木乃县",
                        value: "654326",
                        children: []
                    }
                ]
            },
            {
                id: "1366",
                label: "自治区直辖县级行政区划",
                value: "659000",
                children: [
                    {
                        id: "3184",
                        label: "石河子市",
                        value: "659001",
                        children: []
                    },
                    {
                        id: "3185",
                        label: "阿拉尔市",
                        value: "659002",
                        children: []
                    },
                    {
                        id: "3186",
                        label: "图木舒克市",
                        value: "659003",
                        children: []
                    },
                    {
                        id: "3187",
                        label: "五家渠市",
                        value: "659004",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "30",
        label: "西藏自治区",
        value: "540000",
        children: [
            {
                id: "1308",
                label: "拉萨市",
                value: "540100",
                children: [
                    {
                        id: "2728",
                        label: "市辖区",
                        value: "540101",
                        children: []
                    },
                    {
                        id: "2729",
                        label: "城关区",
                        value: "540102",
                        children: []
                    },
                    {
                        id: "2730",
                        label: "林周县",
                        value: "540121",
                        children: []
                    },
                    {
                        id: "2731",
                        label: "当雄县",
                        value: "540122",
                        children: []
                    },
                    {
                        id: "2732",
                        label: "尼木县",
                        value: "540123",
                        children: []
                    },
                    {
                        id: "2733",
                        label: "曲水县",
                        value: "540124",
                        children: []
                    },
                    {
                        id: "2734",
                        label: "堆龙德庆县",
                        value: "540125",
                        children: []
                    },
                    {
                        id: "2735",
                        label: "达孜县",
                        value: "540126",
                        children: []
                    },
                    {
                        id: "2736",
                        label: "墨竹工卡县",
                        value: "540127",
                        children: []
                    }
                ]
            },
            {
                id: "1309",
                label: "昌都地区",
                value: "542100",
                children: [
                    {
                        id: "2737",
                        label: "昌都县",
                        value: "542121",
                        children: []
                    },
                    {
                        id: "2738",
                        label: "江达县",
                        value: "542122",
                        children: []
                    },
                    {
                        id: "2739",
                        label: "贡觉县",
                        value: "542123",
                        children: []
                    },
                    {
                        id: "2740",
                        label: "类乌齐县",
                        value: "542124",
                        children: []
                    },
                    {
                        id: "2741",
                        label: "丁青县",
                        value: "542125",
                        children: []
                    },
                    {
                        id: "2742",
                        label: "察雅县",
                        value: "542126",
                        children: []
                    },
                    {
                        id: "2743",
                        label: "八宿县",
                        value: "542127",
                        children: []
                    },
                    {
                        id: "2744",
                        label: "左贡县",
                        value: "542128",
                        children: []
                    },
                    {
                        id: "2745",
                        label: "芒康县",
                        value: "542129",
                        children: []
                    },
                    {
                        id: "2746",
                        label: "洛隆县",
                        value: "542132",
                        children: []
                    },
                    {
                        id: "2747",
                        label: "边坝县",
                        value: "542133",
                        children: []
                    }
                ]
            },
            {
                id: "1310",
                label: "山南地区",
                value: "542200",
                children: [
                    {
                        id: "2748",
                        label: "乃东县",
                        value: "542221",
                        children: []
                    },
                    {
                        id: "2749",
                        label: "扎囊县",
                        value: "542222",
                        children: []
                    },
                    {
                        id: "2750",
                        label: "贡嘎县",
                        value: "542223",
                        children: []
                    },
                    {
                        id: "2751",
                        label: "桑日县",
                        value: "542224",
                        children: []
                    },
                    {
                        id: "2752",
                        label: "琼结县",
                        value: "542225",
                        children: []
                    },
                    {
                        id: "2753",
                        label: "曲松县",
                        value: "542226",
                        children: []
                    },
                    {
                        id: "2754",
                        label: "措美县",
                        value: "542227",
                        children: []
                    },
                    {
                        id: "2755",
                        label: "洛扎县",
                        value: "542228",
                        children: []
                    },
                    {
                        id: "2756",
                        label: "加查县",
                        value: "542229",
                        children: []
                    },
                    {
                        id: "2757",
                        label: "隆子县",
                        value: "542231",
                        children: []
                    },
                    {
                        id: "2758",
                        label: "错那县",
                        value: "542232",
                        children: []
                    },
                    {
                        id: "2759",
                        label: "浪卡子县",
                        value: "542233",
                        children: []
                    }
                ]
            },
            {
                id: "1311",
                label: "日喀则地区",
                value: "542300",
                children: [
                    {
                        id: "2760",
                        label: "日喀则市",
                        value: "542301",
                        children: []
                    },
                    {
                        id: "2761",
                        label: "南木林县",
                        value: "542322",
                        children: []
                    },
                    {
                        id: "2762",
                        label: "江孜县",
                        value: "542323",
                        children: []
                    },
                    {
                        id: "2763",
                        label: "定日县",
                        value: "542324",
                        children: []
                    },
                    {
                        id: "2764",
                        label: "萨迦县",
                        value: "542325",
                        children: []
                    },
                    {
                        id: "2765",
                        label: "拉孜县",
                        value: "542326",
                        children: []
                    },
                    {
                        id: "2766",
                        label: "昂仁县",
                        value: "542327",
                        children: []
                    },
                    {
                        id: "2767",
                        label: "谢通门县",
                        value: "542328",
                        children: []
                    },
                    {
                        id: "2768",
                        label: "白朗县",
                        value: "542329",
                        children: []
                    },
                    {
                        id: "2769",
                        label: "仁布县",
                        value: "542330",
                        children: []
                    },
                    {
                        id: "2770",
                        label: "康马县",
                        value: "542331",
                        children: []
                    },
                    {
                        id: "2771",
                        label: "定结县",
                        value: "542332",
                        children: []
                    },
                    {
                        id: "2772",
                        label: "仲巴县",
                        value: "542333",
                        children: []
                    },
                    {
                        id: "2773",
                        label: "亚东县",
                        value: "542334",
                        children: []
                    },
                    {
                        id: "2774",
                        label: "吉隆县",
                        value: "542335",
                        children: []
                    },
                    {
                        id: "2775",
                        label: "聂拉木县",
                        value: "542336",
                        children: []
                    },
                    {
                        id: "2776",
                        label: "萨嘎县",
                        value: "542337",
                        children: []
                    },
                    {
                        id: "2777",
                        label: "岗巴县",
                        value: "542338",
                        children: []
                    }
                ]
            },
            {
                id: "1312",
                label: "那曲地区",
                value: "542400",
                children: [
                    {
                        id: "2778",
                        label: "那曲县",
                        value: "542421",
                        children: []
                    },
                    {
                        id: "2779",
                        label: "嘉黎县",
                        value: "542422",
                        children: []
                    },
                    {
                        id: "2780",
                        label: "比如县",
                        value: "542423",
                        children: []
                    },
                    {
                        id: "2781",
                        label: "聂荣县",
                        value: "542424",
                        children: []
                    },
                    {
                        id: "2782",
                        label: "安多县",
                        value: "542425",
                        children: []
                    },
                    {
                        id: "2783",
                        label: "申扎县",
                        value: "542426",
                        children: []
                    },
                    {
                        id: "2784",
                        label: "索县",
                        value: "542427",
                        children: []
                    },
                    {
                        id: "2785",
                        label: "班戈县",
                        value: "542428",
                        children: []
                    },
                    {
                        id: "2786",
                        label: "巴青县",
                        value: "542429",
                        children: []
                    },
                    {
                        id: "2787",
                        label: "尼玛县",
                        value: "542430",
                        children: []
                    }
                ]
            },
            {
                id: "1313",
                label: "阿里地区",
                value: "542500",
                children: [
                    {
                        id: "2788",
                        label: "普兰县",
                        value: "542521",
                        children: []
                    },
                    {
                        id: "2789",
                        label: "札达县",
                        value: "542522",
                        children: []
                    },
                    {
                        id: "2790",
                        label: "噶尔县",
                        value: "542523",
                        children: []
                    },
                    {
                        id: "2791",
                        label: "日土县",
                        value: "542524",
                        children: []
                    },
                    {
                        id: "2792",
                        label: "革吉县",
                        value: "542525",
                        children: []
                    },
                    {
                        id: "2793",
                        label: "改则县",
                        value: "542526",
                        children: []
                    },
                    {
                        id: "2794",
                        label: "措勤县",
                        value: "542527",
                        children: []
                    }
                ]
            },
            {
                id: "1314",
                label: "林芝地区",
                value: "542600",
                children: [
                    {
                        id: "2795",
                        label: "林芝县",
                        value: "542621",
                        children: []
                    },
                    {
                        id: "2796",
                        label: "工布江达县",
                        value: "542622",
                        children: []
                    },
                    {
                        id: "2797",
                        label: "米林县",
                        value: "542623",
                        children: []
                    },
                    {
                        id: "2798",
                        label: "墨脱县",
                        value: "542624",
                        children: []
                    },
                    {
                        id: "2799",
                        label: "波密县",
                        value: "542625",
                        children: []
                    },
                    {
                        id: "2800",
                        label: "察隅县",
                        value: "542626",
                        children: []
                    },
                    {
                        id: "2801",
                        label: "朗县",
                        value: "542627",
                        children: []
                    }
                ]
            }
        ]
    },
    {
        id: "31",
        label: "台湾省",
        value: "710000",
        children: []
    },
    {
        id: "32",
        label: "香港特别行政区",
        value: "810000",
        children: []
    },
    {
        id: "33",
        label: "澳门特别行政区",
        value: "820000",
        children: []
    },
    {
        id: "37",
        label: "外籍",
        value: "998",
        children: [
            {
                id: "1367",
                label: "外籍",
                value: "WJ",
                children: [
                    {
                        id: "3188",
                        label: "外籍",
                        value: "WJ",
                        children: []
                    }
                ]
            }
        ]
    }
];


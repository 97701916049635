

const ExternalRecAdoption = () => {


    return (
        <div>

        </div>
    )
}

export default ExternalRecAdoption


// 自定义字符计数策略：将 emoji 计为 1 个字符
const emojiCountStrategy = (value) => {
    return Array.from(value).length;
};

// 自定义超出 max 的字符裁剪逻辑
const customExceedFormatter = (value, config) => {
    if (value.length > config.max) {
        return `${value.slice(0, config.max)}`; // 裁剪并加上省略号
    }
    return value;
};



const isEmptyObj = (obj) => {
    return obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);
};

const isEmptyArray = (arr) => {
    return arr == null || (Array.isArray(arr) && arr.length === 0);
};


export {isEmptyObj, isEmptyArray, customExceedFormatter, emojiCountStrategy };
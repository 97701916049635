import {Badge, Flex, Menu} from "antd";
import {
    QuestionCircleOutlined,
    StarOutlined,
    WarningOutlined
} from "@ant-design/icons";
import React from "react";
import {useNavigate} from "react-router-dom";

const items = [
    {
        key: "/message",
        label: (
            <Flex justify="space-between">
                <span>
                  <QuestionCircleOutlined/>
                  <span>Message</span>
                </span>
                <span><Badge count={10} style={{
                    backgroundColor: '#52c41a',
                }} /></span>
            </Flex>
        )
    },
    {
        key: "/user/saved",
        label: (
            <Flex justify="space-between">
                <span>
                  <StarOutlined/>
                  <span>Saved</span>
                </span>
                <span><Badge count={10} style={{
                    backgroundColor: '#52c41a',
                }} /></span>
            </Flex>
        ),
    },
    {
        key: "/report",
        label: (
            <Flex justify="space-between">
                <span>
                  <WarningOutlined/>
                  <span>report</span>
                </span>
                <span><Badge count={0}  /></span>
            </Flex>
        )
    },
]

const SideBar = (props) => {

    const navigate =  useNavigate()

    const handleMenuClick = (e) => {
        navigate(e.key)
    }

    return (
            <Menu
                style={{height: '100%', borderRight: 0}}
                onClick={handleMenuClick}
                items={items}
            >
            </Menu>
    )
}
export default SideBar
import React from "react"
import {useNavigate} from "react-router-dom"
import { Button, Modal, Typography} from "antd"
import {useDispatch} from "react-redux"
import {clearUserInfoAndToken} from "@/store/modules/user";
import {ExclamationCircleOutlined} from "@ant-design/icons";


const {confirm} = Modal;
const {Title} = Typography

const Logout = () => {

    const dispatch = useDispatch()
    const navigator = useNavigate()

    const logoutHandler = () => {
        dispatch(clearUserInfoAndToken())
        navigator("/")
    }

    const showConfirm = () => {
        confirm({
            icon: <ExclamationCircleOutlined/>,
            content: <Title level={5}>are you sure?</Title>,
            onOk() {
                logoutHandler();
            },
            onCancel() {

            },
        });
    };


    return (
            <Button type={'text'} onClick={showConfirm}>Logout</Button>
    );
}

export default Logout;

import React, {useState} from 'react';
import { FileAddOutlined, PlusOutlined,} from '@ant-design/icons';
import {Button, Flex } from 'antd';
import Title from "antd/es/typography/Title";




const QuickFilingDashboard = () => {


    return (
        <Flex vertical={true} justify={"center"} align="center" style={{paddingTop: 75}} >
            <div style={{fontSize: 48}}> <FileAddOutlined /></div>
            <Title level={5}>Create your first profile to get started!</Title>
            <br/>
            <Button icon={<PlusOutlined/>} iconPosition={"start"} href={'/quickfiling/profile'}> Create profile </Button>
        </Flex>
    );
};
export default QuickFilingDashboard;


import { createSlice } from '@reduxjs/toolkit'
import {localStorageSetToken,
    localStorageGetToken,
    localStorageRemoveToken } from '@/utils'


const userStore = createSlice({
    name: "user",
    // 数据状态
    initialState: {
        token: localStorageGetToken() || '',
        userInfo: null,
        showLoginModal: false,
    },
    // 同步修改方法
    reducers: {
        setShowLoginModal (state, action) {
            state.showLoginModal = action.payload
        },
        setToken (state, action) {
            state.token = action.payload
            localStorageSetToken(action.payload)
        },
        setUserInfo (state, action) {
            state.userInfo = action.payload
        },
        clearUserInfoAndToken (state) {
            state.token = ''
            state.isLogin = false
            state.userInfo = {}
            localStorageRemoveToken()
        }
    }
})


const { setShowLoginModal, setToken, setUserInfo, clearUserInfoAndToken } = userStore.actions

const userReducer = userStore.reducer

export { clearUserInfoAndToken, setToken, setUserInfo, setShowLoginModal}

export default userReducer
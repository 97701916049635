import React, {useEffect, useState} from 'react';
import {Card, Button, Avatar, Flex, Typography, Divider} from 'antd';
import {
    LikeOutlined,
    CommentOutlined,
    SendOutlined,
    CloseOutlined, LikeFilled, StarOutlined, StarFilled
} from '@ant-design/icons';
import "./index.scss"
import Comments from "@/components/communityComp/comment";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {default_avatar_png} from '@/data/data'
import TimeDisplay from "src/components/communityComp/timeDisplay";
import {addQuestionLikes, checkLikedApi, checkSavedApi, getQuestionLikesCount, savePostItem} from "@/apis/question";
import PdTimeline from "@/components/communityComp/post/pdTimeline";


const {Meta} = Card;
const {Paragraph, Text} = Typography;


const Post = ({hasSave, closeButton, post}) => {

    const [visible, setVisible] = useState(true);
    const [showComments, setShowComments] = useState(false);
    const [like, setLike] = useState(false);
    const [save, setSave] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [expanded, setExpanded] = useState(false);



    useEffect(() => {
        const data = {
            question_id: post.id,
            author_id: post.authorID,
        }
        getQuestionLikesCount(data).then(
            (response) => {
                if (response.code === 0) {
                    setLikeCount(response.data.count);
                }
            }
        ).catch(
            (error) => {
                console.log(error)
            }
        )
    }, []);



    const handleClose = () => {
        setVisible(false);
    };


    const purePost = DOMPurify.sanitize(post.content)

    useEffect(() => {
        checkLiked();
        checkSaved();
    }, []);

    const toggleComments = () => {
        setShowComments(!showComments);
    };


    const handleSaveClick = () => {
        let bookmark = true;
        if (save) {
            bookmark = false
        }
        const data = {
            object_id: post.id,
            group_id: post.authorID,
            bookmark: bookmark,
        }
        savePostItem(data).then(r => {

        })
        setSave(!save)
    }

    const handleLikeClick = () => {
        let status = true;
        if (like) {
            status = false
        }
        const data = {
            question_id: post.id,
            author_id: post.authorID,
            status: status,
        }
        addQuestionLikes(data).then(r => {

        })
        setLike(!like)
    }

    const checkLiked = () => {
        checkLikedApi(post.id).then(
            (response) => {
                if (response.code === 0) {
                    setLike(response.data.liked);
                }
            }
        ).catch(
            (err) => {
                console.log(err)
            }
        )
    }

    const checkSaved = async () => {
        checkSavedApi(post.id).then(
            (response) => {
                if (response.code === 0) {
                    const dataMap = new Map(Object.entries(response.data));
                    setSave(dataMap.get(post.id));
                }
            }
        ).catch(
            (err) => {
                console.log(err)
            }
        )
    }

    if (!visible) return null;


    return (

        <Card style={{marginBottom: 8}}>
            <Meta style={{marginBottom: '10px'}}
                  avatar={<Avatar size={50} shape={"square"}
                                  src={post.authorInfo.avatar || default_avatar_png}/>}
                  title={
                      <Flex justify={'space-between'} align={'center'}>
                          {post.authorInfo.username || "unDefine"}
                          {closeButton &&
                              <Button onClick={handleClose}
                                      icon={<CloseOutlined/>}
                                      type={'text'} size="small"/>}
                      </Flex>
                  }
                  description={<div>
                      <Flex justify={'space-between'}>
                          <Text style={{
                              color: "#8f9595",
                              fontSize: "0.8rem"
                          }}>{post.authorInfo.description || "no introduction"}</Text>
                          <TimeDisplay timestamp={post.created_at}></TimeDisplay>
                      </Flex>
                  </div>}
            />

            {post.post_type === 3 ?
                <PdTimeline post={post}/> :
                <Paragraph
                    className={'post-content '}
                    ellipsis={
                        {
                            rows: 15,
                            expandable: true,
                            symbol: 'more',
                            expanded,
                            onExpand: (_, info) => setExpanded(info.expanded),

                        }
                    }
                >
                    {parse(purePost)}
                </Paragraph>
            }
            <Divider style={{margin: '0.5rem 5px'}}/>

            <Flex justify={'space-evenly'} align={'middle'} className={'post-button'}>
                <Button size={'small'} type={"text"} block={true}
                        onClick={handleLikeClick}
                        icon={like ? <LikeFilled/> : <LikeOutlined/>}>
                    {likeCount} {like ? <span>Liked</span> : <span>Like</span>}
                </Button>
                <Button size={'small'} type="text" block={true}
                        onClick={handleSaveClick}
                        icon={save ? <StarFilled/> : <StarOutlined/>}>
                    {save ? <span>Saved</span> : <span>Save</span>}
                </Button>
                <Button size={'small'} type="text" block={true}
                        icon={<CommentOutlined/>} onClick={toggleComments}>
                    Comment
                </Button>
                <Button size={'small'} type="text" block={true}
                        icon={<SendOutlined/>}>
                    Send
                </Button>
            </Flex>
            {showComments && (<Comments qid={post.id}/>)}
        </Card>


    )


};


export default Post;


import React, {useState} from 'react'
import './index.css'
import {Button, Card, Descriptions, Empty, Input, Steps, Typography} from "antd";
import {checkCaseApi} from "@/apis/question";
import parse from 'html-react-parser';

const {Search} = Input;
const {Title} = Typography;


const Tracker = () => {

    const [caseResp, setCaseResp] = React.useState({})
    const [historyInfo, setHistoryInfo] = React.useState([])
    const [empty, setEmpty] = useState(false);
    const onSearch = (value) => {
        // console.log("onSearch: ", value)
        checkCaseApi(value).then(response => {
            // console.log(response)
            setCaseResp(response)
            setEmpty(response.code !== 0 )
            if (response.code === 0 ) {
                setHistoryInfo(  [ {
                    title: 'Finished',
                    description: <div>{parse(response.data.CaseStatusResponse.detailsEng.actionCodeDesc)}</div>

                }])
            }
        }).catch(
            err => {
                console.log(err)
            }
        )
    }


    return (
        <div className="mtop container8">
            <div style={{marginBottom: '20px'}}>
                <h2>check status</h2>
            </div>

            <Card hoverable={true} bordered={false}>
                <Search
                    placeholder="please input your receive number"
                    allowClear
                    enterButton="Check"
                    size="large"
                    onSearch={onSearch}
                />
                {caseResp.code === 0 ?
                    <div>
                        <Descriptions  bordered className={'mtop'}
                                      items={
                                        [
                                            {
                                                key: '1',
                                                label: 'Form Type',
                                                children: caseResp.data.CaseStatusResponse.detailsEng.formNum,
                                            },
                                            {
                                                key: '2',
                                                label: 'Form Title',
                                                children: caseResp.data.CaseStatusResponse.detailsEng.formTitle,
                                            },
                                        ]
                                      } />
                        <div className={'flexCenter mtop'}>
                            <Title level={3}>Result: {caseResp.data.CaseStatusResponse.detailsEng.actionCodeText}</Title>
                        </div>

                        <Steps
                            progressDot
                            current={historyInfo.length}
                            direction="vertical"
                            items={historyInfo}
                        />
                    </div> : null}
                {empty && <Empty description={"please try again later"} />}

            </Card>


        </div>
    )
}

export default Tracker